<template>
  <div class="h-100">
    <div class="search">
      <search :o="list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header" style="height: 45px">
      <div class="d-flex actions my-auto mr-2">
        <!-- <div title="Create group" class="btn btn-outline-secondary" v-on:click="createGroup()">
          <i class="fas fa-users"></i>
          <span>Create group</span>
        </div> -->
      </div>
      <div class="flex-grow-1 my-auto text-right">
        <span v-text="records()"></span>
      </div>
    </div>
    <template v-if="ready">
      <scroll-area class="pr-3">
        <table class="table table-sm">
          <thead>
            <tr>
              <th></th>
              <th>
                <span>Group name</span>
              </th>
              <th>
                <span>Households</span>
              </th>
              <th>
                <span>Active contracts</span>
              </th>
              <th>
                <span>Value</span>
              </th>
              <!-- <th>
                <span>Total Value</span>
              </th> -->
              <th>
                <span>Fee schedules</span>
              </th>
              <th>
                <span>Group created on</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(group, i) in getGroups" :key="i">
              <td>
                <i
                  class="far fa-edit"
                  style="cursor: pointer; font-size: 12px"
                  v-on:click="openGroupModal(group)"
                ></i>
              </td>
              <td>
                <span v-text="group.name"></span>
              </td>
              <td>
                <div style="display: flex; flex-direction: column; gap: 4px">
                  <span
                    v-for="household in getGroupHouseholds(group)"
                    v-text="household.name"
                    :key="household.id"
                  ></span>
                </div>
              </td>
              <td>
                <div style="display: flex; flex-direction: column; gap: 4px">
                  <a
                    class="badge badge-custom"
                    v-for="household in getGroupHouseholds(group)"
                    v-on:click="openContractModal(household)"
                    v-text="getContractId(household)"
                    :key="household.id"
                  ></a>
                </div>
              </td>
              <td>
                <div style="display: flex; flex-direction: column; gap: 4px">
                  <span
                    v-for="household in getGroupHouseholds(group)"
                    v-text="showHouseholdValue(group, household)"
                    :key="household.id"
                  ></span>
                </div>
              </td>
              <!-- <td>
                <div style="display: flex; flex-direction: column; gap: 4px">
                  <span
                    v-for="household in getGroupHouseholds(group)"
                    v-text="showGroupValue(group, household)"
                    :key="household.id"
                  ></span>
                </div>
              </td> -->
              <td>
                <div style="display: flex; flex-direction: column; gap: 4px">
                  <div
                    v-for="household in getGroupHouseholds(group)"
                    :key="household.id"
                    style="display: flex; align-items: center; gap: 4px"
                  >
                    <schedule-badges
                      :schedules="getHouseholdActiveSchedules(household)"
                    ></schedule-badges>
                  </div>
                </div>
              </td>
              <td>
                <span v-text="formatDate(group._created)"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </scroll-area>
    </template>
    <template v-else>
      <loading></loading>
    </template>
  </div>
</template>

<style scoped>
span.alt-action {
  font-weight: 200;
  font-style: italic;
  font-size: 0.8rem;
}

div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}
div.header div.actions,
div.header div.actions div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}

i {
  opacity: 0.3;
}
i:hover,
i.sort,
i.active {
  cursor: pointer;
  opacity: 1;
}
i.active {
  color: red;
}

span.link {
  cursor: pointer;
  text-decoration: underline;
}
span {
  font-size: 0.9em;
}
span.badge {
  font-weight: normal;
  font-size: 0.8em;
}
</style>

<script>
import { scrollArea, search, checkbox, loading } from "../../../../component";
import ScheduleBadges from "../../../../component/schedule/schedule-badges.vue";
import { Api, List, Modal, Record } from "../../../../factory";
import { numeral, moment } from "../../../../npm";

export default {
  name: `FirmGroups`,
  get components() {
    return {
      scrollArea,
      search,
      checkbox,
      ScheduleBadges,
      loading,
    };
  },
  data() {
    return {
      table: {
        selected: ``,
      },
      list: {
        search: ``,
        sort: { asc: ``, key: `` },
      },
      ready: false,
      allHouseholds: [],
      allContracts: [],
      allGroups: [],
      accountsValuesMap: new Map(),
    };
  },
  computed: {
    getGroups: function () {
      const { search, sort } = this.list;
      return List.sort(List.filter(this.allGroups, search), sort.key, sort.asc);
    },
    getHouseholds: function () {
      const { search, sort } = this.list;
      return List.sort(
        List.filter(this.allHouseholds, search),
        sort.key,
        sort.asc
      );
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllHouseholds();
      await this.getAllContracts();
      await this.getAllGroups();

      this.ready = true;
    },
    selectAll() {
      const ids = this.getHouseholds.map((h) => h._id);
      this.allHouseholds.map((h) => {
        if (ids.includes(h._id)) {
          h.selected = this.table.selected;
        }
        return h;
      });
    },
    select() {},
    records() {
      return List.records(
        this.getGroups,
        this.allGroups,
        this.getHouseholds,
        this.allHouseholds
      );
    },
    async getAllHouseholds() {
      this.allHouseholds = (await Api.get(`households`)).data.map((h) => ({
        ...h,
        selected: ``,
      }));
    },
    // async getAllContracts() {
    //   this.allContracts = (await Api.get(`contracts`)).data.filter(
    //     (c) => c.status == 2
    //   );
    // },
    async getAllContracts() {
      const contractValues = (await Api.get(`contracts/list-with-values`)).data;
      this.allContracts = contractValues.contracts;
      contractValues.accountsWithValues.forEach((acc) =>
        this.accountsValuesMap.set(acc.number, acc.value)
      );
      this.allHouseholds.sort(
        (a, b) => this.getHouseholdValue(b) - this.getHouseholdValue(a)
      );
    },
    async getAllGroups() {
      this.allGroups = (await Api.get(`groups`)).data;
    },
    getSelectedHouseholds() {
      return this.getHouseholds.filter((h) => h.selected);
    },
    getGroupHouseholds(group) {
      const households = this.allHouseholds.filter((h) =>
        group.householdIds.includes(h._id)
      );

      return households;
    },
    getActiveContractFromHousehold(household) {
      return this.allContracts.find(
        (c) => c.householdId == household._id && c.status == 2
      );
    },
    getScheduleForAccount(contract, account) {
      return contract.schedules[account.schedule];
    },
    getSchedulesInContracts() {
      const schedules = [];

      this.allContracts.forEach((c) => {
        c.accounts.forEach((acc) => {
          const schedule = c.schedules[acc.schedule];
          if (!schedules.some((s) => s.name == schedule.name))
            schedules.push(schedule);
        });
      });

      return schedules;
    },
    scheduleTiers(schedule) {
      var format = {
        range: (v) => numeral(v).format(`$0,0`),
        rate: (v) => numeral(v).format(`0.[00]%`),
      };
      return schedule.breaks.map((b, i) => {
        var range;
        if (schedule.breaks.length > 1) {
          var start = i ? b + 1 : b;
          var end = i < schedule.breaks.length - 1 ? schedule.breaks[i + 1] : 0;
          range = end
            ? `${format.range(start)} - ${format.range(end)}`
            : `> ${format.range(start - 1)}`;
        } else {
          range = `> ${format.range(b)}`;
        }
        var rate = format.rate(schedule.rates[i]);
        return { range, rate };
      });
    },
    minimumFeeFormat(schedule) {
      return `Minimum Annual Fee: ${numeral(schedule.minimum).format(
        `$0,0.[00]`
      )}`;
    },
    getContractId(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? Record.id(contract) : "";
    },
    getSchedulesForHousehold(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.schedules : [];
    },
    getContractDate(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.date : "-----";
    },
    getContractRef(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.ref : "-----";
    },
    getHouseholdAccounts(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.accounts : [];
    },
    getContractDate(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.date : "-----";
    },
    getContractRef(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.ref : "-----";
    },
    getHouseholdAccounts(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.accounts : [];
    },
    getGroupActiveContracts(group) {
      return this.getGroupHouseholds(group).map((h) =>
        this.getActiveContractFromHousehold(h)
      );
    },
    getHouseholdActiveSchedules(household) {
      const contract = this.getActiveContractFromHousehold(household);
      const schedules = [];
      contract?.accounts?.forEach((acc) => {
        const schedule = this.getScheduleForAccount(contract, acc);
        if (!schedules.map((s) => s.name).includes(schedule.name))
          schedules.push(schedule);
      });

      return schedules;
    },
    formatDate(date) {
      const format = `MMM D, YYYY`;
      return moment(date).format(format);
    },
    getHouseholdValue(household) {
      const accounts = this.getHouseholdAccounts(household);

      return accounts.reduce(
        (total, acc) => this.accountsValuesMap.get(acc.number) + total,
        0
      );
    },
    showHouseholdValue(group, household) {
      // console.log("group: ", group);
      // console.log("household: ", household);
      const totalValue = this.getHouseholdValue(household);

      return numeral(totalValue).format(`$0,0.00`);
    },
    showGroupValue(group, household) {
      // const totalValue = this.getHouseholdValue(group, household).reduce(
      //   (partialSum, a) => partialSum + a,
      //   0
      // );
      // return numeral(totalValue).format(`$0,0.00`);
      // console.log(totalValue);
    },
    getHouseholdGroup(household) {
      return this.allGroups.find((g) => g.householdIds.includes(household._id));
    },
    openContractModal(household) {
      const contract = this.getActiveContractFromHousehold(household);

      if (contract)
        Modal.open(`contract`, {
          contract,
          methods: {
            success: () => {
              Modal.close();
              this.getAllContracts();
            },
          },
        });
    },
    openGroupModal(group) {
      Modal.open(`group-create`, {
        group,
        methods: {
          success: () => {
            Modal.close();
            this.getAllGroups();
          },
        },
      });
    },
  },
};
</script>
