
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <scroll-area class="pr-3">
    <div class="d-flex">
      <div>
        <p class="font-weight-bold">Exceptions</p>
      </div>
    </div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <span>Type</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `type`)"
              v-on:click="table.sort(`type`)"
            ></i>
          </th>
          <th>
            <span>Account</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `account`)"
              v-on:click="table.sort(`account`)"
            ></i>
          </th>
          <th>
            <span>Schedule</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `schedule`)"
              v-on:click="table.sort(`schedule`)"
            ></i>
          </th>
          <th>
            <span>Ticker</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `ticker`)"
              v-on:click="table.sort(`ticker`)"
            ></i>
          </th>
          <th class="text-right">
            <i
              class="fas fa-plus-circle"
              :title="table.title(`add`)"
              v-on:click="table.click(`add`)"
            ></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.items" :key="index">
          <td>
            <select
              v-model="o.type"
              class="form-control form-control-sm text-capitalize"
              v-on:change="table.change(`type`, o)"
            >
              <option
                v-for="(t, i) in table.types"
                :key="i"
                class="text-capitalize"
              >
                <span v-text="t"></span>
              </option>
            </select>
          </td>
          <td>
            <select v-model="o.account" class="form-control form-control-sm">
              <option
                v-for="a in table.accounts"
                :key="a.name"
                :disabled="table.disabled(`accounts`, o, a)"
              >
                <span v-text="a.name"></span>
              </option>
            </select>
          </td>
          <td>
            <select v-model="o.schedule" class="form-control form-control-sm">
              <option v-for="s in table.schedules" :key="s.name">
                <span v-text="s.name"></span>
              </option>
            </select>
          </td>
          <td class="ticker">
            <input
              v-model="o.ticker.symbol"
              class="form-control form-control-sm"
              :class="table.class(`ticker`, o)"
              :disabled="table.disabled(`ticker`, o)"
              v-on:keyup="table.keyup(`ticker`, o)"
              v-on:keydown="table.keydown(`ticker`, o)"
            />
            <small
              class="form-text text-muted"
              v-text="o.ticker.name"
            ></small>
          </td>
          <td class="text-right">
            <i
              class="fas fa-minus-circle"
              :title="table.title(`remove`)"
              v-on:click="table.click(`remove`, index)"
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
  </scroll-area>
</div>
