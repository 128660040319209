<template>
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-bell mr-2"></i>
          <span class="font-weight-bold" v-text="table.title(`modal`)"></span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <div class="form-row p-2">
          <div class="col-3">
            <div class="form-row">
              <div class="col-2 text-right">
                <span class="align-middle">Date</span>
              </div>
              <div class="col">
                <template v-if="period.date">
                  <date-picker
                    :o="period"
                    :options="period.options"
                  ></date-picker>
                </template>
              </div>
            </div>
          </div>
          <div class="col-6">
            <template v-if="table.mute">
              <button
                type="button"
                class="btn btn-sm btn-danger"
                :title="table.title(`mute`)"
                :disabled="table.disabled(`mute`)"
                v-on:click="table.click(`mute`)"
              >
                <span>Mute</span>
              </button>
            </template>
          </div>
          <div class="col">
            <search :o="table.list"></search>
          </div>
        </div>
        <hr class="mx-2 mt-1 mb-2" />
        <div class="d-flex px-2">
          <div>
            <span class="font-weight-bold">Messages</span>
          </div>
          <div class="records flex-grow-1 text-right">
            <span v-text="table.records"></span>
          </div>
        </div>
        <div class="main">
          <template v-if="table.ready">
            <template v-if="table.items.length">
              <scroll-area class="pl-2 pr-3 pb-3 pt-2">
                <table class="table table-sm">
                  <tbody>
                    <tr v-for="(s, i) in table.items" :key="i">
                      <td>
                        <span v-text="s"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </scroll-area>
            </template>
            <template v-else>
              <div class="d-flex flex-column justify-content-center h-100">
                <div class="text-center">
                  <span class="none">No Notifications found</span>
                </div>
              </div>
            </template>
          </template>
          <template v-else>
            <loading></loading>
          </template>
        </div>
      </div>
      <div class="modal-footer px-3">
        <div></div>
        <div class="text-center">
          <span class="text-center alerts" v-text="table.alerts"></span>
        </div>
        <div>
          <button
            type="button"
            class="btn btn-sm btn-primary"
            :disabled="table.disabled(`mute`, `all`)"
            :title="table.title(`mute`, `all`)"
            v-on:click="table.click(`mute`, `all`)"
          >
            <span>Mute All</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
div.records {
  font-size: 0.9rem;
}
div.modal-body {
  height: calc(100vh - 12em);
}
div.main {
  height: calc(100% - 5em);
}
div.modal-footer {
  justify-content: space-between;
}

/*
    div.main {
        height: 68vh;
        margin-right: -8px;
    }
    */
div.controls {
  width: 15em;
  margin-right: 0.8em;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
button {
  width: 80px;
}
input.household {
  padding-left: 0.9em;
}
td {
  vertical-align: inherit;
}
i {
  opacity: 0.3;
}
i:hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}
span {
  font-size: 0.9em;
}
span.link {
  cursor: pointer;
  text-decoration: underline;
}
span.alerts {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: red;
}
/*
    table th:nth-child(1) {
        width: 8em;
    }
    table th:nth-last-child(3) {
        width: 12em;
    }
    table th:nth-last-child(2) {
        width: 10em;
    }
    table th:last-child {
        width: 12em;
    }
    */
span.none {
  font-size: 2em;
  font-weight: bold;
  opacity: 0.3;
}
</style>

<script>
import { datePicker, loading, scrollArea, search } from "../../../../../component";
import { Api, lib, List, Record } from "../../../../../factory";
import { moment, numeral } from "../../../../../npm";
import { alert } from "../../../../../service";

export default {
  name: ``,
  get components() {
    return {
      datePicker,
      loading,
      scrollArea,
      search
    };
  },
  props: ["data"],
  data() {
    return {
      table: ``
    };
  },
  computed: {
    period() {
      return this.table.list.period;
    },
    record() {
      return this.table.list.record;
    }
  },
  watch: {
    "table.list.period": {
      handler() {
        var { period } = this.table.list;
        if (period.date) this.table.load();
      },
      deep: true
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var table = {
        _alert: ``,
        _data: ``,
        _date(d) {
          var f = `MMM D, YYYY`;
          return d.includes(`/`)
            ? moment(d, `L`).format(f)
            : moment(d, f).format(`L`);
        },
        async _init() {
          try {
            var [{ data: alerts }, { data: _dates }] = await Promise.all([
              Api.get(this._url(`_sort=-date`)),
              Api.get(this._url(`_dates=true&_sort=-date`))
            ]);
            this._data.alerts = alerts;
            var dates = _dates.map(d => this._date(d));
            var a = this._data.alerts.find(o => !o.mute);
            var date = a ? a.date : alerts.length ? alerts[0].date : ``;
            var { period } = this.list;
            period.date = date
              ? this._date(date)
              : this._date(moment().format(`L`));
            period.options.maxDate = dates.length
              ? dates[0]
              : this._date(moment().format(`L`));
            period.options.minDate =
              dates[dates.length - 1] || period.options.maxDate;
            period.options.dates = dates;
            this._ready = true;
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _items: ``,
        _message: s => s,
        async _mute(all) {
          var alerts = all
            ? this._data.alerts.filter(o => !o.mute)
            : [this._alert];
          try {
            await Promise.all(
              alerts.map(a => Api.put(`alerts/${a._id}?_mute=true`))
            );
            await this._init();
            this.load();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _ready: ``,
        _url(query = ``) {
          var u = `alerts?type=contract&parentId=${data.contract._id}`;
          return query ? `${u}&${query}` : u;
        },
        get alerts() {
          var a = this._data.alerts.filter(o => !o.mute);
          var m = a.reduce((m, o) => (m += o.messages.length), 0);
          var v = l => (l.length > 1 ? `are` : `is`);
          var p = l => ((Array.isArray(l) ? l.length > 1 : l > 1) ? `s` : ``);
          var c = l => (l.length > 1 ? `combined` : ``);
          return a.length
            ? `There ${v(a)} ${a.length} active notification${p(
                a
              )} with ${m} ${c(a)} message${p(m)}`
            : ``;
        },
        click(type, d) {
          switch (type) {
            case `mute`:
              return this._mute(d == `all`);
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type, d) {
          switch (type) {
            case `mute`:
              return (
                this.list.loading ||
                (d ? !this._data.alerts.filter(o => !o.mute).length : false)
              );
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        async load() {
          var { list } = this;
          if (list.loading) return;
          list.loading = true;
          var date = this._date(list.period.date);
          try {
            var {
              data: [a]
            } = await Api.get(this._url(`date=${date}`));
            this._alert = a;
            list.items = (a ? a.messages : []).map(s => this._message(s));
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          } finally {
            list.loading = false;
          }
        },
        init() {
          this._data = { alerts: [] };
          var list = {
            items: ``,
            period: ``,
            search: ``,
            sort: { key: ``, asc: `` },
            record: ``
          };
          list.period = {
            date: ``,
            options: {}
          };
          this.list = list;
          this._init();
          return this;
        },
        get items() {
          var { list } = this;
          return List.sort(
            List.filter(list.items, list.search),
            list.sort.key,
            list.sort.asc
          );
        },
        list: ``,
        get mute() {
          return this._alert && !this._alert.mute;
        },
        get ready() {
          return this._ready && !this.list.loading;
        },
        get records() {
          return List.records(this.items, this.list.items);
        },
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        text(type, d) {
          switch (type) {
            case `positions`:
              return d[type].length;
            case `value`:
              return numeral(d[type]).format(`$0,0.00`);
            case `total`:
              var t = this.items.reduce(
                (v, o) => (v += numeral(o.value).value()),
                0
              );
              return numeral(t).format(`$0,0.00`);
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        title(type, d) {
          switch (type) {
            case `modal`:
              return `Notifications - Contract ${Record.id(data.contract)} (${
                data.contract.ref
              })`;
            case `mute`:
              return d ? `Mute all notifications` : `Mute this notification`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        }
      };
      this.table = table.init();
    }
  }
};
</script>
