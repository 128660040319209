
<div class="h-custom">
  <hr class="mt-0 ml-2 mr-3 mb-2" />
  <scroll-area class="pl-2 pr-3 mb-2">
    <div class="form-row">
      <div class="col">
        <label>Date</label>
        <date-picker :clearable="false"
                     style="display: block;"
                     v-model="contract.date"
                     format="MMM D, YYYY"></date-picker>
        <small class="form-text text-muted">Amend Contract Date</small>
      </div>
      <div class="col">
        <label>Reference</label>
        <input v-model="contract.ref"
               class="form-control form-control-sm"
               placeholder="Reference" />
        <small class="form-text text-muted">Contract Reference</small>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <label>Billing</label>
        <div class="form-row">
          <div class="col">
            <select v-model="contract.billing.type"
                    class="form-control text-capitalize form-control-sm">
              <option class="text-capitalize"
                      v-for="(o, i) in summary.options(`billing`, `type`)"
                      :key="i">
                <span class="text-capitalize"
                      v-text="o"></span>
              </option>
            </select>
            <small class="form-text text-muted">Billing Type</small>
          </div>
          <div class="col">
            <select v-model="contract.billing.schedule"
                    class="form-control form-control-sm">
              <option v-for="(o, i) in summary.options(`billing`, `schedule`)"
                      :key="i">
                <span v-text="o"></span>
              </option>
            </select>
            <small class="form-text text-muted">Billing Schedule</small>
          </div>
          <div class="col">
            <select v-model="contract.billing.values"
                    class="form-control form-control-sm"
                    :disabled="summary.disabled(`billing`, `values`)">
              <option v-for="(o, i) in summary.options(`billing`, `values`)"
                      :key="i">
                <span v-text="o"></span>
              </option>
            </select>
            <small class="form-text text-muted">Billing Values</small>
          </div>
        </div>
      </div>
    </div>

    <hr />
    <div v-for="(s, index) in contract.schedules"
         :key="index"
         class="row">
      <div class="col mt-2">
        <div class="d-flex">
          <div>
            <u class="pl-2"
               v-text="summary.text(`schedule`, index)"></u>
          </div>
          <div class="flex-grow-1 text-right">
            <template v-if="!index">
              <span class="mr-2">Obfuscate account numbers</span>
              <checkbox :o="contract.show"
                        p="numbers"
                        title="Toggle obfuscate account numbers"></checkbox>
            </template>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-4">
            <div class="row">
              <div class="col">
                <span class="title pl-2"
                      v-text="summary.text(`schedule`, s)"></span>
              </div>
            </div>
            <div class="row py-3">
              <div class="col">
                <table class="table table-sm schedule mb-1">
                  <thead>
                    <tr>
                      <th>
                        <span>Range</span>
                      </th>
                      <th>
                        <span>Rate</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(t, i) in summary.tiers(s)"
                        :key="i">
                      <td>
                        <span v-text="t.range"></span>
                      </td>
                      <td>
                        <span v-text="t.rate"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <template v-if="s.minimum">
                  <hr class="my-1" />
                  <span class="font-italic ml-1"
                        v-text="summary.text(`minimum`, s)"></span>
                </template>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="row">
              <div class="col">
                <span class="title pl-2">Accounts</span>
              </div>
            </div>
            <div class="row py-3">
              <div class="col">
                <table class="table table-sm accounts">
                  <thead>
                    <tr>
                      <th>
                        <span>Number</span>
                      </th>
                      <th>
                        <span>Billing</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(a, i) in summary.accounts(s)"
                        :key="i">
                      <td>
                        <span v-text="summary.text(`number`, a)"></span>
                      </td>
                      <td>
                        <span v-text="a.billingNumber ? a.billingNumber : a.name"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="summary.exceptions.length">
      <u class="pl-2">Exceptions</u>
      <div class="mt-3">
        <table class="table table-sm exceptions">
          <thead>
            <tr>
              <th>
                <span>Type</span>
              </th>
              <th>
                <span>Account</span>
              </th>
              <th>
                <span>Schedule</span>
              </th>
              <th>
                <span>Ticker</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(o, i) in summary.exceptions"
                :key="i">
              <td>
                <span v-text="o.typeSelected"></span>
              </td>
              <td>
                <span v-text="summary.text(`accountSelected`, o)"></span>
              </td>
              <td>
                <span v-text="o.scheduleName"></span>
              </td>
              <td>
                <span v-text="summary.text(`ticker`, o)"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <contract-transactions-creation-table v-if="contract.transactionsRules.length"
                                          :contractAccounts="contract.accounts"
                                          v-model="contract.transactionsRules"
                                          readonly />

    <div v-if="summary.lineItems.length">
      <u class="pl-2">Line Items</u>
      <div class="mt-3">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>
                <span>Description</span>
              </th>
              <th>
                <span>Account</span>
              </th>
              <th>
                <span>Timing</span>
              </th>
              <th>
                <span>Amount</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(o, i) in summary.lineItems"
                :key="i">
              <td>
                <span v-text="o.description"></span>
              </td>
              <td>
                <span v-text="o.account"></span>
              </td>
              <td>
                <span v-text="o.timing"></span>
              </td>
              <td>
                <span v-text="summary.text(`amount`, o.amount)"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- hiding signing section for amendement till after billing -->
    <!-- <div class="row">
      <div class="col mt-2">
        <u class="pl-2">Signees</u>
      </div>
    </div>
    <template v-if="summary.signees.length">
      <div class="row py-3">
        <div class="col">
          <table class="table table-sm signees">
            <thead>
              <tr>
                <th>
                  <span>Order</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Email</span>
                </th>
                <th>
                  <span>Role</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(s, i) in summary.signees"
                  :key="i">
                <td>
                  <span v-text="summary.text(`signees`, i)"></span>
                </td>
                <td>
                  <span v-text="s.name"></span>
                </td>
                <td>
                  <span v-text="s.email"></span>
                </td>
                <td>
                  <span class="text-capitalize"
                        v-text="s.role"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template v-else>
      <p class="font-weight-bold font-italic text-center no-signees m-3"
         v-text="summary.text(`signees`)"></p>
    </template>
    <hr /> -->
  </scroll-area>
</div>
