<template>
  <div class="modal-dialog modal-dialog-centered modal-lg"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Send Invoice To Clients</div>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body p-2">
        <div class="px-4 py-3" >
          <Title>The following email will be sent to this household's contacts:</Title>
          <div class="mt-4 email-body">
            <Template603 v-if="isFirm603"></Template603>
            <TemplateEmbarc v-else-if="isFirmEmbarcaderoCapital"></TemplateEmbarc>
            <DefaultTemplate v-else></DefaultTemplate>
          </div>
        </div>
      </div>

      <div class="modal-footer pr-3">
        <div>
          <button type="button"
                  class="btn btn-sm modal-button btn-outline-danger"
                  v-on:click="cancel()">
            <span>Cancel</span>
          </button>
          <button type="button"
                  class="btn btn-sm modal-button btn-primary ml-2"
                  v-on:click="send()">
            <span>Send</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.email-body {
  color: #212529;
}

.modal-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 16px;

  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #616466;
}

.modal-button {
  width: 80px;
}

.modal-body {
  min-height: 50vh;
}

.modal-footer {
  justify-content: center;
}
</style>

<script>
import { Modal } from "../../factory";
import Title from "../../component/title.vue";
import DefaultTemplate from "./default-template.vue";
import Template603 from "./603-template.vue";
import TemplateEmbarc from "./embarc-template.vue";
import { session } from "../../service";

export default {
  props: {
    data: {
      contracts: Array,
      household: {
        name: String,
        contacts: Array,
      }
    }
  },
  computed: {
    isFirm603() {
      var { firm } = session.get("state");
      return firm._id === '64272b169c5b3d23455c40e5';
    },
    isFirmEmbarcaderoCapital() {
      var { firm } = session.get("state");
      return firm._id === '658c6d5bb77150576fb98a0b';
    }
  },
  methods: {
    send() {
      // close the modal then process the request
      Modal.close();
      this.data.methods.send(this.data);
      console.log("Sending invite emails")
    },
    cancel() {
      if (this.data.methods.back) {
        this.data.methods.back()
      } else {
        Modal.close();
      }
    }
  },
  components: { Title, DefaultTemplate, Template603, TemplateEmbarc }
}
</script>
