
<div class="root" :style="alert.style">
  <div
    class="alert"
    :class="alert.data.class"
    role="alert"
    v-on:click="alert.click(`hide`)"
  >
    <span v-text="alert.data.text"></span>
  </div>
</div>
