<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-question-circle mr-2"></i>
          <span class="font-weight-bold">Comments / Questions</span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="p-3">
          <div class="p-3 mb-3 bg-light border">
            Looking for more info? Check out our Knowledge Base <a href="https://www.smartkx.io/knowledge-base">here</a>. 
          </div><br>
          <div class="form-group">
            <label>Comments</label>
            <textarea
              v-model="view.comments"
              class="form-control form-control-sm"
              rows="5"
              placeholder="Comments about the product"
            ></textarea>
          </div>
          <div class="form-group">
            <label>Questions</label>
            <textarea
              v-model="view.questions"
              class="form-control form-control-sm"
              rows="5"
              placeholder="Questions about the product"
            ></textarea>
          </div>
          <div class="form-row">
            <div class="col"></div>
            <div class="col text-right">
              <button
                class="btn btn-primary btn-sm mt-2"
                :disabled="view.disabled"
                v-on:click="view.submit()"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
button {
  width: 80px;
}

</style>

<script>
import { Api } from "../factory";
import { alert, events, session } from "../service";

export default {
  data() {
    return {
      view: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      this.view = {
        comments: ``,
        get disabled() {
          return !this.comments.length && !this.questions.length;
        },
        questions: ``,
        async submit() {
          var { comments, questions } = this;
          try {
            var data = { comments, questions };
            var resp = await Api.post(`users?_action=feedback`, data);
            alert.message(resp.data, 3e3);
            events.$emit(`modal`, { action: `hide` });
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        }
      };
    }
  }
};
</script>
