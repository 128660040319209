
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <scroll-area class="pr-3">
    <table class="table table-sm">
      <caption v-if="table.householdNote || table.scheduleName">
          <hr>
          <p>This fee schedule & notes, if applicable, were listed on the client agreement sent for signature.</p>
          <mark v-if="table.scheduleName">{{ table.scheduleName }}<br></mark>
          <mark v-if="table.householdNote">Notes: {{ table.householdNote }}</mark>
      </caption>
      <thead>
        <tr>
          <th></th>
          <th>
            <span>Number</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `number`)"
               v-on:click="table.sort(`number`)"></i>
          </th>

          <th>
            <span>Name</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `name`)"
               v-on:click="table.sort(`name`)"></i>
          </th>
          <th>
            <span>Schedule</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `schedule`)"
               v-on:click="table.sort(`schedule`)"></i>
          </th>
          <th>
            <span>Billing</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `billing`)"
               v-on:click="table.sort(`billing`)"></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.items"
            :key="index">
          <td>
            <checkbox :o="o"
                      p="select"></checkbox>
          </td>
          <td>
            <span v-text="o.number"></span>
          </td>
          <td>
            <span v-text="o.name"></span>
          </td>
          <td>
            <select v-model="o.scheduleName"
                    class="form-control form-control-sm"
                    :disabled="!o.select">
              <option v-for="s in table.schedules"
                      :key="s.name">
                <span v-text="s.name"></span>
              </option>
            </select>
          </td>
          <td>
            <select v-model="o.billingNumber"
                    class="form-control form-control-sm"
                    :disabled="!o.select">
              <option v-for="(b, i) in table.billing(o)"
                      :key="i">
                <span v-text="b"></span>
              </option>
            </select>
          </td>
          <td class="text-right">
            <i class="fas fa-dollar-sign"
               title="Bill all to this account"
               v-on:click="table.bill(o)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </scroll-area>
</div>
