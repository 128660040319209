
<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="far fa-file-alt mr-2"></i>
        <span class="font-weight-bold" v-text="table.title(`modal`)"></span>
      </p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="p-2">
        <div class="row p-2">
          <div class="col-3">
            <div class="form-row">
              <div class="col-2 text-right">
                <span class="align-middle">Date</span>
              </div>
              <div class="col">
                <template v-if="period.date">
                  <date-picker :o="period" :options="period.options"></date-picker>
                </template>
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-row">
              <div class="col-2 text-right">
                <span class="align-middle">Cash</span>
              </div>
              <div class="col">
                <input
                  class="form-control form-control-sm text-right"
                  :value="table.value(`cash`, record)"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-row">
              <div class="col-2 text-right">
                <span class="align-middle">Total</span>
              </div>
              <div class="col">
                <input
                  class="form-control form-control-sm text-right"
                  :value="table.value(`total`, record)"
                  readonly
                />
              </div>
            </div>
          </div>
          <div class="col">
            <search :o="table.list"></search>
          </div>
        </div>
        <hr class="mx-2 mt-1 mb-2" />
        <div class="d-flex px-2">
          <div>
            <span class="font-weight-bold">Positions</span>
          </div>
          <div class="records flex-grow-1 text-right">
            <span v-text="table.records"></span>
          </div>
        </div>
        <div class="main">
          <scroll-area class="pl-2 pr-3 pb-3 pt-2">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>
                    <span>Ticker</span>
                    <i
                      class="fas fa-sort pl-1"
                      :class="table.class(`sort`, `ticker`)"
                      v-on:click="table.sort(`ticker`)"
                    ></i>
                  </th>
                  <th>
                    <span>Name</span>
                    <i
                      class="fas fa-sort pl-1"
                      :class="table.class(`sort`, `name`)"
                      v-on:click="table.sort(`name`)"
                    ></i>
                  </th>
                  <th class="text-right">
                    <span>Price</span>
                    <i
                      class="fas fa-sort pl-1"
                      :class="table.class(`sort`, `price`)"
                      v-on:click="table.sort(`price`)"
                    ></i>
                  </th>
                  <th class="text-right">
                    <span>Quantity</span>
                    <i
                      class="fas fa-sort pl-1"
                      :class="table.class(`sort`, `qty`)"
                      v-on:click="table.sort(`qty`)"
                    ></i>
                  </th>
                  <th class="text-right">
                    <span>Category</span>
                    <i
                      class="fas fa-sort pl-1"
                      :class="table.class(`sort`, `category`)"
                      v-on:click="table.sort(`category`)"
                    ></i>
                  </th>
                  <th class="text-right">
                    <span>Value</span>
                    <i
                      class="fas fa-sort pl-1"
                      :class="table.class(`sort`, `value`)"
                      v-on:click="table.sort(`value`)"
                    ></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, index) in table.items" :key="index">
                  <td>
                    <span v-text="o.ticker"></span>
                  </td>
                  <td>
                    <span v-text="o.name"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="o.price"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="o.qty"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="table.categories[o.category]"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="o.value"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Sub Total</span>
                  </td>
                  <td class="text-right">
                    <span v-text="table.text(`total`)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </scroll-area>
        </div>
      </div>
    </div>
  </div>
</div>
