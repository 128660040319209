
<div class="h-100">
  <div class="search">
    <search :o="list"></search>
  </div>
  <div class="d-flex p-1 pb-2 header"
       style="height: 45px">
    <div class="d-flex actions my-auto mr-2">
      <div v-if="!getSelectedHouseholds().length">
        <span class="alt-action text-secondary">Select one or more households to create a group</span>
      </div>
      <div v-else
           title="Create group"
           class="btn btn-outline-secondary"
           v-on:click="createGroup()">
        <i class="fas fa-users mr-1"></i>
        <span>Create group</span>
      </div>
    </div>
    <div class="flex-grow-1 my-auto text-right">
      <span v-text="records()"></span>
    </div>
  </div>
  <template v-if="ready">
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <checkbox :o="table"
                        p="selected"
                        v-on:change="selectAll"></checkbox>
            </th>
            <th>
              <span>Household</span>
            </th>
            <th></th>
            <th>
              <span>Contract</span>
            </th>
            <th>
              <span>Date</span>
            </th>
            <th>
              <span>Accounts</span>
            </th>
            <th>
              <span>Value</span>
            </th>
            <th>
              <span>Schedules</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(household, i) in getHouseholds"
              :key="household._id">
            <td>
              <checkbox :o="household"
                        p="selected"
                        :disabled="getHouseholdGroup(household)"></checkbox>
            </td>
            <td>
              <span v-text="household.name"></span>
            </td>
            <td>
              <i v-show="getHouseholdGroup(household)"
                 class="fas fa-users"></i>
            </td>
            <td>
              <a href="#" class="badge badge-custom"
                    v-on:click="openContractModal(household)"
                    v-text="getContractId(household)"></a>
            </td>
            <td style="white-space: nowrap">
              <span v-text="getContractDate(household)"></span>
            </td>
            <td>
              <div style="display: flex; flex-direction: column; gap: 4px">
                <span v-for="account in getHouseholdAccounts(household)"
                      :key="account._id"
                      v-text="account.number"></span>
              </div>
            </td>
            <td>
              <span v-text="showHouseholdValue(household)"></span>
            </td>
            <td>
              <schedule-badges :schedules="getSchedulesForHousehold(household)"></schedule-badges>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </template>
  <template v-else>
    <loading></loading>
  </template>
</div>
