
<div class="card">
  <div class="card-header px-3 py-2" id="headingOne" >
    <div class="card-header-title">
      <button class="btn btn-block text-left" type="button" 
      data-toggle="collapse" data-target="#collapseOne" 
      aria-expanded="false" aria-controls="collapseOne">
        <span class="font-weight-bold">Households</span>
        <i class="fa fa-chevron-up ml-1"></i>
      </button>
    </div>
    <button
      title="Onboard new client"
      type="button"
      class="btn btn-sm btn-outline-secondary new-client"
      v-on:click="table.newClient()"
    >
      <i class="fas fa-user-plus mr-2"></i>
      <span>New Client</span>
  </button>
  </div>
  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionMenu">
   <div class="card-body">
     <search :o="table"></search>
     <div class="d-flex pt-1 pl-2">
     </div>
     <div class="households">
       <scroll-area class="pr-3">
         <table class="table table-sm">
           <tbody>
             <tr v-for="(o, i) in table.items" :key="i" class="household">
               <td>
                 <i
                   class="far fa-edit mr-1"
                   :title="table.title(`edit`)"
                   v-on:click="table.click(`edit`, o)"
                 ></i>
                 <a
                  href="#"
                   class="badge badge-light"
                   :title="table.title(`view`)"
                   v-on:click="table.click(`view`, o)"
                   v-text="o.name"
                 ></a>
               </td>
             </tr>
           </tbody>
         </table>
       </scroll-area>
     </div>
   </div>
  </div>
</div>
