<template>
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="far fa-file-alt mr-2"></i>
          <span class="font-weight-bold" v-text="table.title(`modal`)"></span>
        </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="p-2">
          <div class="row p-2">
            <div class="col-3">
              <div class="form-row">
                <div class="col-2 text-right">
                  <span class="align-middle">Date</span>
                </div>
                <div class="col">
                  <template v-if="period.date">
                    <date-picker :o="period" :options="period.options"></date-picker>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-row">
                <div class="col-2 text-right">
                  <span class="align-middle">Cash</span>
                </div>
                <div class="col">
                  <input
                    class="form-control form-control-sm text-right"
                    :value="table.value(`cash`, record)"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-row">
                <div class="col-2 text-right">
                  <span class="align-middle">Total</span>
                </div>
                <div class="col">
                  <input
                    class="form-control form-control-sm text-right"
                    :value="table.value(`total`, record)"
                    readonly
                  />
                </div>
              </div>
            </div>
            <div class="col">
              <search :o="table.list"></search>
            </div>
          </div>
          <hr class="mx-2 mt-1 mb-2" />
          <div class="d-flex px-2">
            <div>
              <span class="font-weight-bold">Positions</span>
            </div>
            <div class="records flex-grow-1 text-right">
              <span v-text="table.records"></span>
            </div>
          </div>
          <div class="main">
            <scroll-area class="pl-2 pr-3 pb-3 pt-2">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th>
                      <span>Ticker</span>
                      <i
                        class="fas fa-sort pl-1"
                        :class="table.class(`sort`, `ticker`)"
                        v-on:click="table.sort(`ticker`)"
                      ></i>
                    </th>
                    <th>
                      <span>Name</span>
                      <i
                        class="fas fa-sort pl-1"
                        :class="table.class(`sort`, `name`)"
                        v-on:click="table.sort(`name`)"
                      ></i>
                    </th>
                    <th class="text-right">
                      <span>Price</span>
                      <i
                        class="fas fa-sort pl-1"
                        :class="table.class(`sort`, `price`)"
                        v-on:click="table.sort(`price`)"
                      ></i>
                    </th>
                    <th class="text-right">
                      <span>Quantity</span>
                      <i
                        class="fas fa-sort pl-1"
                        :class="table.class(`sort`, `qty`)"
                        v-on:click="table.sort(`qty`)"
                      ></i>
                    </th>
                    <th class="text-right">
                      <span>Category</span>
                      <i
                        class="fas fa-sort pl-1"
                        :class="table.class(`sort`, `category`)"
                        v-on:click="table.sort(`category`)"
                      ></i>
                    </th>
                    <th class="text-right">
                      <span>Value</span>
                      <i
                        class="fas fa-sort pl-1"
                        :class="table.class(`sort`, `value`)"
                        v-on:click="table.sort(`value`)"
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(o, index) in table.items" :key="index">
                    <td>
                      <span v-text="o.ticker"></span>
                    </td>
                    <td>
                      <span v-text="o.name"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="o.price"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="o.qty"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="table.categories[o.category]"></span>
                    </td>
                    <td class="text-right">
                      <span v-text="o.value"></span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="4"></td>
                    <td class="text-right">
                      <span class="font-weight-bold">Sub Total</span>
                    </td>
                    <td class="text-right">
                      <span v-text="table.text(`total`)"></span>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </scroll-area>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
div.records {
  font-size: 0.9rem;
}
div.main {
  height: 68vh;
  margin-right: -8px;
}
div.controls {
  width: 15em;
  margin-right: 0.8em;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
button {
  width: 80px;
}
input.household {
  padding-left: 0.9em;
}
td {
  vertical-align: inherit;
}
i {
  opacity: 0.3;
}
i:hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}
span {
  font-size: 0.9em;
}
span.link {
  cursor: pointer;
  text-decoration: underline;
}
table th:nth-child(1) {
  width: 8em;
}
table th:nth-last-child(3) {
  width: 12em;
}
table th:nth-last-child(2) {
  width: 10em;
}
table th:last-child {
  width: 12em;
}
</style>

<script>
import { datePicker, scrollArea, search } from "../../../../../component";
import { Api, lib, List } from "../../../../../factory";
import { moment, numeral } from "../../../../../npm";
import { alert } from "../../../../../service";
import { _ } from "lodash";

export default {
  name: ``,
  get components() {
    return {
      datePicker,
      scrollArea,
      search,
    };
  },
  props: ["data"],
  data() {
    return {
      table: ``,
    };
  },
  computed: {
    period() {
      return this.table.list.period;
    },
    record() {
      return this.table.list.record;
    },
  },
  watch: {
    "table.list.period": {
      handler() {
        var { period } = this.table.list;
        if (period.date) this.table.load();
      },
      deep: true,
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var table = {
        _date(d) {
          var f = `MMM D, YYYY`;
          return d.includes(`/`)
            ? moment(d, `L`).format(f)
            : moment(d, f).format(`L`);
        },
        async _init() {
          try {
            var { account, methods } = data;
            var { data: _dates } = await Api.get(
              `records?accountId=${account._id}&_dates=true&_sort=-_updated`
            );
            var dates = _dates.map((d) => this._date(d));
            var [date] = dates;
            var { period } = this.list;
            period.date = date || this._date(moment().format(`L`));
            period.options.maxDate = period.date;
            period.options.minDate = dates[dates.length - 1] || period.maxDate;
            period.options.dates = dates;
          } catch (e) {
            console.error(e);
            if (methods && typeof methods.error == `function`)
              return methods.error(e);
            alert.error(e.message);
          }
        },
        _items: ``,
        _position(o) {
          return Object.keys(o).reduce((r, k) => {
            switch (k) {
              case `name`:
                r[k] = o[k]
                  .split(` `)
                  .map((s) => lib.string.capitalize(s))
                  .join(` `);
                break;
              case `price`:
              case `value`:
                r[k] = numeral(o[k]).format(`$0,0.00`);
                break;
              case `qty`:
                r[k] = numeral(o[k]).format(`0,0.[00]`);
                break;
              default:
                r[k] = o[k];
            }
            return r;
          }, {});
        },
        async load() {
          var { list } = this;
          if (list.loading) return alert.warning(`Still loading`, 2e3);
          list.loading = true;
          var { account, methods } = data;
          var date = this._date(list.period.date);
          try {
            var {
              data: [record],
            } = await Api.get(`records?accountId=${account._id}&date=${date}`);

            // console.log('record: ', record);

            if (record) {
              list.record = record;

              list.items = record.hasOwnProperty(`securities`)
                ? record.securities.map((o) => this._position(o))
                : record.positions.map((o) => this._position(o));
            }
          } catch (e) {
            console.error(e);
            if (methods && typeof methods.error == `function`)
              return methods.error(e);
            alert.error(e.message);
          } finally {
            list.loading = false;
          }
        },
        class(type, d) {
          switch (type) {
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        init() {
          var list = {
            items: ``,
            period: ``,
            search: ``,
            sort: { key: ``, asc: `` },
            record: ``,
          };
          list.period = {
            date: ``,
            options: {},
          };
          this.list = list;
          this._init();
          return this;
        },
        get items() {
          var { list } = this;
          return List.sort(
            List.filter(list.items, list.search),
            list.sort.key,
            list.sort.asc
          );
        },
        list: ``,
        categories : {
          'DEBT' : 'Fixed Income',
          'DERV': 'Derivatives',
          'EQTY' : 'Equity',
          'O' : 'Other' 
        },
        get records() {
          return List.records(this.items, this.list.items);
        },
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        text(type, d) {
          switch (type) {
            case `positions`:
              return d[type].length;
            case `value`:
              return numeral(d[type]).format(`$0,0.00`);
            case `total`:
              var t = this.items.reduce(
                (v, o) => (v += numeral(o.value).value()),
                0
              );
              return numeral(t).format(`$0,0.00`);
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        title(type, d) {
          switch (type) {
            case `modal`:
              return `Account Values - ${data.account.name ?? ''} (${data.account.number})`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        value(type, d) {
          switch (type) {
            case `cash`:
              return numeral(d && d[type] ? d[type] : 0).format(`$0,0.00`);
            case `total`:
              var positions = d
                ? d.hasOwnProperty(`securities`)
                  ? d.securities
                  : d.positions
                : [];
              var t = positions.reduce((t, s) => (t += s.value), 0);
              return numeral(d.value || t + d.cash).format(`$0,0.00`);
            default:
              throw new Error(`Invalid value type, ${type}!`);
          }
        },
      };
      this.table = table.init();
    },
  },
};
</script>
