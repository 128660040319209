
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <div class="d-flex p-1 pb-2 header">
    <div class="d-flex actions mr-2">
      <div class="btn btn-outline-secondary" title="Add User" v-on:click="table.click(`add`, `user`)">
        <i class="fas mr-1 fa-plus-square"></i>
        <span>Add</span>
      </div>
    </div>
    <div class="flex-grow-1 text-right">
      <span v-text="table.records"></span>
    </div>
  </div>
  <scroll-area class="pr-3">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <span>Name</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `name`)"
              v-on:click="table.sort(`name`)"
            ></i>
          </th>
          <th>
            <span>Email</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `email`)"
              v-on:click="table.sort(`email`)"
            ></i>
          </th>
          <th>
            <span>Firm</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `firm`)"
              v-on:click="table.sort(`firm`)"
            ></i>
          </th>
          <th>
            <span>Role</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `role`)"
              v-on:click="table.sort(`role`)"
            ></i>
          </th>
          <th>
            <span>Last Seen</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `lastSeen`)"
              v-on:click="table.sort(`lastSeen`)"
            ></i>
          </th>
          <th>
            <span>MFA</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `mfa`)"
              v-on:click="table.sort(`mfa`)"
            ></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.items" :key="index">
          <td>
            <span
              class="text-primary link"
              :title="table.title(`user`)"
              v-on:click="table.click(`open`, o)"
              v-text="o.name"
            ></span>
          </td>
          <td>
            <span v-text="o.email"></span>
          </td>
          <td>
            <span v-text="o.firm"></span>
          </td>
          <td>
            <span class="text-capitalize" v-text="o.role"></span>
          </td>
          <td>
            <span v-text="o.lastSeen"></span>
          </td>
          <td>
            <checkbox
              :o="o"
              p="MFA"
              :disabled="table.disabled(`MFA`, o)"
              :title="table.title(`MFA`, o)"
            ></checkbox>
          </td>
          <td>
            <i
              class="fas fa-key mr-3"
              :title="table.title(`reset`)"
              v-on:click="table.click(`reset`, o)"
            ></i>
            <i
              :class="table.class(`locked`, o)"
              :title="table.title(`locked`, o)"
              v-on:click="table.click(`locked`, o)"
            ></i>
          </td>
          <!--
                      <td>
                          <template v-if="o.household != table.NONE">
                              <span class="text-primary link" v-text="o.household" v-on:click="table.open(`household`, o)" :title="table.title(`household`)"></span>
                          </template>
                          <template v-else>
                              <span v-text="o.household"></span>
                          </template>
                      </td>
                      <td>
                          <template v-if="o.contract != table.NONE">
                              <span class="text-primary link" v-text="o.contract" v-on:click="table.open(`contract`, o)" :title="table.title(`contract`)"></span>
                          </template>
                          <template v-else>
                              <span v-text="o.contract"></span>
                          </template>
                      </td>
                      <td>
                          <checkbox :o="o" p="select" :disabled="table.disabled(`select`, o)"></checkbox>
                      </td>
                      -->
        </tr>
      </tbody>
    </table>
  </scroll-area>
</div>
