<template>
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2 text-secondary"></i>
          <span class="font-weight-bold text-secondary">Delete Invoices</span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="font-weight-bold confirm-alert text-center">
          You are going to delete {{this.countInvoices}} invoices. 
        </div>
            <div class="text-center confirm-info">
                <p>
                Once invoices are deleted the action is irreversible and the invoices will no longer be available.<br>
                Are you sure?
                </p>
          </div>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-sm btn-outline-danger mr-1"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-sm btn-primary submit-btn"
            v-on:click="submit"
          >
            Delete 
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.confirm-alert {
  font-size: 1.2em;
}
.confirm-info {
  font-size: 1.1em;
}
.confirm-sub-info {
  font-size: 0.7em;
}
</style>

<script>

import { loading } from "../../../../component";
export default {
  get components() {
    return { loading };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) =>
        typeof o.methods == `object` && typeof o.methods.success == `function`,
    },
  },
  data() {
    return {
        countInvoices: 0
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
        this.countInvoices = this.data.invoices.length;
    },
    async submit() {
      await this.data.methods.success();
    },
  },
};
</script>
