<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-building mr-2"></i>
          <span class="font-weight-bold" v-text="firm.text(`title`)"></span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <scroll-area class="px-3 py-2">
          <div class="form-row">
            <div class="col-4">
              <div class="form-group">
                <label>Id</label>
                <input
                  class="form-control form-control-sm"
                  :value="firm.id"
                  readonly
                  placeholder="Auto"
                />
                <small class="form-text text-muted">Firm Id</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="firm.name"
                  class="form-control form-control-sm"
                  placeholder="Name"
                />
                <small class="form-text text-muted">Firm Name</small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>Email</label>
                <input
                  v-model="firm.email"
                  class="form-control form-control-sm"
                  type="email"
                  placeholder="Email"
                />
                <small class="form-text text-muted">Firm Email</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Phone</label>
                <input
                  v-model="firm.phone"
                  class="form-control form-control-sm"
                  type="text"
                  placeholder="Phone number"
                />
                <small class="form-text text-muted">Firm Phone</small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label>Address</label>
                <input
                  v-model="firm.address"
                  class="form-control form-control-sm"
                  type="text"
                  placeholder="Address"
                />
                <small class="form-text text-muted">Firm Address</small>
              </div>
            </div>
          </div>
          <template v-if="firm.id">
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <label>Logo</label>
                  <div
                    class="logo my-2"
                    title="Click to upload new logo"
                    v-on:click="file.logo.choose()"
                  >
                    <img :src="firm.logo.data" />
                  </div>
                  <p class="name">
                    <small class="text-muted" v-text="firm.logo.name"></small>
                    <template v-if="firm.logo.file">
                      <i
                        class="fas fa-file-download ml-2"
                        title="Download logo"
                        v-on:click="file.download(firm.logo)"
                      ></i>
                    </template>
                  </p>
                  <file-upload :file="file.logo"></file-upload>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Contract</label>
                  <div
                    class="logo my-2"
                    title="Click to upload new contract"
                    v-on:click="file.contract.choose()"
                  >
                    <img :src="firm.contract.data" />
                  </div>
                  <p class="name">
                    <small
                      class="text-muted"
                      v-text="firm.contract.name"
                    ></small>
                    <template v-if="firm.contract.file">
                      <i
                        class="fas fa-file-download ml-2"
                        title="Download contract"
                        v-on:click="file.download(firm.contract)"
                      ></i>
                    </template>
                  </p>
                  <file-upload :file="file.contract"></file-upload>
                </div>
              </div>
            </div>
          </template>
        </scroll-area>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <remove-button :button="firm.remove"></remove-button>
          <button
            type="button"
            class="btn btn-sm btn-primary ml-2"
            :disabled="firm.disabled(`save`)"
            v-on:click="firm.click(`save`)"
          >
            <span class="button">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
div.logo img {
  width: 150px;
}
div.logo {
  cursor: pointer;
}
p.name i {
  opacity: 0.7;
  cursor: pointer;
}
button {
  width: 80px;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
div.modal-body {
  height: calc(100vh - 12em);
}
div.main {
  margin-right: -0.6em;
}
</style>

<script>
import { fileUpload, removeButton, scrollArea } from "../../../../component";
import { Api, Modal } from "../../../../factory";
import { alert, events } from "../../../../service";

import noImage from "../../../../../../img/no-image.png";
import pdfIcon from "../../../../../../img/pdf-icon.png";

export default {
  get components() {
    return {
      fileUpload,
      removeButton,
      scrollArea
    };
  },
  props: ["data"],
  data() {
    return {
      file: ``,
      firm: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var firm = {
        _file(action, d) {
          var { _id: firmId } = data.firm;
          var load = async o => {
            try {
              var { data: f } = await Api.get(
                `files?firmId=${firmId}&name=${encodeURIComponent(o.name)}`
              );
              o.file = f;
              switch (o.key) {
                case `logo`:
                  o.data = f.dataURI;
                  break;
                case `contract`:
                  o.data = pdfIcon;
                  break;
                default:
                  throw new Error(`Invalid load file object key, ${o.key}!`);
              }
            } catch (e) {
              console.error(e);
              alert.error(e.message);
            }
          };
          var save = o => {
            return new Promise(async (resolve, reject) => {
              try {
                if (o.file) await Api.delete(`files/${o.file._id}`);
                var resp = await Api.post(`files`, { firmId, ...o.upload });
                resolve(resp.data);
              } catch (e) {
                reject(e);
              }
            });
          };
          switch (action) {
            case `load`:
              load(d);
              break;
            case `save`:
              return save(d);
            default:
              throw new Error(`Invalid logo action, ${action}!`);
          }
        },
        _keys: [`address`, `contract`, `id`, `logo`, `name`, `email`, `phone`],
        async _remove() {
          var { methods } = data;
          var { _id: firmId } = data.firm;
          try {
            var r = await Api.delete(`firms/${firmId}`);
            if (typeof methods.success == `function`)
              return methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        async _save() {
          var { contract, logo } = this;
          var logoFile, contractFile;
          if (logo.upload) logoFile = await this._file(`save`, logo);
          if (contract.upload)
            contractFile = await this._file(`save`, contract);
          this._keys.forEach(key => {
            var v;
            switch (key) {
              case `contract`:
                v = contractFile ? contractFile.name : ``;
                break;
              case `logo`:
                v = logoFile ? logoFile.name : ``;
                break;
              default:
                v = this[key];
            }
            if (v) data.firm[key] = v;
          });
          var { firm, methods } = data;
          if (methods && typeof methods.save == `function`)
            return methods.save(firm);
          try {
            var r = firm._id
              ? await Api.put(`firms?_id=${firm._id}`, firm)
              : await Api.post(`firms`, firm);
            if (methods && typeof methods.success == `function`)
              return methods.success(r.data);
            events.$emit(`modal`, { action: `hide` });
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        click(type) {
          switch (type) {
            case `save`:
              return this._save();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type) {
          return false;
          switch (type) {
            case `save`:
              return this.name.length < 1 || this.email.length < 1;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          this._keys.forEach(key => {
            var v;
            switch (key) {
              case `contract`:
              case `logo`:
                v = {
                  key,
                  file: ``,
                  name: data.firm[key] || ``,
                  data: ``,
                  upload: ``
                };
                if (v.name) {
                  this._file(`load`, v);
                } else {
                  v.name = `No ${key}`;
                  v.data = noImage;
                }
                break;
              default:
                v = data.firm[key];
            }
            this[key] = v;
          });
          this.remove = {
            click: () => {
              this._remove();
            },
            disabled: ``
          };
          return this;
        },
        remove: ``,
        text(type) {
          switch (type) {
            case `title`:
              return this.name ? `Firm - ${this.name}` : `Firm`;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        }
      };
      var file = {
        _format: `dataurl`,
        contract: {
          accept: ``,
          format: ``,
          init(format) {
            this.accept = `.pdf`;
            this.format = format;
          },
          upload: (err, f) => {
            if (err) return alert.message(err.message);
            var { contract } = firm;
            contract.data = pdfIcon;
            contract.name = f.name;
            contract.upload = f;
          }
        },
        download(o) {
          var a = document.createElement(`a`);
          a.href = o.file.dataURI;
          a.download = o.file.name;
          a.click();
        },
        init() {
          var { _format } = this;
          this.contract.init(_format);
          this.logo.init(_format);
          return this;
        },
        logo: {
          accept: ``,
          format: ``,
          init(format) {
            this.accept = `.png, .jpg, .jpeg`;
            this.format = format;
          },
          upload: (err, f) => {
            if (err) return alert.message(err.message);
            var { logo } = firm;
            logo.data = f.data;
            logo.name = f.name;
            logo.upload = f;
          }
        }
      };
      this.file = file.init();
      this.firm = firm.init();
    }
  }
};
</script>
