
<div class="modal-dialog modal-dialog-centered modal-md" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-alt mr-2 text-secondary"></i>
        <span class="font-weight-bold text-secondary">Schedule - Confirm</span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close" >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="text-center">
        <p>
        Please note: Changing the fee schedule details will <b>not</b> 
        update the client contracts currently using this fee schedule. Any contracts currently using this 
        fee schedule will remain on the original fee schedule assigned.
        </p>
        <p>
          It is strongly recommended that you create a new fee schedule with the changes and assign the 
          new fee schedule to the client contracts you wish to update. 
        </p>
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button
          type="button"
          class="btn btn-sm btn-outline-danger submit-btn mr-1"
          v-on:click="submit">
          Continue 
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          data-dismiss="modal">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
