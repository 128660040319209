<template>
  <div class="modal-dialog modal-dialog-centered modal-lg"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-database mr-2"></i>
          <span class="font-weight-bold"
                v-text="custodian.text(`title`)"></span>
        </p>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="p-3">
          <div class="form-row">
            <div class="col-3">
              <div class="form-group">
                <label>Id</label>
                <input v-model="custodian.id"
                       class="form-control form-control-sm"
                       placeholder="Id"
                       :readonly="custodian.readonly(`id`)" />
                <small class="form-text text-muted">Custodian Id</small>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Type</label>
                <select v-model="custodian.type"
                        class="form-control form-control-sm"
                        :disabled="custodian.disabled(`type`)"
                        v-on:change="custodian.change(`type`)">
                  <option v-for="(t, i) in custodian.types"
                          :key="i">
                    <span v-text="t"></span>
                  </option>
                </select>
                <small class="form-text text-muted">Custodian Type</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Name</label>
                <input v-model="custodian.name"
                       class="form-control form-control-sm"
                       placeholder="Name" />
                <small class="form-text text-muted">Custodian Name (optional)</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <remove-button :button="custodian.remove"></remove-button>
          <button type="button"
                  class="btn btn-sm btn-primary ml-2"
                  :disabled="custodian.disabled(`save`)"
                  v-on:click="custodian.click(`save`)">
            <span class="button">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
button {
  width: 80px;
}

div.main {
  height: 68vh;
  margin-right: -0.8em;
}

table {
  margin-left: -5px;
  margin-bottom: 2em;
}

table thead td:not(:last-child) {
  width: 45%;
}

i:not(.fa-house-user) {
  opacity: 0.3;
}

i:hover:not(.fa-house-user) {
  cursor: pointer;
  opacity: 1;
}

p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
</style>

<script>
import { removeButton, scrollArea } from "../../../../component";
import { Api, Modal } from "../../../../factory";
import { alert } from "../../../../service";

export default {
  name: `FirmModalMaster`,
  get components() {
    return {
      removeButton,
      scrollArea,
    };
  },
  props: {
    data: {
      type: Object,
      validator: (o) => typeof o.custodian == `object`,
    },
  },
  data() {
    return {
      custodian: ``,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var custodian = {
        _keys: [`_id`, `id`, `name`, `type`],
        nonCustodialType: `Non-custodial assets`,
        async _remove() {
          try {
            var { custodian: c, methods } = data;
            var r = await Api.delete(`custodians/${c._id}`);
            if (methods && typeof methods.success == `function`)
              return data.methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error(e);
            if (methods && methods.error == `function`) return methods.error(e);
            alert.error(e.message);
          }
        },
        async _save() {
          var custodian = this._keys.reduce((o, key) => {
            o[key] = this[key];
            return o;
          }, {});
          try {
            var { methods } = data;

            var r = data.custodian._id
              ? await Api.put(`custodians/${data.custodian._id}`, custodian)
              : await Api.post(`custodians`, {
                  id: custodian.id.replace(/-/g, ""),
                  name: custodian.name,
                type: custodian.type,
              });
            if (methods && typeof methods.success == `function`)
              return data.methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error('Invalid or duplicated custodian id');
            alert.error('Invalid or duplicated custodian id');
          }
        },
        change(type) {
          switch (type) {
            case `type`:
              if (
                this.type == this.nonCustodialType &&
                this.id == ``
              ) {
                this.id = `NCA`;
              }
              // var [c] = this.types;
              // if (this.type != c) {
              //   alert.warning(
              //     `Sorry, ${this.type} is not yet implemented!`,
              //     3e3
              //   );
              //   this.type = c;
              // }
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          switch (type) {
            case `remove`:
              return typeof d == `number`
                ? d > 0
                  ? ``
                  : `disabled`
                : this._delete
                  ? `btn-danger`
                  : `btn-outline-danger`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(type) {
          switch (type) {
            case `save`:
              return this._save();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type) {
          switch (type) {
            case `remove`:
              return !data.custodian._id;
            case `save`:
              return this.id.length < 1 && this.type != this.nonCustodialType;
            case `type`:
              return data.custodian.hasOwnProperty(`_id`);
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          this._keys.forEach((key) => {
            switch (key) {
              case `type`:
                this[key] = data.custodian[key] || this.types[0];
                break;
              default:
                this[key] = data.custodian[key] || ``;
            }
          });
          this.remove = {
            click: () => {
              this._remove();
            },
            disabled: ``,
          };
          return this;
        },
        readonly(type) {
          switch (type) {
            case `ref`:
            case `number`:
            case `id`:
              return data.custodian.hasOwnProperty(`_id`);
            default:
              throw new Error(`Invalid readonly type, ${type}!`);
          }
        },
        remove: ``,
        text(type) {
          switch (type) {
            case `title`:
              var name = this.name || this.type;
              var info = this.id ? `${this.id} (${name})` : ``;
              return info ? `Custodian - ${info}` : `Custodian`;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        get types() {
          return [`Schwab`, `IPX`, `Warren Averett`, this.nonCustodialType, "TD"];
        },
      };
      this.custodian = custodian.init();
    },
  },
};
</script>
