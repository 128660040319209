<template>
    <div class="modal-dialog modal-dialog-centered modal-lg min-modal" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title">
            <i class="fas fa-database mr-2"></i>
            <span class="font-weight-bold">Update Account Values</span>
          </p>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="btn mr-2 btn-outline-secondary" v-on:click="startUpload()">
            <i class="fas mr-1 fa-plus-square"></i>
            <span class="small">Import from CSV</span>
            <input ref="upload" class="d-none" type="file" accept=".csv" v-on:change="upload" />
          </div>
          <div class="btn btn-link" v-on:click="downloadSample()">
            <i class="fas mr-1 fa-file-download"></i>
            <span class="small">Download sample CSV</span>
          </div>
          <table class="mt-3 table table-sm">
            <thead>
              <tr>
                <th>
                  <span>Number *</span>
                </th>
                <th>
                  <span>New value date</span>
                </th>
                <th>
                  <span>Updated value</span>
                </th>
                <th class="text-right">
                  <i class="fas fa-plus-circle" v-on:click="addRow()"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(account, index) in accounts" :key="index">
                <td>
                  <input v-model="account.number" class="form-control form-control-sm" />
                </td>
                <td>
                  <date-picker :o="account" p="startDate"></date-picker>
                </td>
                <td>
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <b class="px-1">$</b>
                      </span>
                    </div>
                    <input
                      v-model="account.initialValue"
                      class="form-control form-control-sm"
                      type="number"
                    />
                  </div>
                </td>
                <td class="text-right">
                  <i class="fas fa-minus-circle" v-on:click="removeRow(index)"></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer pr-3">
          <div class="text-right">
            <button type="button" class="btn btn-sm btn-primary submit-button" v-on:click="submit()">
              <span class="button">Save</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  .submit-button{
    width: 80px;
  }
  .min-modal {
    min-width: 50vw;
  }
  .min-modal .modal-body {
    min-height: 50vh;
  }
  </style>
  
  <script>
  import { scrollArea, datePicker } from "../../../../component";
  import { moment } from "../../../../npm";
  import { Api } from "../../../../factory";
  import { alert } from "../../../../service";
  
  import * as PapaCSV from "papaparse";
  
  export default {
    get components() {
      return {
        scrollArea,
        datePicker,
      };
    },
    props: {
      data: {
        type: Object,
        required: true,
        validator: (o) =>
          typeof o.custodian == `object` &&
          typeof o.methods == `object` &&
          typeof o.methods.success == `function` &&
          typeof o.accounts == `object`
          ,
      },
    },
    data() {
      return {
        accounts: [],
      };
    },
    created() {
      this.accounts = [this.generateDefaultAccount()];
    },
    methods: {
      generateDefaultAccount() {
        return {
          number: "",
          startDate: new Date(),
          initialValue: 0,
        };
      },
      addRow() {
        this.accounts = [...this.accounts, this.generateDefaultAccount()];
      },
      removeRow(index) {
        this.accounts.splice(index, 1);
      },
      upload(e) {
        const [file] = this.$refs.upload.files;
        e.target.value = ``;
  
        const reader = new FileReader();
  
        reader.onloadend = () => {
          const records = PapaCSV.parse(reader.result, {skipEmptyLines: true});
  
          const accounts = [];
          records.data.forEach((row) => {
            if (row.join(",") != `Number,New value date,Updated value`)
              accounts.push({
                number: row[0],
                startDate: new Date(row[1]),
                initialValue: parseFloat(row[2]),
              });
          });
  
          this.accounts = [...accounts, ...this.accounts];
        };
  
        reader.onerror = (e) => {
          console.error(e);
        };
  
        reader.readAsText(file);
      },
      async submit() {
        try {
          await Api.post("accounts/nca-update", {
            accounts: this.accounts
              .filter((acc) => acc.number.length > 0)
              .map((acc) => ({
                ...acc,
                number: acc.number.trim().replace('-', '').replace('$', ''), 
                startDate: moment(acc.startDate).format("L"),
                custodianId: this.data.custodian._id,
              })),
          });
          this.data.methods.success();
        } catch (error) {
          alert.error(error.message);
        }
      },
      startUpload() {
        this.$refs.upload.click();
      },
      downloadSample() {
        var text = `Number,New value date,Updated value`
        const formattedDate = moment().format('MM/DD/YYYY');
        this.data.accounts.forEach(a => {
          text += `\n${a},${moment(formattedDate).format("L")},0`;
        })
        const url = "data:text/plain;charset=utf-8," + encodeURIComponent(text);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
  
        a.download = "nca_update_sample.csv";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      },
    },
  };
  </script>
  