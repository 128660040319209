
<div class="modal-dialog modal-dialog-centered" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-cog mr-2"></i>
        <span class="font-weight-bold">MFA</span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="px-5 py-3">
        <mfa :submit="modal.submit"></mfa>
      </div>
    </div>
  </div>
</div>
