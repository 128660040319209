import http from "../service/http";

import { auth, session } from "../service";

class Api {
  static _options(options = {}) {
    var headers = { ...options };
    var token = session.get(`token`) ? session.get(`token`) : auth.token();
    if (token) {
      //@ts-ignore
      headers.authorization = `Bearer ${token}`;
    }
    var { user } = session.get(`state`);
    if (user && typeof user.mfa == `object` && user.mfa.active) {
      //@ts-ignore
      headers.mfaToken = user.mfa.token || ``;
    }
    return { headers };
  }

  static _path(p) {
    return p[0] == `/` ? p.slice(1) : p;
  }

  static _url(path = `/`) {
    var config = session.get(`config`);

    let baseUrl = config.api.url;
    var url = `${baseUrl}/${Api._path(path)}`;
    return url;
  }

  static delete(path, options) {
    return http.delete(Api._url(path), Api._options(options));
  }

  static get(path, options?) {
    return http.get(Api._url(path), Api._options(options));
  }

  static post(path, data, options) {
    return http.post(Api._url(path), data, Api._options(options));
  }

  static put(path, data, options?) {
    return http.put(Api._url(path), data, Api._options(options));
  }
}

export default Api;
