<template>
  <div class="h-100">
    <navbar></navbar>
    <component :is="view.component"></component>
  </div>
</template>

<script>
import { navbar } from "../../component";
import { events } from "../../service";
import dashView from "./dash/index.vue";
import firmsView from "./firms/index.vue";

export default {
  name: `UserView`,
  get components() {
    return {
      firmsView,
      dashView,
      navbar
    };
  },
  data() {
    return {
      view: ``
    };
  },
  created() {
    this.init();
    var { view } = this;
    this.events = events.$watch({
      view: view.event.bind(view)
    });
  },
  destroyed() {
    events.$unwatch(this.events);
  },
  methods: {
    init() {
      var view = {
        _component(v) {
          return this._components.includes(v) ? `${v}-view` : ``;
        },
        _components: [/*`dash`,*/ `firms`],
        _view: v => {
          if (this.view.component != v) return (this.view.component = v);
          this.view.component = ``;
          this.$nextTick(() => {
            this.view.component = v;
          });
        },
        component: ``,
        event(e) {
          var v = e.data.toLowerCase();
          if (!this._component(v)) throw new Error(`Invalid view, ${v}!`);
          this._view(this._component(v));
        },
        init() {
          return this;
        }
      };
      this.view = view.init();
    }
  }
};
</script>
