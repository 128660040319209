<template>
  <div class="main d-flex m-2">
    <div class="menu h-100" :class="nav.class(`toggle`)">
      <div id="accordionMenu">
        <households></households>
        <schedules></schedules>
      </div>
    </div>
    <div class="toggle" :title="nav.title" v-on:click="nav.toggle()"></div>
    <div class="content flex-grow-1 h-100">
      <household></household>
    </div>
  </div>
</template>

<style scoped>
div.menu:not(.hide) {
  flex: 0 0 22em;
  transition: all 0.2s ease;
}
div.menu.hide {
  flex: 0 1;
  width: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}
div.content {
  width: 70%;
}
div.toggle {
  background-color: black;
  width: 5px;
  opacity: 0.1;
}
div.toggle:hover {
  cursor: pointer;
  opacity: 0.5;
}
div.main {
  height: calc(100% - 4.6rem);
}
</style>

<script>
import { session } from "../../../service";
import households from "./households.vue";
import household from "./household/index.vue";
import schedules from "../shared/schedules.vue";

export default {
  get components() {
    return {
      households,
      household,
      schedules,
    };
  },
  data() {
    return {
      nav: ``,
    };
  },
  created() {
    this._keyListener = function (e) {
      if (e.key == "b" && (e.ctrlKey || e.metaKey)) {
        this.nav.toggle();
      }
    };
    document.addEventListener("keydown", this._keyListener.bind(this));

    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      if (!state.hasOwnProperty(`household`)) this.$set(state, `household`, ``);
      var nav = {
        _hide: false,
        class() {
          return this._hide ? `hide` : ``;
        },
        init() {
          return this;
        },
        get title() {
          return this._hide ? `Show menu` : `Hide menu`;
        },
        toggle() {
          this._hide = !this._hide;
        },
      };
      this.nav = nav.init();
    },
  },
};
</script>
