
<div
  :id="modal.id"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  :data-backdrop="modal.backdrop"
  :data-keyboard="modal.keyboard"
>
  <template v-if="modal.enable">
    <component :is="modal.component" :data="modal.data"></component>
  </template>
</div>
