
<div class="modal-dialog modal-dialog-centered modal-lg"
     role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-database mr-2"></i>
        <span class="font-weight-bold"
              v-text="custodian.text(`title`)"></span>
      </p>
      <button type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="p-3">
        <div class="form-row">
          <div class="col-3">
            <div class="form-group">
              <label>Id</label>
              <input v-model="custodian.id"
                     class="form-control form-control-sm"
                     placeholder="Id"
                     :readonly="custodian.readonly(`id`)" />
              <small class="form-text text-muted">Custodian Id</small>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label>Type</label>
              <select v-model="custodian.type"
                      class="form-control form-control-sm"
                      :disabled="custodian.disabled(`type`)"
                      v-on:change="custodian.change(`type`)">
                <option v-for="(t, i) in custodian.types"
                        :key="i">
                  <span v-text="t"></span>
                </option>
              </select>
              <small class="form-text text-muted">Custodian Type</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Name</label>
              <input v-model="custodian.name"
                     class="form-control form-control-sm"
                     placeholder="Name" />
              <small class="form-text text-muted">Custodian Name (optional)</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <remove-button :button="custodian.remove"></remove-button>
        <button type="button"
                class="btn btn-sm btn-primary ml-2"
                :disabled="custodian.disabled(`save`)"
                v-on:click="custodian.click(`save`)">
          <span class="button">Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
