<template>
  <label>
    <slot>{{ label }}</slot>
    <span v-if="required" class="red-asterisk">*</span>
  </label>
</template>

<style scoped>
label {
  color: #6c757d;
}
.red-asterisk {
    color: rgb(255, 0, 0);
    margin-left: 2px;
    font-size: 12px;
    vertical-align: top;
}

</style>
<script>

export default {
  props: {
    label: "",
    required: false
  }
};
</script>
