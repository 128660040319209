<template>
  <div>

    <p>Dear [Client],</p>
    <p>It's the time of year when we send along a few important reminders and documents for your files.</p>
    <ul>
        <li>Attached is Black Barn's updated form ADV. The ADV is a detailed description of Black Barn's advisory business, and we're required to file it with the SEC and share it with you every year. Review at your leisure and let us know if you have any questions;</li>
        <li>I've also attached our Privacy Policy and;</li>
        <li>Please note that to minimize emails containing account information, we will continue to upload quarterly invoices to your secure "Documents" portal in Blueleaf. In addition to the weekly/monthly emails you can receive from Blueleaf, you can use your Blueleaf subscription at any time to view your Charles Schwab holdings and see portfolio performance over different time periods.</li>
    </ul>
    <p>Thank you,</p>
    <p>Black Barn Financial</p>

  </div>
</template>

<script>
export default {
}
</script>
