<template>
  <div class="h-100">
    <template v-if="ready">
      <scroll-area class="pr-3">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>
                <span>Ref</span>
              </th>
              <th>
                <span>Date</span>
              </th>
              <th>
                <span>Item</span>
              </th>
              <th>
                <span>Modified by</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(audit, i) in allAudits" :key="audit._id">
              <td>
                <a v-if="audit.ref"
                  class="badge badge-custom"
                  title="View contract"
                  v-text="audit.id"
                  v-on:click="viewContract(audit.ref)"
                ></a>
                <span v-else></span>
              </td>
              <td>
                <span v-text="audit.date"></span>
              </td>
              <td>
                <span v-text="audit.item"></span>
              </td>
              <td>
                <span v-text="audit.user ? `${audit.user.name} - ${audit.user.email}` : ``"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </scroll-area>
    </template>
    <template v-else>
      <loading></loading>
    </template>
  </div>
</template>

<style scoped>
span {
  font-size: 0.9em;
}

span.link {
  cursor: pointer;
  text-decoration: underline;
}
</style>

<script>
import moment from "moment";
import { loading, scrollArea } from "../../../../component";
import { Api, Record, Modal } from "../../../../factory";

export default {
  get components() {
    return {
      scrollArea,
      loading,
    };
  },
  data() {
    return {
      ready: false,
      allAudits: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllAudits();
      this.ready = true;
    },
    async getAllAudits() {
      const audits = (await Api.get(`audits`)).data;
      // const user = (await Api.get("users/"))
      this.allAudits = audits.map(audit => {
        const id = Record.id({ id: audit.ref })
        return { ...audit, id, date: moment(audit.date).format(`MMM D, YYYY`) }
      })

    },
    async viewContract(contractId) {
      const contract = (await Api.get(`contracts?id=${contractId}`)).data.pop()
      // console.log({ contract })

      Modal.open(`contract`, {
        contract,
        methods: {
          success: () => {
            Modal.close();
            this._reload();
          },
        },
      });
    }
  },
};
</script>
