<template>
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2 text-secondary"></i>
          <span class="font-weight-bold text-secondary">Set invoices as "Paid"</span>
        </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="font-weight-bold confirm-alert text-center">
          This will mark {{this.countInvoices}} pending invoices selected as "Paid."
        </div>
        <div class="text-center confirm-info">
          <p>
            You will not be able to download the fee file or send the invoices for invoices marked "Paid."<br>
            Please only complete this action after you have submitted and received payment from the custodian.
          </p>
        </div>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button type="button" class="btn btn-sm btn-outline-danger mr-1" data-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-sm btn-warning submit-btn" v-on:click="submit">
           Confirm 
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.confirm-alert {
  font-size: 1.2em;
}
.confirm-info {
  font-size: 1.1em;
}
.confirm-sub-info {
  font-size: 0.7em;
}
</style>

<script>

import { loading } from "../../../../component";
export default {
  get components() {
    return { loading };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) =>
        typeof o.methods == `object` && typeof o.methods.success == `function`,
    },
  },
  data() {
    return {
        countInvoices: 0
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
        this.countInvoices = this.data.invoices.length;
    },
    async submit() {
      await this.data.methods.success();
    },
  },
};
</script>
