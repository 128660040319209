<template>
  <div class="d-flex">
    <div class="flex-grow-1">
      <input
        v-model="search.model"
        class="form-control form-control-sm"
        placeholder="Search"
        v-on:keyup="search.keyup()"
        v-on:keydown="search.keydown()"
      />
    </div>
    <div class="search">
      <template v-if="search.model.length">
        <i
          class="fas fa-times-circle reset"
          title="Clear search"
          v-on:click="search.click(`reset`)"
        ></i>
      </template>
      <template v-else>
        <i class="fas fa-search"></i>
      </template>
    </div>
  </div>
</template>

<style scoped>
i {
  opacity: 0.3;
  margin-left: -25px;
  vertical-align: bottom;
}
i.reset:hover {
  opacity: 1;
  cursor: pointer;
  color: red;
}
</style>

<script>
import { Sys } from "../factory";

export default {
  props: {
    o: {
      type: Object
    },
    p: {
      type: String,
      default: () => `search`
    },
    blip: {
      type: Number,
      default: () => 4e2
    }
  },
  data() {
    return {
      search: ``
    };
  },
  created() {
    var { o, p, blip } = this;
    var search = {
      async _update() {
        await Sys.wait(1e1);
        o[p] = this.model;
      },
      keyup() {
        if (!this._blip) this._blip = setTimeout(this._update.bind(this), blip);
      },
      keydown() {
        if (this._blip) this._blip = clearTimeout(this._blip);
      },
      click(type) {
        switch (type) {
          case `reset`:
            this.model = ``;
            this._update();
            break;
          default:
            throw new Error(`Invalid click type, ${type}!`);
        }
      },
      init() {
        this.model = o[p];
        return this;
      },
      model: ``
    };
    this.search = search.init();
  }
};
</script>
