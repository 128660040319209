
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">Email</div>
      <button type="button" class="close" aria-label="Close" v-on:click="back()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <div class="px-4 py-3">
        <OnboardingTitle>The following email will be sent to {{ clientName }}</OnboardingTitle>
        <div class="mt-4 email-body">
            <p>Dear [First Name],</p>
            <p>Attached are our most recent regulatory disclosures. We are required to disclose this information to you at the onset of our relationship.</p>
            <p>Please review and maintain copies for your records. If you have any questions, please email your advisor.</p>
       </div>
      </div>
    </div>

    <div class="modal-footer pr-3">
      <div>
        <button
          v-if="data.methods.back"
          type="button"
          class="btn btn-sm modal-button btn-outline-danger"
          v-on:click="back()"
        >
          <span>Back</span>
        </button>
        <button
          v-if="data.methods.send"
          type="button"
          class="btn btn-sm modal-button btn-primary ml-2"
          v-on:click="send()"
        >
          <span>Send</span>
        </button>
      </div>
    </div>
  </div>
</div>
