
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <div class="d-flex p-1 pb-2 header">
    <div class="d-flex actions mr-2">
      <div title="Create Invoice"
        class="btn btn-outline-secondary action-button"
           v-on:click="table.click(`create`, `invoice`)" >
        <i class="mr-1 fas fa-file-invoice-dollar"></i>
        <span>Create Invoice</span>
      </div>
    </div>
    <div class="flex-grow-1 text-right">
      <span v-text="table.records"></span>
    </div>
  </div>
<template v-if="ready">
  <scroll-area class="pr-3">
    <a ref="download"
       class="d-none"></a>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <span>Id</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `id`)"
               v-on:click="table.sort(`id`)"></i>
          </th>
          <th>
            <span>Ref</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `ref`)"
               v-on:click="table.sort(`ref`)"></i>
          </th>
          <th>
            <span>Accounts</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `accounts`)"
               v-on:click="table.sort(`accounts`)"></i>
          </th>
          <th>
            <span>Schedules</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `schedules`)"
               v-on:click="table.sort(`schedules`)"></i>
          </th>
          <th class="text-nowrap">
            <span>Date</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `date`)"
               v-on:click="table.sort(`date`)"></i>
          </th>
          <th class="text-nowrap">
            <span>Status</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `status`)"
               v-on:click="table.sort(`status`)"></i>
          </th>
          <th class="text-nowrap"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.items"
            :key="o._id">
          <td>
            <a href="#" class="badge badge-custom"
                  :title="table.title(`contract`)"
                  v-on:click="table.click(`contract`, o)"
                  v-text="o.id"></a>
          </td>
          <td class="text-nowrap">
            <span v-text="o.ref"></span>
          </td>
          <td class="" >
              <a href="#" v-for="(a, i) in o.accounts"
                    :key="i"
                    class="badge badge-custom mr-2"
                    :title="table.title(`account`, a)"
                    v-on:click="table.click(`account`, a)"
                    v-text="a"></a>
          </td>
          <td class="schedules">
            <!-- <schedule-badges :schedules="o.schedules"></schedule-badges> -->
            <span v-for="(s, i) in o.schedules" :key="i" class="mr-2" v-text="s"></span>
          </td>
          <td class="text-nowrap">
            <span v-if="o.expiredOn"
                  class="alert-danger"
                  v-text="o.expiredOn"></span>
            <span v-else
                  v-text="o.date"></span>
          </td>
          <td>
            <div class="dropdown">
              <span class="badge"
                    :data-toggle="prod ? `` : `dropdown`"
                    :class="table.class(`status`, o)"
                    v-text="o.status"></span>

              <div ref="status"
                   class="dropdown-menu">
                <button class="dropdown-item"
                        v-for="(status, i) in table._status"
                        v-on:click="table.updateContract(o._id, i)">{{ status.text }}</button>
              </div>
            </div>
          </td>
          <td class="text-nowrap">
            <i class="far fa-file-pdf mr-2"
               :title="table.title(`pdf`)"
               v-on:click="table.click(`pdf`, o)"></i>
            <i class="fas fa-dollar-sign mr-2"
               :title="table.title(`bill`)"
               :class="o.status != `Active` ? `disabled` : ``"
               v-on:click="o.status == `Active` ? table.click(`bill`, o) : ``"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </scroll-area>
  </template>
  <template v-else>
    <loading></loading>
  </template>
</div>
