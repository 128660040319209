
import firebase from "firebase/app";
import session from "./session";

class Auth {
  static async _token() {
    var user = firebase.auth().currentUser;
    var token = user ? await user.getIdToken(true) : ``;
    session.set(`token`, token);
  }

  static login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  static logout() {
    session.set(`token`, undefined);
    return firebase.auth().signOut();
  }

  static purge() {
    return window.indexedDB.deleteDatabase("firebaseLocalStorageDb");
  }

  static state(fn) {
    if (typeof fn != "function")
      throw new Error(`This method requires a callback function!`);
    return firebase.auth().onAuthStateChanged(fn);
  }

  static token(token = ``) {
    var key = `token`;
    if (token) return session.set(key, token).get(key);
    Auth._token();
    return session.get(key);
  }
}

export default Auth;
