
var _o = new Map();

class Session {
  static _key(id, type) {
    return `${type}-${id}`;
  }

  static get(...keys) {
    var v = keys.map(key => _o.get(key));
    return v.length > 1 ? v : v[0];
  }

  static set(key, val) {
    _o.set(key, val);
    return Session;
  }

  static state(id, data = {}) {
    var key = Session._key(id, `state`);
    return _o.get(key) || _o.set(key, data).get(key);
  }
}

export default Session;
