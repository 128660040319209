<template>
  <input
    type="radio"
    :name="radioName"
    :checked="radioValue == value"
    :value="radioValue"
    v-on:change="$emit('change', $event.target.value)"
  />
</template>

<script>
export default {
  props: [
    "value",
    "radioName",
    "radioValue",
  ],
  model: {
    prop: 'value',
    event: 'change'
  }
};
</script>
