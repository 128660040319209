<template>
  <div class="h-100">
    <alert-component></alert-component>
    <modal-component></modal-component>
    <video-component></video-component>
    <component :is="view.component"></component>
  </div>
</template>

<style scoped>
div.h-100 {
  background-color: #e9ebee;
}
</style>

<script>
import firebase from "firebase/app";

import {
  alert as alertComponent,
  modal as modalComponent,
  video as videoComponent
} from "./component";
import { Api, Modal } from "./factory";
import { alert, auth, events, session } from "./service";
import {
  admin as adminView,
  user as userView,
  landing as landingView,
  login as loginView
} from "./view";

export default {
  get components() {
    return {
      adminView,
      alertComponent,
      landingView,
      loginView,
      modalComponent,
      videoComponent,
      userView
    };
  },
  data() {
    return {
      view: ""
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      var config = session.get(`config`);
      console.info(`Version: ${config.version}`)
      var state = session.get(`state`);
      var view = {
        _authId: ``,
        _authState(a) {
          if (a == null) return this.logout();
          this.login(a.uid);
        },
        _data: ``,
        _firm(user) {
          return new Promise(async (resolve, reject) => {
            try {
              var { data } = await Api.get(`firms/${user.firmId}`);
              resolve(data);
            } catch (e) {
              reject(e);
            }
          });
        },
        _home: () => (state.user.role == `admin` ? `admin` : `user`),
        _init: ``,
        _list: [`login`, `home`],
        _mfa(user) {
          return new Promise(async (resolve, reject) => {
            try {
              var mfa = {
                hidden: false,
                hide() {
                  this.hidden = true;
                  Modal.close();
                }
              };
              Modal.open(`mfa-login`, {
                methods: {
                  success: token => {
                    user.mfa.token = token;
                    mfa.hide();
                    resolve();
                  },
                  error: () => {
                    alert.error(`MFA Login Failed!`, 3e3);
                    mfa.hide();
                    auth.logout();
                  }
                }
              });
              events.$on(`modal`, e => {
                if (!mfa.hidden) auth.logout();
              });
            } catch (e) {
              reject(e);
            }
          });
        },
        _user(authId) {
          return new Promise(async (resolve, reject) => {
            try {
              var { data } = await Api.get(`users?authId=${authId}`);
              resolve(data[0]);
            } catch (e) {
              reject(e);
            }
          });
        },
        get component() {
          var view = this._init
            ? this._authId
              ? this._home()
              : `login`
            : `landing`;
          return `${view}-view`;
        },
        init() {
          auth.state(this._authState.bind(this));
          return this;
        },
        async login(authId) {
          try {
            this._init = false;
            auth.token(await firebase.auth().currentUser.getIdToken());
            var user = await this._user(authId);
            if (!user)
              throw new Error(
                `Failed to retrieve user for firebase uid, ${authId}`
              );
            if (typeof user.mfa == `object` && user.mfa.active)
              await this._mfa(user);
            this._authId = authId;
            state.user = user;
            state.firm = await this._firm(user);
            this._init = true;
          } catch (e) {
            auth.logout()
            auth.purge();
            console.error(e.message);
            alert.error(e.message);
          }
        },
        logout() {
          this._init = true;
          this._authId = ``;
        }
      };
      this.view = view.init();
    }
  }
};
</script>
