<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">New Client Onboarding</div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <div class="px-4 py-3">
          <div class="form-row">
            <div class="col-12">
              <div class="form-group">
                <OnboardingLabel :label="household.name.label" :required="household.name.required" />
                <vue-autosuggest
                  v-model="household.name.value"
                    :suggestions="filteredOptions"
                    :limit="5"
                    @input="onInputChange"
                    @selected="onSelected"
                    :input-props="inputProps" >
                </vue-autosuggest>
                <div class="form-check">
                 <input class="form-check-input" 
                 type="checkbox" :disabled="disableCreateInCrm" 
                 v-model="createInCrm" id="crmCheckbox">
                 <label class="form-check-label small" for="crmCheckbox">
                   Create household in the CRM 
                 </label>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="col-3">
              <OnboardingLabel :label="contacts.firstName.label" :required="contacts.firstName.required"  />
            </div>

            <div class="col-3">
              <OnboardingLabel :label="contacts.lastName.label" :required="contacts.lastName.required" />
            </div>

            <div class="col-3">
              <OnboardingLabel :label="contacts.email.label" :required="contacts.email.required"  />
            </div>
          </div>

          <ContactRow :contact="contacts" v-on:plus="addContact()" />

          <div v-for="(contact, i) in contacts.array">
            <ContactRow
              class="mt-2"
              :key="contact.id"
              :contact="contact"
              :i="i"
              v-on:plus="addContact()"
              v-on:minus="removeContact(i)"
            />
          </div>

          <div class="form-row">
            <div class="col-12">
              <OnboardingSmall :small="contacts.small" />
            </div>
          </div>

          <hr />
           <label>Advisor</label>
            <div class="form-row">
              <div class="col vertical-line">
                <div class="dropdown" ref="dropdown">
                  <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="ddmBtnAdvisors"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {{ selectedAdvisors.length ? selectedAdvisors.map(a => a.name).join(', ') : 'Select Advisors' }}
                  </button>
                  <div class="dropdown-menu" aria-labelledby="ddmBtnAdvisors">
                    <div v-for="advisor in advisors" :key="advisor.id" class="dropdown-item">
                      <div class="form-check">
                        <input type="checkbox" class="form-check-input" :id="'advisor-' + advisor.id" :value="advisor"
                          v-model="selectedAdvisors"
                          :disabled="selectedAdvisors.length >= 2 && !selectedAdvisors.includes(advisor)">
                        <label class="form-check-label" :for="'advisor-' + advisor.id">
                          {{ advisor.name }}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="col">
              <div class="input-group input-group-sm">
                <input type="text" class="form-control" 
                  placeholder="Advisor's name" 
                  aria-label="Advisor's name" 
                  ref="advisorNameInput"
                  @keypress.enter="addAdvisor">
                <div class="input-group-append">
                  <button 
                  @click="addAdvisor"
                  class="btn btn-outline-secondary" 
                  type="button">Add</button>
                </div>
              </div>
              <small class="form-text text-muted">Add a new advisor</small>
            </div>
          </div>
          <hr />
          <label id="addressLabel" data-toggle="collapse" data-target="#collapsibleSection">Household Address
            <span class="chevron"><i class="fas fa-chevron-down"></i></span>
          </label>
          <div id="collapsibleSection" class="form-row collapse">
            <div class="col">
              <div class="form-group">
                    <input type="text" class="form-control form-control-sm" 
                    v-model="address.addressLine1" required>
                    <small class="form-text text-muted">Address Line 1</small>
              </div>
              <div class="form-group">
                    <input type="text" class="form-control form-control-sm" 
                    v-model="address.addressLine2">
                    <small class="form-text text-muted">Address Line 2 (optional)</small>
              </div>
              <div class="form-group">
                  <input type="text" class="form-control form-control-sm" 
                  v-model="address.city">
                  <small class="form-text text-muted">City</small> 
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <select class="form-control form-control-sm" v-model="address.state">
                  <option value="">Select State/Province</option>
                  <optgroup label="United States">
                    <option v-for="(state, code) in usStates" 
                    :value="code" :key="code">{{ state }}</option>
                  </optgroup>
                  <optgroup label="Canadian Provinces">
                      <option v-for="(province, code) in canadianProvinces" 
                      :value="code" :key="code">{{ province }}</option>
                  </optgroup>
                </select> 
                <small class="form-text text-muted">State/Province</small>
              </div>
              <div class="form-group">
                  <input type="text" class="form-control form-control-sm" 
                  v-model="address.postalcode" required>
                  <small class="form-text text-muted">Zip/Postal Code</small> 
              </div>
              <div class="form-group">
                <select class="form-control form-control-sm" v-model="address.country" required>
                  <option value="" disabled>Select Country</option>
                  <option v-for="(country,code) in countries" 
                  :value="code" :key="code" >{{ country }}</option>
                </select>
                <small class="form-text text-muted">Country</small>
              </div>
            </div>
          </div>
          <hr />
          <div class="form-row mt-3">
            <div class="col-12">
              <OnboardingTitle :title="disclosureNotices.title" />
              <OnboardingSmall :small="disclosureNotices.small" />
            </div>

            <div
              class="col-6 mt-2" :key="i"
              v-for="(option, i) in disclosureNotices.options" >
              <OnboardingCheckbox class="mr-2 file-checkbox" v-model="option.checked" />
              <OnboardingLabel class="file-label" :label="option.label" />
            </div>
          </div>

          <hr>
          <div class="form-row mt-3">
            <div class="col-12">
              <OnboardingTitle :title="clientAgreement.title" />
            </div>
            <div class="col-7">
              <OnboardingLabel :label="clientAgreement.radio.label" />
            </div>

            <div class="col">
              <OnboardingRadio
                class="mr-2"
                :radioName="clientAgreement.title"
                :radioValue="clientAgreement.radio.options.yes"
                v-model="clientAgreement.radio.value"
              />
              <OnboardingLabel :label="clientAgreement.radio.options.yes" />
            </div>

            <div class="col">
              <OnboardingRadio
                class="mr-2"
                :radioName="clientAgreement.title"
                :radioValue="clientAgreement.radio.options.no"
                v-model="clientAgreement.radio.value"
              />
              <OnboardingLabel :label="clientAgreement.radio.options.no" />
            </div>
            <hr>
            <div
              class="form-row mt-2"
              v-if="clientAgreement.radio.value == clientAgreement.radio.options.no">
              <div class="col-2"></div>
              <div class="col-8">
                <OnboardingLabel
                  class="text-center"
                  label="You will be able to send a contract for signature at a later time at your discretion"
                />
              </div>
            </div>
            </div>
            <div class="form-row mt-2" 
                v-if="clientAgreement.radio.value == clientAgreement.radio.options.yes" >
                  <div class="col-4">
                     <OnboardingLabel :label="clientAgreement.template.label" />
                     <select class="form-control form-control-sm" v-model="clientAgreement.template.value" required>
                       <option value="" disabled>Select Template</option>
                         <option v-for="template in templates" 
                         :value="template.id" :key="template.id">{{ template.name }}</option>
                       </select>
                     <OnboardingSmall :small="clientAgreement.template.small" />
                  </div>
                  <div class="col-4">
                     <OnboardingLabel :label="clientAgreement.schedule.label" />
                     <select class="form-control form-control-sm" v-model="clientAgreement.schedule.value" required>
                       <option value="" disabled>Select Schedule</option>
                         <option v-for="schedule in schedules" 
                         :value="schedule._id" :key="schedule._id">{{ schedule.name }}</option>
                       </select>
                     <OnboardingSmall :small="clientAgreement.schedule.small" />
                  </div>
                  <div class="col-4">
                    <OnboardingLabel :label="clientAgreement.note.label" />
                    <OnboardingInput v-model="clientAgreement.note.value" />
                    <OnboardingSmall :small="clientAgreement.note.small" />
                  </div>
            </div>
          </div>
      </div>

      <div class="modal-footer d-flex justify-content-between">
        <div class="text-left">
            <p class="text-muted">
              Remaining Signature Requests: {{ _firmSignRequestsLimit ? _firmSignRequestsLimit : 0 }}<br>
              Need more? contact sales@smartkx.io
            </p>
          </div>
        <div class="text-right">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="btn btn-sm modal-button btn-outline-danger ml-2"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            class="btn btn-sm modal-button btn-primary ml-2"
            v-on:click="submit()"
            :disabled="disableSubmit"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* CSS to change the chevron icon when the section is collapsed/expanded */
.chevron i:before {
  content: '\f078'; /* FontAwesome down arrow icon */
  transition: transform 0.3s ease;
}

.collapsed .chevron i:before {
  content: '\f077'; /* FontAwesome up arrow icon */
}

#addressLabel {
  cursor: pointer;
}

.file-checkbox {
  margin-top: 6px;
}
.file-label {
  word-break: break-all;
  width: calc(100% - 21px);
}
.modal-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 16px;

  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #616466;
}
.modal-button {
  width: 80px;
}

.modal-body {
  min-height: 50vh;
}
</style>

<style>
#autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
} 

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  overflow: auto;
  max-height: 200px;
}

.autosuggest__results ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results-item {
  cursor: pointer;
  padding: 15px;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  color: gray;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results-item:active,
.autosuggest__results .autosuggest__results-item:hover,
.autosuggest__results .autosuggest__results-item:focus,
.autosuggest__results
  .autosuggest__results_item.autosuggest__results-item-highlighted {
  background-color: #ddd;
}

.vertical-line {
  border-right: 1px dotted rgb(211, 206, 206); 
  height: 100%; 
  margin-right: 8px;
  
}

#ddm-btn-advisors, .dropdown-item{
  text-transform:capitalize
}
.dropdown-menu {
    height: auto;
    max-height: 260px;
    overflow-x: hidden;
}
</style>

<script>
import IconButton from "../../component/icon-button.vue";
import { Api, Modal } from "../../factory";
import { alert, session } from "../../service";
import { validator } from "../../npm";
import scrollArea from "../../component/scroll-area.vue";
import ContactRow from "./components/contact-row.vue";
import OnboardingCheckbox from "./components/onboarding-checkbox.vue";
import OnboardingInput from "./components/onboarding-input.vue";
import OnboardingLabel from "./components/onboarding-label.vue";
import OnboardingRadio from "./components/onboarding-radio.vue";
import OnboardingSmall from "./components/onboarding-small.vue";
import OnboardingTitle from "./components/onboarding-title.vue";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  computed: {
    disableSubmit: function () {
      let isFormValid = Boolean(this.household.name.value)

      // this will error everytime the email value is missing which can happen alot
      if (this.clientAgreement.radio.value.toLowerCase() == 'yes') {
        isFormValid = isFormValid && this.contacts.email.value != "" 
        && this.clientAgreement.template.value != '' 
      }

      if (this.contacts.array.length) {
        isFormValid = isFormValid && this.contacts.array.every(c => c.firstName.value != "")
      }

      return !isFormValid
    }
  },
  props: {
    data: {
      methods: {
        success: () => ""
      },
      // this.data
      clientOnboardingData: {
        type: Object
      },
      files: []
    }
  },
  data() {
    return {
      createInCrm: false,
      disableCreateInCrm: true,
      households: [],
      address: this.resetAddress(), 
      advisors: null,
      selectedAdvisors: [],
      selectedCrmId: null,
      usStates: {
        "AL": "Alabama",
        "AK": "Alaska",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "FL": "Florida",
        "GA": "Georgia",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PA": "Pennsylvania",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming"
      },
      canadianProvinces: {
        "AB": "Alberta",
        "BC": "British Columbia",
        "MB": "Manitoba",
        "NB": "New Brunswick",
        "NL": "Newfoundland and Labrador",
        "NS": "Nova Scotia",
        "NT": "Northwest Territories",
        "NU": "Nunavut",
        "ON": "Ontario",
        "PE": "Prince Edward Island",
        "QC": "Quebec",
        "SK": "Saskatchewan",
        "YT": "Yukon"
      },
      countries: {
        'US': "United States",
        'CA': "Canada"
      },
      templates:[],
      schedules: [],
      filteredOptions: [],
      inputProps: {
        placeholder: "Type to search a household in CRM...",
        value: "",
        class: "form-control", //bootstrap
      },
      onboardSuccess: () => null,
      household: {
        title: "Client Information",
        name: {
          small: ``,
          label: "New Household Name",
          value: "",
          required: true
        }
      },
      contacts: {
        small: `This will create a new "contact" throughout the system.`,
        firstName: {
          label: "First Name",
          value: "",
          required: true,
        },
        lastName: {
          label: "Last Name",
          value: "",
          required: true
        },
        email: {
          label: "Client Email",
          value: "",
          required: true
        },
        array: []
      },
      disclosureNotices: {
        title: "Documents to send to new client",
        small: "Available files can be accessed and uploaded by going to the dropdown at the top right of the screen and selecting 'Files'.",
        options: []
      },
      clientAgreement: {
        title: "Client Agreement",
        radio: {
          label: "Send Client Agreement for Signature?",
          options: {
            yes: "Yes",
            no: "No"
          },
          value: ""
        },
        note: {
          small: `Notes will be placed in the client agreement contract when sent to the client.`,
          label: "Note (optional)",
          value: ""
        },
        template: {
          small: `The template used for the client agreement.`,
          label: "Agreement Template",
          value: ""
        },
        schedule: {
          small: `The schedule listed in the client agreement.`,
          label: "Schedule",
          value: ""
        }
      },
      _firmSignRequestsLimit: undefined
    };
  },
  async created() {
    const { clientOnboardingData, files } = this.data
    this._firmSignRequestsLimit = session.get(`state`).firm.signRequestsLimit

    if (files) {
      files.forEach(file => {
        this.disclosureNotices.options.push({ label: file.slot ?? file.name, checked: Boolean(file.slot) })
      })
    }

    this.schedules = session.get(`FirmSchedules`);

    if (clientOnboardingData) {
      this.household = clientOnboardingData.household
      this.contacts = clientOnboardingData.contacts
      this.disclosureNotices = clientOnboardingData.disclosureNotices
      this.clientAgreement = clientOnboardingData.clientAgreement
    }

    Api.get(`crm/households`)
      .then(response => { this.households = response.data; })
      .catch(error => { console.error(error); });

    Api.get(`advisors`)
      .then(response => { this.advisors = [{ id: null, name: "none" }, ...response.data]; })
      .catch(error => { console.error(error); });

    Api.get(`templates`)
      .then(response => { this.templates = response.data; })
      .catch(error => { console.error(error); });

  },
  methods: {
    async addAdvisor() {
      const advisorName = this.$refs.advisorNameInput.value
      if (!advisorName) return alert.error('Advisor name is required', 1500)
      try {
        var { data } = await Api.post("advisors", { name: advisorName.trim() });
        const newAdvisor = { name: data.name, id: data.id }
        this.advisors.push(newAdvisor)
        this.$refs.advisorNameInput.value = ''
        this.selectedAdvisor = newAdvisor
        alert.message(`Added new advisor (${advisorName}) successfully`, 1500)
      } catch (e) {
        console.error(e);
        alert.error("Couldn't add the advisor at this time. Please try again later.");
      }
    },
    onInputChange(text) {
      if (text === "" || text === undefined) return;

      const filteredData = this.households
        .map(h => h.name)
        .filter((item) => {
          return item.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, 10);

      this.filteredOptions = [{ data: filteredData, }];

      // if there are no suggestions then enable the create button
      this.disableCreateInCrm = filteredData.length ? true : false;

      if (!filteredData.length) {
        this.contacts.firstName.value = ""
        this.contacts.lastName.value = ""
        this.contacts.email.value = ""
      }
    },
    onSelected(selected) {

      // reset all contacts first
      this.resetContacts()
      this.selectedCrmId = null // reset crm id

      const householdName = selected.item.toLowerCase()
      const [household] = this.households
        .filter(h => h.name.toLowerCase() == householdName)

      this.selectedCrmId = household.id

      // fill out the household info
      this.household.name.value = household.name
      // fill out the first user as the head of household
      let firstTwoMembers = household.members.length >= 2
        ? household.members.slice(0, 2)
        : household.members

      for (const [i, member] of firstTwoMembers.entries()) {
        if (i == 0) { // fill out the first contact
          this.contacts.firstName.value = member.first_name
          this.contacts.lastName.value = member.last_name
          this.contacts.email.value = member.email_address
          continue
        }

        this.contacts.array.push({
          firstName: { value: member.first_name },
          lastName: { value: member.last_name },
          email: { value: member.email_address ?? "" }
        });
      }

      // fill out the address
      const hhAddress = household.address 
      if (hhAddress)
        this.address = {
          addressLine1: hhAddress.addressLine1,
          addressLine2: hhAddress.addressLine2,
          city: hhAddress.city,
          state: hhAddress.state,
          postalcode: hhAddress.postalcode,
          country: hhAddress.country
        } 
      else
        this.address = this.resetAddress()
    },
    resetAddress() {
      return { addressLine1: ``, addressLine2: ``, city: ``, state: ``, postalcode: ``, country: `US` }
    },
    resetContacts() {
      // reset all contacts related info
      this.contacts.firstName.value = ""
      this.contacts.lastName.value = ""
      this.contacts.email.value = ""
      this.contacts.array = []
    },
    newContact: () => ({
      id: Date.now(),
      firstName: {
        value: ""
      },
      lastName: {
        value: ""
      },
      email: {
        value: ""
      }
    }),
    addContact() {
      this.contacts.array.push(this.newContact());
    },
    removeContact(i) {
      this.contacts.array.splice(i, 1);
    },
    async submit() {
      const {
        household,
        contacts,
        disclosureNotices,
        clientAgreement,
        selectedAdvisor,
        address
      } = this

      const clientOnboardingData = {
        household,
        contacts,
        disclosureNotices,
        clientAgreement,
        selectedAdvisor,
        address
      }

      const back = () => {
        Modal.open("ClientOnboarding", {
          methods: this.data.methods,
          clientOnboardingData,
          files: this.data.files,
        })
      }

      // if no disclusure notices checked, skip the second modal
      if (disclosureNotices.options.every(d => d.checked == false)) {
        await this.post(clientOnboardingData)
        this.data.methods.success()
      } else 
        Modal.open("OnboardConfirm", {
          methods: {
            send: async () => {
              await this.post(clientOnboardingData)
              this.data.methods.success()
            },
            back,
          },
          clientOnboardingData,
        })
    },
    async post({ household,
      contacts,
      disclosureNotices,
      clientAgreement,
      address
    }) {
      try {
        var { user } = session.get(`state`);
        await Api.post("/client-onboarding", {
          household: {
            name: household.name.value,
            address,
            firmAdvisorIds: this.selectedAdvisors.map(advisor => advisor.id), // the chosen advisors for this household
            createInCrm: this.createInCrm,
            crmId: !this.createInCrm ? this.selectedCrmId : undefined, //double check we are not trying to recreate
            advisor:  { name: user.name, email: user.email },
            contacts: [
              { firstName: contacts.firstName.value, 
                lastName: contacts.lastName.value, 
                email: contacts.email.value
              },
              ...contacts.array.map(({ firstName, lastName, email }) => (
                { firstName: firstName.value, lastName: lastName.value, email: email.value }
              )),
            ],
          },
          notices: disclosureNotices.options
            .filter(({ checked }) => checked)
            .map(({ label }) => label),
          clientAgreement: {
            send: clientAgreement.radio.value == clientAgreement.radio.options.yes,
            note: clientAgreement.note.value,  
            template: clientAgreement.template.value,
            schedule: clientAgreement.schedule.value
          }
        })

        alert.message("Client created!", 1500)
      } catch (e) {
        console.error(e);
        alert.error(e, 3000);
        throw e;
      }
    }
  },
  components: { 
    VueAutosuggest,
    OnboardingTitle,
    OnboardingLabel,
    OnboardingInput,
    OnboardingSmall,
    IconButton,
    ContactRow,
    OnboardingCheckbox,
    OnboardingRadio,
    scrollArea
  }
};
</script>
