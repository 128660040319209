<template>
  <nav class="navbar navbar-expand-md">
    <a class="navbar-brand" href="#">
      <img class="mx-2" :src="nav.logo" />
    </a>
    <ul class="navbar-nav mr-auto">
      <li v-for="v in nav.views" :key="v.name" class="nav-item">
        <button
          type="button"
          class="btn mr-2 rounded-0"
          :class="nav.class(`active`, v)"
          :title="nav.title(`view`, v)"
          v-on:click="nav.click(`view`, v)"
        >
          <i :class="v.icon" class="ml-1"></i>
          <span class="ml-1">{{ v.name == 'Dash' ? 'Insights' : v.name }}</span>
        </button>
      </li>
    </ul>
    <!-- <div class="dropdown">
      <button
        type="button"
        class="btn mr-2"
        data-toggle="dropdown"
        v-on:click="nav.click(`alerts`)"
      >
        <template v-if="nav.alerts">
          <i class="fas fa-circle alert small"></i>
        </template>
        <i class="far fa-bell"></i>
      </button>
      <div
        ref="alerts"
        class="dropdown-menu dropdown-menu-right"
        v-on:mouseleave="nav.mouse(`leave`, `alerts`)"
      >
        <template v-if="nav.alerts">
          <a
            v-for="(a, i) in nav.alerts"
            :key="i"
            class="dropdown-item"
            v-on:click="nav.click(`alerts`, i)"
          >
            <span v-text="a.message"></span>
          </a>
        </template>
        <template v-else>
          <a class="dropdown-item">
            <span>No new notifications found</span>
          </a>
        </template>
      </div>
    </div>-->
    <div class="dropdown mr-2">
      <button type="button" class="btn py-0 my-1" data-toggle="dropdown">
        <div class="d-flex">
          <div class="m-auto">
            <i class="far fa-user-circle"></i>
          </div>
          <div class="ml-3">
            <span class="d-block text-left" v-text="nav.user"></span>
            <span class="small text-left align-top" v-text="nav.firm"></span>
          </div>
          <div class="m-auto">
            <i class="fas fa-angle-down small ml-2"></i>
          </div>
        </div>
      </button>
      <div ref="user" class="dropdown-menu w-100" v-on:mouseleave="nav.mouse(`leave`, `user`)">
        <template v-if="!nav.admin">
          <a class="dropdown-item" v-on:click="nav.click(`files`)">
            <i class="fas fa-file mr-3"></i>
            <span>Files</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" v-on:click="nav.click(`emailTemplates`)">
            <i class="fas fa-paper-plane mr-3"></i>
            <span>Email Templates</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" v-on:click="nav.click(`settings`)">
            <i class="fas fa-cog mr-3"></i>
            <span>Settings</span>
          </a>
          <div class="dropdown-divider"></div>
        </template>
        <a class="dropdown-item" v-on:click="nav.click(`logout`)">
          <i class="fas fa-sign-out-alt mr-3"></i>
          <span>Logout</span>
        </a>
      </div>
    </div>
    <button type="button" class="btn" v-on:click="nav.click(`help`)">
      <i class="far fa-question-circle"></i>
    </button>
  </nav>
</template>

<style scoped>
ul.mr-auto .nav-item button.active {
  border-bottom: 2px solid white;
}
ul.mr-auto .nav-item button:not(.active):hover {
  border-bottom: 2px solid lightgray;
}
i,
span {
  color: white;
}
button:not(.rounded-0) i:not(.small) {
  font-size: 1.1em;
}
nav.navbar {
  background: #0c2b4b;
  padding: 0 1em;
}
nav.navbar img {
  width: 175px;
  margin-bottom: 3px;
}
a {
  cursor: pointer;
}
.dropdown-menu span,
.dropdown-menu i {
  color: black;
  opacity: 0.9;
  font-size: 0.9em;
}
i.alert {
  color: red;
  font-size: 0.6em;
  margin: -0.1em -2.5em 0 0;
  padding: 0;
  vertical-align: top;
  cursor: pointer;
}
.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}
</style>

<script>
import { scrollArea } from "../component";
import { Api, Modal, Record } from "../factory";
import { alert, auth, events, session } from "../service";

import logo from "../../../img/smartkx-logo.png";

export default {
  get components() {
    return {
      scrollArea
    };
  },
  data() {
    return {
      nav: "",
      state: ""
    };
  },
  created() {
    this.init();
    var { nav } = this;
    this.events = events.$watch({
      modal: nav.events.bind(nav)
    });
  },
  destroyed() {
    events.$unwatch(this.events);
  },
  mounted() {
    this.$nextTick(() => {
      var { user } = this.state;
      var view =
        user.hasOwnProperty(`settings`) && user.settings.view
          ? user.settings.view
          : this.nav.views[0].name;
      this.nav.click(`view`, { name: view });
    });
  },
  methods: {
    init() {
      var state = session.get("state");
      var nav = {
        _active: ``,
        _alert(a) {
          var doc = this._data.docs.find(o => o._id == a.parentId);
          var message;
          switch (a.type) {
            case `contract`:
              message = `Contract ${Record.id(doc)}, ${doc.ref
                } has new notifications`;
              break;
          }
          if (!message) throw new Error(`Invalid alert type, ${a.type}!`);
          return { ...a, message };
        },
        async _alerts(a) {
          var d = this._data.docs.find(o => o._id == a.parentId);
          var { data: h } = await Api.get(`households/${d.householdId}`);
          switch (a.type) {
            case `contract`:
              if (this._active.toLowerCase() != `clients`)
                this.click(
                  `view`,
                  this.views.find(o => o.name.toLowerCase() == `clients`)
                );
              state.household = h;
              state.alert = a;
              break;
            default:
              throw new Error(`Invalid alerts type, ${a.type}!`);
          }
        },
        _data: {},
        _dropdown: (ref, show = true) => {
          var m = show ? `add` : `remove`;
          this.$refs[ref].classList[m](`show`);
        },
        async _load() {
          try {
            if (this._loading) return;
            this._loading = true;
            // var { data: _alerts } = await Api.get(`alerts?mute=false`);
            // var alerts = _alerts.reduce((l, a) => {
            //   if (!l.find(o => o.type == a.type && o.parentId == a.parentId))
            //     l.push(a);
            //   return l;
            // }, []);
            // var docs = alerts.length
            //   ? (
            //       await Promise.all(
            //         alerts.map(a => Api.get(`${a.type}s/${a.parentId}`))
            //       )
            //     ).map(r => r.data)
            //   : [];
            // this._data = { docs };
            // this.alerts = alerts.length ? alerts.map(a => this._alert(a)) : ``;
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          } finally {
            this._loading = false;
          }
        },
        get admin() {
          return state.user.role && state.user.role == `admin`;
        },
        alerts: ``,
        change(type) {
          switch (type) {
            case `user`:
              var component = `change-user`;
              var data = {};
              events.$emit(`modal`, {
                component,
                data,
                class: `modal-dialog-centered modal-lg`
              });
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          switch (type) {
            case `active`:
              return this._active.toLowerCase() == d.name.toLowerCase()
                ? `active`
                : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        async click(type, d) {
          switch (type) {
            case `alerts`:
              return typeof d == `number`
                ? this._alerts(this.alerts[d])
                : this._load();
            case `help`:
              return Modal.open(`help`);
            case `logout`:
              auth.logout();
              break;
            case `settings`:
              return Modal.open(`settings`);
            case `files`:
              return Modal.open(`Files`, {
                files: (await Api.get(`/files/firm-files`)).data
              });
            case `emailTemplates`:
              return Modal.open(`EmailTemplates`);
            case `view`:
              this._active = d.name;
              events.$emit(`view`, d.name);
              break;
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        events(e) {
          switch (e.name) {
            case `modal`:
              if (e.data && e.data.action && e.data.action == `hidden`)
                this._load();
              break;
          }
        },
        get firm() {
          var { firm } = state;
          return firm.id == 1 ? `${firm.name} (Admin)` : firm.name;
        },
        init() {
          this._load();
          return this;
        },
        get logo() {
          return logo;
        },
        mouse(type, d) {
          switch (type) {
            case `leave`:
              return this._dropdown(d, false);
            case `over`:
              return this._dropdown(d);
            default:
              throw new Error(`Invalid mouse type!`);
          }
        },
        title(type, d) {
          switch (type) {
            case `view`:
              return `${d.name} View`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        get user() {
          return state.user.name;
        },
        get views() {
          switch (state.user.role.toLowerCase()) {
            case `admin`:
              return [
                //{ name: `Dash`, icon: `fas fa-chart-pie` },
                { name: `Firms`, icon: `fas fa-building` }
              ];
            case `user`:
              return [
                { name: `Firm`, icon: `fas fa-building` },
                { name: `Clients`, icon: `fas fa-house-user` },
                { name: `Dash`, icon: `fas fa-chart-pie` }
              ];
          }
        }
      };
      this.nav = nav.init();
      this.state = state;
    }
  }
};
</script>
