<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-list-ol mr-2"></i>
          <span class="font-weight-bold" v-text="schedule.text(`name`)"></span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <scroll-area class="px-4 py-3">
          <div class="form-row">
            <div class="col-6">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="schedule.name"
                  class="form-control form-control-sm"
                  placeholder="Name"
                />
                <small class="form-text text-muted">Schedule Name</small>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label>Minimum Annual Fee</label>
                <input
                  v-model="schedule.minimum"
                  class="form-control form-control-sm"
                  placeholder="Minumum"
                  v-on:focus="schedule.focus(`minimum`)"
                  v-on:blur="schedule.blur(`minimum`)"
                />
                <small class="form-text text-muted">Schedule Minimum</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Default</label>
                <p>
                  <checkbox
                    :o="schedule"
                    p="default"
                    title="Toggle default schedule"
                  />
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <td>
                    <span>Range</span>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <span>Rate</span>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(b, i) in schedule.breaks" :key="i">
                  <td>
                    <input
                      class="form-control form-control-sm"
                      :value="schedule.start(i)"
                      readonly
                    />
                  </td>
                  <td class="text-center">
                    <span>-</span>
                  </td>
                  <td class="end">
                    <template v-if="schedule.last(i)">
                      <input
                        class="form-control form-control-sm"
                        value="∞"
                        readonly
                      />
                    </template>
                    <template v-else>
                      <input
                        v-model="schedule.breaks[i + 1]"
                        class="form-control form-control-sm"
                        v-on:focus="schedule.focus(`breaks`, i + 1)"
                        v-on:blur="schedule.blur(`breaks`, i + 1)"
                      />
                    </template>
                  </td>
                  <td class="align-middle">
                    <i class="fas fa-at disabled"></i>
                  </td>
                  <td>
                    <input
                      v-model="schedule.rates[i]"
                      class="form-control form-control-sm"
                      v-on:focus="schedule.focus(`rates`, i)"
                      v-on:blur="schedule.blur(`rates`, i)"
                    />
                  </td>
                  <td class="text-center align-middle">
                    <i
                      class="fas fa-plus-circle mr-2"
                      :class="schedule.class(`add`, i)"
                      :title="schedule.title(`add`, i)"
                      v-on:click="schedule.click(`add`, i)"
                    ></i>
                    <i
                      class="fas fa-minus-circle"
                      :class="schedule.class(`remove`, i)"
                      :title="schedule.title(`remove`, i)"
                      v-on:click="schedule.click(`remove`, i)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </scroll-area>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <remove-button :button="schedule.remove"></remove-button>
          <button
            type="button"
            class="btn btn-sm btn-primary ml-2"
            :disabled="schedule.disabled(`save`)"
            v-on:click="schedule.click(`save`)"
          >
            <span class="button">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
table tbody td:nth-child(1) {
  padding-left: 0;
}
table tbody td:last-child {
  padding-right: 0;
  width: 4rem;
}
table td.text-center:not(.align-middle) {
  width: 2em;
}
table tbody td:nth-child(5) {
  width: 6em;
}
td {
  vertical-align: inherit;
}
button {
  width: 80px;
}
i:not(.fa-list-ol) {
  opacity: 0.3;
}
i:hover:not(.fa-list-ol):not(.fa-at):not(.disabled) {
  cursor: pointer;
  opacity: 1;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
div.modal-body {
  height: calc(100vh - 12em);
}
</style>

<script>
import { checkbox, removeButton, scrollArea } from "../../../../component";
import { Api, Modal } from "../../../../factory";
import { numeral } from "../../../../npm";
import { alert, events, session } from "../../../../service";

export default {
  name: `ModalSchedule`,
  get components() {
    return {
      checkbox,
      removeButton,
      scrollArea
    };
  },
  props: {
    data: {
      type: Object,
      default: () => ({ schedule: {} })
    }
  },
  data() {
    return {
      schedule: ``,
      dataChanged: false
    };
  },
  created() {
    this.init();
  },
  watch: {
    'schedule.name'(newValue, oldValue) {
      if (!oldValue) return //skip intital `undefined` value
      // if the new val is different than the original
      if (this.data.schedule?.name != newValue) this.dataChanged = true
    },
    'schedule.minimum'(newValue, oldValue) {
      if (!oldValue) return //skip intital `undefined` value
      // if the new val is different than the original
      if (this.data.schedule.minimum != newValue) this.dataChanged = true
    },
    'schedule.breaks'(newValue, oldValue) {
      if (!oldValue) return //skip intital `undefined` value
      // if the new val is different than the original
      if (this.data.schedule.breaks != newValue) this.dataChanged = true
    },
    'schedule.rates'(newValue, oldValue) {
      if (!oldValue) return //skip intital `undefined` value
      // if the new val is different than the original
      if (this.data.schedule.rates != newValue) this.dataChanged = true
    },
  },
  methods: {
    init() {
      var { data } = this;
      var _this = this
      var schedules = session.get(`FirmSchedules`);
      var schedule = {
        _add(i) {
          var { breaks, rates } = this;
          if (
            i != breaks.length - 1 ||
            breaks.length > this._limit ||
            rates[rates.length - 1] < 0
          )
            return;
          var b = this._v(breaks[breaks.length - 1]) + this._change.BREAK;
          var r = this._v(rates[rates.length - 1]) + this._change.RATE;
          this.breaks.push(this._v(b, this._format.BREAK));
          this.rates.push(this._v(r, this._format.RATE));
        },
        _change: {
          BREAK: 1e6,
          RATE: -0.1
        },
        _delete: ``,
        _format: {
          BREAK: `$0,0`,
          RATE: `0.[000]%`
        },
        _invalid(type) {
          switch (type) {
            case `break`:
              return this.breaks.find(b => !b);
            case `rate`:
              return this.rates.find(r => !r);
            case `minimum`:
              return !this.minimum;
            default:
              throw new Error(`Invalid validation type, ${type}!`);
          }
        },
        _keys: [`_id`, `breaks`, `default`, `minimum`, `name`, `rates`],
        _limit: 5,
        async _remove(i) {
          if (typeof i == `number`) {
            if (!i) return;
            this.breaks.splice(i, 1);
            this.rates.splice(i, 1);
          } else {

            var { schedule: s, methods } = data;
            if (!s?._id) {
              alert.error("Unable to delete the schedule as it does not exist.", 2e3)
              return
            }

            try {
              var r = await Api.delete(`schedules/${s._id}`);
              if (methods && typeof methods.success == `function`)
                return data.methods.success(r.data);
              Modal.hide();
            } catch (e) {
              console.error(e);
              alert.error(e.message);
            }
          }
        },
        async save() {
          if (!_this.dataChanged) 
            await this._save()
          else
            return Modal.open("schedule-confirm", {
              methods: {
                success: async () => {
                  await this._save();
                  _this.dataChanged = false
                  events.$emit(`view`,`firm`) // refresh the view
                },
              },
            })
        },
        async _save() {
          var schedule = this._keys.reduce((o, key) => {
            switch (key) {
              case `breaks`:
              case `rates`:
                o[key] = this[key].map(v => this._v(v));
                break;
              case `minimum`:
                o[key] = this._v(this[key]);
                break;
              case `_id`:
                break;
              case `name`:
                o[key] = this[key].trim() 
                break;
              default:
                o[key] = this[key];
            }
            return o;
          }, {});
          var { methods } = data;

          if (!data.schedule?._id) {
            const scheduleExist = schedules.findIndex(s => s.name === schedule.name) !== -1
            if (scheduleExist) {
              alert.error("Schedule name is taken. Please choose another.", 3e3);
              return
            }
          }

          try {
            var r =
              typeof data.schedule == `object` && data.schedule._id
                ? await Api.put(`schedules/${data.schedule._id}`, schedule)
                : await Api.post(`schedules`, schedule);
            if (methods && typeof methods.success == `function`)
              return methods.success(r.data);
            Modal.hide();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _v: (v, f) => (f ? numeral(v).format(f) : numeral(v).value()),
        click(type, d) {
          console.log("d => ", d)
          switch (type) {
            case `add`:
              return this._add(d);
            case `remove`:
              return this._remove(d);
            case `save`:
              return this.save();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        blur(type, d) {
          switch (type) {
            case `breaks`:
              var { breaks } = this;
              var b =
                this._v(breaks[d]) > this._v(breaks[d - 1])
                  ? this._v(breaks[d], this._format.BREAK)
                  : this._v(
                      this._v(breaks[d - 1]) + this._change.BREAK,
                      this._format.BREAK
                    );
              this.breaks.splice(d, 1, b);
              break;
            case `rates`:
              var { rates } = this;
              var r =
                rates.length > 1 && d
                  ? this._v(rates[d]) < this._v(rates[d - 1])
                    ? this._v(rates[d], this._format.RATE)
                    : this._v(
                        this._v(rates[d - 1]) + this._change.RATE,
                        this._format.RATE
                      )
                  : this._v(rates[d], this._format.RATE);
              this.rates.splice(d, 1, r);
              break;
            case `minimum`:
              var m = this._v(this.minimum) < 0 ? 0 : this._v(this.minimum);
              this.minimum = this._v(m, this._format.BREAK);
              break;
            case `remove`:
              this._delete = 0;
              break;
          }
        },
        class(type, d) {
          switch (type) {
            case `add`:
              return d == this.breaks.length - 1
                ? this.breaks.length > this._limit ||
                  this._v(this.rates[this.rates.length - 1]) <= 0
                  ? `disabled`
                  : ``
                : `disabled`;
            case `remove`:
              return typeof d == `number`
                ? d > 0
                  ? ``
                  : `disabled`
                : this._delete
                ? `btn-danger`
                : `btn-outline-danger`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        disabled(type) {
          switch (type) {
            case `remove`:
              return !data.schedule._id;
            case `save`:
              return (
                this.name.length < 1 ||
                this._invalid(`break`) ||
                this._invalid(`rate`) ||
                this._invalid(`minimum`)
              );
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        focus(type, d) {
          switch (type) {
            case `breaks`:
              this.breaks.splice(d, 1, this._v(this.breaks[d]));
              break;
            case `rates`:
              this.rates.splice(d, 1, this._v(this.rates[d]));
              break;
            case `minimum`:
              this.minimum = this._v(this.minimum);
              break;
            default:
              throw new Error(`Invalid focus type, ${type}!`);
          }
        },
        init() {
          var d = data.schedule || {};
          this._keys.forEach(key => {
            switch (key) {
              case `breaks`:
                this[key] = d[key]
                  ? d[key].map(v => this._v(v, this._format.BREAK))
                  : [];
                break;
              case `rates`:
                this[key] = d[key]
                  ? d[key].map(v => this._v(v, this._format.RATE))
                  : [];
                break;
              case `minimum`:
                this[key] = this._v(d[key] || 0, this._format.BREAK);
                break;
              case `name`:
                this[key] = d[key] || ``;
                break;
              case `_id`:
                this[key] = d[key] || ``;
                break;
              default:
                this[key] = d[key];
            }
          });
          if (!this.breaks.length) {
            this.breaks = [this._v(0, this._format.BREAK)];
            this.rates = [this._v(1, this._format.RATE)];
          }
          this.remove = {
            click: () => {
              this._remove();
            },
            disabled: ``
          };
          return this;
        },
        last(i) {
          return i == this.breaks.length - 1;
        },
        remove: ``,
        start(i) {
          return !i
            ? `$0`
            : this._v(this._v(this.breaks[i]) + 1, this._format.BREAK);
        },
        text(type) {
          switch (type) {
            case `name`:
              return this.name ? `Schedule - ${this.name}` : `Schedule`;
            case `remove`:
              return this._delete ? `Confirm` : `Delete`;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        title(type, d) {
          switch (type) {
            case `add`:
              return d == this.breaks.length - 1 ? `Add range` : ``;
            case `remove`:
              return d > 0 ? `Remove range` : ``;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        }
      };
      this.schedule = schedule.init();
    }
  }
};
</script>
