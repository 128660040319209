<template>
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2"></i>
          <span class="font-weight-bold">{{ data.group ? "Edit" : "Create new" }} group</span>
        </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <template v-if="ready">
          <scroll-area class="px-4 py-3">
            <div class="alert alert-warning" role="alert">
              <strong>Attention</strong>: If there are pending invoices associated with one or more of 
              the households in this group, adding or removing households from the group will change any pending invoices to “Paid."
            </div>
            <div class="form-row pb-3">
              <div class="col">
                <p class="font-weight-bold">Set group name *</p>
                <div class="form-row">
                  <div class="col-4" style="position: relative">
                    <input
                      required
                      v-model="name"
                      class="form-control form-control-sm"
                      placeholder="Name"
                      :class="
                        !name && hoverSubmit ? `form-control is-invalid` : ``
                      "
                    />
                    <div
                      v-if="!name && hoverSubmit"
                      class="text-danger"
                      style="font-size: 0.8em; position: absolute"
                    >A group name is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="data.group" class="form-row">
              <div class="col-4 offset-7">
                <select v-model="householdToAdd" class="form-control form-control-sm">
                  <option v-for="household in householdsToSelect" :key="household.id" :value="household">{{ household.name }}
                  </option>
                </select>
                <small class="form-text text-muted">Add household to group</small>
              </div>
              <div class="col-1">
                <button type="submit" @click="add(householdToAdd)" class="btn btn-sm btn-secondary">Add</button>
              </div>
            </div>
            <div class="form-row pt-3">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <span>Household</span>
                    </th>
                    <th>
                      <span>Contract</span>
                    </th>
                    <th>
                      <span>Accounts</span>
                    </th>
                    <th>
                      <span>Schedules</span>
                    </th>
                    <th>
                      <span>Date</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(household, i) in currentHouseholds" :key="household.name">
                    <td>
                      <i class="far fa-trash-alt" style="cursor: pointer; font-size: 12px"
                        title="Remove this household from group" v-on:click="remove(i)"></i>
                    </td>
                    <td>
                      <span v-text="household.name"></span>
                    </td>
                    <td>
                      <span v-text="getContractId(household)"></span>
                      <!-- <span v-text="getContractRef(household)"></span> -->
                    </td>
                    <td style="white-space: nowrap;">
                      <div style="display: flex; flex-direction: column; gap: 4px">
                        <span v-for="account in getHouseholdAccounts(household)" :key="account._id"
                          v-text="account.number"></span>
                      </div>
                    </td>
                    <td>
                      <schedule-badges :schedules="getSchedulesForHousehold(household)"></schedule-badges>
                    </td>
                    <td>
                      <span v-text="getContractDate(household)"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-row pt-3">
              <div class="col">
                <p class="font-weight-bold">Schedules</p>
                <hr />
                <span class="text-secondary">
                  <small>
                    These schedules are applied in the contracts. Exceptions
                    are not shared between members of a group.
                  </small>
                </span>
              </div>
            </div>
            <div
              style="
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;
              "
            >
              <div
                v-for="schedule in getSchedulesInContracts()"
                :key="schedule.name"
                class="card mt-2"
                style="width: 30%"
              >
                <div class="card-body">
                  <div class="card-title">
                    <schedule-badge :schedule="schedule.name"></schedule-badge>
                  </div>
                  <div class="card-text">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th class="border-top-0 border-bottom-0">
                            <span>Range</span>
                          </th>
                          <th class="border-top-0 border-bottom-0">
                            <span>Rate</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(s, n) in scheduleTiers(schedule)" :key="n">
                          <td>
                            <span v-text="s.range"></span>
                          </td>
                          <td>
                            <span v-text="s.rate"></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <template v-if="schedule.minimum">
                      <hr class="my-1" />
                      <span class="font-italic ml-1" v-text="minimumFeeFormat(schedule)"></span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </scroll-area>
        </template>
        <template v-else>
          <div
            style="
              height: 60vh;
              display: flex;
              justify-content: center;
              align-content: center;
            "
          >
            <loading></loading>
          </div>
        </template>
      </div>
      <div
        class="modal-footer pr-3"
        :style="
          data.group
            ? `justify-content: space-between;`
            : `justify-content: flex-end;`
        "
      >
        <div v-if="data.group">
          <button
            type="button"
            class="btn btn-sm btn-danger"
            v-on:click="removeGroup()"
          >Remove group</button>
        </div>
        <div class="text-right" style="display: flex">
          <button
            type="button"
            class="btn btn-sm btn-outline-danger mr-1"
            data-dismiss="modal"
          >Cancel</button>
          <div v-on:mouseenter="hoverSubmit = true" v-on:mouseleave="hoverSubmit = false">
            <button
              type="button"
              class="btn btn-sm btn-primary submit-btn"
              :disabled="!name || currentHouseholds.length < 1"
              v-on:click="submit()"
            >{{ data.group ? "Save" : "Create" }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.submit-btn {
  width: auto;
}
span {
  font-size: 0.9em;
}
span.badge {
  font-weight: normal;
  font-size: 0.8em;
}
div.modal-body {
  height: calc(100vh - 12em);
}
</style>

<script>
import { checkbox, datePicker, loading, scrollArea } from "../../../../../component";
import ScheduleBadges from "../../../../../component/schedule/schedule-badges.vue";
import ScheduleBadge from "../../../../../component/schedule/schedule-badge.vue";

import { Api, Record, Modal } from "../../../../../factory";
import { numeral } from "../../../../../npm";

export default {
  get components() {
    return {
      ScheduleBadges,
      ScheduleBadge,
      checkbox,
      datePicker,
      loading,
      scrollArea,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) =>
        (typeof o.households == `object` || typeof o.group == `object`) &&
        typeof o.methods == `object` &&
        typeof o.methods.success == `function`,
    },
  },
  computed: {
    householdsToSelect() {
      const currentIds = this.currentHouseholds.map(h => h._id);
      const otherGroupIds = this.allGroups.flatMap(g => g.householdIds);
      return this.allHouseholds.filter(h =>
        !currentIds.includes(h._id) && !otherGroupIds.includes(h._id)
      );
    }
  },
  data() {
    return {
      name: ``,
      hoverSubmit: false,
      ready: false,
      allHouseholds: [],
      allContracts: [],
      householdToAdd: ``,
      householdIdsToDelete: [],
      householdIdsToAdd: [],
      allGroups: [],
      originalHouseholds: [],
      currentHouseholds: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async _removeGroup() {
      await Api.delete(`groups/${this.data.group._id}`);

      this.data.methods.success();
    },
    async submit() {
      const { name, currentHouseholds } = this;
      const { group } = this.data;

      if (group) {
        const householdIdsToAdd = currentHouseholds
          .filter(h => !this.originalHouseholds.some(oh => oh._id === h._id))
          .map(h => h._id);
        const householdIdsToDelete = this.originalHouseholds
          .filter(h => !currentHouseholds.some(ch => ch._id === h._id))
          .map(h => h._id);

        await Api.put(`groups/${group._id}`, {
          name,
          householdIdsToAdd,
          householdIdsToDelete
        });
      } else {
        await Api.post("groups", {
          name,
          householdIds: currentHouseholds.map(h => h._id),
        });
      }

      this.data.methods.success();
    },
    async removeGroup() {
      return Modal.open("group-confirm", {
        methods: {
          success: async () => {
            await this._removeGroup();
            Modal.close();
          },
        },
      });
    },
    id({ id }) {
      return Record.id({ id });
    },
    add(household) {
      if (!this.currentHouseholds.some(h => h._id === household._id)) {
        this.currentHouseholds.push(household);
      }
      this.householdToAdd = null;
    },
    remove(index) {
      this.currentHouseholds.splice(index, 1);
    },
    async init() {
      await this.getAllHouseholds();
      await this.getAllContracts();
      await this.getAllGroups();

      if (this.data.group) {
        this.originalHouseholds = this.allHouseholds.filter((h) =>
          this.data.group.householdIds.includes(h._id)
        );
        this.currentHouseholds = [...this.originalHouseholds];
        this.name = this.data.group.name;
      } else {
        this.currentHouseholds = [...this.data.households];
      }

      this.ready = true;
    },
    async getAllHouseholds() {
      this.allHouseholds = (await Api.get(`households`)).data;
    },
    async getAllContracts() {
      this.allContracts = (await Api.get(`contracts`)).data;
    },
    async getAllGroups() {
      this.allGroups = (await Api.get(`groups`)).data;
    },
    getActiveContractFromHousehold(household) {
      return this.allContracts.find(
        (c) => c.householdId == household._id && c.status == 2
      );
    },
    getScheduleForAccount(contract, account) {
      return contract.schedules[account.schedule];
    },
    getSchedulesInContracts() {
      const schedules = [];

      this.currentHouseholds
        .map((h) => this.getActiveContractFromHousehold(h))
        .filter(Boolean)
        .forEach((c) => {
          c.accounts.forEach((acc) => {
            const schedule = c.schedules[acc.schedule];
            if (!schedules.some((s) => s.name == schedule.name))
              schedules.push(schedule);
          });
        });

      return schedules;
    },
    scheduleTiers(schedule) {
      var format = {
        range: (v) => numeral(v).format(`$0,0`),
        rate: (v) => numeral(v).format(`0.[00]%`),
      };
      return schedule.breaks.map((b, i) => {
        var range;
        if (schedule.breaks.length > 1) {
          var start = i ? b + 1 : b;
          var end = i < schedule.breaks.length - 1 ? schedule.breaks[i + 1] : 0;
          range = end
            ? `${format.range(start)} - ${format.range(end)}`
            : `> ${format.range(start - 1)}`;
        } else {
          range = `> ${format.range(b)}`;
        }
        var rate = format.rate(schedule.rates[i]);
        return { range, rate };
      });
    },
    minimumFeeFormat(schedule) {
      return `Minimum Annual Fee: ${numeral(schedule.minimum).format(
        `$0,0.[00]`
      )}`;
    },
    getContractId(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? Record.id(contract) : "";
    },
    getSchedulesForHousehold(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.schedules : []
    },
    getContractDate(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.date : "-----";
    },
    getContractRef(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.ref : "-----";
    },
    getHouseholdAccounts(household) {
      const contract = this.getActiveContractFromHousehold(household);
      return contract ? contract.accounts : [];
    },
  },
};
</script>
