<template>
  <div class="card">
    <div class="card-header px-3 py-2" id="headingTwo">
      <div class="card-header-title">
        <button class="btn btn-block text-left" type="button" 
        data-toggle="collapse" data-target="#collapseTwo" 
        aria-expanded="false" aria-controls="collapseTwo">
          <span class="font-weight-bold">Schedules</span>
          <i class="fa fa-chevron-up ml-1"></i>
        </button> 
      </div>
      <button type="button"
        class="btn btn-sm btn-outline-secondary new-client"
        :title="title(`add`)"
        v-on:click="click(`add`)"
      >
        <i class="fas fa-plus-square mr-2"></i>
        <span>Add</span>
      </button>
    </div>
    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionMenu">
      <div class="card-body pt-3">
        <div class="schedules">
          <scroll-area class="pr-3">
            <table class="table table-sm">
              <tbody>
                <tr v-for="(s, i) in schedules" :key="s._id" class="schedule">
                  <td>
                    <schedule-badge
                      style="cursor: pointer;"
                      :schedule="s.name"
                      v-on:click="click(`edit`, s)"
                    ></schedule-badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </scroll-area>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.new-client {
  float: right;
  font-size: 0.8rem;
}
div.card-header {
  display: flex;
  justify-content: space-between;
}
div.card-header-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.card-header i,
div.card-header span {
  opacity: 0.7;
}
div.schedules {
  height: 50vh;
  margin-top: 6px;
  margin-right: -15px;
  margin-bottom: -10px;
}
div.action {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.75rem;
}
div.action:hover i {
  opacity: 1;
}
div.action i {
  vertical-align: bottom;
  opacity: 0.3;
  padding-bottom: 2px;
}
tr.schedule {
  font-size: 0.9rem;
}
i {
  opacity: 0.3;
}
table i:hover {
  cursor: pointer;
  opacity: 1;
}
span.badge {
  font-weight: normal;
  font-size: 0.8em;
  cursor: pointer;
}
</style>

<script>
import { scrollArea } from "../../../component";
import { Api, Modal } from "../../../factory";
import { alert, session } from "../../../service";
import ScheduleBadge from "../../../component/schedule/schedule-badge.vue";

export default {
  name: `SharedSchedules`,
  get components() {
    return {
      scrollArea,
      ScheduleBadge
    };
  },
  data() {
    return {
      table: ``,
      schedules: null
    };
  },
  created() {
    this._load();
  },
  methods: {
    async _load() {
      try {
        var { data } = await Api.get(`schedules`);
        this.schedules = data
        // to be used later
        session.set(`FirmSchedules`, data);
      }
      catch (e) {
        console.error(e);
        alert.error(e.message);
      }
    },
    click(type, d) {
      switch (type) {
        case `add`:
        case `edit`:
          return Modal.open(`schedule`, {
            schedule: d,
            methods: {
              success: () => {
                this._load();
                Modal.close();
              },
            },
          });
        default:
          throw new Error(`Invalid click type, ${type}!`);
      }
    },
    text(type, d) {
      switch (type) {
        case `name`:
          return d.default ? `${d.name} (*)` : d.name;
        default:
          throw new Error(`Invalid text type, ${type}!`);
      }
    },
    title(type) {
      switch (type) {
        case `add`:
          return `Add schedule`;
        case `edit`:
          return `Edit schedule`;
        case `import`:
          return `Import schedules`;
        default:
          throw new Error(`Invalid title type, ${type}!`);
      }
    }
  },
};
</script>
