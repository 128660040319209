<template>
  <div :id="id" class="h-100" data-simplebar>
    <slot></slot>
  </div>
</template>

<style scoped></style>

<script>
import { events } from "../service";

import Simplebar from "simplebar";
import "simplebar/dist/simplebar.css";

export default {
  data() {
    return {
      scrollbar: ``
    };
  },
  computed: {
    id() {
      return this._uid;
    }
  },
  mounted() {
    var el = document.getElementById(this.id);
    var scrollbar = new Simplebar(el);
    scrollbar.getScrollElement().addEventListener(`scroll`, e => {
      events.$emit(`scroll`, e);
    });
    this.scrollbar = scrollbar;
  }
};
</script>
