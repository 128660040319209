
<div :key="table.master_key"
     class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <div class="d-flex p-1 pb-2 header"
       style="height: 45px">
    <div class="d-flex actions my-auto mr-2">
      <div v-if="table.has_selected_item()"
           title="Create Invoice"
           class="btn btn-outline-secondary"
           v-on:click="table.click(`invoice-bulk`)">
        <i class="fas fa-file-invoice-dollar"></i>
        <span class="ml-1">Bulk Invoice</span>
      </div>
      <div v-if="table.has_selected_item()"
           :title="table.title(`discloseFeeScheduleBulk`)"
           class="btn btn-outline-secondary"
           v-on:click="table.click(`discloseFeeScheduleBulk`)">
        <i class="fas fa-mail-bulk"></i>
        <span class="ml-1">Disclose Fee Schedule</span>
      </div>
      <!-- disclose annual ammendment -->
      <div v-if="table.has_selected_item()"
           :title="table.title(`discloseAnnualAmendment`)"
           class="btn btn-outline-secondary"
           v-on:click="table.click(`discloseAnnualAmendment`)">
        <i class="fas fa-mail-bulk"></i>
        <span class="ml-1">Disclose Annual Amendment</span>
      </div>
    </div>
    <div v-if="!table.has_selected_item()"
         class="d-flex my-auto mr-2">
      <div>
        <span class="alt-action text-secondary">Select one or more contracts for options</span>
      </div>
    </div>
    <div class="flex-grow-1 my-auto text-right" 
    style="display: flex; flex-direction: column; font-size: 0.8rem" >
      <span v-text="table.records()"></span>
      <span v-text="table.selectedItemsCount()"></span>
    </div>
  </div>

  <template v-if="ready">
  <scroll-area class="pr-3">
    <a ref="download"
       class="d-none"></a>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <checkbox :o="table"
                      p="selected"
                      v-on:change="table.change()"></checkbox>
          </th>
          <th>
            <span>Id</span>
            <i class="fas fa-sort"
               :class="table.class(`sort`, `id`)"
               v-on:click="table.sort(`id`)"></i>
          </th>
          <th>
            <span>Ref</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `ref`)"
               v-on:click="table.sort(`ref`)"></i>
          </th>
          <th>
            <span>Billing Type</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `billing`)"
               v-on:click="table.sort(`billing`)"></i>
          </th>
          <th>
            <span>Accounts</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `accounts`)"
               v-on:click="table.sort(`accounts`)"></i>
          </th>
          <th>
            <span>Schedules</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `schedules`)"
               v-on:click="table.sort(`schedules`)"></i>
          </th>
          <th class="text-nowrap">
            <span>Date</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `date`)"
               v-on:click="table.sort(`date`)"></i>
          </th>
          <!-- <th class="text-nowrap">
            <span>Status</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `status`)"
              v-on:click="table.sort(`status`)"
            ></i>
          </th> -->
          <th class="text-nowrap"></th>
        </tr>
      </thead>
      <tbody :key="table.body_key">
        <tr v-for="(o, index) in table.items()"
            :key="o._id"
            data-cy="custodian-contract-row">
          <td>
            <checkbox :o="o"
                      p="select"
                      v-on:change="table.change(o)"
                      :disabled="o.status != `Active`"></checkbox>
          </td>
          <td>
            <a href="#" class="badge badge-custom"
                  :title="table.title(`contract`)"
                  v-on:click="table.click(`contract`, o)"
                  v-text="o.id"></a>
          </td>
          <td class="text-nowrap">
            <!-- <span v-text="o.ref"></span> -->
            <span>{{ o.ref.split("- ")[0] }}</span>
          </td>
          <td>
            <span style="text-transform: capitalize;">{{ o.billingType }}</span>
          </td>
          <td class="accounts">
            <div>
              <span v-for="(a, i) in o.accounts"
                    :key="i"
                    class="mr-2"
                    v-text="a"></span>
            </div>
          </td>
          <td class="schedules">
            <schedule-badges :schedules="o.schedules"></schedule-badges>
          </td>
          <td class="text-nowrap">
            <span v-text="o.date"></span>
          </td>
          <!-- <td>
            <span class="badge" :class="table.class(`status`, o)" v-text="o.status"></span>
          </td> -->
          <td class="text-nowrap">
            <i class="far fa-file-pdf mr-2"
               :title="table.title(`pdf`)"
               v-on:click="table.click(`pdf`, o)"></i>
            <i class="fas fa-dollar-sign mr-2"
               :title="table.title(`bill`)"
               :class="o.status != `Active` ? `disabled` : ``"
               v-on:click="o.status == `Active` ? table.click(`bill`, o) : ``"></i>
            <i class="far fa-paper-plane mr-2"
               :title="table.title(`discloseFeeSchedule`)"
               v-on:click="table.click(`discloseFeeSchedule`, o)"></i>
            <i v-if="o.showSignatureReminder" class="far fa-bell mr-2"
               :title="table.title(`reminder`)"
               v-on:click="table.click(`reminder`, o)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </scroll-area>
</template>
<template v-else>
    <loading></loading>
</template>
</div>
