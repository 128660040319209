<template>
  <div class="root" :style="alert.style">
    <div
      class="alert"
      :class="alert.data.class"
      role="alert"
      v-on:click="alert.click(`hide`)"
    >
      <span v-text="alert.data.text"></span>
    </div>
  </div>
</template>

<style scoped>
div.root {
  position: fixed;
  width: 500px;
  z-index: 1000000;
  left: 50%;
  margin-left: -250px;
}
div.root {
  transition: all 0.5s;
}
div.alert {
  cursor: pointer;
  width: 100%;
}
span {
  text-align: center;
  display: block;
}
</style>

<script>
import { events } from "../service";
import { Sys } from "../factory";

export default {
  data() {
    return {
      alert: ""
    };
  },
  created() {
    this.init();
    var { alert } = this;
    this.events = events.$watch({
      alert: alert.event.bind(alert)
    });
  },
  destroyed() {
    events.$unwatch(this.events);
  },
  methods: {
    init() {
      var alert = {
        _active: "",
        data: {},
        click(type) {
          switch (type) {
            case `hide`:
              return this.init();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        async event(e) {
          this.data = { ...e.data };
          this._active = true;
          var { hide } = e.data;
          if (!isNaN(hide) && hide > 0) {
            await Sys.wait(hide);
            this.click(`hide`);
          }
        },
        init() {
          this._active = false;
          this.data.text = ``;
          return this;
        },
        get style() {
          var px = this._active ? 150 : -100;
          return `top: ${px}px`;
        }
      };
      this.alert = alert.init();
    }
  }
};
</script>
