
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-list-ol mr-2"></i>
        <span class="font-weight-bold" v-text="schedule.text(`name`)"></span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <scroll-area class="px-4 py-3">
        <div class="form-row">
          <div class="col-6">
            <div class="form-group">
              <label>Name</label>
              <input
                v-model="schedule.name"
                class="form-control form-control-sm"
                placeholder="Name"
              />
              <small class="form-text text-muted">Schedule Name</small>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group">
              <label>Minimum Annual Fee</label>
              <input
                v-model="schedule.minimum"
                class="form-control form-control-sm"
                placeholder="Minumum"
                v-on:focus="schedule.focus(`minimum`)"
                v-on:blur="schedule.blur(`minimum`)"
              />
              <small class="form-text text-muted">Schedule Minimum</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Default</label>
              <p>
                <checkbox
                  :o="schedule"
                  p="default"
                  title="Toggle default schedule"
                />
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <table class="table table-sm table-borderless">
            <thead>
              <tr>
                <td>
                  <span>Range</span>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <span>Rate</span>
                </td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(b, i) in schedule.breaks" :key="i">
                <td>
                  <input
                    class="form-control form-control-sm"
                    :value="schedule.start(i)"
                    readonly
                  />
                </td>
                <td class="text-center">
                  <span>-</span>
                </td>
                <td class="end">
                  <template v-if="schedule.last(i)">
                    <input
                      class="form-control form-control-sm"
                      value="∞"
                      readonly
                    />
                  </template>
                  <template v-else>
                    <input
                      v-model="schedule.breaks[i + 1]"
                      class="form-control form-control-sm"
                      v-on:focus="schedule.focus(`breaks`, i + 1)"
                      v-on:blur="schedule.blur(`breaks`, i + 1)"
                    />
                  </template>
                </td>
                <td class="align-middle">
                  <i class="fas fa-at disabled"></i>
                </td>
                <td>
                  <input
                    v-model="schedule.rates[i]"
                    class="form-control form-control-sm"
                    v-on:focus="schedule.focus(`rates`, i)"
                    v-on:blur="schedule.blur(`rates`, i)"
                  />
                </td>
                <td class="text-center align-middle">
                  <i
                    class="fas fa-plus-circle mr-2"
                    :class="schedule.class(`add`, i)"
                    :title="schedule.title(`add`, i)"
                    v-on:click="schedule.click(`add`, i)"
                  ></i>
                  <i
                    class="fas fa-minus-circle"
                    :class="schedule.class(`remove`, i)"
                    :title="schedule.title(`remove`, i)"
                    v-on:click="schedule.click(`remove`, i)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </scroll-area>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <remove-button :button="schedule.remove"></remove-button>
        <button
          type="button"
          class="btn btn-sm btn-primary ml-2"
          :disabled="schedule.disabled(`save`)"
          v-on:click="schedule.click(`save`)"
        >
          <span class="button">Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
