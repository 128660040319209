import moment, { Moment } from "moment";
import session from "./session";

/**
 * show the period for a line item taking into consideration the invoice period
 * 
 * @param charge the flat charge to show the period for
 * @param period the invoice period 
 * @returns 
 */
 export function showPeriodLength(
  charge: { startDate?: string, endDate?: string, amount: number}, 
  period: { range?: [Date, Date]; start: string; end: string })
  : string {

  let bPeriod = "N/A" 
  const fStartDate = charge.startDate ? moment.utc(charge.startDate,'MM/DD/YYYY') : undefined
  const fEndDate = charge.endDate ? moment.utc(charge.endDate,'MM/DD/YYYY') : undefined

  const periodStartD = moment.utc(period.start)
  const periodEndD = moment.utc(period.end)

  if (fStartDate && fEndDate) {

    const startDate = periodStartD.isAfter(fStartDate) ? periodStartD : fStartDate
    const endDate = periodEndD.isAfter(fEndDate) ?  fEndDate : periodEndD

    bPeriod = startDate.format( "MMM D, YYYY") + " - " + endDate.format("MMM D, YYYY")

  } if (fStartDate && !fEndDate) {

    const startDate = periodStartD.isAfter(fStartDate) ? periodStartD : fStartDate
    const endDate = periodEndD.isAfter(startDate) ? periodEndD : startDate 
    bPeriod = startDate.format("MMM D, YYYY") + " - " + endDate.format("MMM D, YYYY")
  }
  return bPeriod;

}

/**
 * find the multiplier based on the number of 
 * days difference between start and end date for a flat charge
 * 
 * @param startDate the start date of this flat charge
 * @param endDate the end date of this flat charge
 * @returns multiplier value to use it to multiply the amount
 */
export function getLineItemMultiplier(startDate: Moment, endDate: Moment) {

  // if the firm asked for quarterly billing then we divide by 4
  const state = session.get(`state`);
  if (state?.firm?.features?.quarterBill) {
    return 0.25;
  } else if (state?.firm?.features?.monthlyBill) {
    return 0.083;
  }

  // otherwise we divide by 365
  const a = startDate.utc(false)
  const b = endDate.utc(false)

  const daysDiff = b.diff(a, "days") + 1
  const daysInYear = b.isLeapYear() ? 366 : 365;

  console.log(`days diff for ${a} - ${b} => ${daysDiff} `)

  return daysDiff / daysInYear

}

export function getPeriodMultiplier(
  period: { range?: [Date, Date]; start: string; end: string },
) {
  const state = session.get(`state`);

  if (state?.firm?.features?.quarterBill) {
    return 0.25;
  } else if (state?.firm?.features?.monthlyBill) {
    return 0.083;
  }

  const a = period.range
    ? moment(period.range[1])
    : moment(period.end, "MMM D, YYYY");
  const b = period.range
    ? moment(period.range[0])
    : moment(period.start, "MMM D, YYYY");
  const days = a.diff(b, "days") + 1;
  const daysInYear = b.isLeapYear() ? 366 : 365;
  return days / daysInYear;
}

/**
 * calculate the line item amount based on the period of the line item 
 * and the user selected period (from the UI). Taking those periods into consideration
 * 
 * where it is used: create.vue, invoice.vue
 * 
 * Note: this only happens for Annual item calculation
 * 
 * @param item the line amount to cacluate the amount for
 * @param period the user selected period for which we want to calculate the amount for the invoice
 * @returns the calculated amount
 */
export function calculateLineItemAmount(
  item: { startDate?: string, endDate?: string, amount: number, timing: string}, 
  period: { range?: [Date, Date]; start: string; end: string }) {

  const nonAnnualTypes = ['OneTime', 'Quarter', 'Month']
  if (nonAnnualTypes.includes(item.timing)) return item.amount 

  let amountValue = 0;
  const periodStartD = moment.utc(period.start,'MM/DD/YYYY')
  const periodEndD = moment.utc(period.end,'MM/DD/YYYY')

  const lineItemStartD = item.startDate ?  moment.utc(item.startDate) :  undefined
  const lineItemEndD = item.endDate ? moment.utc(item.endDate) : undefined

  if (lineItemStartD && lineItemEndD) { // the old flat charge 
    const startDate = periodStartD.isAfter(lineItemStartD) ? periodStartD : lineItemStartD
    const endDate = periodEndD.isAfter(lineItemEndD) ? lineItemEndD : periodEndD
    amountValue = item.amount * getLineItemMultiplier(startDate, endDate)
  } else if (lineItemStartD) { // the current flat charge (start date - end of period)
    // if the period end date is before the start date of the charge, just return v as is
    if (periodEndD.isBefore(lineItemStartD)) return amountValue

    // otherwise see which start date is better
    const startDate = periodStartD.isAfter(lineItemStartD) ? periodStartD : lineItemStartD
    amountValue = item.amount * getLineItemMultiplier(startDate, periodEndD)
  } else
    amountValue = item.amount * getPeriodMultiplier(period)
  
  return amountValue;

}