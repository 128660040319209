<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-house-user mr-2"></i>
          <span class="font-weight-bold">Custodian - Accounts</span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <scroll-area class="px-4 py-3">
          <div class="form-row mr-1">
            <div class="col-3">
              <div class="form-group">
                <label>Custodian</label>
                <input
                  class="form-control form-control-sm"
                  placeholder="Master"
                  :value="custodian.id"
                  readonly
                />
                <small class="form-text text-muted">Custodian Id</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Household</label>
                <select
                  v-model="custodian.household"
                  class="form-control form-control-sm"
                  v-on:change="custodian.change(`household`)"
                >
                  <option v-for="h in custodian.households" :key="h.name">
                    <span v-text="h.name"></span>
                  </option>
                </select>
                <small class="form-text text-muted">Accounts Household</small>
              </div>
            </div>
          </div>
          <hr class="mr-2" />
          <div class="form-row">
            <div class="col">
              <table class="table table-sm table-borderless">
                <thead>
                  <tr>
                    <td>
                      <span>Number</span>
                    </td>
                    <td>
                      <span>Name</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(o, i) in data.accounts" :key="i">
                    <td>
                      <input
                        class="form-control form-control-sm"
                        :value="o.number"
                        placeholder="Number"
                        readonly
                      />
                    </td>
                    <td>
                      <input
                        v-model="o.name"
                        class="form-control form-control-sm"
                        placeholder="Name"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </scroll-area>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-sm btn-primary ml-2"
            :disabled="custodian.disabled(`save`)"
            v-on:click="custodian.click(`save`)"
          >
            <span class="button">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
button {
  width: 80px;
}
table {
  margin-left: -5px;
  margin-bottom: 2em;
}
table thead td:nth-child(1) {
  width: 7em;
}
i:not(.fa-house-user) {
  opacity: 0.3;
}
i:hover:not(.fa-house-user) {
  cursor: pointer;
  opacity: 1;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
div.modal-body {
  height: calc(100vh - 12em);
}
</style>

<script>
import { scrollArea } from "../../../../component";
import { Api, Modal } from "../../../../factory";
import { alert } from "../../../../service";

export default {
  name: `FirmModalCustodianAccounts`,
  get components() {
    return {
      scrollArea
    };
  },
  props: {
    data: {
      type: Object,
      validator: o =>
        o.hasOwnProperty(`custodian`) &&
        Array.isArray(o.accounts) &&
        Array.isArray(o.households)
    }
  },
  data() {
    return {
      custodian: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var custodian = {
        _delete: ``,
        _invalid(type) {
          switch (type) {
            case `contact`:
              return this.contacts.find(o => !o.name.length || !o.email.length);
            default:
              throw new Error(`Invalid validation type, ${type}!`);
          }
        },
        _keys: [`_id`, `contacts`, `id`, `name`],
        async _save() {
          var { custodian: c, methods, households } = data;
          var { _id: custodianId } = c;
          var { _id: householdId } = households.find(
            o => o.name == this.household
          );
          var accounts = data.accounts.map(a => {
            var [name, number] = [a.name, a.number].map(s => s.trim());
            return { custodianId, householdId, name, number };
          });
          try {
            var r = await Promise.all(
              accounts.map(a => Api.post(`accounts`, a))
            );
            if (methods && typeof methods.success == `function`)
              return methods.success(r);
            Modal.close();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _select: ``,
        get id() {
          return data.custodian.id;
        },
        add(i = -1) {
          if (i == this.contacts.length - 1)
            this.contacts.splice(i + 1, 0, { email: ``, name: `` });
        },
        change(type) {
          switch (type) {
            case `household`:
              if (!this._select) this._select = true;
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          switch (type) {
            case `add`:
              return d == this.contacts.length - 1 ? `` : `disabled`;
            case `remove`:
              return typeof d == `number`
                ? d > 0
                  ? ``
                  : `disabled`
                : this._delete
                ? `btn-danger`
                : `btn-outline-danger`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(type) {
          switch (type) {
            case `save`:
              return this._save();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type) {
          switch (type) {
            case `remove`:
              return true;
            case `save`:
              return this.households.find(o => !o.hasOwnProperty(`_id`));
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        household: ``,
        get households() {
          return this._select
            ? data.households
            : [{ name: `Select...` }, ...data.households];
        },
        init() {
          this.household = this.households[0].name;
          return this;
        },
        title(type, d) {
          switch (type) {
            case `add`:
              return d == this.contacts.length - 1 ? `Add contact` : ``;
            case `remove`:
              return d > 0 ? `Remove contact` : ``;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        }
      };
      this.custodian = custodian.init();
    }
  }
};
</script>
