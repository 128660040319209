<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Disclose Annual Amendment</div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body p-2">
        <div class="px-4 py-3" v-if="missingContacts && !multipleContracts">
          <Title error>Can't send emails to household "{{ data.household.name }}" because it does not have contacts.
          </Title>
        </div>
        <div class="px-4 py-3" v-else>
          <Title>The following email will be sent to {{ data.household ? data.household.contacts.map(c =>
            c.firstName).join(", ") : `selected households.`
            }}</Title>
          <div class="mt-4 email-body">
            <IsBlackBarn v-if="data.isBlackBarn"></IsBlackBarn>
            <IsSims v-else-if="data.isSims"></IsSims>
            <Is603 v-else-if="data.is603"></Is603>
            <DefaultTemplate v-else></DefaultTemplate>
          </div>
        </div>
      </div>

      <div class="modal-footer pr-3">
        <div>
          <button type="button" class="btn btn-sm modal-button btn-outline-danger" v-on:click="cancel()">
            <span>Cancel</span>
          </button>
          <button v-if="!missingContacts || multipleContracts" type="button"
            class="btn btn-sm modal-button btn-primary ml-2" v-on:click="send()">
            <span>Send</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.email-body {
  color: #212529;
}

.modal-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 16px;

  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #616466;
}

.modal-button {
  width: 80px;
}

.modal-body {
  min-height: 50vh;
}

.modal-footer {
  justify-content: center;
}
</style>

<script>
import { Modal } from "../../factory";
import Title from "../../component/title.vue";
import IsBlackBarn from "./is-black-barn.vue";
import Is603 from "./is-603-firm.vue";
import IsSims from "./is-sims-firm.vue"
import DefaultTemplate from "./default-template.vue";

export default {
  props: {
    data: {
      contracts: Array,
      household: {
        name: String,
        contacts: Array,
      },
      isBlackBarn: Boolean,
      is603: Boolean,
      isSims: Boolean,
    }
  },
  data() {
    return {
      missingContacts: true,
      multipleContracts: false,
    }
  },
  created() {
    const { household, contracts } = this.data

    if (!household) {
      this.missingContacts = false
    } else {
      this.multipleContracts = contracts && contracts.length > 1
      this.missingContacts = !household || !household.contacts || !household.contacts.length
    }


  },
  methods: {
    send() {
      // close the modal then process the request
      Modal.close();
      this.data.methods.send(this.data);
    },
    cancel() {
      if (this.data.methods.back) {
        this.data.methods.back()
      } else {
        Modal.close();
      }
    }
  },
  components: { Title, IsBlackBarn, Is603, IsSims, DefaultTemplate }
}
</script>
