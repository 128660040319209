<template>
  <div class="input-group">
    <input
      :id="id"
      ref="date"
      class="form-control form-control-sm"
      type="text"
      readonly
      placholder="Date"
    />
    <div class="input-group-append">
      <i class="input-group-text far fa-calendar-alt" v-on:click="focus"></i>
    </div>
  </div>
</template>

<style scoped>
div input {
  background-color: transparent !important;
  padding-left: 8px;
}
div i {
  cursor: pointer;
}
</style>

<script>
import moment from "moment";
import { Pikaday } from "../npm";
import { alert } from "../service";
import "pikaday/css/pikaday.css";

export default {
  props: ["o", "p", "options"],
  data() {
    return {
      picker: "",
    };
  },
  computed: {
    dateOptions() {
      var { options = {} } = this;
      var { dates = [], format = `MMM D, YYYY` } = options;
      var ignore = [`dates`, `format`, `disableDayFn`];
      var disableDayFn = (d) =>
        dates.length && !dates.includes(moment(d).format(format));
      return Object.keys(options)
        .filter((key) => ignore.indexOf(key) < 0)
        .reduce(
          (o, key) => {
            switch (key) {
              case `minDate`:
              case `maxDate`:
                o[key] = moment(options[key], format).toDate();
                break;
              case `events`:
                o[key] = options[key].map((d) =>
                  moment(d, format).format(`ddd MMM D YYYY`)
                );
                break;
              case `disable`:
                break;
              default:
                o[key] = Array.isArray(options[key])
                  ? [...options[key]]
                  : typeof options[key] == `object`
                  ? { ...options[key] }
                  : options[key];
            }
            return o;
          },
          { disableDayFn, format }
        );
    },
    id() {
      return this._uid;
    },
    prop() {
      return this.p || `date`;
    },
    style() {
      var { options = {} } = this;
      var { width = `130px` } = options;
      return `width: ${width}`;
    },
  },
  watch: {
    o: {
      handler() {
        var date = this.o[this.prop];
        this.picker.setDate(date);
      },
      deep: true,
    },
    options: {
      handler() {
        Object.keys(this.options).forEach((key) => {
          switch (key) {
            case `minDate`:
            case `maxDate`:
              if (this.options.hasOwnProperty(`format`))
                this.picker.config({
                  [key]: moment(
                    this.options[key],
                    this.options.format
                  ).toDate(),
                });
              break;
            case `format`:
            case `dates`:
              break;
            default:
              throw new Error(`Unsupported date option, ${key}!`);
          }
        });
      },
      deep: true,
    },
  },
  mounted() {
    var { id, dateOptions, prop, select } = this;
    var field = document.getElementById(id);
    var picker = new Pikaday({
      field,
      onSelect: select.bind(this),
      ...dateOptions,
    });
    var date = this.o[prop] || moment().format(dateOptions.format);
    picker.setDate(date);
    picker.gotoDate(new Date());
    this.picker = picker;
  },
  destroyed() {
    this.picker.destroy();
  },
  methods: {
    focus() {
      this.$refs["date"].click();
    },
    select(e) {
      var { dateOptions, prop, options } = this;
      if (typeof options == `object` && options.disable)
        return alert.warning(`Date selection is disabled!`);
      var date = moment(e).format(dateOptions.format);
      this.o[prop] = date;
    },
  },
};
</script>
