<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <caption v-if="table.householdNote || table.scheduleName">
            <hr>
            <p>This fee schedule & notes, if applicable, were listed on the client agreement sent for signature.</p>
            <mark v-if="table.scheduleName">{{ table.scheduleName }}<br></mark>
            <mark v-if="table.householdNote">Notes: {{ table.householdNote }}</mark>
        </caption>
        <thead>
          <tr>
            <th>
              <span>Number</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `number`)"
                 v-on:click="table.sort(`number`)"></i>
            </th>
            <th>
              <span>Name</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `name`)"
                 v-on:click="table.sort(`name`)"></i>
            </th>
            <th>
              <span class="ml-3">Household</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `household`)"
                 v-on:click="table.sort(`household`)"></i>
            </th>
            <th>
              <span>Schedule</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `schedule`)"
                 v-on:click="table.sort(`schedule`)"></i>
            </th>
            <th>
              <span>Billing</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `billing`)"
                 v-on:click="table.sort(`billing`)"></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items"
              :key="index">
            <td>
              <span v-text="o.number"></span>
            </td>
            <td>
              <span v-text="o.name"></span>
            </td>
            <td>
              <span class="ml-3"
                    v-text="o.household"></span>
            </td>
            <td>
              <select v-model="o.schedule"
                      class="form-control form-control-sm">
                <option v-for="s in table.schedules"
                        :key="s.name">
                  <span v-text="s.name"></span>
                </option>
              </select>
            </td>
            <td>
              <select v-model="o.billing"
                      class="form-control form-control-sm" @change="onChange">
                <option v-for="(b, i) in table.billing(o)"
                        :key="i">
                  <span v-text="b"></span>
                </option>
              </select>
            </td>
            <td class="text-right">
              <i class="fas fa-dollar-sign"
                 title="Bill all to this account"
                 v-on:click="table.bill(o)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </div>
</template>

<style scoped>
div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  max-width: 15em;
}

table th:nth-child(1) {
  min-width: 6em;
}

table th:nth-child(3) {
  min-width: 12em;
}

table th:nth-child(4) {
  min-width: 8em;
}

table th:nth-last-child(2) {
  width: 7em;
}

table th:nth-last-child(1) {
  width: 2em;
}

i {
  opacity: 0.3;
}

i:hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}

span {
  font-size: 0.9em;
}

caption { 
  caption-side: bottom; 
  }
</style>

<script>
import { scrollArea, search } from "../../../../../../../component";
import { Api, List } from "../../../../../../../factory";
import { alert, session } from "../../../../../../../service";

export default {
  get components() {
    return {
      scrollArea,
      search
    };
  },
  props: {
    contract: {
      type: Object,
      required: true,
      validator: o =>
        Array.isArray(o.accounts) &&
        typeof o.data == `object` &&
        typeof o.state == `object`
    }
  },
  data() {
    return {
      table: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    onChange(event) {
      if (event.target.value.toLowerCase() == "bill to invoice")
        alert.message("'Billing to Invoice' will be applied to"
          + " all accounts under this contract")
    },
    init() {
      var { contract } = this;
      var table = {
        _init: (o, k, v) => this.$set(o, k, v),
        _items: ``,
        billing(o) {
          let options = contract.accounts.map(a =>
            a.number == o.number ? this.SELF : a.number
          );
          options = [...options,  "Bill to Invoice"]
          return options
        },
        bill(o) {
          contract.accounts.forEach(a => {
            a.billing = a.number == o.number ? this.SELF : o.number;
          });
        },
        class(type, d) {
          switch (type) {
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        init() {
          this.SELF = `Self`;
          if (!contract.state.hasOwnProperty(`accounts`)) {
            this._init(contract.state, `accounts`, {
              list: {
                search: ``,
                sort: { asc: ``, key: `` }
              }
            });
          }
          var schedule = this.schedules.find(o => o.default) || this.schedules[0];
          if (!schedule)
            throw new Error(`There is no Schedule configured yet!`);

          contract.accounts.forEach(a => {
            if (!a.hasOwnProperty(`billing`)) {
              this._init(a, `billing`, this.SELF);
              this._init(a, `schedule`, schedule.name);
            }
          });
          return this;
        },
        get items() {
          var { search, sort } = this.list;
          return List.sort(
            List.filter(contract.accounts, search),
            sort.key,
            sort.asc
          );
        },
        get list() {
          return contract.state.accounts.list;
        },
        get schedules() {
          return contract.data.schedules;
        },
        get householdNote() {
          const [household] = contract.data.households
          return household?.note 
        },
        get scheduleName() {
          const [household] = contract.data.households
          const schedules = session.get(`FirmSchedules`);

          if (household?.scheduleId)
            return schedules.find(o => o._id == household?.scheduleId)?.name
        },
        SELF: ``,
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        }
      };
      this.table = table.init();
    }
  }
};
</script>
