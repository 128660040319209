<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <scroll-area class="pr-3">
      <div class="d-flex">
        <div>
          <p class="font-weight-bold">Exceptions</p>
        </div>
      </div>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Type</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `type`)"
                v-on:click="table.sort(`type`)"
              ></i>
            </th>
            <th>
              <span>Account</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `account`)"
                v-on:click="table.sort(`account`)"
              ></i>
            </th>
            <th>
              <span>Schedule</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `schedule`)"
                v-on:click="table.sort(`schedule`)"
              ></i>
            </th>
            <th>
              <span>Ticker</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `ticker`)"
                v-on:click="table.sort(`ticker`)"
              ></i>
            </th>
            <th class="text-right">
              <i
                class="fas fa-plus-circle"
                :title="table.title(`add`)"
                v-on:click="table.click(`add`)"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items" :key="index">
            <td>
              <select
                v-model="o.type"
                class="form-control form-control-sm text-capitalize"
                v-on:change="table.change(`type`, o)"
              >
                <option
                  v-for="(t, i) in table.types"
                  :key="i"
                  class="text-capitalize"
                >
                  <span v-text="t"></span>
                </option>
              </select>
            </td>
            <td>
              <select v-model="o.account" class="form-control form-control-sm">
                <option
                  v-for="a in table.accounts"
                  :key="a.name"
                  :disabled="table.disabled(`accounts`, o, a)"
                >
                  <span v-text="a.name"></span>
                </option>
              </select>
            </td>
            <td>
              <select v-model="o.schedule" class="form-control form-control-sm">
                <option v-for="s in table.schedules" :key="s.name">
                  <span v-text="s.name"></span>
                </option>
              </select>
            </td>
            <td class="ticker">
              <input
                v-model="o.ticker.symbol"
                class="form-control form-control-sm"
                :class="table.class(`ticker`, o)"
                :disabled="table.disabled(`ticker`, o)"
                v-on:keyup="table.keyup(`ticker`, o)"
                v-on:keydown="table.keydown(`ticker`, o)"
              />
              <small
                class="form-text text-muted"
                v-text="o.ticker.name"
              ></small>
            </td>
            <td class="text-right">
              <i
                class="fas fa-minus-circle"
                :title="table.title(`remove`)"
                v-on:click="table.click(`remove`, index)"
              ></i>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </div>
</template>

<style scoped>
td.ticker small {
  margin: 0 0 0 0.6em;
  font-size: 0.7em;
}
div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}
table th:nth-child(1) {
  width: 7em;
}
table th:nth-child(3) {
  width: 12em;
}
table th:nth-last-child(2) {
  width: 30%;
}
table th:nth-last-child(2) {
  width: 15em;
}
table th:nth-last-child(1) {
  width: 2em;
}
i {
  opacity: 0.3;
}
i:not(.disable):hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}
span {
  font-size: 0.9em;
}
i.fa-minus-circle:hover {
  color: red;
}
</style>

<script>
import { scrollArea, search } from "../../../../../../../component";
import { Api, List } from "../../../../../../../factory";
// import { debounce } from "../../../../../../../npm";
import { alert } from "../../../../../../../service";

export default {
  get components() {
    return {
      scrollArea,
      search,
    };
  },
  props: {
    contract: {
      type: Object,
      required: true,
      validator: (o) =>
        Array.isArray(o.accounts) &&
        typeof o.data == `object` &&
        typeof o.state == `object`,
    },
  },
  data() {
    return {
      table: ``,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { contract } = this;
      var blip = 5e2;
      var table = {
        get _accounts() {
          return contract.accounts.filter((o) => o.schedule != contract.NONE);
        },
        _data: { markets: `` },
        _text: {
          ALL: ``,
          CASH: ``,
          NONE: ``,
          POSITION: ``,
          STOCKS: ``,
        },
        _init: (...args) => this.$set(...args),
        _ticker(o) {
          var search = async function () {
            this._blip = clearTimeout(this._blip);
            //this._blip.ticker[i] = clearTimeout(this._blip.ticker[i])
            //var o = contract.exceptions[i]
            var symbol = o.ticker.symbol.toUpperCase();
            o.ticker.search = false;
            if (!symbol.length) {
              o.ticker.name = ``;
              return;
            }
            try {
              var {
                data: [ticker],
              } = await Api.get(`tickers?symbol=${symbol}`);
              o.ticker.name = ticker ? ticker.name : ``;
              if (o.ticker.name) o.ticker.symbol = ticker.symbol;
              o.ticker.search = true;
            } catch (e) {
              console.error(e);
              alert.error(e.message);
            }
          };
          return search.bind(this);
        },
        get accounts() {
          var all = { name: this._text.ALL };
          var list = contract.accounts.map((a) => ({
            name: `(${a.number.trim()}) ${a.name.trim()}`,
          }));
          return [all, ...list];
        },
        change(type, d) {
          switch (type) {
            case `type`:
              d.ticker.symbol =
                d.type == this._text.CASH ? this._text.NONE : ``;
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          switch (type) {
            case `ticker`:
              return d.ticker.search
                ? d.ticker.name
                  ? `is-valid`
                  : `is-invalid`
                : ``;
            case `up`:
              return d == 0 ? `disable` : ``;
            case `down`:
              return d < contract.signees.length - 1 ? `` : `disable`;
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(type, d) {
          switch (type) {
            case `add`:
              var [account] = this.accounts;
              var [schedule] = this.schedules;
              var type = contract.exceptions.find(
                (o) => o.type == this._text.CASH && o.account == this._text.ALL
              )
                ? this.types[1]
                : this.types[0];

              contract.exceptions.push({
                account: account.name,
                schedule: schedule.name,
                ticker: {
                  name: ``,
                  search: ``,
                  symbol: ``,
                },
                type,
              });
              break;
            case `remove`:
              return contract.exceptions.splice(d, 1);
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type, d, e) {
          switch (type) {
            case `accounts`:
              if (d.type == `Position` && d.account == this._all)
                d.account = this.accounts.filter(
                  (a) => a.name != this._all
                )[0].name;
              return d.type == `Position` && e.name == this._all;
            case `category`:
            case `ticker`:
              return d.type == this.types[0];
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          if (!contract.state.hasOwnProperty(`exceptions`)) {
            this._init(contract.state, `exceptions`, {
              list: {
                search: ``,
                sort: { asc: ``, key: `` },
              },
            });
          }
          if (!contract.hasOwnProperty(`exceptions`))
            this._init(contract, `exceptions`, []);
          this._data = { markets: {} };
          this._text = {
            ALL: `All Accounts`,
            CASH: `Cash`,
            NONE: `-`,
            POSITION: `Position`,
            STOCKS: `Stocks`,
          };
          // this.ticker = debounce(this._ticker, 5e2)
          return this;
        },
        get items() {
          var { search, sort } = this.list;
          return List.sort(
            List.filter(contract.exceptions, search),
            sort.key,
            sort.asc
          );
        },
        keydown(type, d) {
          switch (type) {
            case `ticker`:
              if (this._blip /*&& this._blip[type] && this._blip[type][d]*/)
                this._blip /*[type][d]*/ = clearTimeout(
                  this._blip /*[type][d]*/
                );
              break;
          }
        },
        keyup(type, d) {
          switch (type) {
            case `ticker`:
              // if (!this._blip)
              //   /*
              //                       this._blip = {}
              //                   if (!this._blip[type])
              //                       this._blip[type] = {}
              //                   if (!this._blip[type][d])
              //                   */
              //   this._blip /*[type][d]*/ = setTimeout(this._ticker(d), blip);
              break;
            default:
              throw new Error(`Invalid keyup type, ${type}!`);
          }
        },
        get list() {
          return contract.state.exceptions.list;
        },
        get schedules() {
          return contract.data.schedules;
        },
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        title(type, d) {
          switch (type) {
            case `add`:
              return `Add new exception`;
            case `remove`:
              return `Remove exception`;
            case `billing`:
              return d
                ? `Remove billing assignment`
                : `Apply billing assignment`;
            case `down`:
              return d < contract.signees.length - 1 ? `Move down` : ``;
            case `household`:
              return `Toggle household filter`;
            case `select`:
              return `Select all / none`;
            case `up`:
              return d == 0 ? `` : `Move up`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        get types() {
          var { CASH, POSITION } = this._text;
          return [CASH, POSITION];
        },
      };
      this.table = table.init();
    },
  },
};
</script>
