<template>
  <div class="schedules">
    <schedule-badge
      v-for="(s, i) in schedules"
      :key="i"
      :schedule="typeof s == `string` ? s : s.name"
    ></schedule-badge>
  </div>
</template>

<style scoped>
div.schedules {
  display: flex;
  gap: 4px;
  flex-direction: column;
}
</style>

<script lang="ts">
import ScheduleBadge from "./schedule-badge.vue";
export default {
  get components() {
    return { ScheduleBadge }
  },
  props: {
    schedules: {
      type: Array,
    }
  },
  data(): {} {
    return {
    };
  },
};
</script>

<style></style>
