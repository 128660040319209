
<div id="insights-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="py-4 pl-5">
          <h3 class="display-5 fw-bold">Hi, {{ username }}! Let's have a look at revenue.</h3>
          <p class="lead text-muted">Keep informed and up to date with key billing metrics.</p>
        </div>
      </div>
    </div>
  </div>
  <div id="superset-container"></div>
</div>
