
<div class="h-100">
  <div class="search">
    <search :o="list"></search>
  </div>
  <div class="d-flex p-1 pb-2 header" style="height: 45px">
    <div class="d-flex actions my-auto mr-2">
      <!-- <div title="Create group" class="btn btn-outline-secondary" v-on:click="createGroup()">
        <i class="fas fa-users"></i>
        <span>Create group</span>
      </div> -->
    </div>
    <div class="flex-grow-1 my-auto text-right">
      <span v-text="records()"></span>
    </div>
  </div>
  <template v-if="ready">
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <thead>
          <tr>
            <th></th>
            <th>
              <span>Group name</span>
            </th>
            <th>
              <span>Households</span>
            </th>
            <th>
              <span>Active contracts</span>
            </th>
            <th>
              <span>Value</span>
            </th>
            <!-- <th>
              <span>Total Value</span>
            </th> -->
            <th>
              <span>Fee schedules</span>
            </th>
            <th>
              <span>Group created on</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(group, i) in getGroups" :key="i">
            <td>
              <i
                class="far fa-edit"
                style="cursor: pointer; font-size: 12px"
                v-on:click="openGroupModal(group)"
              ></i>
            </td>
            <td>
              <span v-text="group.name"></span>
            </td>
            <td>
              <div style="display: flex; flex-direction: column; gap: 4px">
                <span
                  v-for="household in getGroupHouseholds(group)"
                  v-text="household.name"
                  :key="household.id"
                ></span>
              </div>
            </td>
            <td>
              <div style="display: flex; flex-direction: column; gap: 4px">
                <a
                  class="badge badge-custom"
                  v-for="household in getGroupHouseholds(group)"
                  v-on:click="openContractModal(household)"
                  v-text="getContractId(household)"
                  :key="household.id"
                ></a>
              </div>
            </td>
            <td>
              <div style="display: flex; flex-direction: column; gap: 4px">
                <span
                  v-for="household in getGroupHouseholds(group)"
                  v-text="showHouseholdValue(group, household)"
                  :key="household.id"
                ></span>
              </div>
            </td>
            <!-- <td>
              <div style="display: flex; flex-direction: column; gap: 4px">
                <span
                  v-for="household in getGroupHouseholds(group)"
                  v-text="showGroupValue(group, household)"
                  :key="household.id"
                ></span>
              </div>
            </td> -->
            <td>
              <div style="display: flex; flex-direction: column; gap: 4px">
                <div
                  v-for="household in getGroupHouseholds(group)"
                  :key="household.id"
                  style="display: flex; align-items: center; gap: 4px"
                >
                  <schedule-badges
                    :schedules="getHouseholdActiveSchedules(household)"
                  ></schedule-badges>
                </div>
              </div>
            </td>
            <td>
              <span v-text="formatDate(group._created)"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </template>
  <template v-else>
    <loading></loading>
  </template>
</div>
