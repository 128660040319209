<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header">
      <div class="d-flex actions mr-2">
        <div
          title="Export Invoice(s)"
          class="btn btn-outline-secondary action-button"
          v-on:click="table.click(`export`, `invoice`)"
        >
          <i class="mr-1 fas fa-file-csv"></i>
          <span>Download Fee File</span>
        </div>
      </div>
      <div class="flex-grow-1 text-right">
        <span v-text="table.records"></span>
      </div>
    </div>
    <template v-if="ready">
    <scroll-area class="pr-3">
      <a ref="download" class="d-none"></a>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Id</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `id`)"
                v-on:click="table.sort(`id`)"
              ></i>
            </th>
            <th>
              <span>Ref</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `ref`)"
                v-on:click="table.sort(`ref`)"
              ></i>
            </th>
            <th>
              <span>Accounts</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `accounts`)"
                v-on:click="table.sort(`accounts`)"
              ></i>
            </th>
            <th>
              <span>Schedules</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `schedules`)"
                v-on:click="table.sort(`schedules`)"
              ></i>
            </th>
            <th>
              <span>Date</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `date`)"
                v-on:click="table.sort(`date`)"
              ></i>
            </th>
            <th>
              <span>Status</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `status`)"
                v-on:click="table.sort(`status`)"
              ></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items" :key="index">
            <td>
              <a href="#"
                class="badge badge-custom"
                :title="table.title(`id`)"
                v-on:click="table.click(`invoice`, o)"
                v-text="o.id"
              ></a>
            </td>
            <td class="text-nowrap">
              <span v-text="o.ref"></span>
            </td>
            <td class="pre">
              <a href="#"
                v-for="(a, i) in o.accounts"
                :key="i"
                class="badge badge-custom mr-2"
                :title="table.title(`account`, a)"
                v-on:click="table.click(`account`, a)"
                v-text="a"
              ></a>
            </td>
            <td class="pre">
              <span v-for="(s, i) in o.schedules" :key="i" class="mr-2" v-text="s"></span>
            </td>
            <td class="text-nowrap">
              <span v-text="o.date"></span>
            </td>
            <td>
              <span
                class="badge"
                :class="table.class(`status`, o)"
                v-text="o.status"
                v-on:click="table.changePaymentStatus(o.id, o.status == `Pending`)"
              ></span>
            </td>
            <td class="text-nowrap">
              <i
                class="far fa-file-pdf mr-2"
                :title="table.title(`pdf`)"
                v-on:click="table.click(`pdf`, o)"
              ></i>
              <!-- <template v-if="!o.refund">
                <i
                  class="fas fa-dollar-sign"
                  :title="table.title(`refund`)"
                  v-on:click="table.click(`refund`, o)"
                ></i>
              </template> -->
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
    </template>
    <template v-else>
      <loading></loading>
    </template>
  </div>
</template>

<style scoped>
div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}
div.header div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}
table {
  table-layout: fixed;
}
table span {
  font-size: 0.9rem;
}
span.link {
  cursor: pointer;
  text-decoration: underline;
}
table th:nth-child(1) {
  width: 5em;
}
table th:nth-child(2) {
  width: 25%;
}
table th:nth-child(3) {
  width: auto;
}
table th:nth-child(4) {
  width: auto;
}
table th:nth-child(5) {
  width: 7em;
}
table th:nth-child(6) {
  width: 6em;
}
table th:nth-child(7) {
  width: 4em;
}
td.pre span {
  white-space: pre;
}
td {
  vertical-align: inherit;
}
i {
  opacity: 0.3;
}
i:hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}
span.badge {
  color: white;
  font-weight: normal;
  width: 5em;
  cursor: pointer;
}
</style>

<script>
import { scrollArea, search, loading } from "../../../../component";
import { Api, Component, List, Modal, Record } from "../../../../factory";
import { numeral } from "../../../../npm";
import { alert, events, session } from "../../../../service";

import pdfIcon from "../../../../../../img/pdf-icon.svg";

export default {
  name: `HouseholdBilling`,
  get components() {
    return {
      scrollArea,
      search,
      loading
    };
  },
  data() {
    return {
      table: ``,
      ready: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      var _this = this
      var table = {
        _data: ``,
        _id(id, len = 5, pad = `0`) {
          var s = `${id}`;
          while (s.length < len) {
            s = `${pad}${s}`;
          }
          return s;
        },
        _items: ``,
        async _load() {
          if (this._loading) return;
          this._loading = true;
          try {
            var { _id: householdId } = state.household;
            const cashflowAdujstment = state.firm.features.cashflowAdjustment ? state.firm.features.cashflowAdjustment : false
            var [{ data: invoices }, { data: accounts }, { data: contracts }] =
              await Promise.all(
                [
                  `invoices?householdId=${householdId}`,
                  `accounts?householdId=${householdId}`,
                  `contracts?householdId=${householdId}&status=2`,
                ].map((p) => Api.get(p))
              );
            // check if cashflowAdjustment is off for this firm 
            if (!cashflowAdujstment)
              invoices.forEach(i => {
                if (i.adjustments) delete i.adjustments
            })
            this._items = invoices.sort((a, b) =>
              a._updated.localeCompare(b._updated)
            );
            this._data = { accounts, contracts };
          } catch (e) {
            console.error(e);
          } finally {
            this._loading = false;
            _this.ready = true
          }
        },
        _loading: ``,
        _pdf: async (invoice) => {
          try {
            if (!invoice.file) {
              var { data: f } = await Api.get(
                `files?name=${encodeURIComponent(invoice.pdf)}`
              );
              invoice.file = f;
            }
            var el = this.$refs.download;
            el.setAttribute(`href`, invoice.file.dataURI);
            el.setAttribute(`download`, invoice.file.name);
            el.click();
            el.removeAttribute(`href`);
            el.removeAttribute(`download`);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _record(o) {
          var accounts = o.accounts.map((a) => a.number);
          var schedules = o.schedules.map((o) => o.name);
          var { _id, date, ref, refund } = o;
          var id = refund ? `${Record.id(o)}-R` : Record.id(o);
          var status = this._status[o.status].text;
          return { _id, accounts, date, id, ref, refund, schedules, status };
        },
        _reload() {
          events.$emit(`nav`, `invoices`);
        },
        _status: [
          { badge: `warning`, text: `Pending` },
          { badge: `success`, text: `Paid` },
          { badge: `danger`, text: `Void` },
        ],
        class(type, d) {
          switch (type) {
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            case `status`:
              var i = this._status.findIndex((o) => o.text == d.status);
              var { badge } = this._status[i];
              return `badge-${badge}`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        _export: async (items) => {
          var list = items
            .filter((o) => o.status == 0 && !o.refund)
            .map((o) => o._id);

          if (!list.length) {
            return alert.warning(`No invoice(s) selected!`, 3e3);
          }
          try {
            var { data: file } = await Api.post( `invoices?_export=csv`, { list });
            //var csv = { file }
            //return
            var el = this.$refs.download;
            el.setAttribute(`href`, file.dataURI);
            el.setAttribute(`download`, file.name);
            el.click();
            el.removeAttribute(`href`);
            el.removeAttribute(`download`);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        click(type, d) {
          switch (type) {
            case `export`:
              return this._export(this._items);
            case `account`:
              var account = this._data.accounts.find((o) => o.number == d);
              return Modal.open(`account`, { account });
            case `invoice`:
              var invoice = this._items.find((o) => o._id == d._id);
              return Modal.open(`invoice`, {
                invoice,
                methods: {
                  success: () => {
                    Modal.hide();
                    this._reload();
                  },
                },
              });
            case `pdf`:
              return this._pdf(this._items.find((o) => o._id == d._id));
            case `refund`:
              var invoice = this._items.find((o) => o._id == d._id);
              var contract = this._data.contracts.find(
                (o) => o._id == invoice.contractId
              );
              return Modal.open(`invoice-refund`, {
                contract,
                invoice,
                methods: {
                  discard: () => {
                    Modal.hide();
                  },
                  success: () => {
                    Modal.hide();
                    this._reload();
                  },
                },
              });
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        display(type, d) {
          switch (type) {
            case `value`:
              return numeral(d.value).format(`$0,0.00`);
            default:
              throw new Error(`Invalid display type, ${type}!`);
          }
        },
        edit(account) {
          var component = `account-profile`;
          var { success } = this;
          var data = { account, methods: { success: success.bind(this) } };
          events.$emit(`modal`, { component, data });
        },
        img(type) {
          switch (type) {
            case `pdf`:
              return pdfIcon;
            default:
              throw new Error(`Invalid img type, ${type}!`);
          }
        },
        init(c) {
          this._data = { accounts: [], contracts: [] };
          this.state = Component.state(c, {
            invoice: {},
            list: {
              search: ``,
              sort: { asc: ``, key: `` },
            },
          });
          this.NONE = `-`;
          this._load();
          return this;
        },
        get items() {
          var items = Array.from(this._items).map((o) => this._record(o));
          var { search, sort } = this.list;
          return List.sort(List.filter(items, search), sort.key, sort.asc);
        },
        get list() {
          return this.state.list;
        },
        async open(type, d) {
          var success = this.success.bind(this);
          switch (type) {
            case `pdf`:
              this._pdf(this._items.find((o) => o._id == d._id));
              break;
            default:
              throw new Error(`Invalid open type, ${type}!`);
          }
        },
        NONE: ``,
        get records() {
          return List.records(this.items, this._items);
        },
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        style(type) {
          switch (type) {
            case `pdf`:
              return `width: 1em`;
            default:
              throw new Error(`Invalid style type, ${type}!`);
          }
        },
        title(type, d) {
          switch (type) {
            case `refund`:
              return `Create refund`;
            case `id`:
              return `View invoice`;
            case `pdf`:
              return `Download invoice`;
            case `account`:
              return `View ${type}`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        async changePaymentStatus(id, paid = true) {

          try {
            await Api.post('invoices/update', { ids: [parseInt(id.slice(1))], paid })
            alert.message("Invoice updated!")
            this._reload();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
      };
      this.table = table.init(this);
    },
  },
};
</script>
