
<div class="main d-flex m-2">
  <div class="menu h-100" :class="nav.class(`toggle`)">
    <firms></firms>
  </div>
  <div class="toggle" :title="nav.title" v-on:click="nav.toggle()"></div>
  <div class="content flex-grow-1 h-100">
    <firm></firm>
  </div>
</div>
