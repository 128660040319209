import {
  accountValues,
  contractAlerts,
  contractAmend,
  contractCreate,
  contractTerminate,
  invoiceCreate,
  invoiceRefund,
  NCAAccountValues,
} from "../view/user/clients/modal";
import {
  custodian,
  custodianAccounts,
  groupConfirm,
  groupCreate,
  invoiceBulkConfirm,
  invoiceBulkCreate,
  NCAAccountAdd,
  NCAAccountUpdate,
  invoicesDeleteConfirm,
  invoicesPaidConfirm,
  signTemplate
} from "../view/user/firm/modal";
import {
  account,
  contract,
  household,
  invoice,
  schedule,
  scheduleConfirm,
} from "../view/user/shared/modal";
import { firm, user } from "../view/admin/firms/modal";
import help from "./help.vue";
import mfaLogin from "./mfa-login.vue";
import settings from "./settings.vue";
import ClientOnboarding from "./client-onboarding/client-onboarding.vue";
import EmailConsent from "./client-onboarding/email-consent.vue";
import OnboardConfirm from "./client-onboarding/onboard-confirm.vue";
import Files from "./settings/files.vue";
import EmailTemplates from "./settings/email-templates.vue";
import DiscloseFeeSchedule from "./disclose-fee-schedule/template.vue";
import DiscloseAnnualAmendment from "./disclose-annual-amendment/template.vue"
import InvoiceEmail from "./invoice-sending/template.vue"

export default {
  EmailTemplates,
  DiscloseFeeSchedule,
  DiscloseAnnualAmendment,
  InvoiceEmail,
  account,
  firm,
  user,
  Files,
  accountValues,
  NCAAccountValues,
  contract,
  contractAlerts,
  contractCreate,
  contractAmend,
  custodian,
  contractTerminate,
  custodianAccounts,
  help,
  household,
  invoice,
  invoiceBulkCreate,
  groupCreate,
  groupConfirm,
  invoiceBulkConfirm,
  invoicesDeleteConfirm,
  invoicesPaidConfirm,
  invoiceCreate,
  invoiceRefund,
  mfaLogin,
  schedule,
  settings,
  NCAAccountAdd,
  NCAAccountUpdate,
  ClientOnboarding,
  EmailConsent,
  OnboardConfirm,
  scheduleConfirm,
  signTemplate
};
