var capitalize = (str = "") => {
  var s = str.length ? str[0].toUpperCase() : s;
  return str.length > 1 ? s + str.substr(1).toLowerCase() : s;
};

var truncate = (str = "", max) => {
  var a = str.substr(0, max);
  var b = str.slice(max);
  var c = "...";
  return b ? a + c : a;
};

export default { string: { capitalize, truncate } };
