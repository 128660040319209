
<div class="modal-dialog modal-dialog-centered modal-md" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-alt mr-2 text-secondary"></i>
        <span class="font-weight-bold text-secondary">Generating invoice</span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        :disabled="step !== 'confirm'"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div v-if="step === 'confirm'">
        <div class="font-weight-bold confirm-alert text-center">
          You are generating
          <span v-text="countContracts"></span> new
          <span v-text="invoiceText"></span>
        </div>
        <br />
        <div
          class="text-center text-secondary confirm-info"
        >The report will be unavailable after generating the invoices</div>
      </div>
      <div v-else class="text-center">
        <loading></loading>
        <div class="progress mt-3">
          <div
            :style="`width: ${(progressBarValue / progressBarMax) * 100}%`"
            class="progress-bar"
            role="progressbar"
          ></div>
        </div>
        <div
          class="font-weight-bold text-center text-secondary mt-4 confirm-info"
        >Please, wait until invoices are generated.</div>
        <div
          class="text-center text-secondary confirm-sub-info"
        >Don't close or refresh this window.</div>
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button
          type="button"
          class="btn btn-sm btn-outline-danger mr-1"
          data-dismiss="modal"
          :disabled="step !== 'confirm'"
        >Cancel</button>
        <button
          data-cy="invoice-bulk-confirm-submit-btn"
          type="button"
          class="btn btn-sm btn-primary submit-btn"
          :disabled="step !== 'confirm'"
          v-on:click="submit"
        >Generate invoices</button>
      </div>
    </div>
  </div>
</div>
