<template>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-cog mr-2"></i>
          <span class="font-weight-bold">MFA</span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="px-5 py-3">
          <mfa :submit="modal.submit"></mfa>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
button {
  width: 80px;
}
div.form-row i {
  opacity: 0.5;
  vertical-align: sub;
}
</style>

<script>
import { Api } from "../factory";
import { mfa } from "../component";
import { alert } from "../service";

export default {
  get components() {
    return {
      mfa
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: o =>
        typeof o.methods == `object` &&
        typeof o.methods.success == `function` &&
        typeof o.methods.error == `function`
    }
  },
  data() {
    return {
      modal: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { methods } = this.data;
      var modal = {
        error: ``,
        init() {
          this.error = { count: 0, limit: 3 };
          return this;
        },
        submit: async (code, reset) => {
          try {
            var { modal } = this;
            var data = { mfa: { code } };
            var resp = await Api.post(`users?_action=login`, data);
            var { mfaToken } = resp.data;
            methods.success(mfaToken);
          } catch (e) {
            console.error(e);
            var { error } = modal;
            error.count++;
            if (error.count >= error.limit) return methods.error();
            alert.error(e.message, 15e2);
            reset();
          }
        }
      };
      this.modal = modal.init();
    }
  }
};
</script>
