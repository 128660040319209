
import events from "./events";
import { Sys } from "../factory";

class Alert {
  static _payload(text, hide, color) {
    return { class: Alert._class(color), text, hide };
  }

  static _class(color = "success") {
    return `alert-${color}`;
  }

  static _message(text, hide = 0, color = undefined) {
    events.$emit("alert", Alert._payload(text, hide, color));
    return hide ? Sys.wait(hide) : undefined;
  }

  static error(text, hide) {
    return Alert._message(text, hide, "danger");
  }

  static info(text, hide) {
    return Alert._message(text, hide, `info`);
  }

  static message(text, hide) {
    return Alert._message(text, hide);
  }

  static warning(text, hide) {
    return Alert._message(text, hide, "warning");
  }
}

export default Alert;
