
<div class="d-flex">
  <div class="flex-grow-1">
    <input
      v-model="search.model"
      class="form-control form-control-sm"
      placeholder="Search"
      v-on:keyup="search.keyup()"
      v-on:keydown="search.keydown()"
    />
  </div>
  <div class="search">
    <template v-if="search.model.length">
      <i
        class="fas fa-times-circle reset"
        title="Clear search"
        v-on:click="search.click(`reset`)"
      ></i>
    </template>
    <template v-else>
      <i class="fas fa-search"></i>
    </template>
  </div>
</div>
