
<div>
  <div class="d-flex mt-2 mb-2">
    <u class="font-weight-bold">Rebate Transactions</u>
  </div>
  <table class="table table-sm">
    <thead>
      <tr>

        <th style="width: 19.8%;">
          <span>Account</span>
        </th>
        <th>
          <span>Apply on Transaction:</span>
        </th>
        <th v-if="!readonly"
            class="text-right">
          <i class="fas fa-plus-circle"
             title="Add new transaction rule"
             v-on:click="addNewTransactionRule()"></i>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(transactionRule, index) in rules"
          :key="index">
        <td>
          <select v-if="!readonly"
                  v-model="transactionRule.account"
                  class="form-control form-control-sm">
            <option v-for="a in accounts"
                    :key="a">
              <span v-text="a"></span>
            </option>
          </select>
          <span v-else>{{ transactionRule.account }}</span>

        </td>
        <td>
          <select v-if="!readonly"
                  v-model="transactionRule.detail"
                  class="form-control form-control-sm">
            <option v-for="c in details"
                    :key="c">
              <span v-text="c"></span>
            </option>
          </select>
          <span v-else>{{ transactionRule.detail }}</span>
        </td>
        <td class="text-right"
            v-if="!readonly">
          <i class="fas fa-minus-circle"
             title="Remove transaction rule"
             v-on:click="removeTransactionRule(index)"></i>
        </td>
      </tr>
    </tbody>
  </table>
</div>
