<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header">
      <div class="d-flex actions mr-2">
        <div title="Create Invoice"
          class="btn btn-outline-secondary action-button"
             v-on:click="table.click(`create`, `invoice`)" >
          <i class="mr-1 fas fa-file-invoice-dollar"></i>
          <span>Create Invoice</span>
        </div>
      </div>
      <div class="flex-grow-1 text-right">
        <span v-text="table.records"></span>
      </div>
    </div>
  <template v-if="ready">
    <scroll-area class="pr-3">
      <a ref="download"
         class="d-none"></a>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Id</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `id`)"
                 v-on:click="table.sort(`id`)"></i>
            </th>
            <th>
              <span>Ref</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `ref`)"
                 v-on:click="table.sort(`ref`)"></i>
            </th>
            <th>
              <span>Accounts</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `accounts`)"
                 v-on:click="table.sort(`accounts`)"></i>
            </th>
            <th>
              <span>Schedules</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `schedules`)"
                 v-on:click="table.sort(`schedules`)"></i>
            </th>
            <th class="text-nowrap">
              <span>Date</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `date`)"
                 v-on:click="table.sort(`date`)"></i>
            </th>
            <th class="text-nowrap">
              <span>Status</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `status`)"
                 v-on:click="table.sort(`status`)"></i>
            </th>
            <th class="text-nowrap"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items"
              :key="o._id">
            <td>
              <a href="#" class="badge badge-custom"
                    :title="table.title(`contract`)"
                    v-on:click="table.click(`contract`, o)"
                    v-text="o.id"></a>
            </td>
            <td class="text-nowrap">
              <span v-text="o.ref"></span>
            </td>
            <td class="" >
                <a href="#" v-for="(a, i) in o.accounts"
                      :key="i"
                      class="badge badge-custom mr-2"
                      :title="table.title(`account`, a)"
                      v-on:click="table.click(`account`, a)"
                      v-text="a"></a>
            </td>
            <td class="schedules">
              <!-- <schedule-badges :schedules="o.schedules"></schedule-badges> -->
              <span v-for="(s, i) in o.schedules" :key="i" class="mr-2" v-text="s"></span>
            </td>
            <td class="text-nowrap">
              <span v-if="o.expiredOn"
                    class="alert-danger"
                    v-text="o.expiredOn"></span>
              <span v-else
                    v-text="o.date"></span>
            </td>
            <td>
              <div class="dropdown">
                <span class="badge"
                      :data-toggle="prod ? `` : `dropdown`"
                      :class="table.class(`status`, o)"
                      v-text="o.status"></span>

                <div ref="status"
                     class="dropdown-menu">
                  <button class="dropdown-item"
                          v-for="(status, i) in table._status"
                          v-on:click="table.updateContract(o._id, i)">{{ status.text }}</button>
                </div>
              </div>
            </td>
            <td class="text-nowrap">
              <i class="far fa-file-pdf mr-2"
                 :title="table.title(`pdf`)"
                 v-on:click="table.click(`pdf`, o)"></i>
              <i class="fas fa-dollar-sign mr-2"
                 :title="table.title(`bill`)"
                 :class="o.status != `Active` ? `disabled` : ``"
                 v-on:click="o.status == `Active` ? table.click(`bill`, o) : ``"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
    </template>
    <template v-else>
      <loading></loading>
    </template>
  </div>
</template>

<style scoped>
i.disabled {
  cursor: not-allowed !important;
  opacity: 0.1 !important;
}

div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}

div.header div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}

table span {
  font-size: 0.9rem;
}

span.link {
  cursor: pointer;
  text-decoration: underline;
}



td.accounts span,
td.schedules span {
  white-space: pre;
}

td {
  vertical-align: inherit;
}

i {
  opacity: 0.3;
}

i:hover,
i.sort,
i.active {
  cursor: pointer;
  opacity: 1;
}

span.badge {
  color: white;
  font-weight: normal;
  width: 5em;
}

i.active {
  color: red;
}
</style>

<script>
import { scrollArea, search, loading } from "../../../../component";
import ScheduleBadges from "../../../../component/schedule/schedule-badges.vue";
import { Api, Component, List, Modal, Record } from "../../../../factory";
import { numeral } from "../../../../npm";
import { alert, events, session } from "../../../../service";

export default {
  name: `HouseholdContracts`,
  get components() {
    return {
      scrollArea,
      search,
      ScheduleBadges,
      loading
    };
  },
  data() {
    return {
      table: ``,
      prod: import.meta.env.MODE == "prod",
      ready: false
    };
  },
  created() {
    this.init();
    var { table } = this;
    this.events = events.$watch({
      modal: table.events.bind(table),
    });
  },
  destroyed() {
    events.$unwatch(this.events);
  },
  methods: {
    init() {
      var state = session.get(`state`);
      var _this = this
      var table = {
        async _alerts(contracts) {
          try {
            // var alerts = (
            //   await Promise.all(
            //     contracts.map((o) =>
            //       Api.get(`alerts?type=contract&parentId=${o._id}&mute=false`)
            //     )
            //   )
            // ).reduce((a, r) => {
            //   a.push(...r.data);
            //   return a;
            // }, []);
            // this._data.alerts = alerts;
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _data: ``,
        _items: ``,
        async _load() {
          if (this._loading) return;
          this._loading = true;
          try {
            var [{ data: contracts }, { data: accounts }] = await Promise.all(
              [`contracts?householdId=${state.household._id}`, `accounts?householdId=${state.household._id}`].map(
                (p) => Api.get(p)
              )
            );
            // this._alerts(contracts);
            this._data.accounts = accounts;
            this._items = contracts.sort((a, b) =>
              b.date.localeCompare(a.date)
            );
          } catch (e) {
            console.error(e);
          } finally {
            this._loading = false;
            _this.ready = true
          }
        },
        _loading: ``,
        _pdf: async (contract) => {
          try {
            if (!contract.file) {
              if (!contract.pdf)
                return alert.warning(`Sorry, this contract has no pdf?`, 3e3);
              var { data: f } = await Api.get(
                `files?name=${encodeURIComponent(contract.pdf)}`
              );
              contract.file = f;
            }
            var el = this.$refs.download;
            el.setAttribute(`href`, contract.file.dataURI);
            el.setAttribute(`download`, contract.file.name);
            el.click();
            el.removeAttribute(`href`);
            el.removeAttribute(`download`);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _record(o) {
          var accounts = o.accounts.map((a) => a.number);
          var schedules = o.schedules.map((o) => o.name);
          var { _id, ref, date } = o;
          var id = Record.id(o);
          var status = this._status[o.status].text;

          const expiredOn = o.expiredOn ? moment(o.expiredOn).format("MMM D, YYYY") : undefined
          return { _id, accounts, date, expiredOn, id, ref, schedules, status };
        },
        _reload() {
          events.$emit(`nav`, `contracts`);
        },
        _status: [
          { badge: `warning`, text: `Pending` },
          { badge: `success`, text: `Signed` },
          { badge: `primary`, text: `Active` },
          { badge: `danger`, text: `Expired` },
          { badge: `warning`, text: `Amended` },
        ],
        class(type, d) {
          switch (type) {
            case `alerts`:
              return this._data.alerts.find((o) => o.parentId == d._id)
                ? `fas active`
                : `far`;
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            case `status`:
              var i = this._status.findIndex((o) => o.text == d.status);
              var { badge } = this._status[i];
              return `badge-${badge}`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(type, d) {
          switch (type) {
            case `account`:
              var account = this._data.accounts.find((o) => o.number == d);
              return Modal.open(`account`, { account });
            case `alerts`:
              var contract = this._items.find((o) => o._id == d._id);
              return Modal.open(`contract-alerts`, { contract });
            case `bill`:
              var contract = this._items.find((o) => o._id == d._id);
              return this.click(`invoice`, contract);
            case `invoice`:
              return Modal.open(`invoice-create`, {
                contract: d,
                methods: {
                  success: () => {
                    Modal.close();
                    events.$emit(`nav`, `invoices`);
                  },
                },
              });
            case `contract`:
              var contract = this._items.find((o) => o._id == d._id);

              return Modal.open(`contract`, {
                contract,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  },
                },
              });
            case `create`:
              var contract = this._items.find((o) => o.status == 2);
              var e = this._items.length
                ? `No active contract found! Click the contract $ icon to invoice`
                : `No active contract found!`;
              if (!contract) return alert.warning(e, 3e3);
              return this.click(`invoice`, contract);
            case `pdf`:
              return this._pdf(this._items.find((o) => o._id == d._id));
          }
        },
        display(type, d) {
          switch (type) {
            case `value`:
              return numeral(d.value).format(`$0,0.00`);
            default:
              throw new Error(`Invalid display type, ${type}!`);
          }
        },
        events(e) {
          switch (e.name) {
            case `modal`:
              if (e.data && e.data.action && e.data.action == `hidden`)
                this._load();
              break;
          }
        },
        init(c) {
          this._data = { alerts: [], accounts: [] };
          this.state = Component.state(c, {
            invoice: {},
            list: {
              search: ``,
              sort: { asc: ``, key: `` },
            },
          });
          this.NONE = `-`;
          this._load();
          return this;
        },
        get items() {
          var items = Array.from(this._items).map((o) => this._record(o));
          var { search, sort } = this.list;
          return List.sort(List.filter(items, search), sort.key, sort.asc);
        },
        get list() {
          return this.state.list;
        },
        NONE: ``,
        get records() {
          return List.records(this.items, this._items);
        },
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        state: ``,
        title(type, d) {
          switch (type) {
            case `account`:
              var a = this._data.accounts.find((o) => o.number == d);
              return a ? a.name : ``;
            case `alerts`:
              return `View notifications`;
            case `bill`:
              return `Invoice contract`;
            case `contract`:
              return `View ${type}`;
            case `pdf`:
              return `Download contract`;
            case `instance`:
              return `View Smart Contract`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        updateContract(_id, status) {
          Api.put(`contracts/${_id}`, { status })
            .then(() => this._reload())
            .catch(error => console.error(error));
        }
      };
      this.table = table.init(this);
    },
  },
};
</script>
