<template>
  <div class="root">
    <div>
      <div class="card">
        <div class="card-body">
          <div>
            <img :src="login.logo" />
            <form>
              <div class="form-group">
                <label>2FA</label>
                <input
                  v-model="login.mfa"
                  class="form-control"
                  placeholder="2FA Code"
                />
              </div>
              <div class="form-group">
                <hr style="opacity: 0.4" />
                <button
                  type="button"
                  class="btn btn-info btn-block"
                  :disabled="login.disabled('reset')"
                  v-on:click="login.submit()"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
p.forgot {
  text-align: right;
  margin-top: -15px;
  font-size: 12px;
}
div.root {
  background-color: #e9ebee;
  width: 100%;
  height: 100vh;
}
div.root > div {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 400px;
  margin-left: -300px;
  margin-top: -250px;
}
div.card {
  width: 600px;
  margin: auto;
}
div.card-body {
  padding: 30px;
  background: white;
}
div.card-body form {
  margin-top: 25px;
}
img {
  max-width: 100%;
}
</style>

<script>
import { Api, Sys } from "../factory";
import { validator } from "../npm";
import { alert, auth } from "../service";

import logo from "../../../img/logo-skx-kw.png";

export default {
  data() {
    return {
      login: ""
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var login = {
        disabled(type) {
          return false;
          switch (type) {
            case `login`:
              return !this.pending &&
                validator.isEmail(this.email) &&
                this.pass.length > 3
                ? false
                : true;
            case `reset`:
              return !this.pending && validator.isEmail(this.email)
                ? false
                : true;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init(forgot = ``) {
          this.email = ``;
          this.forgot = forgot;
          this.pass = ``;
          this.pending = ``;
          return this;
        },
        get logo() {
          return logo;
        },
        reset(forgot = false) {
          this.init(forgot);
        },
        async submit(reset) {
          var wait = 3e3;
          this.pending = true;
          if (!reset) {
            var error;
            try {
              await auth.login(this.email, this.pass);
            } catch (e) {
              console.error(e);
              await alert.error(e.message, wait);
              this.reset();
            }
          } else {
            try {
              var resp = await Api.post(`auth/reset`, { email });
              await alert.message(resp.data, wait);
              this.reset();
            } catch (e) {
              console.error(e);
              alert.error(`Password reset failed!`);
            }
          }
        }
      };
      this.login = login.init();
    }
  }
};
</script>
