
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">Disclose Annual Amendment</div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body p-2">
      <div class="px-4 py-3" v-if="missingContacts && !multipleContracts">
        <Title error>Can't send emails to household "{{ data.household.name }}" because it does not have contacts.
        </Title>
      </div>
      <div class="px-4 py-3" v-else>
        <Title>The following email will be sent to {{ data.household ? data.household.contacts.map(c =>
          c.firstName).join(", ") : `selected households.`
          }}</Title>
        <div class="mt-4 email-body">
          <IsBlackBarn v-if="data.isBlackBarn"></IsBlackBarn>
          <IsSims v-else-if="data.isSims"></IsSims>
          <Is603 v-else-if="data.is603"></Is603>
          <DefaultTemplate v-else></DefaultTemplate>
        </div>
      </div>
    </div>

    <div class="modal-footer pr-3">
      <div>
        <button type="button" class="btn btn-sm modal-button btn-outline-danger" v-on:click="cancel()">
          <span>Cancel</span>
        </button>
        <button v-if="!missingContacts || multipleContracts" type="button"
          class="btn btn-sm modal-button btn-primary ml-2" v-on:click="send()">
          <span>Send</span>
        </button>
      </div>
    </div>
  </div>
</div>
