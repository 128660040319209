<template>
  <div>
    <div class="d-flex mt-2 mb-2">
      <u class="font-weight-bold">Rebate Transactions</u>
    </div>
    <table class="table table-sm">
      <thead>
        <tr>

          <th style="width: 19.8%;">
            <span>Account</span>
          </th>
          <th>
            <span>Apply on Transaction:</span>
          </th>
          <th v-if="!readonly"
              class="text-right">
            <i class="fas fa-plus-circle"
               title="Add new transaction rule"
               v-on:click="addNewTransactionRule()"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(transactionRule, index) in rules"
            :key="index">
          <td>
            <select v-if="!readonly"
                    v-model="transactionRule.account"
                    class="form-control form-control-sm">
              <option v-for="a in accounts"
                      :key="a">
                <span v-text="a"></span>
              </option>
            </select>
            <span v-else>{{ transactionRule.account }}</span>

          </td>
          <td>
            <select v-if="!readonly"
                    v-model="transactionRule.detail"
                    class="form-control form-control-sm">
              <option v-for="c in details"
                      :key="c">
                <span v-text="c"></span>
              </option>
            </select>
            <span v-else>{{ transactionRule.detail }}</span>
          </td>
          <td class="text-right"
              v-if="!readonly">
            <i class="fas fa-minus-circle"
               title="Remove transaction rule"
               v-on:click="removeTransactionRule(index)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
i {
  opacity: 0.3;
}

i:not(.disable):hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}

span {
  font-size: 0.9em;
}
</style>
<script lang="ts">
import { toHandlers } from "@vue/runtime-core";
import Vue, { PropType } from "vue";
import { Api } from "../../factory";

interface Account {
  name: string;
  number: string;
}
interface TransactionRule { detail: string, account: string, description: string }

export default Vue.extend({
  model: {
    prop: 'transactionsRules',
    event: 'change'
  },
  props: {
    contractAccounts: {
      type: Array as PropType<Account[]>,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    transactionsRules: {
      type: Array as PropType<TransactionRule[]>,
      required: true,
    }
  },
  data() {
    return {
      rules: [] as TransactionRule[],
      details: ["Wiring and Transactional Fees", "WIRING FEE", "TRANSACTIONAL FEE"],
      accounts: ["All Accounts"],
    }
  },
  created() {
    this.rules = this.transactionsRules;

    this.contractAccounts?.forEach(acc => {
      this.accounts.push(acc.number)
    })
  },
  methods: {
    handleChange() {
      this.$emit('change', this.rules)
    },
    addNewTransactionRule() {
      this.rules.push({ detail: this.details[0], account: "All Accounts", description: "" })
      this.handleChange()
    },
    removeTransactionRule(index: number) {
      this.rules.splice(index, 1)
      this.handleChange();
    }
  }
})
</script>