<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header">
      <div class="d-flex actions mr-2">
        <div class="btn btn-outline-secondary" title="Add User" v-on:click="table.click(`add`, `user`)">
          <i class="fas mr-1 fa-plus-square"></i>
          <span>Add</span>
        </div>
      </div>
      <div class="flex-grow-1 text-right">
        <span v-text="table.records"></span>
      </div>
    </div>
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Name</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `name`)"
                v-on:click="table.sort(`name`)"
              ></i>
            </th>
            <th>
              <span>Email</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `email`)"
                v-on:click="table.sort(`email`)"
              ></i>
            </th>
            <th>
              <span>Firm</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `firm`)"
                v-on:click="table.sort(`firm`)"
              ></i>
            </th>
            <th>
              <span>Role</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `role`)"
                v-on:click="table.sort(`role`)"
              ></i>
            </th>
            <th>
              <span>Last Seen</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `lastSeen`)"
                v-on:click="table.sort(`lastSeen`)"
              ></i>
            </th>
            <th>
              <span>MFA</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `mfa`)"
                v-on:click="table.sort(`mfa`)"
              ></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items" :key="index">
            <td>
              <span
                class="text-primary link"
                :title="table.title(`user`)"
                v-on:click="table.click(`open`, o)"
                v-text="o.name"
              ></span>
            </td>
            <td>
              <span v-text="o.email"></span>
            </td>
            <td>
              <span v-text="o.firm"></span>
            </td>
            <td>
              <span class="text-capitalize" v-text="o.role"></span>
            </td>
            <td>
              <span v-text="o.lastSeen"></span>
            </td>
            <td>
              <checkbox
                :o="o"
                p="MFA"
                :disabled="table.disabled(`MFA`, o)"
                :title="table.title(`MFA`, o)"
              ></checkbox>
            </td>
            <td>
              <i
                class="fas fa-key mr-3"
                :title="table.title(`reset`)"
                v-on:click="table.click(`reset`, o)"
              ></i>
              <i
                :class="table.class(`locked`, o)"
                :title="table.title(`locked`, o)"
                v-on:click="table.click(`locked`, o)"
              ></i>
            </td>
            <!--
                        <td>
                            <template v-if="o.household != table.NONE">
                                <span class="text-primary link" v-text="o.household" v-on:click="table.open(`household`, o)" :title="table.title(`household`)"></span>
                            </template>
                            <template v-else>
                                <span v-text="o.household"></span>
                            </template>
                        </td>
                        <td>
                            <template v-if="o.contract != table.NONE">
                                <span class="text-primary link" v-text="o.contract" v-on:click="table.open(`contract`, o)" :title="table.title(`contract`)"></span>
                            </template>
                            <template v-else>
                                <span v-text="o.contract"></span>
                            </template>
                        </td>
                        <td>
                            <checkbox :o="o" p="select" :disabled="table.disabled(`select`, o)"></checkbox>
                        </td>
                        -->
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </div>
</template>

<style scoped>
div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}
div.header div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}
div.actions div:hover i,
div.filters i:hover {
  opacity: 1;
}
div.filters div {
  border-left: 1px solid lightgray;
  padding-left: 1em;
}
table span {
  font-size: 0.9rem;
}
span.link {
  cursor: pointer;
  text-decoration: underline;
}
table th:nth-child(1) {
  width: 18%;
}
table th:nth-child(2) {
  width: 22%;
}
table th:nth-last-child(4) {
  width: 6em;
}
table th:nth-last-child(3) {
  width: 14em;
}
table th:nth-last-child(2) {
  width: 4em;
}
table th:nth-last-child(1) {
  width: 5em;
}
/*
    table th:nth-child(3) {
        width: 7em;
    }
    */
/*
    table th:nth-last-child(3) {
        width: 22%;
    }
    table th:nth-last-child(2) {
        width: 7em;
    }
    */
td {
  vertical-align: inherit;
}
i {
  opacity: 0.3;
}
i:not(.fa-filter):hover,
i.sort,
i.fa-spin,
i.remove,
i.filter {
  cursor: pointer;
  opacity: 1;
}
i.remove {
  color: red;
}
</style>

<script>
import { checkbox, scrollArea, search } from "../../../../component";
import { Api, Component, lib, List, Modal, Record } from "../../../../factory";
import { numeral } from "../../../../npm";
import { alert, events, session } from "../../../../service";
import moment from "moment";
import firebase from 'firebase';

export default {
  name: `FirmUsers`,
  get components() {
    return {
      checkbox,
      scrollArea,
      search
    };
  },
  data() {
    return {
      table: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      var table = {
        _data: ``,
        _items: ``,
        _loading: ``,
        async _locked(o) {
          try {
            if (o.id == 1)
              return alert.warning(
                `Unlocking the admin account is forbidden!`,
                3e3
              );
            if (state.user.id == o.id)
              return alert.warning(`Unable to lock your own account!`, 3e3);
            var locked = !o.locked;
            await Api.put(`users/${o._id}`, { locked });
            this.load();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _records: ``,
        _reload() {
          events.$emit(`nav`, `users`);
        },
        async _reset(d) {
          try {
            var { email } = d;
            await Api.put(`users?_action=reset`, { email });
            alert.message(`Password reset email has been sent!`, 3e3);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        change(type) {
          switch (type) {
            case `select`:
              this.items
                .filter(o => !o.hasOwnProperty(`_id`))
                .forEach(o => (o.select = this.select));
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          var { filter, sort } = this.list;
          switch (type) {
            case `locked`:
              return d.locked ? `fas fa-lock` : `fas fa-lock-open`;
            case `filter`:
              return filter[d] ? `filter` : ``;
            case `sort`:
              return sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        async click(type, d) {
          switch (type) {
            case `open`:
              return Modal.open(`user`, {
                user: d,
                methods: {
                  success: () => {
                    this.load();
                    Modal.close();
                  }
                }
              });
            case `reset`:
              return this._reset(d);
            case `locked`:
              return this._locked(d);
            case `add`:
              var { _id: firmId } = state.firm;
              return Modal.open(`user`, {
                user: { firmId },
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  }
                }
              });
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        display(type, d) {
          switch (type) {
            case `value`:
              return d.value ? numeral(d.value).format(`$0,0.00`) : this.NONE;
            default:
              throw new Error(`Invalid display type, ${type}!`);
          }
        },
        disabled(type, d) {
          switch (type) {
            case `active`:
              return d._id == state.user._id;
            case `MFA`:
              return !(d.hasOwnProperty(`mfa`) && d.mfa.active);
            case `select`:
              return d.hasOwnProperty(`_id`);
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init(c) {
          this.state = Component.state(c, {
            list: {
              search: ``,
              sort: { asc: ``, key: `` }
            }
          });
          //                        this._data = { contracts: [], households: [] }
          this.NONE = `-`;
          this.load();
          return this;
        },
        get items() {
          var items = Array.from(this._items);
          var { search, sort } = this.list;
          return List.sort(List.filter(items, search), sort.key, sort.asc);
        },
        get list() {
          return this.state.list;
        },
        async load() {
          if (this._loading) return;
          this._loading = true;
          try {
            var { _id: firmId } = state.firm;
            var [
              { data: users },
              { data: sessions },
              { data: firms }
            ] = await Promise.all([
              Api.get(state.firm.id == 1 ? `users` : `users?firmId=${firmId}`),
              Api.get(`sessions`),
              Api.get(`firms`)
            ]);
            // console.log('ID Token: ', firebase.auth().currentUser.getIdToken(/* forceRefresh */ true));
            this._items = users.map(o => {
              var s = sessions.find(s => s.userId == o._id);
              var lastSeen = s ? moment(s._updated).format(`LLL`) : this.NONE;
              var MFA = o.hasOwnProperty(`mfa`) ? o.mfa.active : false;
              var f =
                state.firm.id == 1 ? firms.find(f => f._id == o.firmId) : ``;
              var firm = f ? f.name : this.NONE;
              return { firm, lastSeen, MFA, ...o };
            });
          } catch (e) {
            console.error(e);
          } finally {
            this._loading = false;
          }
        },
        NONE: ``,
        async open(type, d) {
          switch (type) {
            case `account`:
              var { NONE } = this;
              return Modal.open(`account`, {
                account: d,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  }
                },
                NONE
              });
            case `contract`:
              var contract = this._data.contracts.find(
                o => o._id == d.contractId
              );
              return Modal.open(`contract`, {
                contract,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  }
                }
              });
            case `household`:
              var household = this._data.households.find(
                o => d.household == o.name
              );
              return Modal.open(`household`, {
                household,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  }
                }
              });
            default:
              throw new Error(`Invalid open type, ${type}!`);
          }
        },
        get records() {
          return List.records(this.items, this._items);
        },
        select: ``,
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        state: ``,
        title(type, d) {
          switch (type) {
            case `account`:
            case `contract`:
            case `household`:
            case `user`:
              return `View ${type}`;
            case `records`:
              return `View account records`;
            case `reset`:
              return `Reset password`;
            case `locked`:
              return d.locked ? `Unlock account` : `Lock account`;
            case `MFA`:
              return d.hasOwnProperty(`mfa`) && d.mfa.active
                ? `Disable MFA`
                : ``;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        }
      };
      this.table = table.init(this);
    }
  }
};
</script>
