
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-building mr-2"></i>
        <span class="font-weight-bold" v-text="firm.text(`title`)"></span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <scroll-area class="px-3 py-2">
        <div class="form-row">
          <div class="col-4">
            <div class="form-group">
              <label>Id</label>
              <input
                class="form-control form-control-sm"
                :value="firm.id"
                readonly
                placeholder="Auto"
              />
              <small class="form-text text-muted">Firm Id</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Name</label>
              <input
                v-model="firm.name"
                class="form-control form-control-sm"
                placeholder="Name"
              />
              <small class="form-text text-muted">Firm Name</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-6">
            <div class="form-group">
              <label>Email</label>
              <input
                v-model="firm.email"
                class="form-control form-control-sm"
                type="email"
                placeholder="Email"
              />
              <small class="form-text text-muted">Firm Email</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Phone</label>
              <input
                v-model="firm.phone"
                class="form-control form-control-sm"
                type="text"
                placeholder="Phone number"
              />
              <small class="form-text text-muted">Firm Phone</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label>Address</label>
              <input
                v-model="firm.address"
                class="form-control form-control-sm"
                type="text"
                placeholder="Address"
              />
              <small class="form-text text-muted">Firm Address</small>
            </div>
          </div>
        </div>
        <template v-if="firm.id">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label>Logo</label>
                <div
                  class="logo my-2"
                  title="Click to upload new logo"
                  v-on:click="file.logo.choose()"
                >
                  <img :src="firm.logo.data" />
                </div>
                <p class="name">
                  <small class="text-muted" v-text="firm.logo.name"></small>
                  <template v-if="firm.logo.file">
                    <i
                      class="fas fa-file-download ml-2"
                      title="Download logo"
                      v-on:click="file.download(firm.logo)"
                    ></i>
                  </template>
                </p>
                <file-upload :file="file.logo"></file-upload>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Contract</label>
                <div
                  class="logo my-2"
                  title="Click to upload new contract"
                  v-on:click="file.contract.choose()"
                >
                  <img :src="firm.contract.data" />
                </div>
                <p class="name">
                  <small
                    class="text-muted"
                    v-text="firm.contract.name"
                  ></small>
                  <template v-if="firm.contract.file">
                    <i
                      class="fas fa-file-download ml-2"
                      title="Download contract"
                      v-on:click="file.download(firm.contract)"
                    ></i>
                  </template>
                </p>
                <file-upload :file="file.contract"></file-upload>
              </div>
            </div>
          </div>
        </template>
      </scroll-area>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <remove-button :button="firm.remove"></remove-button>
        <button
          type="button"
          class="btn btn-sm btn-primary ml-2"
          :disabled="firm.disabled(`save`)"
          v-on:click="firm.click(`save`)"
        >
          <span class="button">Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
