<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Files</div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <div class="px-4 py-3">
          <div class="list-group list-group-flush">
            <div class="list-group-item list-group-item-action" v-for="fileSlot in defaultFiles">
              <div class="form-row">
                <div class="col-1">
                  <i class="far fa-file-pdf"></i>
                </div>
                <span class="col-3">{{ fileSlot.slot }}</span>
                <div class="col form-row" v-if="fileSlot.file.name">
                  <span class="col-11 text-right">{{ fileSlot.file.name }}</span>
                  <div class="col-1">
                    <IconButton icon="far fa-trash-alt" v-on:click="changeFile(fileSlot.slot)" />
                  </div>
                </div>
                <!-- <div class="col" v-if="!fileSlot.file.name"></div> -->
                <div class="col text-right" v-if="!fileSlot.file.name">
                  <label :for="fileSlot.slot" class="btn btn-sm btn-outline-secondary">Choose File</label>
                  <input
                    style="display: none;"
                    :id="fileSlot.slot"
                    type="file"
                    accept="application/pdf"
                    v-on:change="filesChange($event.target.files, fileSlot.slot)"
                  />
                </div>
              </div>
            </div>
          </div>

          <h6 class="mt-3 mb-3">Add other files</h6>

          <label for="files" class="btn btn-sm btn-outline-secondary">Add File</label>
          <input
            style="display: none;"
            id="files"
            type="file"
            accept="application/pdf"
            v-on:change="filesChange($event.target.files)"
          />
          <hr />
          <div class="list-group list-group-flush">
            <div class="list-group-item list-group-item-action" v-for="file in files">
              <i class="far fa-file-pdf mr-1"></i>
              {{ file.name }}
              <IconButton icon="far fa-trash-alt float-right" v-on:click="deleteFile(file)" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-sm modal-button btn-primary ml-2"
            v-on:click="save()"
          >
            <span>Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-button {
  width: 80px;
}
.modal-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 16px;

  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #616466;
}

.modal-body {
  min-height: 50vh;
}
</style>

<script>
import { alert } from "../../service";
import IconButton from "../../component/icon-button.vue";
import { Api } from "../../factory";


export default {
  props: {
    data: {
      files: []
    }
  },
  data() {
    return {
      files: [],
      defaultFiles: [
        { slot: "ADV Part 2A", file: { name: "" } },
        { slot: "ADV Part 2B", file: { name: "" } },
        { slot: "Privacy Policy", file: { name: "" } },
        { slot: "Form CRS", file: { name: "" } },
      ],
      promises: [],
      deletes: [],
    };
  },
  created() {
    for (const file of this.data.files) {
      if (file.slot) {
        this.defaultFiles.filter(f => f.slot == file.slot).forEach(f => f.file = file)
      } else {
        this.files.push(file)
      }
    }

    // trigger render
    this.defaultFiles = this.defaultFiles
  },
  methods: {
    changeFile(slot) {
      this.defaultFiles.filter(f => f.slot == slot).forEach(f => {
        if (f.file._id) this.deletes.push(f.file._id);
        f.file = { name: "" }
      })
      this.defaultFiles = this.defaultFiles
    },
    filesChange(fileList, slot = "") {
      if (!fileList.length)
        return;

      const [file] = fileList

      if (slot) {
        this.defaultFiles.filter(f => f.slot == slot).forEach(f => f.file = file)
        return this.defaultFiles = this.defaultFiles
      }

      if (this.files.some(f => f.name.localeCompare(file.name) == 0))
        return

      this.files.push(file)
    },
    save() {
      for (const fileSlot of this.defaultFiles) {
        if (fileSlot.file.name && !fileSlot.file._id) {
          this.upload(fileSlot.file, fileSlot.slot)
        }
      }

      for (const file of this.files) {
        if (!file._id) {
          this.upload(file)
        }
      }

      if (this.deletes.length) {
        Promise.all(this.deletes.map((_id) => Api.post("/files/delete-firm-files", { _id })))
          .then(() => { alert.message("Files deleted!", 1500) })
          .catch((e) => { console.error(e); alert.error(e) });
      }
    },
    upload(file, slot = "") {
      const reader = new FileReader();

      reader.onloadend = () => {
        const { type, name, size } = file;
        const request = { type, name, size, dataURL: reader.result }

        if (slot)
          request.slot = slot

        Api.post("/files/firm-files", request)
          .then(() => { alert.message("Files uploaded!", 1500) })
          .catch((e) => { console.error(e); alert.error(`${name}: ${e}`) });
      };

      reader.readAsDataURL(file);
    },
    deleteFile(file) {
      this.files = this.files.filter(f => f.name.localeCompare(file.name) != 0);

      if (file._id) this.deletes.push(file._id)
    }
  },
  components: { IconButton }
};
</script>
