<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <caption v-if="table.householdNote || table.scheduleName">
            <hr>
            <p>This fee schedule & notes, if applicable, were listed on the client agreement sent for signature.</p>
            <mark v-if="table.scheduleName">{{ table.scheduleName }}<br></mark>
            <mark v-if="table.householdNote">Notes: {{ table.householdNote }}</mark>
        </caption>
        <thead>
          <tr>
            <th></th>
            <th>
              <span>Number</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `number`)"
                 v-on:click="table.sort(`number`)"></i>
            </th>

            <th>
              <span>Name</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `name`)"
                 v-on:click="table.sort(`name`)"></i>
            </th>
            <th>
              <span>Schedule</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `schedule`)"
                 v-on:click="table.sort(`schedule`)"></i>
            </th>
            <th>
              <span>Billing</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `billing`)"
                 v-on:click="table.sort(`billing`)"></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items"
              :key="index">
            <td>
              <checkbox :o="o"
                        p="select"></checkbox>
            </td>
            <td>
              <span v-text="o.number"></span>
            </td>
            <td>
              <span v-text="o.name"></span>
            </td>
            <td>
              <select v-model="o.scheduleName"
                      class="form-control form-control-sm"
                      :disabled="!o.select">
                <option v-for="s in table.schedules"
                        :key="s.name">
                  <span v-text="s.name"></span>
                </option>
              </select>
            </td>
            <td>
              <select v-model="o.billingNumber"
                      class="form-control form-control-sm"
                      :disabled="!o.select">
                <option v-for="(b, i) in table.billing(o)"
                        :key="i">
                  <span v-text="b"></span>
                </option>
              </select>
            </td>
            <td class="text-right">
              <i class="fas fa-dollar-sign"
                 title="Bill all to this account"
                 v-on:click="table.bill(o)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </div>
</template>

<style scoped>
div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  max-width: 15em;
}

i {
  opacity: 0.3;
}

i:hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}

span {
  font-size: 0.9em;
}

caption { 
  caption-side: bottom; 
  }
</style>

<script>
import { scrollArea, search, checkbox } from "../../../../../../../component";
import { Api, List } from "../../../../../../../factory";
import { session } from "../../../../../../../service";


export default {
  get components() {
    return {
      scrollArea,
      search, checkbox,
    };
  },
  props: {
    contract: {
      type: Object,
      required: true,
      validator: o =>
        Array.isArray(o.accounts) &&
        typeof o.data == `object` &&
        typeof o.state == `object`
    },
    accounts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      table: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { contract } = this;


      var table = {
        select: ``,
        _init: (o, k, v) => this.$set(o, k, v),
        _items: ``,
        billing(o) {
          let options = contract.accounts.map(a =>
            a.number == o.number ? this.SELF : a.number
          );

          options = [...options,  "Bill to Invoice"]
          return options
        },
        bill(o) {
          contract.accounts.forEach(a => {
            a.billing = a.number == o.number ? this.SELF : o.number;
          });
        },
        class(type, d) {
          switch (type) {
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        init() {
          this.SELF = `Self`;
          this.BTI = `Bill to Invoice`
          if (!contract.state.hasOwnProperty(`accounts`)) {
            this._init(contract.state, `accounts`, {
              list: {
                search: ``,
                sort: { asc: ``, key: `` }
              }
            });
          }
          var schedule = this.schedules.find(o => o.default) || this.schedules[0];
          if (!schedule)
            throw new Error(`There is no Schedule configured yet!`);

          contract.accounts.forEach(a => {
            if (!a.hasOwnProperty(`billing`)) {
              this._init(a, `billing`, this.SELF);
              this._init(a, `schedule`, schedule.name);
            }
            else
              switch (a.billing) {
                case `99`:
                  a.billingNumber = this.BTI;
                  break;
                case `-1`:
                case this.SELF:
                  a.billingNumber = this.SELF;
                  break;
                default:
                  a.billingNumber = contract.accounts.find(ac => ac._id == a.billing).number 
              }
          });
          return this;
        },
        get items() {
          var { search, sort } = this.list;


          return List.sort(
            List.filter(contract.accounts, search),
            sort.key,
            sort.asc
          );
        },
        get list() {
          return contract.state.accounts.list;
        },
        get schedules() {
          return contract.data.schedules;
        },
        get householdNote() {
          const [household] = contract.data.households
          return household?.note 
        },
        get scheduleName() {
          const [household] = contract.data.households
          const schedules = session.get(`FirmSchedules`);

          if (household?.scheduleId)
            return schedules.find(o => o._id == household?.scheduleId)?.name
        },
        SELF: ``,
        BTI: ``,
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        }
      };
      this.table = table.init();
    }
  }
};
</script>
