
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-user mr-2"></i>
        <span class="font-weight-bold" v-text="user.text(`title`)"></span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="p-3">
        <div class="form-row">
          <div class="col-3">
            <div class="form-group">
              <label>Id</label>
              <input
                class="form-control form-control-sm"
                :value="user.id"
                readonly
                placeholder="Auto"
              />
              <small class="form-text text-muted">User Id</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Auth Id</label>
              <input
                v-model="user.authId"
                class="form-control form-control-sm"
                placeholder="Auto"
                :readonly="user.readonly(`id`)"
              />
              <small class="form-text text-muted">User Id</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-5">
            <div class="form-group">
              <label>Name</label>
              <input
                v-model="user.name"
                class="form-control form-control-sm"
                placeholder="Name"
                :readonly="user.readonly(`name`)"
              />
              <small class="form-text text-muted">User Name</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Email</label>
              <input
                v-model="user.email"
                class="form-control form-control-sm"
                type="email"
                placeholder="Email"
                :readonly="user.readonly(`email`)"
              />
              <small class="form-text text-muted">User Email</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-4">
            <div class="form-group">
              <label>Role</label>
              <select
                v-model="user.role"
                class="form-control form-control-sm"
                :disabled="user.disabled(`role`)"
                v-on:change="user.change(`role`)"
              >
                <option v-for="(role, index) in user.roles" :key="index">
                  <span v-text="role"></span>
                </option>
              </select>
              <small class="form-text text-muted">User Role</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Firm</label>
              <select
                v-model="user.firm"
                class="form-control form-control-sm"
                :disabled="user.disabled(`firm`)"
                v-on:change="user.change(`firm`)"
              >
                <option v-for="(firm, index) in user.firms" :key="index">
                  <span v-text="firm.label"></span>
                </option>
              </select>
              <small class="form-text text-muted">Firm Name</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <remove-button :button="user.remove"></remove-button>
        <button
          type="button"
          class="btn btn-sm btn-primary ml-2"
          :disabled="user.disabled(`save`)"
          v-on:click="user.click(`save`)"
        >
          <span class="button">Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
