<template>
  <input ref="input" type="file" :accept="file.accept" v-on:change="change" />
</template>

<style scoped>
input {
  display: none;
}
</style>

<script>
export default {
  props: {
    file: {
      type: Object,
      validation(o) {
        return (
          typeof o.upload == `function` &&
          o.format &&
          [
            `arraybuffer`,
            `binarystring`,
            `buffer`,
            `base64`,
            `dataurl`,
            `text`
          ].find(f => o.format.toLowerCase())
        );
      }
    }
  },
  data() {
    return {};
  },
  created() {
    this.init();
  },
  methods: {
    change(e) {
      var [f] = this.$refs.input.files;
      var { file } = this;
      var reader = new FileReader();
      reader.onloadend = () => {
        var { result: data } = reader;
        var { name, size } = f;
        var type = name.split(`.`).pop();
        file.upload(null, {
          data,
          name,
          size,
          type: type == name ? `unknown` : type
        });
      };
      reader.onerror = err => {
        file.upload(err);
      };
      var { format = `` } = file;
      switch (format.toLowerCase()) {
        case `base64`:
        case `dataurl`:
          reader.readAsDataURL(f);
          break;
        case `arraybuffer`:
        case `buffer`:
          reader.readAsArrayBuffer(f);
          break;
        case `binarystring`:
          reader.readAsBinaryString(f);
          break;
        default:
          reader.readAsText(f);
      }
      //                reader.readAsText(this.$refs.input.files[0])
      e.target.value = ``;
    },
    choose() {
      this.$refs.input.click();
    },
    init() {
      var { file } = this;
      file.choose = this.choose.bind(this);
    },
    select() {
      this.$refs.input.click();
    }
  }
};
</script>
