
<div class="card h-100">
  <template v-if="nav.household">
    <div class="card-header px-3 py-2">
      <i class="fas fa-house-user mr-2"></i>
      <span class="font-weight-bold" v-text="nav.title()"></span>
    </div>
    <div class="card-body pt-3">
      <ul class="nav nav-tabs">
        <li v-for="(item, index) in nav.items" :key="index" class="nav-item">
          <a
            class="nav-link"
            :class="nav.class(`active`, item)"
            v-on:click="nav.click(item)"
          >
            <i :class="nav.class(`icon`, item)" class="mr-1"></i>
            <span v-text="item"></span>
          </a>
        </li>
      </ul>
      <template v-if="nav.item">
        <div class="component">
          <component :is="nav.component" :list="nav.list"></component>
        </div>
      </template>
      <template v-else>
        <div class="card-header px-3 py-2">
          <i class="fas fa-database mr-2"></i>
          <span class="font-weight-bold">Household</span>
        </div>
        <div class="card-body pt-3">
          <h5 class="text-center my-5">No household selected</h5>
        </div>
      </template>
    </div>
  </template>
  <template v-else>
    <div class="card-header px-3 py-2">
      <i class="fas fa-database mr-2"></i>
      <span class="font-weight-bold">Household</span>
    </div>
    <div class="card-body pt-3">
      <h5 class="text-center my-5">No household selected</h5>
    </div>
  </template>
</div>
