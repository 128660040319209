<template>
  <div class="card">
    <div class="card-header px-3 py-2" id="headingOne">
      <div class="card-header-title">
       <button class="btn btn-block text-left" type="button" 
         data-toggle="collapse" data-target="#collapseOne" 
         aria-expanded="true" aria-controls="collapseOne">
           <span class="font-weight-bold">Custodians</span>
           <i class="fa fa-chevron-up ml-1"></i>
       </button>
      </div>
      <button 
        type="button"
        class="btn btn-sm btn-outline-secondary new-client"
        :title="table.title(`add`)"
        v-on:click="table.click(`add`)"
      >
       <i class="fas fa-plus-square mr-2"></i>
       <span>Add</span>
      </button>
    </div>
    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionMenu">
      <div class="card-body">
        <file-upload :file="file"></file-upload>
        <search :o="table"></search>
        <div class="masters">
          <scroll-area class="pr-3">
            <table class="table table-sm">
              <tbody>
                <tr class="master"><td>
                  <i class="far fa-edit mr-1"></i>
                  <a
                  href="#"
                  class="badge badge-light" 
                  title="View all custodians"
                  v-on:click="table.click(`view`, undefined)"
                  >All Custodians</a>
                </td></tr>
                <tr v-for="(o, i) in table.items" :key="i" class="master">
                  <td>
                    <i
                      class="far fa-edit mr-1"
                      :title="table.title(`edit`)"
                      v-on:click="table.click(`edit`, o)"
                    ></i>
                    <a
                      href="#"    
                      class="badge badge-light"
                      :title="table.title(`view`)"
                      v-on:click="table.click(`view`, o)"
                      v-text="o.label"
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </scroll-area>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* for all the accoridon cards */
.card-header button i {
  transition: transform 0.3s ease;
}

.card-header button[aria-expanded="true"] i {
  transform: rotate(180deg);
}

/* for all the badeges menus */
a.badge-light {
  background-color: #fff;
  font-weight: 400;
}
</style>
<style scoped>
a.badge {
  font-size: 0.8rem;
}


.new-client {
  float: right;
  font-size: 0.8rem;
}
div.card-header-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.card-header {
  display: flex;
  justify-content: space-between;
}
div.card-header i,
div.card-header span {
  opacity: 0.7;
}
div.masters {
  height: calc(65vh - 200px);
  margin-top: 6px;
  margin-right: -15px;
  margin-bottom: -10px;
}
div.action:hover i {
  opacity: 1;
}
div.action i {
  vertical-align: bottom;
  opacity: 0.3;
  padding-bottom: 2px;
}
tr.master {
  font-size: 0.9rem;
}
i {
  opacity: 0.3;
}
table i:hover {
  cursor: pointer;
  opacity: 1;
}
span.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<script>
import { fileUpload, scrollArea, search } from "../../../component";
import { Api, List, Modal } from "../../../factory";
import { alert, session } from "../../../service";

export default {
  name: `FirmCustodians`,
  get components() {
    return {
      fileUpload,
      scrollArea,
      search,
    };
  },
  data() {
    return {
      file: ``,
      table: ``,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      var file = {
        _data(d) {
          var lines = d.split(`\r\n`);
          var keys = lines.length
            ? lines[0].split(`,`).map((s) => s.toLowerCase())
            : [];
          if (!keys.length)
            throw new Error(`Invalid import data, no fields found!`);
          keys.forEach((k, i) => {
            if (k != this._keys[i])
              throw new Error(
                `Invalid import field: ${k}, expected: ${this._keys[i]}!`
              );
          });
          return lines.slice(1).reduce((d, line) => {
            var t = line.split(`,`);
            var o = keys.reduce((o, key, i) => {
              o[key] = t[i];
              return o;
            }, {});
            if (o.name) {
              var contact =
                o.contact && o.email ? { name: o.contact, email: o.email } : ``;
              d.push({ name: o.name, contacts: contact ? [contact] : [] });
            } else if (o.contact) {
              var h = d[d.length - 1];
              if (h.name && o.contact && o.email)
                h.contacts.push({ name: o.contact, email: o.email });
            }
            return d;
          }, []);
        },
        get _keys() {
          return [`name`, `contact`, `email`];
        },
        _open: (masters) => {
          Modal.show(`masters-import`, { masters });
        },
        accept: `.csv`,
        init() {
          return this;
        },
        success() {
          this.ready = false;
        },
        upload(err, text) {
          try {
            if (err) throw new Error(err.message || err);
            var data = this._data(text);
            file._open(data);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
      };
      this.file = file.init();
      var table = {
        async _load() {
          try {
            var r = await Api.get(`custodians`);
            this._data = r.data.map((o) => this._record(o));
            if (this._data.length) {
              if (
                !state.custodian ||
                !this._data.find((o) => o._id == state.custodian._id)
              ) {
                state.custodian = this._data[0];
              }
            } else {
              state.custodian = ``;
            }
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _record: (o) => {
          var name = o.name || o.type;
          var label = `${o.id} (${name})`;
          return { ...o, label };
        },
        _view: (o) => {
          if (!o) {
            // o = undefined, we want to get all the custodians
            return (state.custodian = {_id :'*', label: 'All Custodians'})
          }
          if (state.custodian && state.custodian._id != o._id)
            return (state.custodian = o);
          
          /*
          state.custodian = o;
          this.$nextTick(() => {
            state.custodian = o;
          });
          */
        },
        click(type, d) {
          switch (type) {
            case `add`:
            case `edit`:
              return Modal.open(`custodian`, {
                custodian: d || {},
                methods: {
                  success: () => {
                    this._load();
                    Modal.close();
                  },
                },
              });
            case `import`:
              return alert.warning(`Depreciated!`, 3e3);
            case `view`:
              return this._view(d);
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        init() {
          this._data = ``;
          this.search = ``;
          this._load();
          return this;
        },
        get items() {
          return List.filter(Array.from(this._data), this.search);
        },
        title(type) {
          switch (type) {
            case `add`:
              return `Add Custodian`;
            case `edit`:
              return `Edit Custodian`;
            /*
                            case `import`:
                                return `Import Master Accounts`
                            */
            case `view`:
              return `View Custodian`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
      };
      this.table = table.init();
    },
  },
};
</script>
