<template>
  <div class="main d-flex m-2">
    <div class="menu h-100" :class="nav.class(`toggle`)">
      <firms></firms>
    </div>
    <div class="toggle" :title="nav.title" v-on:click="nav.toggle()"></div>
    <div class="content flex-grow-1 h-100">
      <firm></firm>
    </div>
  </div>
</template>

<style scoped>
div.menu:not(.hide) {
  flex: 0 0 22em;
  transition: all 0.2s ease;
}
div.menu.hide {
  flex: 0 1;
  width: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}
div.content {
  width: 70%;
}
div.toggle {
  background-color: black;
  width: 5px;
  opacity: 0.1;
}
div.toggle:hover {
  cursor: pointer;
  opacity: 0.5;
}
div.main {
  height: calc(100% - 4.6rem);
}
</style>

<script>
import { session } from "../../../service";
import firm from "./firm/index.vue";
import firms from "./firms.vue";

export default {
  get components() {
    return {
      firm,
      firms
    };
  },
  data() {
    return {
      nav: ``,
      state: ""
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      if (!state.hasOwnProperty(`master`)) this.$set(state, `master`, ``);
      var nav = {
        _hide: false,
        class() {
          return this._hide ? `hide` : ``;
        },
        init() {
          return this;
        },
        get title() {
          return this._hide ? `Show menu` : `Hide menu`;
        },
        toggle() {
          this._hide = !this._hide;
        }
      };
      this.nav = nav.init();
    }
  }
};
</script>
