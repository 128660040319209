<template>
  <small v-if="small" class="form-text text-muted">{{ small }}</small>
</template>

<style scoped>
small {
  font-style: italic;
  font-size: 12px;
  color: #6c757d;
}
</style>

<script>
export default {
  props: {
    small: "",
  },
};
</script>
