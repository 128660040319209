<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">Email Templates</div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <div class="px-4 py-3">
          <div class="mt-2">
            <button
              type="button"
              class="btn btn-sm btn-outline-primary mr-2"
              v-on:click="viewConsentEmail()"
            >
              <span>View</span>
            </button>
            Email consent
          </div>
          <hr />
          <div class="mt-2">
            <button
              type="button"
              class="btn btn-sm btn-outline-primary mr-2"
              v-on:click="viewDiscloseFeeSchedule()"
            >
              <span>View</span>
            </button>
            Disclose fee schedule in effect
          </div>
          <hr />
          <div class="mt-2">
            <button
              type="button"
              class="btn btn-sm btn-outline-primary mr-2"
              v-on:click="viewUpdatedFeeSchedule()"
            >
              <span>View</span>
            </button>
            Updated fee schedule
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modal-button {
  width: 80px;
}
.modal-title {
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 16px;

  font-weight: bold;
  font-size: 17px;
  line-height: 20px;
  color: #616466;
}

.modal-body {
  min-height: 50vh;
}
</style>

<script>
import IconButton from "../../component/icon-button.vue";
import { Modal } from "../../factory";

export default {
  components: { IconButton },
  methods: {
    viewDiscloseFeeSchedule() {
      Modal.open("DiscloseFeeSchedule", {
        inEffect: true,
        methods: {
          back: () => { Modal.open("EmailTemplates") },
          send: () => { Modal.open("EmailTemplates") }
        }
      })
    },
    viewUpdatedFeeSchedule() {
      Modal.open("DiscloseFeeSchedule", {
        updated: true,
        methods: {
          back: () => { Modal.open("EmailTemplates") },
          send: () => { Modal.open("EmailTemplates") }
        }
      })
    },
    viewConsentEmail() {
      Modal.open("EmailConsent", {
        methods: {
          back: () => { Modal.open("EmailTemplates") },
          send: () => { Modal.open("EmailTemplates") }
        },
      })
    }
  }
};
</script>
