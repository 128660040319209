
<div class="root">
  <template v-if="option.src">
    <video
      :id="id"
      width="320"
      height="240"
      controls
      :src="option.src"
    ></video>
  </template>
</div>
