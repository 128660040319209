<template>
  <loading />
</template>

<style scoped></style>

<script>
import { loading } from "../component";

export default {
  get components() {
    return {
      loading
    };
  },
  data() {
    return {};
  }
};
</script>
