import { numeral } from "../npm";

class List {
  static _contains(v, s, c) {
    var field, search;
    if (typeof v == `object` && v != null) {
      field = s.includes(`:`) ? s.split(`:`).shift() : ``;
      search = field ? s.split(`:`).pop() : s;
      for (var key of Object.keys(v)) {
        if (field && field != key) continue;
        if (this._contains(v[key], search, c)) return true;
      }
      return false;
    }
    return c ? String(v).includes(s) : String(v)
      .toLowerCase()
      .includes(s.toLowerCase());
  }

  static _search(s) {
    return s;
    var sea = s == `-` ? `\-` : s;
    return sea;
  }

  static filter(data, search = ``, caseSensitive = false) {
    return search
      ? data.filter((i) =>
        List._contains(i, List._search(search), caseSensitive)
      )
      : [...data];
  }

  static sort(data, key, asc = true) {
    return key
      ? data.sort((a, b) => {
        if (key == "date") {
          const [dateA, dateB] = [new Date(a[key]), new Date(b[key])];

          return asc
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        }

        return asc
          ? String(a[key]).localeCompare(String(b[key]))
          : String(b[key]).localeCompare(String(a[key]));
      })
      : [...data];
  }

  static records(...list) {
    var [a, b] = list;
    var r = Array.isArray(a) && Array.isArray(b)
      ? [a.length, b.length].map((v) => numeral(v).format(`0,0`))
      : ``;
    return r ? `Showing ${r[0]} of ${r[1]} record(s)` : ``;
  }
}

export default List;
