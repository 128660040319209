<template>
  <div class="form-group">
    <label>Code</label>
    <div class="form-row">
      <div v-for="(n, i) in mfa.code.slice(0, 3)" :key="i" class="col pr-3">
        <input
          :ref="mfa.ref(i)"
          v-model="mfa.code[i]"
          class="form-control"
          v-on:keyup="mfa.keyup(i)"
        />
      </div>
      <div class="col-1 text-center">
        <i class="fas fa-minus"></i>
      </div>
      <div v-for="(n, i) in mfa.code.slice(3)" :key="i + 3" class="col pl-3">
        <input
          :ref="mfa.ref(i + 3)"
          v-model="mfa.code[i + 3]"
          class="form-control"
          v-on:keyup="mfa.keyup(i + 3)"
        />
      </div>
    </div>
    <small class="form-text text-muted">2FA Authentication App Code</small>
  </div>
</template>

<style scoped>
div.form-row i {
  opacity: 0.5;
  vertical-align: sub;
}
</style>

<script>
export default {
  props: {
    submit: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      mfa: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var mfa = {
        _reset() {
          this.init(true);
        },
        _submit: () => {
          var { mfa } = this;
          var code = mfa.code.join(``);
          this.submit(code, mfa._reset.bind(mfa));
        },
        code: ``,
        el: i => this.$refs[this.mfa.ref(i)][0],
        init() {
          this.code = new Array(6).fill(``);
          setTimeout(() => {
            this.el(0).focus();
          }, 5e2);
          return this;
        },
        keyup(i) {
          if (i < this.code.length - 1) {
            this.el(i + 1).focus();
          } else {
            this._submit();
          }
        },
        ref: i => `code-${i}`
      };
      this.mfa = mfa.init();
    }
  }
};
</script>
