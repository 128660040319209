
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">Email</div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <div class="px-4 py-3">
        <OnboardingTitle>
          The following attachments will be sent to {{ clientName }}
          <button
            type="button"
            class="btn btn-sm btn-outline-primary ml-2"
            v-on:click="viewConsentEmail()"
          >
            <span>View Email</span>
          </button>
        </OnboardingTitle>

        <div class="list-group list-group-flush">
          <button
            class="list-group-item list-group-item-action"
            v-for="file in attachments"
            v-on:click="viewFile(file)"
          >
            <i class="fas fa-file-download mr-1"></i>
            {{ file }}
          </button>
        </div>
      </div>
    </div>
    <a ref="download" class="d-none"></a>

    <div class="modal-footer pr-3">
      <div>
        <button
          type="button"
          class="btn btn-sm modal-button btn-outline-danger"
          v-on:click="back()"
        >
          <span>Back</span>
        </button>
        <button
          type="button"
          class="btn btn-sm modal-button btn-primary ml-2"
          v-on:click="send()"
        >
          <span>Send</span>
        </button>
      </div>
    </div>
  </div>
</div>
