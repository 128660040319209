
<div class="card">
  <div class="card-header px-3 py-2" id="headingTwo">
    <div class="card-header-title">
      <button class="btn btn-block text-left" type="button" 
      data-toggle="collapse" data-target="#collapseTwo" 
      aria-expanded="false" aria-controls="collapseTwo">
        <span class="font-weight-bold">Schedules</span>
        <i class="fa fa-chevron-up ml-1"></i>
      </button> 
    </div>
    <button type="button"
      class="btn btn-sm btn-outline-secondary new-client"
      :title="title(`add`)"
      v-on:click="click(`add`)"
    >
      <i class="fas fa-plus-square mr-2"></i>
      <span>Add</span>
    </button>
  </div>
  <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionMenu">
    <div class="card-body pt-3">
      <div class="schedules">
        <scroll-area class="pr-3">
          <table class="table table-sm">
            <tbody>
              <tr v-for="(s, i) in schedules" :key="s._id" class="schedule">
                <td>
                  <schedule-badge
                    style="cursor: pointer;"
                    :schedule="s.name"
                    v-on:click="click(`edit`, s)"
                  ></schedule-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </scroll-area>
      </div>
    </div>
  </div>
</div>
