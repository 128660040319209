
<div>
    <p>
        Hello [client]
    </p>
    <p>
        Attached is your most recent statement of account fees. These fees are debited directly from your custodian
        account and no action is required for you to remit payment.
    </p>
    <p>
        Please review the statement thoroughly in conjunction with your fee arrangement and your custodial
        statements.
        If there are any discrepancies or you have questions, please reach out to your advisor.
    </p>
    <p>
        Sincerely,<br>
        [firm name] 
    </p>
</div>
