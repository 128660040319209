<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-cog mr-2"></i>
          <span class="font-weight-bold">Settings</span>
        </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="p-3">
           <div class="row">
             <h4 class="col-4">Features</h4>
           </div>
           <hr />
           <div class="row">
             <div class="col-4">
               <div class="form-group">
                 <label>Default Home View</label>
                 <select
                   v-model="modal.view"
                   class="form-control form-control-sm"
                   v-on:change="modal.change(`view`)"
                 >
                   <option v-for="(o, i) in modal.options(`view`)" :key="i">
                     <span v-text="o"></span>
                   </option>
                 </select>
                 <small class="form-text text-muted">Your default home screen </small>
               </div>
             </div>
             <div class="col"></div>
           </div>
           <div class="row">
             <h4 class="col-4">Integrations</h4>
           </div>
           <hr />
           <div class="row">
             <div class="col">
              <div class="form-group">
                <label><strong>Redtail CRM</strong></label>
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="redTailIntegrationSwitch" 
                  @change="changeRedtailVars" :checked="rtUserAuthKey" :disabled="wbAuthenticated">
                  <label class="custom-control-label" for="redTailIntegrationSwitch">Allow bi-directional data exchange with Redtail</label>
                </div>
                 <div v-if="rtShowInput" class="row pt-2">
                   <div class="col-10">
                       <input type="text" class="form-control form-control-sm" placeholder="User Auth Key" v-model="rtUserAuthKey">
                       <small class="form-text text-muted">Copy the UserAuthKey from Redtail Imaging and paste it here</small>
                   </div>
                   <div class="col-2" style="padding-left: 0;">
                     <button class="btn btn-sm btn-primary" @click="saveRTUserAuthKey">Save</button>
                   </div>
                </div>
              </div>
           </div>
           </div>
           <div class="row">
              <div class="col">
                <div class="form-group">
                  <label><strong>Wealthbox CRM</strong></label>
                  <div class="row align-items-center">
                    <div class="col-9">
                      Allow bi-directional data exchange with Wealthbox
                    </div>
                    <div class="col-3 text-right">
                      <button v-show="wbAuthenticated" class="btn btn-sm btn-secondary" @click="disconnectOauth">Disconnect</button>
                      <button v-show="!wbAuthenticated" :disabled="rtShowInput" class="btn btn-sm btn-primary" @click="openAuthPopup">Connect</button>
                    </div>
                  </div>
                </div>
              </div>
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* div.qrcode {
  margin-top: -8px;
} */
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
</style>

<script>
import { mfa } from "../component";
import { Api, lib } from "../factory";
// import { qrcode } from "../npm";
import { alert, session } from "../service";

export default {
  get components() {
    return {
      mfa,
    };
  },
  data() {
    return {
      modal: ``,
      rtShowInput: null,
      rtUserAuthKey: null,
      wbAuthenticated: false,
      authWindow: null,
    };
  },
  created() {
    this.init();
  },
  mounted() {
    window.addEventListener('message', this.handleAuthMessage);
  },
  methods: {
    saveRTUserAuthKey() {
      this.modal._save(`redtail`, 
      { redTailKey: this.rtUserAuthKey })
    },
    changeRedtailVars(event) {
      this.rtShowInput = event.target.checked;
      if (event.target.checked) return

      // only update the backend when we are disconnecting from Redtail
      this.rtUserAuthKey = null
      this.saveRTUserAuthKey()

    },
    async disconnectOauth() {

      const firm = session.get(`state`).firm
      try {
        const { data } = await Api.delete(`crm/tokens/${firm.providerTokensId}`);

        if (!data.affectedRows)
          return alert.error(`No WealthBox integration was found.`, 2e3)

        // nullify data
        this.wbAuthenticated = false;
        firm.providerTokensId = null

      } catch(error) {
          alert.error(`There was an error disconnecting from WealthBox CRM. Please try again.`, 2e3)
          console.log(error)
      }

    },
    openAuthPopup() {

      const clientId = 'jaUFatLJ2hg-qCgEKjQWNjAX25tHeazhWnJNrCKsBtg'
      const redirectURI = 'https://app.smartkx.io/wb/auth-callback'
      const url = `https://app.crmworkspace.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectURI}&response_type=code&scope=login+data`;

      const width = 600;
      const height = 600;
      const left = window.screenX + (window.innerWidth - width) / 2;
      const top = window.screenY + (window.innerHeight - height) / 2;

      this.authWindow = window.open(url, 'auth',
        `width=${width},height=${height},left=${left},top=${top}`);

    },
    async handleAuthMessage(event) {
      if (event.data.type !== 'wb-oauth-response')
        return

      // Capture the code from the message
      const code = event.data.code;
      console.log('Received code:', code);

      // Close the pop-up window
      this.authWindow.close();
      // You can now use the 'code' in your application for further OAuth2 token exchange

      const state = session.get(`state`)
      const providerTokensId = state.firm.providerTokensId
      var { data } = await Api.post(`crm/tokens`, {
        authCode: code,
        providerTokensId: providerTokensId
      });
      console.log("oauth resp =>", data)
      if (data.providerTokensId) {
        this.wbAuthenticated = true
        session.get(`state`).firm.providerTokensId = data.providerTokensId
      }
    },
    init() {
      var _this = this
      var state = session.get(`state`);
      var modal = {
        features: {},
        async _save(type, d) {
          try {
            switch (type) {
              case `user`:
                var { _id: userId } = state.user;
                await Api.put(`/users/${userId}`, d);
                break;
              case `redtail`:
                var { _id: firmId } = state.firm;
                await Api.put(`/firms/${firmId}`, d);
                state.firm.redTailKey = d.redTailKey 
                alert.message("Auth key was successfully updated.", 15e2);
                break;
              default:
                throw new Error(`Invalid save type, ${type}!`);
            }
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        change(type) {
          switch (type) {
            case `view`:
              if (!state.user.settings) state.user.settings = {};
              state.user.settings.view = this.view.toLowerCase();
              var { settings } = state.user;
              this._save(`user`, { settings });
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        click(type) {
          switch (type) {
            case `done`:
              this._enable(true);
              break;
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        mfa: ``,
        options(type) {
          switch (type) {
            // case `mfa`:
            //   return [`Disabled`, `Enabled`];
            case `view`:
              return [`Firm`, `Clients`];
            default:
              throw new Error(`Invalid options type, ${type}!`);
          }
        },
        disabled(type) {
          switch (type) {
            // case `mfa`:
            //   return this.mfa.active;
            case `done`:
              return this.mfa.pending;
            default:
              throw new Error(`Invalid disabled type!`);
          }
        },
        init() {
          // var active =
          //   typeof state.user.mfa == `object` ? state.user.mfa.active : false;
          // var option = this.options(`mfa`)[Number(active)];
          // this.mfa = { active, option, pending: false, qrcode: `` };
          this.view =
            state.user.hasOwnProperty(`settings`) && state.user.settings.view
              ? lib.string.capitalize(state.user.settings.view)
              : this.options(`view`)[0];

          this.features = Object.assign({}, state.firm.features)
          // Redtail specific
          _this.rtUserAuthKey = state.firm.redTailKey
          _this.rtShowInput = state.firm.redTailKey ? true : false

          // Wealthbox specific
          _this.wbAuthenticated = state.firm.providerTokensId ? true : false

          return this;
        },
        submit: async (code, reset) => {
          try {
            // var { modal } = this;
            // var data = { mfa: { code } };
            // await Api.put(`users?_action=mfa`, data);
            // var { mfa } = state.user;
            // var active = false;
            // var option = modal.options(`mfa`)[0];
            // mfa.active = false;
            // mfa.token = ``;
            // modal.mfa.active = active;
            // modal.mfa.option = modal.options(`mfa`)[0];
          } catch (e) {
            console.error(e);
            alert.error(e.message, 15e2);
            reset();
          }
        },
        user: ``,
        view: ``,
        async setFeature(featureName, toggle) {
          if (!state.firm.features) state.firm.features = {};
          this.features[featureName] = toggle;
          state.firm.features[featureName] = toggle;
          var { features, _id: firmId } = state.firm;
          await Api.put(`/firms/${firmId}`, { features });
        },
        featuresLabel(featureName, toggle) {
          return `btn btn-light ${this.features[featureName] == toggle ? "active" : ""}`
        }
      };
      this.modal = modal.init();
    },
  },
};
</script>
