<template>
  <div>
    <p>Hello [Client]</p>
    <p>Attached are our most recent regulatory disclosures. We are required to disclose this information to you
      annually.</p>
    <p>Please review and maintain copies for your records. If you have any questions, please email your advisor.</p>
    [Firm]
  </div>
</template>

<script>
export default {
}
</script>
