<template>
  <i
    class="far"
    :class="check.class"
    :title="check.title()"
    v-on:click="check.toggle()"
  ></i>
</template>

<style scoped>
i {
  vertical-align: middle;
  opacity: 0.3;
}
i:hover {
  cursor: pointer;
  opacity: 1;
}
i.disabled {
  cursor: not-allowed !important;
  opacity: 0.1 !important;
}
</style>

<script>
export default {
  props: [`o`, `p`, `title`, `disabled`],
  data() {
    return {
      check: ``
    };
  },
  watch: {
    o: {
      handler(o) {
        var v = o[this.p];
        if (v != this.check.value) this.check.value = v;
      },
      deep: true
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var check = {
        _disabled: () => this.disabled,
        get class() {
          var c = this.value ? `fa-check-square` : `fa-square`;
          return this._disabled() ? `${c} disabled` : c;
        },
        init(v) {
          this.value = v;
          this.ready = true;
          return this;
        },
        ready: ``,
        title: () => {
          return this.title || (this.disabled ? `` : `Toggle`);
        },
        toggle: () => {
          if (this.disabled) return;
          var v = !this.check.value;
          this.check.value = this.o[this.p] = !this.check.value;

          this.$emit(`change`, v);
        },
        value: ``
      };
      this.check = check.init(this.o[this.p]);
    }
  }
};
</script>
