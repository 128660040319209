
<div style="padding: 1rem 1.5rem">
  <div>
    <p>Hello [Client],</p>

    <p>This email is among the more formal that you'll receive from me over the year. To comply with
      securities regulations governing Registered Investment Advisors ("RIAs"), I am required to send you certain
      documents each year.</p>

    <p>Attached you'll find a copy of 603 Financial's Form ADV Part 2A (“Disclosure Brochure”) and Form ADV 2B
      ("Brochure Supplement[s]") as well as our Privacy Policy.</p>

    <h3>Disclosure Brochure and Supplement[s]</h3>
    <p>The Disclosure Brochure and Brochure Supplement[s] contain a summary of our business practices, our fees, and
      backgrounds of our advisory personnel. We encourage you to read these documents and discuss any questions you
      may have with us. There have been no material changes to our Disclosure Brochure since the last time we
      delivered the brochure to clients.</p>

    <h3>Privacy Policy</h3>
    <p>603 Financial strives to always protect your interests, especially the privacy of your personal information.
      Attached, please also find our current Privacy Policy, which outlines our policies for servicing your account
      and protecting your interests.</p>

    <h3>Changes to your Situation</h3>
    <p>Please also inform me of any changes in your financial situation, your goals, tolerance for risks or other
      matters relating to your account[s] with 603 Financial Advisors.</p>

    <p>Please email or call anytime with questions. Thank you very much,</p>

    <p>
      Chris Eddy, CFP®<br>
      603 Financial Advisors, LLC<br>
      P.O. Box 138<br>
      6 Main Street, #138<br>
      Newfields, NH 03856<br>
      603-770-3791<br>
      <a href="mailto:chris@603financial.com">chris@603financial.com</a><br>
      <a href="http://www.603financial.com">www.603financial.com</a>
    </p>
  </div>
</div>
