
<div class="h-100">
  <template v-if="ready">
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Ref</span>
            </th>
            <th>
              <span>Date</span>
            </th>
            <th>
              <span>Item</span>
            </th>
            <th>
              <span>Modified by</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(audit, i) in allAudits" :key="audit._id">
            <td>
              <a v-if="audit.ref"
                class="badge badge-custom"
                title="View contract"
                v-text="audit.id"
                v-on:click="viewContract(audit.ref)"
              ></a>
              <span v-else></span>
            </td>
            <td>
              <span v-text="audit.date"></span>
            </td>
            <td>
              <span v-text="audit.item"></span>
            </td>
            <td>
              <span v-text="audit.user ? `${audit.user.name} - ${audit.user.email}` : ``"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </template>
  <template v-else>
    <loading></loading>
  </template>
</div>
