<template>
  <i type="button" class="btn btn-sm" :class="classes" v-on:click="click()"></i>
</template>

<style scoped>
i {
  opacity: 0.7;
  transition: all ease-in-out 100ms;
}
i:hover {
  opacity: 1;
  transform: scale(1.1);
  transition: all ease-in-out 100ms;
}
.click {
  transform: scale(0.9) !important;
  transition: all linear 50ms !important;
}
</style>

<script>

export default {
  props: {
    icon: "",
  },
  computed: {
    classes() {
      return [this.icon, this.clicked]
    }
  },
  data() {
    return {
      clicked: ``
    };
  },
  get components() {
    return {
    };
  },
  methods: {
    click() {
      this.clicked = "click"
      this.$emit("click")

      setTimeout(() => {
        this.clicked = ""
      }, 100);
    }
  }
};
</script>
