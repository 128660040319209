
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">Files</div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <div class="px-4 py-3">
        <div class="list-group list-group-flush">
          <div class="list-group-item list-group-item-action" v-for="fileSlot in defaultFiles">
            <div class="form-row">
              <div class="col-1">
                <i class="far fa-file-pdf"></i>
              </div>
              <span class="col-3">{{ fileSlot.slot }}</span>
              <div class="col form-row" v-if="fileSlot.file.name">
                <span class="col-11 text-right">{{ fileSlot.file.name }}</span>
                <div class="col-1">
                  <IconButton icon="far fa-trash-alt" v-on:click="changeFile(fileSlot.slot)" />
                </div>
              </div>
              <!-- <div class="col" v-if="!fileSlot.file.name"></div> -->
              <div class="col text-right" v-if="!fileSlot.file.name">
                <label :for="fileSlot.slot" class="btn btn-sm btn-outline-secondary">Choose File</label>
                <input
                  style="display: none;"
                  :id="fileSlot.slot"
                  type="file"
                  accept="application/pdf"
                  v-on:change="filesChange($event.target.files, fileSlot.slot)"
                />
              </div>
            </div>
          </div>
        </div>

        <h6 class="mt-3 mb-3">Add other files</h6>

        <label for="files" class="btn btn-sm btn-outline-secondary">Add File</label>
        <input
          style="display: none;"
          id="files"
          type="file"
          accept="application/pdf"
          v-on:change="filesChange($event.target.files)"
        />
        <hr />
        <div class="list-group list-group-flush">
          <div class="list-group-item list-group-item-action" v-for="file in files">
            <i class="far fa-file-pdf mr-1"></i>
            {{ file.name }}
            <IconButton icon="far fa-trash-alt float-right" v-on:click="deleteFile(file)" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button
          type="button"
          class="btn btn-sm modal-button btn-primary ml-2"
          v-on:click="save()"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
