<template>
  <div class="card h-100">
    <template v-if="nav.custodian">
      <div class="card-header px-3 py-2">
        <i class="fas fa-database mr-2"></i>
        <span class="font-weight-bold" v-text="nav.title()"></span>
      </div>
      <div class="card-body pt-3">
        <ul class="nav nav-tabs">
          <li v-for="(item, i) in nav.items" :key="i" class="nav-item">
            <a
              class="nav-link"
              :data-cy="`custodian-nav-link-${item}`"
              :class="nav.class(`active`, item)"
              v-on:click="nav.click(item)"
            >
              <i :class="nav.class(`icon`, item)" class="mr-1"></i>
              <span v-text="item"></span>
            </a>
          </li>
        </ul>
        <template v-if="nav.item">
          <div class="component">
            <component :is="nav.component" :list="nav.list"></component>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="card-header px-3 py-2">
        <i class="fas fa-database mr-2"></i>
        <span class="font-weight-bold">Custodian</span>
      </div>
      <div class="card-body pt-3">
        <h5 class="text-center my-5">No custodian selected</h5>
      </div>
    </template>
  </div>
</template>

<style scoped>
div.card-body h5 {
  opacity: 0.5;
}
span.link {
  text-decoration: underline;
  cursor: pointer;
}
ul span,
ul i {
  font-size: 0.9rem;
}
div.search {
  float: right;
  width: 15em;
  margin-left: -15em;
}
div.card-header i,
div.card-header span {
  opacity: 0.7;
}
div.card-header div.action i {
  opacity: 0.3;
}
div.card-header div.action i:hover {
  opacity: 1;
  cursor: pointer;
}
li.nav-item {
  cursor: pointer;
}
a.nav-link {
  color: #337cc5;
}
a.nav-link.active i,
a.nav-link.active span {
  opacity: 0.7;
}
div.component {
  height: calc(100vh - 225px);
  margin-top: 6px;
  margin-right: -15px;
  margin-bottom: -10px;
}
</style>

<script>
import { scrollArea, search } from "../../../../component";
import { events, session } from "../../../../service";

import firmAccounts from "./accounts.vue";
import firmInvoices from "./invoices.vue";
import firmContracts from "./contracts.vue";
import FirmHouseholds from "./households.vue";
import FirmGroups from "./groups.vue";
import FirmAudits from './audits.vue';

export default {
  name: `FirmCustodian`,
  get components() {
    return {
      firmAccounts,
      firmInvoices,
      scrollArea,
      search,
      firmContracts,
      FirmHouseholds,
      FirmGroups,
      FirmAudits
    };
  },
  data() {
    return {
      file: ``,
      nav: ``,
      state: ``,
      custodian: ``,
      link: ``,
    };
  },
  watch: {
    "state.custodian": {
      handler() {
        this.nav.custodian = this.state.custodian;
      },
      deep: true,
    },
  },
  created() {
    this.init();
    var { nav } = this;
    this.events = events.$watch({
      nav: nav.event.bind(nav),
    });
  },
  destroyed() {
    events.$unwatch(this.events);
  },
  methods: {
    init() {
      var state = session.get(`state`);
      if (!state.hasOwnProperty(`nav`)) this.$set(state, `nav`, {});
      var nav = {
        _custodian: ``,
        _init: (o, k, v) => this.$set(o, k, v),
        _list() {
          var { item } = this;
          var lists = this._state(`lists`, []);
          var list = lists.find((o) => o.item == item);
          if (list) return list;
          lists.push({ item, search: ``, sort: { key: ``, asc: `` } });
          return this._list();
        },
        _refresh: () => new Promise((r) => this.$nextTick(r)),
        _state(k, v) {
          if (this.state.hasOwnProperty(k)) return this.state[k];
          this._init(this.state, k, v);
          return this._state(k);
        },
        get component() {
          return `firm-${this.item}`;
        },
        class(type, item) {
          switch (type) {
            case `active`:
              return item == this.item ? `active` : ``;
            case `icon`:
              switch (item) {
                case `Accounts`:
                  return `fas fa-database`;
                case `Contracts`:
                  return `far fa-file-alt`;
                case `Invoices`:
                  return `fas fa-dollar-sign`;
                case `Households`:
                  return `fas fa-house-user`;
                case `Groups`:
                  return `fas fa-users`;
                case `Audits`:
                  return `fas fa-history`;
                default:
                  throw new Error(
                    `Unable to determine icon for item, ${item}!`
                  );
              }
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(item) {
          if (item == this.item) return this.reload();
          this.item = this.state.item = item;
        },
        create() {
          this.add(`Households`);
        },
        event(e) {
          var item = this.items.find(
            (s) => s.toLowerCase() == e.data.toLowerCase()
          );
          this.click(item);
        },
        get custodian() {
          return this._custodian ? this._custodian.label : ``;
        },
        set custodian(o) {
          this._custodian = o;
          var { _id: custodianId } = o;
          var s = state.nav.custodians.find((s) => s.custodianId == custodianId);
          if (!s)
            state.nav.custodians.push({ custodianId, item: this.items[0] });
          var r = this.item;
          this.item = this.state.item;
          if (r) this.reload();
        },
        init() {
          if (!state.nav.custodians) this._init(state.nav, `custodians`, []);
          return this;
        },
        item: ``,
        get items() {
          return [`Contracts`, `Invoices`, `Accounts`, `Households`, `Groups`, `Audits`];
        },
        get list() {
          return this._list();
        },
        async reload() {
          if (!this.item) return;
          var item = this.item;
          this.item = ``;
          await this._refresh();
          this.item = item;
        },
        get state() {
          return state.nav.custodians.find(
            (o) => o.custodianId == this._custodian._id
          );
        },
        text(type) {
          switch (type) {
            case `add`:
              switch (this.item) {
                case `Accounts`:
                case `Contracts`:
                  return `Add`;
                case `Invoices`:
                  return `Create`;
                default:
                  throw new Error(
                    `Unable to determine add type for item, ${this.item}!`
                  );
              }
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        title(type) {
          switch (type) {
            case `add`:
              switch (this.item) {
                case `Accounts`:
                  return `Add account(s)`;
                case `Invoices`:
                  return `Create a new Invoice`;
                case `Contracts`:
                  return `Add a new ${this.item.slice(0, -1)}`;
                default:
                  throw new Error(
                    `Unable to determine add title for item, ${this.item}!`
                  );
              }
            case `create`:
              switch (this.item) {
                case `Accounts`:
                  return `Create a new Household`;
              }
              break;
            case `import`:
              return ``;
            default:
              return `${this.custodian} - ${this.item}`;
          }
        },
      };
      this.nav = nav.init();
      this.state = state;
    },
  },
};
</script>
