<template>
    <div>
        <p>Hello [client],</p>

        <p>Enclosed please find your monthly billing statement from Embarcadero Capital Advisors, Inc.</p>

        <p>There is no need to send payment, as per our servicing agreement, the fees have already been deducted from
            your account.</p>

        <p>We continue to appreciate the privilege of your trust and confidence and we look forward to working with you
            in the future.</p>

        <p>If you have any questions or concerns about this invoice, please do not hesitate to contact your Advisor
            directly, or the main office at: (949) 371-9796.</p>

        <p>With Gratitude,</p>
        <p>Parker and Geoff</p>
    </div>
</template>

<script>
export default { }
</script>
