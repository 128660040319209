
<div class="card">
  <div class="card-header px-3 py-2">
    <i class="fas fa-building mr-2"></i>
    <span class="font-weight-bold">Firms</span>
  </div>
  <div class="card-body">
    <search :o="table"></search>
    <div class="d-flex pt-1 pl-2">
      <div
        class="action"
        :title="table.title(`add`)"
        v-on:click="table.click(`add`)"
      >
        <i class="fas fa-plus-square"></i>
        <span class="text-primary link">Add</span>
      </div>
    </div>
    <div class="firms">
      <scroll-area class="pr-3">
        <table class="table table-sm">
          <tbody>
            <tr v-for="(o, i) in table.items" :key="i" class="firm">
              <td>
                <i
                  class="fas fa-building mr-2"
                  :title="table.title(`edit`)"
                  v-on:click="table.click(`edit`, o)"
                ></i>
                <span
                  class="text-primary link"
                  :title="table.title(`view`)"
                  v-on:click="table.click(`view`, o)"
                  v-text="o.name"
                ></span>
              </td>
            </tr>
          </tbody>
        </table>
      </scroll-area>
    </div>
  </div>
</div>
