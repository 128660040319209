<template>

    <div>
        <div class="d-flex">
            <div>
                <p class="font-weight-bold">Rebated Transactions</p>
            </div>
        </div>
        <table class="table table-sm mb-4">
            <thead>
                <tr>
                    <th style="white-space: nowrap">
                        <span>Date</span>
                    </th>
                    <th>
                        <span>Account</span>
                    </th>
                    <th>
                        <span>Category</span>
                    </th>
                    <th>
                        <span>Ticker</span>
                    </th>
                    <th class="text-right">
                        <span>Amount</span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(transaction, i) of transactions"
                    :key="i">
                    <td style="white-space: nowrap">
                        <span v-text="formatDate(transaction.date)"></span>
                    </td>
                    <td>
                        <span v-text="obfuscateAccountNumber(transaction.accountNumber)"></span>
                    </td>
                    <td>
                        <span
                              v-text="transaction.trnFees > 0 ? transaction.category : transaction.detailDescription"></span>
                    </td>
                    <td>
                        <span v-text="transaction.ticker"></span>
                    </td>
                    <td class="text-right">
                        <span
                              v-text="formatMoney(transaction.trnFees > 0 ? transaction.trnFees : transaction.amount)"></span>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right">
                        <span class="font-weight-bold">Total</span>
                    </td>
                    <td class="text-right no-wrap">
                        <span class="total"
                              v-text="total"></span>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</template>

<style scoped>
h3 {
    opacity: 0.5;
}

span.multi {
    font-size: 0.8em;
    opacity: 0.7;
    font-style: italic;
}

small {
    padding-left: 0.7em;
    margin-top: 0.1em;
}

div.menu {
    height: 2.5em;
}

div.controls {
    width: 15em;
}

span.date {
    font-size: 0.7em;
}

button {
    width: 80px;
}

div.modal-body {
    height: calc(100vh - 12em);
}

div.accounts div.form-group {
    margin-bottom: 0.5em;
}

p.modal-title i,
p.modal-title span {
    opacity: 0.7;
}

table.accounts th:nth-child(1) {
    width: 8%;
}

table.accounts th:nth-child(2) {
    width: 50%;
}

table.accounts th:nth-last-child(2) {
    width: 12%;
}

table.accounts th:nth-last-child(1) {
    width: 12%;
}

table.schedule th:nth-child(1) {
    width: 25%;
}

table.schedule th:nth-child(2) {
    width: 15%;
}

table.schedule th:nth-last-child(2) {
    width: 12%;
}

table.schedule th:nth-last-child(1) {
    width: 12%;
}

span.total {
    font-weight: 600;
}

span.title {
    font-size: 1em !important;
}

p.schedule {
    font-weight: 500;
}

span:not(.button) {
    font-size: 0.9em;
}
</style>
<script lang="ts">
import moment from "moment";
import Vue, { PropType } from "vue";
import { numeral } from "../../../../../npm";

export interface Transaction {
    custodianId: string;
    firmId: string;
    accountId: string;
    accountNumber: string;
    date: Date;
    category: string;
    source: string;
    detailDescription: string;
    type: string;
    quantity: number;
    price: number;
    amount: number;
    ticker: string;
    trnFees: number;
    rebated: boolean;
}

export default Vue.extend({
    props: {
        transactions: {
            type: Array as PropType<Transaction[]>,
            required: true,
        },
        obfuscateNumbers: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
    },
    computed: {
        total(): string {
            return this.formatMoney(this.transactions?.reduce((acc, transaction) => {
                return acc + (transaction.trnFees ? transaction.trnFees : transaction.amount);
            }, 0))
        },

    },
    created() {
    },
    methods: {
        formatDate(date: Date): string {
            return moment(date).format("MMM D, YYYY");
        },
        formatMoney(amount: number): string {
            return numeral(amount).format(`$0,0.00`);
        },
        obfuscateAccountNumber(accountNumber: string): string {
            const len = Math.floor(accountNumber.length / 2);

            return this.obfuscateNumbers
                ? [...new Array(len).fill(`*`), accountNumber.slice(len)].join(``)
                : accountNumber;
        },
    }
})
</script>