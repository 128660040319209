<template>
  <h5>Dashboard</h5>
</template>

<style scoped></style>

<script>
export default {
  data() {
    return {};
  }
};
</script>
