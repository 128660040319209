<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header">
      <div class="d-flex actions">
        <div
          title="Export Invoice(s)"
          class="btn btn-outline-secondary action-button"
          v-on:click="table.click(`export`, `invoice`)"
        >
          <i class="fas fa-file-csv"></i>
          <span>Download Fee File</span>
        </div>

        <div
          title="Download Invoice(s)"
          class="btn btn-outline-secondary action-button"
          v-on:click="table.downloadSelected(table.items.filter(i => i.select && !i.refund).map(i => i.pdf))"
        >
          <i class="fas fa-file-download"></i>
          <span>Download Invoices</span>
        </div>

        <div
          title="Send Invoice(s) to Clients"
          class="btn btn-outline-secondary action-button"
          v-on:click="table.openSendInvoices()"
        >
          <i class="fas fa-paper-plane"></i>
          <span>Send to clients</span>
        </div>

        <div
          title="Delete Invoice(s)"
          class="btn btn-outline-secondary action-button"
          v-on:click="table.deleteSelected()"
        >
          <i class="fas fa-trash-alt"></i>
          <span>Delete selected</span>
        </div>

        <div
          title="Set Invoice(s) status as paid"
          class="btn btn-outline-secondary action-button"
          v-on:click="table.paidSelected()"
        >
          <i class="fas fa-check"></i>
          <span>Set as paid</span>
        </div>

        <!-- <div
          title="Set Invoice(s) status as paid"
          class="btn btn-outline-secondary action-button"
          v-on:click="table.pendingSelected()"
        >
          <i class="fas fa-trash-alt"></i>
          <span>Set as pending</span>
        </div>-->
      </div>
      <div class="d-flex filters">
        <select v-model="table.selectedYear" class="custom-select custom-select-sm" @change="table.handleYearSelection()" >
          <option v-for="year in table.years" :key="year" :value="year">
            Range: {{ year }}
          </option>
        </select>
      </div>
      <div class="flex-grow-1 text-right" style="display: flex; flex-direction: column">
        <span v-text="table.records"></span>
        <span v-text="table.selected"></span>
      </div>
    </div>
    <template v-if="ready">
    <scroll-area class="pr-3">
      <a ref="download" class="d-none"></a>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <checkbox :o="table" p="select" v-on:change="table.change(`select`)"></checkbox>
            </th>
            <th>
              <span>Id</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `id`)"
                v-on:click="table.sort(`id`)"
              ></i>
            </th>
            <th>
              <span>Ref</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `ref`)"
                v-on:click="table.sort(`ref`)"
              ></i>
            </th>
            <th>
              <span>Accounts</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `accounts`)"
                v-on:click="table.sort(`accounts`)"
              ></i>
            </th>
            <th>
              <span>Schedules</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `schedules`)"
                v-on:click="table.sort(`schedules`)"
              ></i>
            </th>
            <th>
              <span>Date</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `date`)"
                v-on:click="table.sort(`date`)"
              ></i>
            </th>
            <th>
              <span>Status</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `status`)"
                v-on:click="table.sort(`status`)"
              ></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody :key="table.body_key">
          <tr
            v-for="(o, index) in table.items"
            :key="o._id"
            data-cy="custodian-billing-invoice-row"
          >
            <td>
              <checkbox
                :o="o"
                p="select"
                v-on:change="table.change(`select`, o)"
                :disabled="table.disabled(`select`, o)"
              ></checkbox>
            </td>
            <td>
              <a
                href="#"
                class="badge badge-custom"
                :title="table.title(`id`)"
                v-on:click="table.click(`invoice`, o)"
                v-text="o.id"
              ></a>
            </td>
            <td class="text-nowrap">
              <span v-text="o.ref"></span>
            </td>
            <td class="pre accounts">
              <div>
                <span
                  v-for="(a, i) in o.accounts"
                  :key="i"
                  class="mr-2"
                  :title="table.title(`account`, a)"
                  v-text="a"
                ></span>
              </div>
            </td>
            <td class="pre">
              <schedule-badges :schedules="o.schedules"></schedule-badges>
            </td>
            <td class="text-nowrap">
              <span v-text="o.date"></span>
            </td>
            <td>
              <span class="badge" :class="table.class(`status`, o)" v-text="o.status"></span>
            </td>
            <td class="text-nowrap">
              <i
                class="far fa-file-pdf mr-2"
                :title="table.title(`pdf`)"
                v-on:click="table.click(`pdf`, o)"
              ></i>
              <!-- <template v-if="!o.refund">
                <i
                  class="fas fa-dollar-sign"
                  :title="table.title(`refund`)"
                  v-on:click="table.click(`refund`, o)"
                ></i>
              </template>-->
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
   </template>
    <template v-else>
      <loading></loading>
   </template>
  </div>
</template>

<style scoped>

div.actions {
  border-right: 1px solid gray;
}
div.filters {
  align-items: center;
}
.action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}
div.header div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}
table span {
  font-size: 0.9rem;
}
span.link {
  cursor: pointer;
  text-decoration: underline;
}

table th:nth-child(1) {
  width: 2em;
}
table th:nth-child(2) {
  width: 5em;
}
table th:nth-child(3) {
  width: 25%;
}
table th:nth-child(4) {
  width: auto;
}
table th:nth-child(5) {
  width: auto;
}
table th:nth-child(6) {
  width: 7em;
}
table th:nth-child(7) {
  width: 6em;
}
table th:nth-child(8) {
  width: 4em;
}
td.pre span {
  white-space: pre;
}

td.accounts div {
  display: flex;
  flex-direction: column;
}
td {
  vertical-align: inherit;
}
i {
  opacity: 0.3;
}
i:hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}
span.badge {
  color: white;
  font-weight: normal;
  width: 5em;
}
span.schedule-badge {
  font-weight: normal;
  font-size: 0.8em;
  width: auto;
}
</style>

<script>
import { checkbox, scrollArea, search, loading } from "../../../../component";
import { Api, Component, List, Modal, Record } from "../../../../factory";
import { numeral } from "../../../../npm";
import { alert, events, session } from "../../../../service";

import pdfIcon from "../../../../../../img/pdf-icon.svg";
import ScheduleBadges from "../../../../component/schedule/schedule-badges.vue";

export default {
  name: `FirmInvoices`,
  get components() {
    return {
      checkbox,
      scrollArea,
      search,
      ScheduleBadges,
      loading
    };
  },
  data() {
    return {
      table: ``,
      ready: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const _this = this
      var table = {
        _export: async (items) => {
          var list = items
            .filter((o) => o.select && !o.refund)
            .map((o) => o._id);
          if (!list.length) {
            return alert.warning(`No invoice(s) selected!`, 3000);
          }
          try {
            const isAllCustodians = session.get(`state`).custodian?._id == '*' 
            const args = isAllCustodians ? '_export=csv&_all=1' : '_export=csv'
            var { data: file } = await Api.post(`invoices?${args}`,{ list });
            //var csv = { file }
            //return
            var el = this.$refs.download;
            el.setAttribute(`href`, file.dataURI);
            el.setAttribute(`download`, file.name);
            el.click();
            el.removeAttribute(`href`);
            el.removeAttribute(`download`);
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _mail: async (items) => {
          var list = items
            .filter((o) => o.select && !o.refund)
            .map((o) => o._id);
          if (!list.length) {
            return alert.warning(`No invoice(s) selected!`, 3000);
          }
          try {
            await Api.post(`mail/invoices`, { ids: list.join(`,`) });
            alert.info("Mail sent to clients that have consented to receive mails.");
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _id(id, len = 5, pad = `0`) {
          var s = `${id}`;
          while (s.length < len) {
            s = `${pad}${s}`;
          }
          return s;
        },
        _items: ``,
        selectedYear: (new Date()).getFullYear(),
        async handleYearSelection(target){
          _this.ready = false // show the loading spinner
          this._items = `` // reset the visual counter
          await this._load()
        },
        async markInvoiceToExport() {
          this.select = true;
          this.change("select");
        },
        async _load() {
          if (this._loading)
            return;
          this._loading = true;
          try {
            //fetch
            const url = `invoices/list?&year=${table.selectedYear}`
            const custodian = session.get(`state`).custodian?._id != '*' ? `&custodianId=${session.get(`state`).custodian._id}` : ``
            var invoices = (await Api.get( url + custodian)).data

            const cashflowAdjustment = session.get(`state`).firm?.features?.cashflowAdjustment ?
              session.get(`state`).firm?.features?.cashflowAdjustment : false

            // check if cashflowAdjustment is off for this firm
            if (!cashflowAdjustment)
              invoices.forEach(i => {
                if (i.adjustments) delete i.adjustments
              })

            this._items = invoices
              .sort((a, b) => a._updated.localeCompare(b._updated))
              .map((o) => ({ ...o, select: `` }));
            // console.log("invoices: ", invoices);
            this.markInvoiceToExport();
          }
          catch (e) {
            console.error(e);
          }
          finally {
            this._loading = false;
            _this.ready = true
          }
        },
        _loading: ``,
        _pdf: async (invoice) => {
          try {
            if (!invoice.file) {
              var { data: f } = await Api.get(`files?name=${encodeURIComponent(invoice.pdf)}`);
              invoice.file = f;
            }
            var el = this.$refs.download;
            el.setAttribute(`href`, invoice.file.dataURI);
            el.setAttribute(`download`, invoice.file.name);
            el.click();
            el.removeAttribute(`href`);
            el.removeAttribute(`download`);
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        downloadSelected: async (files) => {
          try {
            var { data } = await Api.post(`files/zip`, { files });

            var el = this.$refs.download;
            el.setAttribute(`href`, data.dataURI);
            el.setAttribute(`download`, data.name);
            el.click();
            el.removeAttribute(`href`);
            el.removeAttribute(`download`);
          }
          catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        async openSendInvoices() {
          try {
            const ids = this._items.filter(i => i.select && i.status == 0).map(i => i._id)
            Modal.open("InvoiceEmail", {
              methods: {
                send: async () => {
                  try {
                    const ids = this._items.filter(i => i.select && i.status == 0).map(i => i._id)
                    await Api.post(`email/invoices`, { 
                      ids, 
                      firmId: session.get(`state`).firm._id 
                    });
                    alert.message("Invoices sent to clients!")
                  } catch (e) {
                    console.error(e);
                    alert.error(e.message);
                  }
                },
              },
            }); 

          } catch (e) {
            console.error(e);
            alert.error(e.message);
          } 
        },
        async deleteSelected() {
          return Modal.open("invoices-delete-confirm", {
            invoices: this._items.filter(i => i.select && i.status == 0),
            methods: {
              success: async () => {
                await this._deleteSelected();
                Modal.close();
              },
            }
          });
        },
        async _deleteSelected() {
          const ids = this._items.filter(i => i.select && i.status == 0).map(i => i.id)
          try {
            await Api.post('invoices/delete_multiple', { ids })
            alert.message("Invoices deleted!")
            this._reload();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        async paidSelected() {
          return Modal.open("invoices-paid-confirm", {
            invoices: this._items.filter(i => i.select && i.status == 0),
            methods: {
              success: async () => {
                await this._paidSelected();
                Modal.close();
              },
            }
          });
        },
        async _paidSelected(paid = true) {
          const ids = this._items.filter(i => i.select && i.status == 0).map(i => i.id)
          try {
            await Api.post('invoices/update', { ids, paid })
            alert.message("Invoices updated!")
            this._reload();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        async pendingSelected() {
          await this._paidSelected(false)
        },
        detectDate(date) {
          if (date.includes(",")) {
            return date
          } else {
            return moment(date, "L").format("MMM D, YYYY")
          }
        },
        _record(o) {
          var accounts = o.accounts.map((a) => a.number);
          var schedules = o.schedules.map((o) => o.name);
          var id = o.refund ? `${Record.id(o)}-R` : Record.id(o);
          var status = this._status[o.status].text;
          return { ...o, date: this.detectDate(o.date), accounts, id, schedules, status };
        },
        _reload() {
          events.$emit(`nav`, `invoices`);
        },
        _status: [
          { badge: `warning`, text: `Pending` },
          { badge: `success`, text: `Paid` },
          { badge: `danger`, text: `Void` },
        ],
        selectedItems: ``,
        body_key: 0,
        change(type, item) {
          switch (type) {
            case `select`:
              if (!item) {
                if (this.select) {
                  const items = this.items;
                  this.selectedItems = {};
                  items
                    .filter((i) => i.status == "Pending" && !i.refund)
                    .forEach((el) => (this.selectedItems[el._id] = this.select));
                  this._items.forEach((o) => {
                    o.select = !!this.selectedItems[o._id];
                  });
                }
                else {
                  this._items.forEach((o) => {
                    o.select = this.select;
                  });
                  this.selectedItems = {};
                }
                this.body_key += 1;
              }
              else {
                delete this.selectedItems[item._id];
                this._items
                  .filter((o) => item._id == o._id)
                  .forEach((o) => (o.select = item.select));
              }
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          switch (type) {
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            case `status`:
              var i = this._status.findIndex((o) => o.text == d.status);
              var { badge } = this._status[i];
              return `badge-${badge}`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        async click(type, d) {
          switch (type) {
            case `export`:
              return this._export(this._items);
            case `mail`:
              return this._mail(this._items);
            case `invoice`:
              return Modal.open(`invoice`, {
                invoice: this._items.find((o) => o._id == d._id),
                methods: {
                  success: () => {
                    Modal.hide();
                    this._reload();
                  },
                },
              });
            case `pdf`:
              return this._pdf(this._items.find((o) => o._id == d._id));
            case `refund`:
              return Modal.open(`invoice-refund`, {
                contract: (await Api.get(`contracts?_id=${this._items.find((o) => o._id == d._id).contractId}`)).data,
                invoice: this._items.find((o) => o._id == d._id),
                methods: {
                  discard: () => {
                    Modal.hide();
                  },
                  success: () => {
                    Modal.hide();
                    this._reload();
                  },
                },
              });
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type, d) {
          switch (type) {
            case `select`:
              var o = this._items.find((o) => o._id == d._id);
              return !(o.status == 0 && !d.refund);
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        display(type, d) {
          switch (type) {
            case `value`:
              return numeral(d.value).format(`$0,0.00`);
            default:
              throw new Error(`Invalid display type, ${type}!`);
          }
        },
        edit(account) {
          var component = `account-profile`;
          var { success } = this;
          var data = { account, methods: { success: success.bind(this) } };
          events.$emit(`modal`, { component, data });
        },
        img(type) {
          switch (type) {
            case `pdf`:
              return pdfIcon;
            default:
              throw new Error(`Invalid img type, ${type}!`);
          }
        },
        init(c) {
          this.state = Component.state(c, {
            invoice: {},
            list: {
              search: ``,
              sort: { asc: ``, key: `` },
            },
          });
          this.NONE = `-`;
          this._load();
          return this;
        },
        get items() {
          var items = Array.from(this._items).map((o) => this._record(o));
          var { search, sort } = this.list;
          return List.sort(List.filter(items, search), sort.key, sort.asc);
        },
        get list() {
          return this.state.list;
        },
        async open(type, d) {
          var success = this.success.bind(this);
          switch (type) {
            case `pdf`:
              this._pdf(this._items.find((o) => o._id == d._id));
              break;
            default:
              throw new Error(`Invalid open type, ${type}!`);
          }
        },
        NONE: ``,
        get years() {
          return Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i)
        },
        get records() {
          return List.records(this.items, this._items);
        },
        get selected() {
          var a = this.items.filter((i) => i.select && !i.refund);
          var b = this._items;
          var r = Array.isArray(a) && Array.isArray(b)
            ? [a.length, b.length].map((v) => numeral(v).format(`0,0`))
            : ``;
          return r ? `Selected ${r[0]} of ${r[1]} record(s)` : ``;
        },
        select: ``,
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        style(type) {
          switch (type) {
            case `pdf`:
              return `width: 1em`;
            default:
              throw new Error(`Invalid style type, ${type}!`);
          }
        },
        title(type, d) {
          switch (type) {
            case `refund`:
              return `Create refund`;
            case `id`:
              return `View invoice`;
            case `pdf`:
              return `Download invoice`;
            case `account`:
              return `View ${type}`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
      };
      this.table = table.init(this);
    },
  },
};
</script>
