
<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-alt mr-2"></i>
        <span class="font-weight-bold" v-text="invoice.text(`modal`)"></span>
      </p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <template v-if="invoice.ready">
        <scroll-area class="px-4 py-3">
          <div class="form-row">
            <div class="col-12">
              <p class="font-weight-bold">Invoice</p>
              <div class="form-row">
                <div class="col-3">
                  <div class="form-group">
                    <date-picker :o="invoice" p="date" :options="invoice.options(`date`)"></date-picker>
                    <small class="form-text text-muted">Invoice Date</small>
                  </div>
                </div>
                <div class="col-3">
                  <select
                    v-model="invoice.type"
                    class="form-control form-control-sm"
                    :disabled="invoice.disabled(`type`)"
                    v-on:change="invoice.change(`type`)"
                  >
                    <option v-for="(o, i) in invoice.options(`type`)" :key="i">
                      <span v-text="o"></span>
                    </option>
                  </select>
                  <small class="form-text text-muted">Invoice Type</small>
                </div>
              </div>
            </div>
            <div class="col">
              <p class="font-weight-bold">Period</p>
              <div class="form-row">
                <div class="col-3">
                  <template v-if="invoice.advance">
                    <div class="form-group">
                      <date-picker
                        :o="invoice.period"
                        p="values"
                        :options="invoice.options(`period`, `values`)"
                      ></date-picker>
                      <small class="form-text text-muted">Period Values</small>
                    </div>
                  </template>
                  <template v-else>
                    <select
                      v-model="invoice.values"
                      class="form-control form-control-sm"
                      v-on:change="invoice.change(`values`)"
                    >
                      <option v-for="(o, i) in invoice.options(`values`)" :key="i">
                        <span v-text="o"></span>
                      </option>
                    </select>
                    <small class="form-text text-muted">Period Values</small>
                  </template>
                </div>

                <div class="col-3">
                  <div class="form-group">
                    <date-picker
                      :o="invoice.period"
                      p="start"
                      :options="invoice.options(`period`, `start`)"
                    ></date-picker>
                    <small class="form-text text-muted">Period Start</small>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <date-picker
                      :o="invoice.period"
                      p="end"
                      :options="invoice.options(`period`, `end`)"
                    ></date-picker>
                    <small class="form-text text-muted">Period End</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="d-flex">
            <div>
              <p class="font-weight-bold">Accounts Refund</p>
            </div>
            <div class="flex-grow-1 text-right">
              <span class="mr-2">Obfuscate account numbers</span>
              <checkbox :o="invoice.show" p="numbers" title="Toggle obfuscate account numbers"></checkbox>
            </div>
            <div class="text-right ml-4">
              <span class="mr-2">Show all accounts</span>
              <checkbox :o="invoice.show.all" p="due" title="Toggle all accounts"></checkbox>
            </div>
          </div>
          <table class="table table-sm accounts mb-5">
            <thead>
              <tr>
                <th>
                  <span>Number</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Household</span>
                </th>
                <th class="text-right">
                  <span>Billing</span>
                </th>
                <th class="text-right">
                  <span>Amount</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(o, i) in invoice.accounts(`due`)" :key="i">
                <td>
                  <span v-text="o.number"></span>
                </td>
                <td>
                  <span v-text="o.name"></span>
                </td>
                <td>
                  <span v-text="o.household"></span>
                </td>
                <td class="text-right">
                  <span v-text="o.billing"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`due`, o)"></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3"></td>
                <td class="text-right">
                  <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right">
                  <span class="total" v-text="invoice.text(`total`, `due`)"></span>
                </td>
              </tr>
            </tfoot>
          </table>
          <hr />
          <div class="d-flex">
            <div>
              <p class="font-weight-bold">Account Fees</p>
            </div>
            <div class="flex-grow-1 text-right">
              <span class="mr-2">Show all fees</span>
              <checkbox :o="invoice.show.all" p="fees" title="Toggle all fees"></checkbox>
            </div>
          </div>
          <table class="table table-sm accounts mb-0">
            <thead>
              <tr>
                <th>
                  <span>Number</span>
                </th>
                <th>
                  <span>Name</span>
                </th>
                <th>
                  <span>Schedule</span>
                </th>
                <th class="text-right">
                  <span>Value</span>
                </th>
                <th class="text-right">
                  <span>Fee</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(o, i) in invoice.accounts(`fees`)" :key="i">
                <td>
                  <span v-text="o.number"></span>
                </td>
                <td>
                  <span v-text="o.name"></span>
                </td>
                <td>
                  <span v-text="o.schedule"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`value`, o)"></span>
                </td>
                <td class="text-right">
                  <span v-text="invoice.text(`fee`, o)"></span>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2"></td>
                <td class="text-right">
                  <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right">
                  <span class="total" v-text="invoice.text(`total`, `account-values`)"></span>
                </td>
                <td class="text-right">
                  <span class="total" v-text="invoice.text(`total`, `account-fees`)"></span>
                </td>
              </tr>
            </tfoot>
          </table>
          <p class="text-right pr-3">
            <small class="text-muted" v-text="invoice.text(`owing`)"></small>
          </p>
          <hr />
          <div class="d-flex">
            <div>
              <p class="font-weight-bold">Schedules</p>
            </div>
            <div class="flex-grow-1 text-right">
              <span class="mr-2">Show all tiers</span>
              <checkbox :o="invoice.show.all" p="tiers" title="Toggle all tiers"></checkbox>
            </div>
          </div>
          <div v-for="(o, index) in invoice.schedules" :key="index">
            <hr class="mt-0" />
            <p class="schedule" v-text="invoice.text(`schedule`, o, index)"></p>
            <table class="table table-sm schedule mb-4">
              <thead>
                <tr>
                  <th>
                    <span>Tier</span>
                  </th>
                  <th class="text-right">
                    <span>Rate</span>
                  </th>
                  <th></th>
                  <th class="text-right">
                    <span>Value</span>
                  </th>
                  <th class="text-right">
                    <span>Fee</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(split, i) of invoice.tiers(o)" :key="i">
                  <td>
                    <span v-text="invoice.text(`tier`, o, i)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`rate`, o, i)"></span>
                  </td>
                  <td></td>
                  <td class="text-right">
                    <span v-text="invoice.text(`split`, o, i)"></span>
                  </td>
                  <td class="text-right">
                    <span v-text="invoice.text(`fee`, o, i)"></span>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2"></td>
                  <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                  </td>
                  <td class="text-right">
                    <span class="total" v-text="invoice.text(`total`, `schedule-splits`, o)"></span>
                  </td>
                  <td class="text-right">
                    <span class="total" v-text="invoice.text(`total`, `schedule-fees`, o)"></span>
                  </td>
                </tr>
              </tfoot>
            </table>
            <template v-if="o.owing">
              <p class="text-right">
                <small class="text-muted" v-text="invoice.text(`owing`, o)"></small>
              </p>
            </template>
          </div>
        </scroll-area>
      </template>
      <template v-else>
        <loading></loading>
      </template>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          :disabled="invoice.disabled(`submit`)"
          v-on:click="invoice.click(`submit`)"
        >Submit</button>
      </div>
    </div>
  </div>
</div>
