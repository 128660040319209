
<div class="form-row">
  <div class="col-3">
    <OnboardingInput v-model="contact.firstName.value" />
  </div>

  <div class="col-3">
    <OnboardingInput v-model="contact.lastName.value" />
  </div>

  <div class="col-3">
    <OnboardingInput v-model="contact.email.value"  />
  </div>

  <div class="col-2">
    <IconButton icon="far fa-plus-square" v-on:click="$emit('plus')" />
    <IconButton v-if="i >= 0" icon="far fa-minus-square" v-on:click="$emit('minus')" />
  </div>
  <div class="col-1"></div>
</div>
