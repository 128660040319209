<template>
  <h6 :class="error ? `error` : `title`">
    <slot>{{ title }}</slot>
  </h6>
</template>

<style scoped>
h6 {
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
}

.title {
  color: #6c757d;
}
.error {
  color: var(--danger);
}
</style>

<script>

export default {
  props: {
    error: Boolean,
    title: String,
  }
};
</script>
