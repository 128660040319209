
<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-alt mr-2"></i>
        <span class="font-weight-bold" v-text="invoices.text(`modal`)"></span>
      </p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <a ref="download" class="d-none"></a>
      <template v-if="invoices.ready">
        <scroll-area class="px-4 py-3">
          <div class="form-row">
            <div class="col">
              <div class="form-row">
                <div class="col-3">
                  <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                  v-model="invoices.date" format="MMM D, YYYY">
                  </date-picker>
                  <small class="form-text text-muted">Invoice Date</small>
                </div>
                <div class="col-3">
                 <select class="form-control form-control-sm text-capitalize" disabled>
                     <option class="text-capitalize">
                     <span class="text-capitalize">{{ invoices.hasAdvance ? "Advance" : "Arrears" }}</span>
                   </option>
                 </select>
                 <small class="form-text text-muted">Invoice Type</small>
               </div>
              </div>
              <hr>
              <h6>Values used to calculate fees</h6>
              <template v-if="invoices.hasAdvance">
                <div class="form-row">
                  <div class="form-group col-4" v-if="invoices.hasLatest">
                    <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                    v-model="invoices.period.advance.latest"
                      format="MMM D, YYYY"></date-picker>
                    <small class="form-text text-muted">Latest</small>
                  </div> 
                  <div class="form-group col-4" v-if="invoices.hasAverageDaily">
                    <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                    v-model="invoices.period.advance.adb"
                      format="MMM D, YYYY" range></date-picker>
                    <small class="form-text text-muted">Average Daily Balance</small>
                  </div> 
                </div>
              </template>
              <template v-if="invoices.hasArrears">
                <div class="form-row">
                  <div class="form-group col-4" v-if="invoices.hasLatest">
                   <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                   v-model="invoices.period.arrears.latest"
                     format="MMM D, YYYY"></date-picker>
                   <small class="form-text text-muted">Latest</small>
                  </div>
                  <div class="form-group col-4" v-if="invoices.hasAverageDaily">
                    <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                    v-model="invoices.period.arrears.adb"
                      format="MMM D, YYYY" range></date-picker>
                    <small class="form-text text-muted">Average Daily Balance</small>
                  </div> 
                </div>
              </template>
              <div class="form-row">
                <div class="form-group col-5">
                   <h6>Fee applied to the following period</h6>
                   <date-picker :clearable="false" class="date-picker" 
                     v-model="invoices.period.range"
                       format="MMM D, YYYY" range></date-picker>
                   <small class="form-text text-muted">Apply for Period</small>
                </div>
                <div class="form-group offset-5 col-2 align-self-end">
                    <button type="button" class="btn btn-sm btn-primary submit-btn"
                      v-on:click="invoices.click(`recalculate`)">Calculate Fees</button>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <transition name="fade" mode="out-in">
            <template v-if="invoices.clean">
              <div style="position: relative">
                <div class="d-flex billable-contracts total">
                  <span class="total" style="flex-grow: 1">
                    Total of billable contracts:
                    {{ invoices.billableContracts.length }}
                  </span>
                </div>
              </div>
            </template>
            <template v-else>
              <div>
                <div class="d-flex billable-contracts total">
                  <span class="total" style="flex-grow: 1">
                    Total of billable contracts:
                    {{ invoices.billableContracts.length }}
                  </span>

                  <span class="total" style="margin-right: 8px">Total</span>
                  <span class="total">{{ invoices.animatedTotal }}</span>
                </div>
                <div v-if="invoices.recalculatingFees">
                  <div class="progress mt-3">
                    <div :style="`width: ${(invoices.progressBarValue /
                      invoices.progressBarMax) *
                      100
                      }%`" class="progress-bar" role="progressbar"></div>
                  </div>
                </div>

                <div class="mt-3">
                  <div class="table-responsive">
                    <p><em>Below are the accounts that have insufficient cash to cover the amount 
                      due; all billing information is available by clicking "Generate report."</em>
                    </p>
                    <table class="table table-bordered table-sm table-secondary">
                      <thead>
                        <tr>
                          <th scope="col">Household</th>
                          <th scope="col">Account</th>
                          <th scope="col">Cash Available</th>
                          <th scope="col">Fee Due</th>
                        </tr>
                      </thead>
                      <tbody>
                        <LastCashValueTable v-for="(acc, i) in invoices.billedAccounts" :key="i" :account="acc">
                        </LastCashValueTable>
                      </tbody>
                    </table>
                  </div>
                </div>

                <template v-if="invoices.contractsWithException">
                  <div key="contractsWithException-if" class="d-flex non-billable-contracts total">
                    <span class="total" style="flex-grow: 1">
                      Total of non-billable contracts:
                      {{ invoices.nonBillableContracts.length }}
                    </span>
                  </div>

                  <table class="table table-sm mb-5 non-billable-contracts">
                    <thead>
                      <tr>
                        <th>
                          <span>Id</span>
                        </th>
                        <th>
                          <span>Ref</span>
                        </th>
                        <th>
                          <span>Accounts</span>
                        </th>
                        <th>
                          <span>Reason</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(o, i) in invoices.contractsWithException" :key="i">
                        <td>
                          <span v-text="invoices.text(`contract-id`, o)"></span>
                        </td>
                        <td>
                          <span v-text="o.ref"></span>
                        </td>
                        <td>
                          <span v-for="(a, i) in o.accounts" :key="i" class="mr-2" v-text="a.number"></span>
                        </td>
                        <td>
                          <span v-text="o.reason"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </template>
                <template v-else>
                  <div key="contractsWithException-else"></div>
                </template>
              </div>
            </template>
          </transition>
        </scroll-area>
      </template>
      <template v-else>
        <loading></loading>
      </template>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button type="button" class="btn btn-sm btn-outline-danger mr-2" data-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-sm btn-secondary submit-btn mr-2" :disabled="invoices.disabled(`submit`)"
          v-on:click="invoices.click(`report`)">Generate report</button>
        <button type="button" class="btn btn-sm btn-primary submit-btn" :disabled="invoices.disabled(`submit`)"
          v-on:click="invoices.click(`submit`)">Generate invoices</button>

      </div>
    </div>
  </div>
</div>
