
<div class="card">
  <div class="card-header px-3 py-2" id="headingOne">
    <div class="card-header-title">
     <button class="btn btn-block text-left" type="button" 
       data-toggle="collapse" data-target="#collapseOne" 
       aria-expanded="true" aria-controls="collapseOne">
         <span class="font-weight-bold">Custodians</span>
         <i class="fa fa-chevron-up ml-1"></i>
     </button>
    </div>
    <button 
      type="button"
      class="btn btn-sm btn-outline-secondary new-client"
      :title="table.title(`add`)"
      v-on:click="table.click(`add`)"
    >
     <i class="fas fa-plus-square mr-2"></i>
     <span>Add</span>
    </button>
  </div>
  <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionMenu">
    <div class="card-body">
      <file-upload :file="file"></file-upload>
      <search :o="table"></search>
      <div class="masters">
        <scroll-area class="pr-3">
          <table class="table table-sm">
            <tbody>
              <tr class="master"><td>
                <i class="far fa-edit mr-1"></i>
                <a
                href="#"
                class="badge badge-light" 
                title="View all custodians"
                v-on:click="table.click(`view`, undefined)"
                >All Custodians</a>
              </td></tr>
              <tr v-for="(o, i) in table.items" :key="i" class="master">
                <td>
                  <i
                    class="far fa-edit mr-1"
                    :title="table.title(`edit`)"
                    v-on:click="table.click(`edit`, o)"
                  ></i>
                  <a
                    href="#"    
                    class="badge badge-light"
                    :title="table.title(`view`)"
                    v-on:click="table.click(`view`, o)"
                    v-text="o.label"
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
        </scroll-area>
      </div>
    </div>
  </div>
</div>
