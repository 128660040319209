<template>
  <tr v-if="display">
  <td>{{ household }}</td>
  <td>{{ accountNum }}</td>
  <td>{{ lastCash }}</td>
  <td>{{ grandTotal }}</td>
  </tr>
</template>

<style scoped> </style>
<script lang="ts">
import { numeral } from "../../npm";
export default {
  props: {
    account: { type: Object },
  },
  data() {
    return {
      household: "",
      accountNum: "",
      lastCash: "",
      grandTotal: "",
      display: false
    }
  },
  created() {

    const { householdName, number, lastCashValue, grandTotal } = this.account
    if (lastCashValue < grandTotal) {
      this.household = householdName
      this.accountNum = number
      this.lastCash = this.toCurrency(lastCashValue)
      this.grandTotal = this.toCurrency(grandTotal)

      // show this row
      this.display = true
    }
  },
  methods: {
    toCurrency(n) {
      return numeral(n).format(`$0,0.00`);
    }
  }
};
</script>

<style></style>
