<template>
  <div class="card h-100">
    <template v-if="nav.household">
      <div class="card-header px-3 py-2">
        <i class="fas fa-house-user mr-2"></i>
        <span class="font-weight-bold" v-text="nav.title()"></span>
      </div>
      <div class="card-body pt-3">
        <ul class="nav nav-tabs">
          <li v-for="(item, index) in nav.items" :key="index" class="nav-item">
            <a
              class="nav-link"
              :class="nav.class(`active`, item)"
              v-on:click="nav.click(item)"
            >
              <i :class="nav.class(`icon`, item)" class="mr-1"></i>
              <span v-text="item"></span>
            </a>
          </li>
        </ul>
        <template v-if="nav.item">
          <div class="component">
            <component :is="nav.component" :list="nav.list"></component>
          </div>
        </template>
        <template v-else>
          <div class="card-header px-3 py-2">
            <i class="fas fa-database mr-2"></i>
            <span class="font-weight-bold">Household</span>
          </div>
          <div class="card-body pt-3">
            <h5 class="text-center my-5">No household selected</h5>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="card-header px-3 py-2">
        <i class="fas fa-database mr-2"></i>
        <span class="font-weight-bold">Household</span>
      </div>
      <div class="card-body pt-3">
        <h5 class="text-center my-5">No household selected</h5>
      </div>
    </template>
  </div>
</template>

<style scoped>
div.card-body h5 {
  opacity: 0.5;
}
span.link {
  text-decoration: underline;
  cursor: pointer;
}
ul span,
ul i {
  font-size: 0.9rem;
}
div.card-header i,
div.card-header span {
  opacity: 0.7;
}
div.card-header div.action i {
  opacity: 0.3;
}
div.card-header div.action i:hover {
  opacity: 1;
  cursor: pointer;
}
li.nav-item {
  cursor: pointer;
}
a.nav-link {
  color: #337cc5;
}
a.nav-link.active i,
a.nav-link.active span {
  opacity: 0.7;
}
div.component {
  height: calc(100vh - 210px);
  margin-top: 6px;
  margin-right: -15px;
  margin-bottom: -10px;
}
</style>

<script>
import { fileUpload, scrollArea, search } from "../../../../component";
import { Api, List } from "../../../../factory";
import { moment } from "../../../../npm";
import { alert, events, session } from "../../../../service";

import householdAccounts from "./accounts.vue";
import householdInvoices from "./invoices.vue";
import householdContracts from "./contracts.vue";
import householdAudits from './audits.vue';

export default {
  get components() {
    return {
      fileUpload,
      householdAccounts,
      householdInvoices,
      householdContracts,
      householdAudits,
      scrollArea,
      search
    };
  },
  data() {
    return {
      file: ``,
      nav: ``,
      state: ``
    };
  },
  watch: {
    "state.household": {
      handler() {
        this.nav.household = this.state.household;
      },
      deep: true
    }
  },
  created() {
    this.init();
    var { nav } = this;
    this.events = events.$watch({
      nav: nav.event.bind(nav)
      //                call(nav, `nav`),
      //                records: nav.event.call(nav, `records`)
    });
  },
  destroyed() {
    events.$unwatch(this.events);
  },
  methods: {
    init() {
      var [state, config] = session.get(`state`, `config`);
      if (!state.hasOwnProperty(`nav`)) this.$set(state, `nav`, {});
      var file = {
        _data(d) {
          return d
            .split(`\r\n`)
            .filter(l => l)
            .map(l => l.replace(/\",\"/g, `|`));
        },
        _import() {
          var component = `schedules-import`;
          var success = this.success.bind(this);
          var data = { methods: { success } };
          events.$emit(`modal`, { component, data });
        },
        get _keys() {
          return [`name`, `minimum`, `breaks`, `rates`];
        },
        _open: o => {
          var { household } = state;
          var success = () => {
            var item = this.nav.item;
            this.nav.item = ``;
            this.$nextTick(() => {
              this.nav.item = item;
              this.nav.hide();
            });
          };
          var component, data;
          switch (o.type) {
            case `profile`:
              component = `accounts-profiles`;
              data = {
                accounts: o.data,
                date: o.date,
                household,
                master: o.master
              };
              break;
            case `balances`:
              component = `accounts-balances`;
              data = { accounts: o.data, date: o.date, master: o.master };
              break;
            default:
              throw new Error(`Invalid open type, ${o.type}!`);
          }
          data.methods = { success };
          events.$emit(`modal`, { component, data });
        },
        accept: `.csv`,
        init() {
          return this;
        },
        success() {
          this.ready = false;
        },
        upload(err, d) {
          try {
            if (err) throw new Error(err.message || err);
            var types = {
              balances: {
                _terms: (line, delim) => line.split(delim),
                _trim: (s = ``) => s.replace(/\"/g, ``),
                keys(line) {
                  return line.includes(`Total Acct Value`)
                    ? this._terms(line, `,`).map(t => this._trim(t))
                    : ``;
                },
                line(line, keys) {
                  var terms = this._terms(line, `|`);
                  var o = {};
                  var fields = { number: `Account`, value: `Total Acct Value` };
                  var i;
                  for (var key of Object.keys(fields)) {
                    i = keys.indexOf(fields[key]);
                    o[key] = this._trim(terms[i]);
                  }
                  return o;
                }
              },
              profile: {
                _terms: (line, delim) => line.split(delim),
                _trim: (s = ``) => s.replace(/\"/g, ``),
                keys(line) {
                  return line.includes(`Account#`)
                    ? this._terms(line, `|`).map(t => this._trim(t))
                    : ``;
                },
                line(line, keys) {
                  var terms = this._terms(line, `|`);
                  var o = {};
                  var fields = { number: `Account#`, name: `Account Name 1` };
                  var i;
                  for (var key of Object.keys(fields)) {
                    i = keys.indexOf(fields[key]);
                    switch (key) {
                      case `number`:
                        o[key] = this._trim(terms[i]);
                        break;
                      case `name`:
                        o[key] = [terms[i], terms[i + 1], terms[i + 2]]
                          .map(t => this._trim(t))
                          .join(`, `);
                        break;
                    }
                  }
                  return o;
                }
              }
            };
            var data = this._data(d.data).reduce((d, line, i) => {
              if (d.type) {
                if (!d.keys) {
                  d.keys = types[d.type].keys(line);
                } else {
                  if (!d.data) d.data = [];
                  d.data.push(types[d.type].line(line, d.keys));
                }
              } else {
                if (line.includes(`Master Account`)) {
                  d.master = line
                    .split(`Master Account`)
                    .pop()
                    .trim()
                    .split(`as`)
                    .shift()
                    .trim();
                  d.type = line
                    .split(` `)
                    .shift()
                    .toLowerCase();
                  d.date = state.firm.demo
                    ? moment().format(`L`)
                    : moment(
                        line
                          .split(`as of`)
                          .pop()
                          .trim()
                          .split(` `)
                          .shift(),
                        `MM/DD/YY`
                      ).format(`L`);
                }
              }
              return d;
            }, {});
            this._open(data);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        }
      };
      this.file = file.init();
      var nav = {
        _household: ``,
        _init: (o, k, v) => this.$set(o, k, v),
        _list() {
          var { item } = this;
          var lists = this._state(`lists`, []);
          var list = lists.find(o => o.item == item);
          if (list) return list;
          lists.push({ item, search: ``, sort: { key: ``, asc: `` } });
          return this._list();
        },
        _modal(component, data) {
          events.$emit(`modal`, { component, data });
        },
        _records: ``,
        _refresh: () => {
          return new Promise(r => this.$nextTick(r));
        },
        _state(k, v) {
          if (this.state.hasOwnProperty(k)) return this.state[k];
          this._init(this.state, k, v);
          return this._state(k);
        },
        get component() {
          return `household-${this.item}`;
        },
        class(type, item) {
          switch (type) {
            case `active`:
              return item == this.item ? `active` : ``;
            case `create`:
              switch (this.item) {
                case `Accounts`:
                  return `far fa-file-alt`;
              }
              break;
            case `icon`:
              switch (item) {
                case `Accounts`:
                  return `fas fa-database`;
                case `Contracts`:
                  return `far fa-file-alt`;
                case `Invoices`:
                  return `fas fa-dollar-sign`;
                case `Audits`:
                  return `fas fa-history`;
                default:
                  throw new Error(
                    `Unable to determine icon for item, ${item}!`
                  );
              }
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(item) {
          if (item == this.item) return this.reload();
          this.item = this.state.item = item;
        },
        event(e) {
          var item = this.items.find(
            s => s.toLowerCase() == e.data.toLowerCase()
          );
          this.click(item);
        },
        get household() {
          return this._household.name;
        },
        set household(o) {
          this._household = o;
          var { _id: householdId } = o;
          var s = state.nav.household.find(s => s.householdId == householdId);
          if (!s)
            state.nav.household.push({ householdId, item: this.items[0] });
          var r = this.item;
          this.item = this.state.item;
          if (state.alert) {
            var item = this.items.find(i =>
              i.toLowerCase().includes(state.alert.type)
            );
            this.click(item);
            state.alert = ``;
          }
          if (r) this.reload();
        },
        async import() {
          //if (state.firm._id == `5fd183c06b8f8400189b1ec8`)
          //  return file.choose()
          //return file.choose() // testing womack feed data
          if (state.firm.demo) {
            var { data: f } = await Api.get(`files?name=${config.demo.values}`);
            var r = await fetch(f.dataURI);
            var reader = new FileReader();
            reader.onloadend = () => {
              file.upload(null, { data: reader.result });
            };
            reader.onerror = e => {
              console.error(e);
            };
            reader.readAsText(await r.blob());
          } else {
            file.choose();
          }
        },
        init() {
          if (!state.nav.household) this._init(state.nav, `household`, []);
          return this;
        },
        item: ``,
        get items() {
          return [`Accounts`, `Contracts`, `Invoices`, `Audits`];
        },
        get list() {
          return this._list();
        },
        get records() {
          return List.records(this._records);
        },
        async reload() {
          if (!this.item) return;
          var item = this.item;
          this.item = ``;
          await this._refresh();
          this.item = item;
        },
        get state() {
          return state.nav.household.find(
            s => s.householdId == this._household._id
          );
        },
        text(type) {
          switch (type) {
            case `add`:
              switch (this.item) {
                case `Accounts`:
                case `Contracts`:
                  return `Add`;
                case `Invoices`:
                  return `Create`;
                default:
                  throw new Error(
                    `Unable to determine add type for item, ${this.item}!`
                  );
              }
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        title(type) {
          switch (type) {
            case `add`:
              switch (this.item) {
                case `Accounts`:
                  return `Add a new Account`;
                case `Invoices`:
                  return `Create a new Invoice`;
                case `Contracts`:
                  return `Add a new ${this.item.slice(0, -1)}`;
                default:
                  throw new Error(
                    `Unable to determine add title for item, ${this.item}!`
                  );
              }
            case `create`:
              switch (this.item) {
                case `Accounts`:
                  return `Create a new Contract`;
              }
              break;
            case `import`:
              switch (this.item) {
                case `Accounts`:
                  return `Import ${this.item}`;
                case `Contracts`:
                  return `Import Account Values`;
              }
              break;
            default:
              return `${this.household} - ${this.item}`;
          }
        }
      };
      this.nav = nav.init();
      this.state = state;
    }
  }
};
</script>
