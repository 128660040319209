
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <div class="d-flex p-1 pb-2 header">
    <div class="d-flex actions">
      <div title="Import selected account(s)"
           :class="[
             `btn`,
             table.items.some((item) => item.missingOnDB)
               ? `btn-outline-primary`
               : `btn-outline-secondary`,
           ]"
           v-on:click="table.click(`add`, `accounts`)">
        <i class="fas mr-1 fa-plus-square"></i>
        <span>Import selected</span>
      </div>
      <div  v-if="table.isNCA"
           title="Add new NCA account"
           class="btn btn-outline-secondary"
           v-on:click="table.addNCAAccount()">
        <i class="fas mr-1 fa-plus-square"></i>
        <span>Add account</span>
      </div>
      <div  v-if="table.isNCA"
           title="Add new NCA account"
           class="btn btn-outline-secondary"
           v-on:click="table.updateNCAAccount()">
        <i class="fas mr-1 fa-plus-square"></i>
        <span>Update account values</span>
      </div>
      <div title="Create Household"
           class="btn btn-outline-secondary"
           v-on:click="table.click(`create`, `household`)">
        <i class="fas mr-1 fa-house-user"></i>
        <span>Create Household</span>
      </div>
    </div>
    <div class="d-flex filters">
      <div title="Filter: No Household"
           class="btn"
           :class="table.class(`div-filter`, `household`)"
           v-on:click="table.click(`filter`, `household`)">
        <i class="fas fa-filter"
           :class="table.class(`filter`, `household`)"></i>
        <span class="ml-1">No Household</span>
      </div>
      <div title="Filter: No Contract"
           class="btn"
           :class="table.class(`div-filter`, `contract`)"
           v-on:click="table.click(`filter`, `contract`)">
        <i class="fas fa-filter"
           :class="table.class(`filter`, `contract`)"></i>
        <span class="ml-1">No Contract</span>
      </div>
    </div>
    <div class="flex-grow-1 text-right">
      <span v-text="table.records"></span>
    </div>
  </div>
  <template v-if="ready">
  <scroll-area class="pr-3">
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <checkbox :o="table"
                      p="select"
                      :disabled="table.disabled(`select-all`)"
                      v-on:change="table.change(`select`)"></checkbox>
          </th>
          <th>
            <span>Number</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `number`)"
               v-on:click="table.sort(`number`)"></i>
          </th>
          <th>
            <span>Name</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `name`)"
               v-on:click="table.sort(`name`)"></i>
          </th>
          <th>
            <span>Household</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `household`)"
               v-on:click="table.sort(`household`)"></i>
          </th>
          <th>
            <span>Contract</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `contract`)"
               v-on:click="table.sort(`contract`)"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.items"
            :key="index">
          <td>
            <i title="This account is missing on the most recent custodian data."
               v-if="o.missingOnCustodian"
               class="fas fa-exclamation-triangle missing-account-icon"></i>
            <checkbox v-else
                      :o="o"
                      p="select"
                      :disabled="table.disabled(`select`, o)"></checkbox>
          </td>
          <td>
            <a href="#" class="badge badge-custom"
                  :title="table.title(`account`)"
                  v-on:click="table.open(`account`, o)"
                  v-text="o.number"></a>
          </td>
          <td>
            <span v-text="o.name"></span>
          </td>
          <td>
            <template v-if="o.household != table.NONE">
              <a href="#" class="badge badge-custom"
                    :title="table.title(`household`)"
                    v-on:click="table.open(`household`, o)"
                    v-text="o.household"></a>
            </template>
            <template v-else>
              <span v-text="o.household"></span>
            </template>
          </td>
          <td>
            <template v-if="o.contract != table.NONE">
              <a href="#" class="badge badge-custom"
                    :title="table.title(`contract`)"
                    v-on:click="table.open(`contract`, o)"
                    v-text="o.contract"></a>
            </template>
            <template v-else>
              <span v-text="o.contract"></span>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </scroll-area>
  </template>
  <template v-else>
    <loading></loading>
  </template>
</div>
