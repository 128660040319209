
<div class="form-group">
  <label>Code</label>
  <div class="form-row">
    <div v-for="(n, i) in mfa.code.slice(0, 3)" :key="i" class="col pr-3">
      <input
        :ref="mfa.ref(i)"
        v-model="mfa.code[i]"
        class="form-control"
        v-on:keyup="mfa.keyup(i)"
      />
    </div>
    <div class="col-1 text-center">
      <i class="fas fa-minus"></i>
    </div>
    <div v-for="(n, i) in mfa.code.slice(3)" :key="i + 3" class="col pl-3">
      <input
        :ref="mfa.ref(i + 3)"
        v-model="mfa.code[i + 3]"
        class="form-control"
        v-on:keyup="mfa.keyup(i + 3)"
      />
    </div>
  </div>
  <small class="form-text text-muted">2FA Authentication App Code</small>
</div>
