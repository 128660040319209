
<div class="modal-dialog modal-dialog-centered modal-xl"
     role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="far fa-file-alt mr-2"></i>
        <span class="font-weight-bold"
              v-text="contract.text(`modal`)"></span>
      </p>
      <button type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <template v-if="contract.ready">
        <scroll-area class="px-3 pb-3">
          <div class="form-row pt-3">
            <div class="col-12">
              <div class="form-row">
                <div class="col-3">
                  <label>Date</label>
                  <date-picker class="datepicker"
                               inputClass="form-control form-control-sm"
                               v-model="contract.jsDate"
                               format="MMM D, YYYY"
                               :clearable="false"
                               disabled></date-picker>
                  <small class="form-text text-muted">Contract Date</small>
                </div>

                <div class="col-5">
                  <label>Reference</label>
                  <input class="form-control form-control-sm"
                         :value="contract.ref"
                         readonly />
                  <small class="form-text text-muted">Contract Reference</small>
                </div>

                <div class="col-3"
                     v-if="contract.amendedOn">
                  <label>Amend date</label>
                  <date-picker :clearable="false"
                               class="datepicker"
                               inputClass="form-control form-control-sm"
                               disabled
                               v-model="contract.amendedOn"
                               format="MMM D, YYYY"></date-picker>
                  <small class="form-text text-muted">Contract amended on</small>
                </div>
              </div>
            </div>
            <div class="col-6 mt-4">
              <label>Billing</label>
              <div class="form-row">
                <div class="col">
                  <input class="form-control form-control-sm text-capitalize"
                         :value="contract.billing.type"
                         readonly />
                  <small class="form-text text-muted">Billing Type</small>
                </div>
                <div class="col">
                  <input class="form-control form-control-sm"
                         :value="contract.billing.schedule"
                         readonly />
                  <small class="form-text text-muted">Billing Schedule</small>
                </div>
                <div class="col">
                  <input class="form-control form-control-sm"
                         :value="contract.billing.values"
                         readonly />
                  <small class="form-text text-muted">Billing Values</small>
                </div>
                <!-- <div class="col">
                  <input
                    class="form-control form-control-sm"
                    :value="contract.expiredOn"
                    readonly
                  />
                  <small class="form-text text-muted">Billing Values</small>
                </div> -->
              </div>
            </div>
          </div>
          <hr />
          <div v-for="(s, index) in contract.schedules"
               :key="index"
               class="row">
            <div class="col mt-2">
              <div class="d-flex">
                <div>
                  <u class="pl-2"
                     v-text="contract.text(`schedule`, index)"></u>
                </div>
                <div class="flex-grow-1 text-right">
                  <template v-if="!index && contract.show">
                    <span class="mr-2">Obfuscate account numbers</span>
                    <checkbox :o="contract.show"
                              p="numbers"
                              title="Toggle obfuscate account numbers"></checkbox>
                  </template>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-4">
                  <div class="row">
                    <div class="col">
                      <span class="title pl-2"
                            v-text="contract.text(`schedule`, s)"></span>
                    </div>
                  </div>
                  <div class="row py-3">
                    <div class="col">
                      <table class="table table-sm schedule mb-1">
                        <thead>
                          <tr>
                            <th>
                              <span>Range</span>
                            </th>
                            <th>
                              <span>Rate</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(t, i) in contract.tiers(s)"
                              :key="i">
                            <td>
                              <span v-text="t.range"></span>
                            </td>
                            <td>
                              <span v-text="t.rate"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <template v-if="contract.text(`minimum`, s)">
                        <hr class="my-1" />
                        <span class="font-italic ml-1"
                              v-text="contract.text(`minimum`, s)"></span>
                      </template>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col">
                      <span class="title pl-2">Accounts</span>
                    </div>
                  </div>
                  <div class="row py-3">
                    <div class="col">
                      <table class="table table-sm accounts">
                        <thead>
                          <tr>
                            <th>
                              <span>Number</span>
                            </th>
                            <th>
                              <span>Name</span>
                            </th>
                            <th>
                              <span>Billing</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(a, j) in contract.accounts(s)"
                              :key="j">
                            <td>
                              <span v-text="contract.text(`number`, a)"></span>
                            </td>
                            <td>
                              <span v-text="a.name"></span>
                            </td>
                            <td>
                              <span v-text="a.billing"></span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="contract.exceptions.length">
            <u class="pl-2">Exceptions</u>
            <div class="mt-3">
              <table class="table table-sm exceptions">
                <thead>
                  <tr>
                    <th>
                      <span>Type</span>
                    </th>
                    <th>
                      <span>Account</span>
                    </th>
                    <th>
                      <span>Schedule</span>
                    </th>
                    <th>
                      <span>Ticker</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(o, i) in contract.exceptions"
                      :key="i">
                    <td>
                      <span class="text-capitalize"
                            v-text="o.type"></span>
                    </td>
                    <td>
                      <span v-text="contract.text(`account`, o)"></span>
                    </td>
                    <td>
                      <span v-text="contract.text(`schedule`, o)"></span>
                    </td>
                    <td>
                      <span v-text="contract.text(`ticker`, o)"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="contract._accounts">
            <contract-transactions-creation-table v-if="contract.transactionsRules.length"
                                                  :contractAccounts="contract._accounts"
                                                  v-model="contract.transactionsRules"
                                                  readonly />
          </div>
          <div v-else></div>

          <div v-if="contract.lineItems.length">
            <u class="pl-2">Line Items</u>
            <div class="mt-3">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th>
                      <span>Description</span>
                    </th>
                    <th>
                      <span>Account</span>
                    </th>
                    <th>
                      <span>Timing</span>
                    </th>
                    <th>
                      <span>Amount</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(o, i) in contract.lineItems"
                      :key="i">
                    <td>
                      <span v-text="o.description"></span>
                    </td>
                    <td>
                      <span v-text="contract.text(`account`, o)"></span>
                    </td>
                    <td>
                      <span v-text="o.timing"></span>
                    </td>
                    <td>
                      <span v-text="contract.text(`amount`, o.amount)"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col mt-2">
              <u class="pl-2">Signees</u>
            </div>
          </div>
          <template v-if="contract.signees.length">
            <div class="row py-3">
              <div class="col">
                <table class="table table-sm signees">
                  <thead>
                    <tr>
                      <th>
                        <span>Order</span>
                      </th>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Email</span>
                      </th>
                      <th>
                        <span>Role</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(s, i) in contract.signees"
                        :key="i">
                      <td>
                        <span v-text="contract.text(`signees`, i)"></span>
                      </td>
                      <td>
                        <span v-text="s.name"></span>
                      </td>
                      <td>
                        <span v-text="s.email"></span>
                      </td>
                      <td>
                        <span class="text-capitalize"
                              v-text="s.role"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="font-weight-bold font-italic text-center no-signees m-3"
               v-text="contract.text(`signees`)"></p>
          </template>
          <hr />
        </scroll-area>
        <template v-if="contract.expired">
          <span class="expired">Expired</span>
        </template>
      </template>
      <template v-else>
        <loading></loading>
      </template>
    </div>
    <div class="modal-footer pr-3">
      <div v-if="contract.hasPendingInvoices"
           class="alert alert-warning">
        This contract has pending invoices.
      </div>
      <div class="text-right">
        <remove-button :button="contract.remove"></remove-button>
        <template v-if="contract.text(`action`) == `Expire`">
          <button type="button"
                  class="btn btn-sm btn-warning ml-2"
                  :disabled="
                    contract.hasPendingInvoices || contract.disabled(`action`)
                  "
                  v-on:click="contract.amend()">
            <span>Amend</span>
          </button>
        </template>
        <template v-if="contract.text(`action`) == `Expire`">
          <button type="button"
                  class="btn btn-sm btn-warning ml-2"
                  :disabled="
                    contract.hasPendingInvoices || contract.disabled(`action`)
                  "
                  v-on:click="contract.terminate()">
            <span>Terminate</span>
          </button>
        </template>
        <template v-if="contract.text(`action`)">
          <button type="button"
                  class="btn btn-sm btn-primary ml-2"
                  :disabled="
                    contract.hasPendingInvoices || contract.disabled(`action`)
                  "
                  v-on:click="contract.click(`action`)">
            <span v-text="contract.text(`action`)"></span>
          </button>
        </template>
      </div>
    </div>
  </div>
</div>
