
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <scroll-area class="pr-3">
    <div class="d-flex mt-2 mb-2">
      <u class="font-weight-bold">Exceptions</u>
    </div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <span>Type</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `type`)"
               v-on:click="table.sort(`type`)"></i>
          </th>
          <th>
            <span>Account</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `account`)"
               v-on:click="table.sort(`account`)"></i>
          </th>
          <th>
            <span>Schedule</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `schedule`)"
               v-on:click="table.sort(`schedule`)"></i>
          </th>
          <th>
            <span>Ticker</span>
            <i class="fas fa-sort pl-1"
               :class="table.class(`sort`, `ticker`)"
               v-on:click="table.sort(`ticker`)"></i>
          </th>
          <th class="text-right">
            <i class="fas fa-plus-circle"
               :title="table.title(`add`)"
               v-on:click="table.click(`add`)"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.items"
            :key="index">
          <td>
            <select v-model="o.type"
                    class="form-control form-control-sm text-capitalize"
                    v-on:change="table.change(`type`, o)">
              <option v-for="(t, i) in table.types"
                      :key="i"
                      class="text-capitalize">
                <span v-text="t"></span>
              </option>
            </select>
          </td>
          <td>
            <select v-model="o.account"
                    class="form-control form-control-sm">
              <option v-for="a in table.accounts"
                      :key="a.name"
                      :disabled="table.disabled(`accounts`, o, a)">
                <span v-text="a.name"></span>
              </option>
            </select>
          </td>
          <td>
            <select v-model="o.schedule"
                    class="form-control form-control-sm">
              <option v-for="s in table.schedules"
                      :key="s.name">
                <span v-text="s.name"></span>
              </option>
            </select>
          </td>
          <td class="ticker">
            <input v-model="o.ticker.symbol"
                   class="form-control form-control-sm"
                   :class="table.class(`ticker`, o)"
                   :disabled="table.disabled(`ticker`, o)" />
            <small class="form-text text-muted"
                   v-text="o.ticker.name"></small>
          </td>
          <td class="text-right">
            <i class="fas fa-minus-circle"
               :title="table.title(`remove`)"
               v-on:click="table.click(`remove`, index)"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mt-4" />
    <div class="d-flex mt-2 mb-2">
      <u class="font-weight-bold">Line Items</u>
    </div>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <span>Description</span>
          </th>
          <th>
            <span>Account</span>
          </th>
          <th>
            <span>Timing</span>
          </th>
          <th>
            <span>Amount</span>
          </th>
          <th class="text-right">
            <i class="fas fa-plus-circle"
               :title="table.title(`add-line-item`)"
               v-on:click="table.click(`add-line-item`)"></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(o, index) in table.lineItemItems"
            :key="index">
          <td>
            <input v-model="o.description"
                   class="form-control form-control-sm" />
          </td>
          <td>
            <select v-model="o.account"
                    class="form-control form-control-sm">
              <option v-for="a in table.accounts"
                      :key="a.name"
                      :disabled="table.disabled(`accounts`, o, a)">
                <span v-text="a.name"></span>
              </option>
            </select>
          </td>
          <td>
            <select v-model="o.timing"
                    class="form-control form-control-sm">
              <option v-for="(t, i) in o.timings" :key="i">
                <span v-text="t"></span>
              </option>
            </select>
          </td>
          <td>
            <div class="input-group input-group-sm">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <b class="px-1">$</b>
                </span>
              </div>
              <input v-model="o.amount"
                     class="form-control form-control-sm"
                     type="number" />
            </div>
          </td>
          <td class="text-right">
            <i class="fas fa-minus-circle"
               :title="table.title(`remove-line-item`)"
               v-on:click="table.click(`remove-line-item`, index)"></i>
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mt-4" />
    <div class="d-flex mt-2 mb-2">
    </div>
    <!-- <contract-transactions-creation-table :contractAccounts="contract.accounts"
                                          v-model="contract.transactionsRules" /> -->

  </scroll-area>
</div>
