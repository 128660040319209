<template>
    <div class="modal-dialog modal-dialog-centered modal-lg"
         role="document">
      <div class="modal-content">
        <div class="modal-header">
          <p class="modal-title">
            <i class="fas fa-file-contract mr-2"></i>
            <span class="font-weight-bold">Sign Template - New</span>
          </p>
          <button type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <mark v-if="!_firm.signTemplatesLimit">You reached the limit on the number of templates to create. Please contact sales.</mark>
          <div class="p-3">
            <div class="form-row">
              <div class="col-6">
                <div class="form-group">
                  <input v-model="name"
                         class="form-control form-control-sm"
                         placeholder="Name" />
                  <small class="form-text text-muted">Template Name</small>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                    <label for="files" class="btn btn-sm btn-outline-secondary">Add Template File</label>
                    <input
                      style="display: none;"
                      id="files"
                      type="file"
                      ref="file"
                      accept="application/pdf"
                      @change="handleFilesChange()"
                    />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                    <select class="custom-select custom-select-sm"
                    :disabled="!options.length"
                    v-model="selectedKind">
                      <option selected disabled>Select an option</option>
                       <option
                         v-for="option in options"
                         :value="option.value"
                         :key="option.value" >
                       {{ option.name }}
                     </option>
                 </select>
                 <small class="form-text text-muted">Kind</small>
                </div>
              </div>
            </div>
            <div class="form-row" v-if="selectedFile">
              <div class="col">
                <hr />
                <div class="list-group list-group-flush">
                  <div class="list-group-item list-group-item-action">
                    <i class="far fa-file-pdf mr-1"></i>
                    {{ selectedFile.name }}
                    <IconButton icon="far fa-trash-alt float-right" @click="handleFileRemoval" />
                  </div>
                </div>
              </div>
           </div>
          </div>
        </div>
        <div class="modal-footer pr-3">
          <div class="text-right">
            <button type="button" data-dismiss="modal"
               aria-label="Prepare"
               class="btn btn-sm btn-primary ml-2"
               :disabled="!name || !selectedFile || !selectedKind"
               @click="handleFilePrepare">Prepare
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  button {
    width: 80px;
  }
  
  div.main {
    height: 68vh;
    margin-right: -0.8em;
  }
  
  table {
    margin-left: -5px;
    margin-bottom: 2em;
  }
  
  table thead td:not(:last-child) {
    width: 45%;
  }
  
  i:not(.fa-house-user) {
    opacity: 0.3;
  }
  
  i:hover:not(.fa-house-user) {
    cursor: pointer;
    opacity: 1;
  }
  
  p.modal-title i,
  p.modal-title span {
    opacity: 0.7;
  }
  </style>
  
  <script>
  import { IconButton } from "../../../../component";
  import { Api } from "../../../../factory";
  import { alert } from "../../../../service";
  import HelloSign from 'hellosign-embedded';
  
  export default {
    name: `TemplateModal`,
    get components() {
      return { IconButton };
    },
    props: {
      data: {
        type: Object
      },
    },
    data() {
      return {
        name: '',
        providerId: undefined,
        options: undefined,
        signClient: null,
        selectedFile: null,
        selectedKind: undefined,
        _templates: undefined,
        _methods:undefined,
        _firm: undefined
      }
    },
    async created() {
      const { data } = this;
      const { templates, methods, firm } = data
      this._templates = templates
      this._methods = methods
      this._firm = firm

      const availableKinds = ['template_1','template_2']
      const usedKinds = this._templates.map(t => t.kind)
      this.options = availableKinds
          .filter(a => !usedKinds.includes(a))
          .map(t => {
            return { value: t, 
            // remove underscores and uppercase first letter of each word
              name: t.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()) } 
          })

      // Dropbox sign related
      this.signClient = new HelloSign({
        clientId: '854d48422307ce41fab025e43530d611'
      });

      this.signClient.on('error', (data) => {
        console.log('Sign error', data);
      })

      this.signClient.on('message', (data) => {
        console.log('Sign message', data);
        // finished creating the template, then store in backend
        if (data.type == 'hellosign:userCreateTemplate') {
          const payload = data.payload
          const template = { 
            name: this.name, 
            providerId: payload.templateId,
            kind: this.selectedKind,
            isEmbedded: true
          }
          this.saveChanges(template)
        }
      })
    },
    methods: {
      handleFilesChange() {
        const [file] = this.$refs.file.files;
        this.selectedFile = file
        if (!this.name) this.name = file.name
      },
      handleFileRemoval() {
        this.$refs.file.value = '';
        this.selectedFile = null;
        this.name = null;
      },
      handleFilePrepare() {
        if (!this.selectedFile) {
          alert.message("No file was selected", 1500)
          return
        }

        const reader = new FileReader();

        reader.onloadend = async () => {
          const { type, name, size } = this.selectedFile;
          const request = { type, name, size, dataURL: reader.result }

          try {
            const { data } = await Api.post("/templates/draft", request)
            const { editUrl } = data
            this.openEmbeddedSign(editUrl)
          } catch (e) {
            console.error(e);
            alert.error(`Couldn't upload and/or creating the draft`)
          }
        };
        reader.readAsDataURL(this.selectedFile);
      },
      async saveChanges(template) {
        try {
          await Api.post(`/templates`, template);
          if (this._methods && typeof this._methods.success == `function`)
                return this._methods.success();
        } catch (e) {
          console.error(e);
          alert.error('Error saving template');
        }
      },
      async openEmbeddedSign(url) {
        try {
          this.signClient.open(url, {
            skipDomainVerification: true,
            debug: true
          });
        } catch (e) {
          console.error(e);
          alert.error("Issue when preparing the document for signing");
        }
      }
    },
  };
  </script>
  