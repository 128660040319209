
import { session } from "../service";

class Component {
  static _key(c) {
    var { name } = c.$options;
    if (!name) throw new Error(`Invalid component name, ${name}!`);
    return name;
  }

  static state(component, data) {
    var key = Component._key(component);
    return session.get(key) || session.set(key, data).get(key);
  }
}

export default Component;
