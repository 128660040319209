<template>
  <span v-on:click="$emit(`click`)" class="schedule-badge" :style="style">{{ scheduleName }}</span>
</template>

<style scoped>
.schedule-badge {
  font-weight: normal;
  font-size: 0.8em;
  width: fit-content;
  padding: 0.25em 0.4em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: rgba(0, 0, 0, 0.667);
}
</style>
<script lang="ts">
export default {
  props: {
    schedule: {
      type: String,
    }
  },
  data() {
    return {
      style: { backgroundColor: `` },
      scheduleName: ``
    };
  },
  async created() {
    const storedColor = localStorage.getItem(this.schedule)

    if (storedColor) {
      this.style.backgroundColor = storedColor
    } else {
      this.style.backgroundColor = await this.stringToColor()
      localStorage.setItem(this.schedule, this.style.backgroundColor)
    }

    this.scheduleName = this.schedule
  },
  methods: {
    async sha1() {
      const binary = new Uint8Array(
        await crypto.subtle.digest(
          "SHA-1",
          new TextEncoder().encode(this.schedule),
        ),
      );

      return Array
        .from(binary)
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("");
    },
    async stringToColor() {
      const str = await this.sha1()

      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      let color = "#";

      for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        color += ("00" + value.toString(16)).substr(-2);
      }

      return color + "aa";
    },
  }
};
</script>

<style></style>
