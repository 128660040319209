<template>
  <div>
    <p>Hello [client] -</p>

    <p>Attached is a copy of your current fee arrangement with [Firm Name]; this is a list of accounts we manage on your behalf and how we charge for our services. This fee schedule will remain in effect until further notice. There is no action required on your behalf.</p>

    <p>If you have any questions, please respond to this email or reach out to your advisor.</p>
  </div>
</template>

<script>
export default {
}
</script>
