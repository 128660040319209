import demo from "./demo.json";
import firebase from "./firebase.json";
import { version } from "../../package.json";

const uriMap = {
  prod: "https://app.smartkx.io/v1",
  uat: "https://uat.smartkx.io/v1",
  stage: "https://dev.smartks.io/v1",
};

const api = {
  url: uriMap[import.meta.env.MODE] || "http://localhost:3000/v1"
};

export default {
  api,
  version,
  demo,
  firebase,
};
