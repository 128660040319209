
<div class="modal-dialog modal-dialog-centered modal-md" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-alt mr-2 text-secondary"></i>
        <span class="font-weight-bold text-secondary">Remove group</span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="font-weight-bold confirm-alert text-center">
        This action is irreversible, are you sure you want to proceed?
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button
          type="button"
          class="btn btn-sm btn-outline-danger mr-1"
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary submit-btn"
          v-on:click="submit"
        >
          Delete group
        </button>
      </div>
    </div>
  </div>
</div>
