<template>
    <div style="padding: 1rem 1.5rem">
        <div>
            <p>Dear [Client],</p>
            <p>At Sims Investment Management, LLC, we take pride in our tradition of customer service and support.
                Enclosed with this email you will find a copy of our firm’s Privacy Policy. This
                document is required to be delivered to you annually. We take the privacy of your personal information
                very seriously. Please review it and let us know if you have any questions on how we keep your
                non-public information. </p>
            <p>Also enclosed with this letter is a copy of our firm’s Form ADV 2A and ADV-CRS Part 3. These documents
                provide a detailed description of the various aspects of our firm and important disclosures that you
                should be aware of. Copies of these documents were provided to you when you opened your account with us.
            </p>
            <p>Sims Investment Management, LLC values your relationship and strives to provide you with quality products
                and services to suit your individual investment needs. As the Chief Compliance Officer, it is my job to
                keep you up to date and informed of any material changes to our firm. Thank you for the opportunity to
                serve you and as always, feel free to contact me with any questions.</p>
            <p>
                - Cory K. Sims<br>
                Managing Member<br>
                Chief Compliance Officer
            </p>
        </div>
    </div>
</template>

<script>
export default {
}
</script>