<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <checkbox class="align-middle"
                        :o="table"
                        p="select"
                        v-on:change="table.change(`select`)"></checkbox>
            </th>
            <th>
              <span>Name</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `name`)"
                 v-on:click="table.sort(`name`)"></i>
            </th>
            <th>
              <span>Email</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `email`)"
                 v-on:click="table.sort(`email`)"></i>
            </th>
            <th>
              <span>Role</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `role`)"
                 v-on:click="table.sort(`role`)"></i>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items"
              :key="index">
            <td>
              <checkbox class="align-middle"
                        :o="o"
                        p="select"
                        title="Select signee"></checkbox>
            </td>
            <td>
              <span v-text="o.name"></span>
            </td>
            <td>
              <span v-text="o.email"></span>
            </td>
            <td>
              <span class="text-capitalize"
                    v-text="o.role"></span>
            </td>
            <td class="text-right">
              <i class="fas fa-long-arrow-alt-up mr-1"
                 :class="table.class(`up`, index)"
                 :title="table.title(`up`, index)"
                 v-on:click="table.click(`up`, index)"></i>
              <i class="fas fa-long-arrow-alt-down"
                 :class="table.class(`down`, index)"
                 :title="table.title(`down`, index)"
                 v-on:click="table.click(`down`, index)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </div>
</template>

<style scoped>
div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  max-width: 15em;
}

table th:nth-child(1) {
  width: 2em;
}

table th:nth-child(2) {
  width: 30%;
}

table th:nth-last-child(2) {
  width: 10em;
}

table th:nth-last-child(1) {
  width: 4em;
}

table th,
table td {
  vertical-align: middle;
}

i:not(.bill-to) {
  opacity: 0.3;
}

i.bill-to {
  opacity: 0.7;
}

i:not(.disable):hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}

span {
  font-size: 0.9em;
}
</style>

<script>
import { checkbox, scrollArea, search } from "../../../../../../../component";
import { List } from "../../../../../../../factory";
import { session } from "../../../../../../../service";

export default {
  get components() {
    return {
      checkbox,
      scrollArea,
      search
    };
  },
  props: {
    contract: {
      type: Object,
      required: true,
      validator: o =>
        Array.isArray(o.accounts) &&
        Array.isArray(o.exceptions) &&
        typeof o.data == `object` &&
        typeof o.state == `object`
    }
  },
  data() {
    return {
      table: ``
    };
  },
  watch: {
    /*
            'contract.signees': {
                handler() {
                    var select = this.contract.signees
                        .find(s => s.select)
                    if (this.contract.esign != select)
                        this.contract.esign = select
                },
                deep: true
            },
            */
    "contract.esign": {
      handler() {
        var { esign } = this.contract;
        this.table.select = esign;
        this.table.change(`select`);
      }
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { contract } = this;
      var table = {
        _init: (o, k, v) => this.$set(o, k, v),
        _signees() {
          var { firm, user } = session.get(`state`);
          var signees = contract.data.households.reduce((s, h) => {
            h.contacts.forEach(c => {
              var { email, firstName, lastName } = c;
              var role = `client`;
              var select = false;
              var group = h.name;
              var name = firstName + ' ' + lastName
              s.push({ email, group, name, role, select });
            });
            return s;
          }, []);
          return [
            ...signees,
            {
              group: firm.name,
              name: user.name,
              email: user.email,
              role: `firm`,
              select: false
            }
          ];
        },
        change(type) {
          switch (type) {
            case `select`:
              this.items.forEach(a => {
                a.select = this.select;
              });
              break;
          }
        },
        class(type, d) {
          switch (type) {
            case `up`:
              return d == 0 ? `disable` : ``;
            case `down`:
              return d < contract.signees.length - 1 ? `` : `disable`;
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        click(type, d) {
          switch (type) {
            case `up`:
              if (d > 0)
                contract.signees.splice(
                  d - 1,
                  0,
                  contract.signees.splice(d, 1)[0]
                );
              break;
            case `down`:
              if (d < contract.signees.length - 1)
                contract.signees.splice(
                  d + 1,
                  0,
                  contract.signees.splice(d, 1)[0]
                );
              break;
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type, d) {
          switch (type) {
            case `select`:
              return !contract.signees.find(o => o.select) && !contract.esign;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          if (!contract.state.hasOwnProperty(`signing`)) {
            this._init(contract.state, `signing`, {
              list: {
                search: ``,
                sort: { asc: ``, key: `` }
              }
            });
          }
          if (!contract.hasOwnProperty(`signees`))
            this._init(contract, `signees`, this._signees());
          this.select = contract.signees.find(o => o.select) ? true : false;
          return this;
        },
        get items() {
          var { search, sort } = this.list;
          return List.sort(
            List.filter(contract.signees, search),
            sort.key,
            sort.asc
          );
        },
        get list() {
          return contract.state.signing.list;
        },
        select: ``,
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        title(type, d) {
          switch (type) {
            case `billing`:
              return d
                ? `Remove billing assignment`
                : `Apply billing assignment`;
            case `down`:
              return d < contract.signees.length - 1 ? `Move down` : ``;
            case `household`:
              return `Toggle household filter`;
            case `select`:
              return `Select all / none`;
            case `up`:
              return d == 0 ? `` : `Move up`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        }
      };
      this.table = table.init();
    }
  }
};
</script>
