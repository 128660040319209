<template>
  <div id="insights-container">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="py-4 pl-5">
            <h3 class="display-5 fw-bold">Hi, {{ username }}! Let's have a look at revenue.</h3>
            <p class="lead text-muted">Keep informed and up to date with key billing metrics.</p>
          </div>
        </div>
      </div>
    </div>
    <div id="superset-container"></div>
  </div>
</template>

<script>
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { Api } from "../../../factory";
import { session } from "../../../service";

export default {
  name: "Insights",
  data() {
    return {
      username: ''
    };
  },
  mounted() {
    this.embedSuperset();
    this.setUsername();
  },
  methods: {
    setUsername() {
      const state = session.get('state');
      this.username = state.user.name || state.user.email;
    },
    async getURLAndGuestToken() {
      try {
        const response = await Api.get('firms/gtoken');
        return response.data;
      } catch (error) {
        console.error('Error fetching guest token:', error);
        return null;
      }
    },
    async embedSuperset() {
      var state = session.get(`state`);
      var { _id: firmId } = state.firm;
      const { token, url }  = await this.getURLAndGuestToken();
      embedDashboard({
        id: "f2bd8bbf-e315-48ac-b48c-4f8a0fd89c89",
        supersetDomain: url,
        mountPoint: document.getElementById("superset-container"),
        fetchGuestToken: () => token,
        dashboardUiConfig: {
          hideTitle: true,
          hideTab: false,
          hideChartControls: true,
          filters: {
            visible: false,
            expanded: false,
          },
          urlParams: { firmId },
          expandAll: true
        },
        iframeSandboxExtras: ['allow-top-navigation', 'allow-popups-to-escape-sandbox']
      });
    }
  }
};
</script>

<style scoped>
div#insights-container {
  background-color: #f7f7f7 !important;
}
</style>

<style>

iframe {
  width: 100%;
  height: 100vh;
  border: 0;
}
</style>