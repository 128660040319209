import { events } from "../service";

class Modal {
  static _emit(modal = {}) {
    events.$emit(`modal`, modal);
  }

  static hide() {
    Modal._emit();
  }

  static close() {
    return Modal.hide();
  }

  static open(component, data) {
    return Modal.show(component, data);
  }

  static show(component, data) {
    Modal._emit({ component, data });
  }
}

export default Modal;
