<template>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-user mr-2"></i>
          <span class="font-weight-bold" v-text="user.text(`title`)"></span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="p-3">
          <div class="form-row">
            <div class="col-3">
              <div class="form-group">
                <label>Id</label>
                <input
                  class="form-control form-control-sm"
                  :value="user.id"
                  readonly
                  placeholder="Auto"
                />
                <small class="form-text text-muted">User Id</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Auth Id</label>
                <input
                  v-model="user.authId"
                  class="form-control form-control-sm"
                  placeholder="Auto"
                  :readonly="user.readonly(`id`)"
                />
                <small class="form-text text-muted">User Id</small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-5">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="user.name"
                  class="form-control form-control-sm"
                  placeholder="Name"
                  :readonly="user.readonly(`name`)"
                />
                <small class="form-text text-muted">User Name</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Email</label>
                <input
                  v-model="user.email"
                  class="form-control form-control-sm"
                  type="email"
                  placeholder="Email"
                  :readonly="user.readonly(`email`)"
                />
                <small class="form-text text-muted">User Email</small>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-4">
              <div class="form-group">
                <label>Role</label>
                <select
                  v-model="user.role"
                  class="form-control form-control-sm"
                  :disabled="user.disabled(`role`)"
                  v-on:change="user.change(`role`)"
                >
                  <option v-for="(role, index) in user.roles" :key="index">
                    <span v-text="role"></span>
                  </option>
                </select>
                <small class="form-text text-muted">User Role</small>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Firm</label>
                <select
                  v-model="user.firm"
                  class="form-control form-control-sm"
                  :disabled="user.disabled(`firm`)"
                  v-on:change="user.change(`firm`)"
                >
                  <option v-for="(firm, index) in user.firms" :key="index">
                    <span v-text="firm.label"></span>
                  </option>
                </select>
                <small class="form-text text-muted">Firm Name</small>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <remove-button :button="user.remove"></remove-button>
          <button
            type="button"
            class="btn btn-sm btn-primary ml-2"
            :disabled="user.disabled(`save`)"
            v-on:click="user.click(`save`)"
          >
            <span class="button">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
button {
  width: 80px;
}
div.form-check {
  padding-top: 3px;
}
div.form-check span {
  font-size: 0.9rem;
  vertical-align: text-bottom;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
</style>

<script>
import { removeButton } from "../../../../component";
import { Api, lib, Modal } from "../../../../factory";
import { alert } from "../../../../service";

export default {
  get components() {
    return {
      removeButton
    };
  },
  props: ["data"],
  data() {
    return {
      user: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var user = {
        _data: ``,
        _label(type, d) {
          switch (type) {
            case `firm`:
              return `${d.id} - ${d.name}`;
            default:
              throw new Error(`Invalid label type, ${type}!`);
          }
        },
        async _load() {
          try {
            var { data: firms } = await Api.get(`firms`);
            this._data.firms = firms;
            var f = data.user.firmId
              ? firms.find(o => o._id == data.user.firmId)
              : firms.find(o => o.id == 1);
            this.firm = this._label(`firm`, f);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _keys: [`authId`, `id`, `name`, `email`, `firmId`, `role`],
        _role(role, submit = false) {
          var r = role || this._roles[1];
          return submit ? r.toLowerCase() : lib.string.capitalize(r);
        },
        _roles: [`admin`, `user` /*`demo`*/],
        async _remove() {
          var { methods } = data;
          try {
            var r = await Api.delete(`users/${data.user._id}`);
            if (methods && typeof methods.success == `function`)
              return methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        async _save() {
          var user = this._keys.reduce((o, key) => {
            switch (key) {
              case `authId`:
              case `id`:
                break;
              case `role`:
                o[key] = this._role(this[key], true);
                break;
              default:
                o[key] = this[key];
            }
            return o;
          }, {});
          var { methods } = data;
          try {
            var r = data.user._id
              ? await Api.put(`users/${data.user._id}`, user)
              : await Api.post(`users`, user);
            if (methods && typeof methods.success == `function`)
              return methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        change(type) {
          switch (type) {
            case `firm`:
              var f = this.firms.find(f => f.label == this.firm);
              this.firmId = f._id;
              break;
            case `role`:
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        click(type) {
          switch (type) {
            case `save`:
              return this._save();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type) {
          switch (type) {
            case `remove`:
              return (
                !(data.user && data.user?.hasOwnProperty(`_id`)) ||
                this.name?.toLowerCase() == `admin`
              );
            case `firm`:
              return (
                this.role?.toLowerCase() == `admin` || this.firms.length < 1
              );
            case `save`:
              return (
                !this.firm ||
                this.name?.toLowerCase() == `admin` ||
                (this.role?.toLowerCase() == `user` && this.firm.length < 1) ||
                this.name.length < 1 ||
                this.email.length < 1
              );
            case `role`:
              return this.name && this.name.toLowerCase() == `admin`;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        firm: ``,
        get firms() {
          return Array.from(this._data.firms)
            .map(f => {
              var label = this._label(`firm`, f);
              return { label, ...f };
            })
            .sort((a, b) => a.label.localeCompare(b.label));
        },
        init() {
          this._data = { firms: `` };
          this._keys.forEach(key => {
            this[key] =
              key == `role` ? this._role(data.user[key]) : data.user[key];
          });
          this.remove = {
            click: () => {
              this._remove();
            },
            disabled: this.disabled(`remove`)
          };
          this._load();
          return this;
        },
        readonly(type) {
          switch (type) {
            case `email`:
            case `name`:
              return this.name?.toLowerCase() == `admin`;
            case `id`:
              return true;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        remove: ``,
        get roles() {
          return this._roles.map(r => lib.string.capitalize(r));
        },
        text(type) {
          switch (type) {
            case `title`:
              return this.name ? `User - ${this.name}` : `User`;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        }
      };
      this.user = user.init();
    }
  }
};
</script>
