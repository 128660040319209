
<div class="modal-dialog modal-dialog-centered modal-lg"
     role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-file-contract mr-2"></i>
        <span class="font-weight-bold">Sign Template - New</span>
      </p>
      <button type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <mark v-if="!_firm.signTemplatesLimit">You reached the limit on the number of templates to create. Please contact sales.</mark>
      <div class="p-3">
        <div class="form-row">
          <div class="col-6">
            <div class="form-group">
              <input v-model="name"
                     class="form-control form-control-sm"
                     placeholder="Name" />
              <small class="form-text text-muted">Template Name</small>
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
                <label for="files" class="btn btn-sm btn-outline-secondary">Add Template File</label>
                <input
                  style="display: none;"
                  id="files"
                  type="file"
                  ref="file"
                  accept="application/pdf"
                  @change="handleFilesChange()"
                />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
                <select class="custom-select custom-select-sm"
                :disabled="!options.length"
                v-model="selectedKind">
                  <option selected disabled>Select an option</option>
                   <option
                     v-for="option in options"
                     :value="option.value"
                     :key="option.value" >
                   {{ option.name }}
                 </option>
             </select>
             <small class="form-text text-muted">Kind</small>
            </div>
          </div>
        </div>
        <div class="form-row" v-if="selectedFile">
          <div class="col">
            <hr />
            <div class="list-group list-group-flush">
              <div class="list-group-item list-group-item-action">
                <i class="far fa-file-pdf mr-1"></i>
                {{ selectedFile.name }}
                <IconButton icon="far fa-trash-alt float-right" @click="handleFileRemoval" />
              </div>
            </div>
          </div>
       </div>
      </div>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button type="button" data-dismiss="modal"
           aria-label="Prepare"
           class="btn btn-sm btn-primary ml-2"
           :disabled="!name || !selectedFile || !selectedKind"
           @click="handleFilePrepare">Prepare
        </button>
      </div>
    </div>
  </div>
</div>
