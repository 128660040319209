
<div class="card">
  <div class="card-header px-3 py-2" id="headingThree">
    <div class="card-header-title">
      <button class="btn btn-block text-left" type="button" 
      data-toggle="collapse" data-target="#collapseThree" 
      aria-expanded="false" aria-controls="collapseThree">
        <span class="font-weight-bold">Templates</span>
        <i class="fa fa-chevron-up ml-1"></i>
      </button> 
    </div>
    <button type="button"
      class="btn btn-sm btn-outline-secondary new-client"
      :title="title(`add`)"
      v-on:click="click(`add`)" >
      <i class="fas fa-plus-square mr-2"></i>
      <span>Add</span>
    </button>
  </div>
  <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionMenu">
    <div class="card-body pt-3">
      <div class="templates">
        <scroll-area class="pr-3">
          <table class="table table-sm">
            <tbody>
              <tr v-for="(o, i) in templates" :key="i" class="template">
                <td>
                    <i
                    class="fas fa-trash mr-1"
                    :title="title(`delete`)"
                    v-on:click="click(`delete`, o)"
                  ></i>
                  <schedule-badge
                    style="cursor: pointer;"
                    :schedule="o.name"
                    v-on:click="click(`edit`, o)"
                  ></schedule-badge>
                </td>
              </tr>
            </tbody>
          </table>
        </scroll-area>
      </div>
    </div>
  </div>
</div>
