
<div class="h-100">
  <div class="d-flex p-1 pb-2 header">
    <div class="d-flex actions mr-2">
      <div v-if="householdSignatureData" title="Remind Signees" class="btn btn-outline-secondary action-button"
        @click="sendReminder(householdSignatureData)">
        <span>Remind {{ householdSignatureData.contacts.map(c => c.firstName).join(' and ') }} for agreement</span>
      </div>
      <div v-if="contractSignatureData" title="Remind Signees" class="btn btn-outline-secondary action-button ml-1"
        @click="sendReminder(contractSignatureData)">
        <span>Remind {{ contractSignatureData.contacts.map(c => c.firstName).join(' and ') }} for contract</span>
      </div>
    </div>
  </div>
  <template v-if="ready">
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Contract Id</span>
            </th>
            <th>
              <span>Date</span>
            </th>
            <th>
              <span>Description</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(audit, _i) in householdAudits" :key="audit._id">
            <td>
              <a v-if="audit.ref" class="badge badge-custom" title="View contract" v-text="audit.id"></a>
              <span v-else></span>
            </td>
            <td>
              <span v-text="audit.date"></span>
            </td>
            <td>
              <span v-text="audit.item"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </template>
  <template v-else>
    <loading></loading>
  </template>
</div>
