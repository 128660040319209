<template>
  <div class="h-100">
    <div class="d-flex p-1 pb-2 header">
      <div class="d-flex actions mr-2">
        <div v-if="householdSignatureData" title="Remind Signees" class="btn btn-outline-secondary action-button"
          @click="sendReminder(householdSignatureData)">
          <span>Remind {{ householdSignatureData.contacts.map(c => c.firstName).join(' and ') }} for agreement</span>
        </div>
        <div v-if="contractSignatureData" title="Remind Signees" class="btn btn-outline-secondary action-button ml-1"
          @click="sendReminder(contractSignatureData)">
          <span>Remind {{ contractSignatureData.contacts.map(c => c.firstName).join(' and ') }} for contract</span>
        </div>
      </div>
    </div>
    <template v-if="ready">
      <scroll-area class="pr-3">
        <table class="table table-sm">
          <thead>
            <tr>
              <th>
                <span>Contract Id</span>
              </th>
              <th>
                <span>Date</span>
              </th>
              <th>
                <span>Description</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(audit, _i) in householdAudits" :key="audit._id">
              <td>
                <a v-if="audit.ref" class="badge badge-custom" title="View contract" v-text="audit.id"></a>
                <span v-else></span>
              </td>
              <td>
                <span v-text="audit.date"></span>
              </td>
              <td>
                <span v-text="audit.item"></span>
              </td>
            </tr>
          </tbody>
        </table>
      </scroll-area>
    </template>
    <template v-else>
      <loading></loading>
    </template>
  </div>
</template>

<style scoped>
span {
  font-size: 0.9em;
}

span.link {
  cursor: pointer;
  text-decoration: underline;
}
</style>

<script>
import moment from "moment";
import { loading, scrollArea } from "../../../../component";
import { Api, Record } from "../../../../factory";
import { alert, session } from "../../../../service";

export default {
  get components() {
    return {
      scrollArea,
      loading,
    };
  },
  data() {
    return {
      ready: false,
      householdAudits: [],
      householdSignatureData: null, 
      contractSignatureData: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      var state = session.get(`state`);
      await this.getHouseholdAudits(state.household._id);
      this.getClientContractStatus(state.household._id);
      this.ready = true;
    },
    async getHouseholdAudits(householdId) {
      const audits = (await Api.get(`audits?h_id=${householdId}`)).data;
      this.householdAudits = audits.map(audit => {
        const id = Record.id({ id: audit.ref })
        return { ...audit, id, date: moment(audit.date).format(`MMM D, YYYY h:mm A`) }})
    },
    //add a button to query the status of the client agreement
    async getClientContractStatus(householdId) {
      const { householdSignatureData, contractSignatureData } = (await Api.get(`households/status?h_id=${householdId}`)).data;

      this.householdSignatureData = householdSignatureData
      this.contractSignatureData = contractSignatureData

    },
    async sendReminder(data) {
      const payload = {
        signatureRequestId: data.signingRequestId,
        signees: data.contacts
      }
      try {
        await Api.post(`/templates/remind`, payload);
        alert.message("Signature reminder was sent.", 1500);
      } catch (error) {
        console.error(error);
        alert.error(error, 3000);
      }
    }
  },
};
</script>
