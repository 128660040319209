<template>
  <Title>
    <slot>{{ title }}</slot>
  </Title>
</template>


<script>
import Title from "../../../component/title.vue";

export default {
  props: {
    title: "",
  },
  components: { Title }
};
</script>
