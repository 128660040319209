<template>
  <div class="root">
    <div>
      <div class="card">
        <div class="card-body">
          <div>
            <img :src="login.logo" />
            <form @submit="login.checkForm">
              <template v-if="login.state == 2">
                <div class="form-group">
                  <label>Name</label>
                  <input v-model="login.name"
                         class="form-control"
                         placeholder="Name" />
                </div>
              </template>
              <div class="form-group">
                <label>Email</label>
                <input v-model="login.email"
                       class="form-control"
                       data-cy="login-email"
                       placeholder="Email" />
              </div>
              <template v-if="!login.state">
                <div class="form-group">
                  <label>Password</label>
                  <input v-model="login.pass"
                         type="password"
                         data-cy="login-password"
                         class="form-control"
                         placeholder="Password" />
                </div>
              </template>
              <div class="form-group mb-0">
                <hr style="opacity: 0.4" />
                <button data-cy="login-submit"
                        type="submit"
                        class="btn btn-block btn-custom"
                        :class="login.class(`submit`)"
                        :disabled="login.disabled(`submit`)"
                        v-on:click="login.click()">
                  <span v-text="login.text(`submit`)"></span>
                </button>
              </div>
              <template v-if="!login.state">
                <div class="form-row links">
                  <div class="col">
                    <p class="mb-0 text-right">
                      <a href="#"
                         class="text-right"
                         v-on:click="login.click(`forgot`)"
                         v-text="login.text(`forgot`)"></a>
                    </p>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="form-row links">
                  <div class="col">
                    <p class="mb-0 text-right">
                      <a href="#"
                         v-on:click="login.click(`cancel`)"
                         v-text="login.text(`cancel`)"></a>
                    </p>
                  </div>
                </div>
              </template>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
div.links a {
  margin-top: -15px;
  font-size: 12px;
}

div.root {
  background-color: #215B9480;
  width: 100%;
  height: 100vh;
}

div.root>div {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 400px;
  margin-left: -300px;
  margin-top: -250px;
}

div.card {
  width: 600px;
  margin: auto;
}

div.card-body {
  padding: 30px;
  background: white;
}

div.card-body form {
  margin-top: 25px;
}

img {
  max-width: 100%;
}
</style>

<script>
import { Api } from "../factory";
import { validator } from "../npm";
import { alert, auth, session } from "../service";

// import logo from "../../../img/logo-skx-kw.png";
import logo from "../../../img/logo-redesigned.png";

export default {
  data() {
    return {
      login: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var login = {
        checkForm(e) {
          e.preventDefault();
          this.click()
        },
        async _auth() {
          try {
            this._pending = true;
            await auth.login(this.email, this.pass);
          } catch (e) {
            console.error('Invalid email or password');
            await alert.error(e.message, this._wait);
            this.reset();
          } finally {
            this._pending = false;
          }
        },
        _pending: ``,
        async _reset() {
          try {
            this._pending = true;
            var { email } = this;
            var resp = await Api.put(`users?_action=reset`, { email });
            await alert.message(resp.data, this._wait);
            this.reset();
          } catch (e) {
            console.error(e);
            await alert.error(e.message);
            this.reset();
          } finally {
            this._pending = false;
          }
        },
        _wait: ``,
        class(type) {
          switch (type) {
            case `submit`:
              return `btn-primary`;
            default:
              throw new Error(`Invalid login class type, ${type}!`);
          }
        },
        click(type) {
          switch (type) {
            case `forgot`:
              this.state =  1;
              break;
            case `cancel`:
              return (this.state = 0);
            default:
              switch (this.state) {
                case 0:
                  return this._auth();
                case 1:
                  return this._reset();
                default:
                  throw new Error(`Invalid login click state, ${this.state}!`);
              }
          }
        },
        text(type) {
          switch (type) {
            case `submit`:
              switch (this.state) {
                case 0:
                  return `Sign in`;
                case 1:
                  return `Reset password`;
                case 2:
                  return `Register`;
                default:
                  throw new Error(`Invalid login state, ${this.state}!`);
              }
            case `forgot`:
              return `Forgot password`;
            case `cancel`:
              return `Cancel`;
            default:
              throw new Error(`Invalid login text type, ${type}!`);
          }
        },
        disabled(type) {
          switch (type) {
            case `submit`:
              switch (this.state) {
                case 0:
                  return !this._pending &&
                    validator.isEmail(this.email) &&
                    this.pass.length > 3
                    ? false
                    : true;
                case 1:
                  return !this._pending && validator.isEmail(this.email)
                    ? false
                    : true;
                case 2:
                  return !this._pending &&
                    this.name.length > 4 &&
                    validator.isEmail(this.email)
                    ? false
                    : true;
                default:
                  throw new Error(`Invalid login state, ${this.state}!`);
              }
            default:
              throw new Error(`Invalid login disabled type, ${type}!`);
          }
        },
        init() {
          this._pending = ``;
          this._wait = 2e3;
          this.email = this.email ? this.email : ``;
          this.pass = this.pass ? this.pass : ``;
          this.name = ``;
          this.state = 0;
          return this;
        },
        get logo() {
          return logo;
        },
        reset() {
          this.init();
        },
        state: ``,
      };
      this.login = login.init();
    },
  },
};
</script>
