<template>
    <div class="card">
      <div class="card-header px-3 py-2" id="headingThree">
        <div class="card-header-title">
          <button class="btn btn-block text-left" type="button" 
          data-toggle="collapse" data-target="#collapseThree" 
          aria-expanded="false" aria-controls="collapseThree">
            <span class="font-weight-bold">Templates</span>
            <i class="fa fa-chevron-up ml-1"></i>
          </button> 
        </div>
        <button type="button"
          class="btn btn-sm btn-outline-secondary new-client"
          :title="title(`add`)"
          v-on:click="click(`add`)" >
          <i class="fas fa-plus-square mr-2"></i>
          <span>Add</span>
        </button>
      </div>
      <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionMenu">
        <div class="card-body pt-3">
          <div class="templates">
            <scroll-area class="pr-3">
              <table class="table table-sm">
                <tbody>
                  <tr v-for="(o, i) in templates" :key="i" class="template">
                    <td>
                        <i
                        class="fas fa-trash mr-1"
                        :title="title(`delete`)"
                        v-on:click="click(`delete`, o)"
                      ></i>
                      <schedule-badge
                        style="cursor: pointer;"
                        :schedule="o.name"
                        v-on:click="click(`edit`, o)"
                      ></schedule-badge>
                    </td>
                  </tr>
                </tbody>
              </table>
            </scroll-area>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  .new-client {
    float: right;
    font-size: 0.8rem;
  }
  div.card-header {
    display: flex;
    justify-content: space-between;
  }
  div.card-header-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  div.card-header i,
  div.card-header span {
    opacity: 0.7;
  }
  div.templates {
    height: 50vh;
    margin-top: 6px;
    margin-right: -15px;
    margin-bottom: -10px;
  }
  div.action {
    margin-right: 1em;
    cursor: pointer;
    font-size: 0.75rem;
  }
  div.action:hover i {
    opacity: 1;
  }
  div.action i {
    vertical-align: bottom;
    opacity: 0.3;
    padding-bottom: 2px;
  }
  tr.template {
    font-size: 0.9rem;
  }
  i {
    opacity: 0.3;
  }
  table i:hover {
    cursor: pointer;
    opacity: 1;
  }
  span.badge {
    font-weight: normal;
    font-size: 0.8em;
    cursor: pointer;
  }
  </style>
  
  <script>
  import { scrollArea } from "../../../component";
  import { alert, session } from "../../../service";
  import { Api, List, Modal } from "../../../factory";
  import scheduleBadge from "../../../component/schedule/schedule-badge.vue";
  import HelloSign from 'hellosign-embedded';
  
  export default {
    name: `Signtemplates`,
    get components() {
      return {
        scrollArea,
        scheduleBadge,
        session
      };
    },
    data() {
      return {
        signClient: null,
        templates: null
      };
    },
    created() {
      this._load();

      this.signClient = new HelloSign({
        clientId: '854d48422307ce41fab025e43530d611'
      });
      this.signClient.on('error', (data) => {
        console.log('Sign error', data);
      })
      this.signClient.on('message', (data) => {
        console.log('Sign message', data);
        if (data.type == 'hellosign:userFinishRequest') {
          alert.message('Template was saved.')
        }
      })

    },
    methods: {
      async _load() {
        try {
          var { data } = await Api.get(`templates`);
          // delay for a bit until dropbox registers the created template change
          setTimeout(() => { 
            this.templates = data ;
            session.set(`FirmTemplates`, this.templates)
          }, 2300); 
        } catch (e) {
          console.error(e);
          alert.error("Couldn't load the templates");
        }
      },
      async click(type, d) {

        const state = session.get(`state`)
        var firm = (await Api.get(`firms/${state.firm._id}`)).data
        if (firm) state.firm = firm  // update firm in state

        switch (type) {
          case `add`:
            return Modal.open(`sign-template`, {
              templates: this.templates,
              firm: firm,
              methods: {
                success: () => {
                  this._load();
                  Modal.close();
                },
              },
            });
            break;
          case `delete`:
            var result = confirm(`Are you sure you want to delete the template "${d.name}"?`);
            if (!result) return
            console.log('deleting template => ', d)
            try {
              var r = await Api.delete(`templates/${d.id}`);
              alert.message(`Template deleted!`, 1500);
              this._load()
            } catch (e) {
              console.error(e);
              alert.error("Couldn't delete the template");
            }
            break;
          case `edit`:
            // open edit template modal
            try {
              var { data } = await Api.put(`templates/${d.id}`);
              if (data.editUrl)
                this.signClient.open(data.editUrl, { skipDomainVerification: true });
            } catch (e) {
              console.error(e);
              alert.error("Couldn't pull the template draft");
            }
            break;
          default:
            throw new Error(`Invalid click type, ${type}!`);
        }
      },
      text(type, d) {
        switch (type) {
          case `name`:
            return d.default ? `${d.name} (*)` : d.name;
          default:
            throw new Error(`Invalid text type, ${type}!`);
        }
      },
      title(type) {
        switch (type) {
          case `add`:
            return `Add template`;
          case `edit`:
            return `Edit template`;
          case `import`:
            return `Import templates`;
          case `delete`:
            return `Delete template`;
          default:
            throw new Error(`Invalid title type, ${type}!`);
        }
      }
    }
  }
  </script>
  