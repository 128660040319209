
<div class="input-group">
  <input
    :id="id"
    ref="date"
    class="form-control form-control-sm"
    type="text"
    readonly
    placholder="Date"
  />
  <div class="input-group-append">
    <i class="input-group-text far fa-calendar-alt" v-on:click="focus"></i>
  </div>
</div>
