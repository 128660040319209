
<nav class="navbar navbar-expand-md">
  <a class="navbar-brand" href="#">
    <img class="mx-2" :src="nav.logo" />
  </a>
  <ul class="navbar-nav mr-auto">
    <li v-for="v in nav.views" :key="v.name" class="nav-item">
      <button
        type="button"
        class="btn mr-2 rounded-0"
        :class="nav.class(`active`, v)"
        :title="nav.title(`view`, v)"
        v-on:click="nav.click(`view`, v)"
      >
        <i :class="v.icon" class="ml-1"></i>
        <span class="ml-1">{{ v.name == 'Dash' ? 'Insights' : v.name }}</span>
      </button>
    </li>
  </ul>
  <!-- <div class="dropdown">
    <button
      type="button"
      class="btn mr-2"
      data-toggle="dropdown"
      v-on:click="nav.click(`alerts`)"
    >
      <template v-if="nav.alerts">
        <i class="fas fa-circle alert small"></i>
      </template>
      <i class="far fa-bell"></i>
    </button>
    <div
      ref="alerts"
      class="dropdown-menu dropdown-menu-right"
      v-on:mouseleave="nav.mouse(`leave`, `alerts`)"
    >
      <template v-if="nav.alerts">
        <a
          v-for="(a, i) in nav.alerts"
          :key="i"
          class="dropdown-item"
          v-on:click="nav.click(`alerts`, i)"
        >
          <span v-text="a.message"></span>
        </a>
      </template>
      <template v-else>
        <a class="dropdown-item">
          <span>No new notifications found</span>
        </a>
      </template>
    </div>
  </div>-->
  <div class="dropdown mr-2">
    <button type="button" class="btn py-0 my-1" data-toggle="dropdown">
      <div class="d-flex">
        <div class="m-auto">
          <i class="far fa-user-circle"></i>
        </div>
        <div class="ml-3">
          <span class="d-block text-left" v-text="nav.user"></span>
          <span class="small text-left align-top" v-text="nav.firm"></span>
        </div>
        <div class="m-auto">
          <i class="fas fa-angle-down small ml-2"></i>
        </div>
      </div>
    </button>
    <div ref="user" class="dropdown-menu w-100" v-on:mouseleave="nav.mouse(`leave`, `user`)">
      <template v-if="!nav.admin">
        <a class="dropdown-item" v-on:click="nav.click(`files`)">
          <i class="fas fa-file mr-3"></i>
          <span>Files</span>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" v-on:click="nav.click(`emailTemplates`)">
          <i class="fas fa-paper-plane mr-3"></i>
          <span>Email Templates</span>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" v-on:click="nav.click(`settings`)">
          <i class="fas fa-cog mr-3"></i>
          <span>Settings</span>
        </a>
        <div class="dropdown-divider"></div>
      </template>
      <a class="dropdown-item" v-on:click="nav.click(`logout`)">
        <i class="fas fa-sign-out-alt mr-3"></i>
        <span>Logout</span>
      </a>
    </div>
  </div>
  <button type="button" class="btn" v-on:click="nav.click(`help`)">
    <i class="far fa-question-circle"></i>
  </button>
</nav>
