
class Contract {
  static exceptions(contract) {
    return contract.exceptions.filter(
      o => o.type.toLowerCase() == `cash` || o.ticker.symbol
    );
  }

  static signees(contract) {
    return contract.signees.filter(o => o.select);
  }
}

export default Contract;
