<template>
  <button
    type="button"
    class="btn btn-sm"
    :class="remove.class"
    :disabled="remove.disabled"
    v-on:click="remove.click()"
    v-on:blur="remove.blur()"
  >
    <span v-text="remove.text"></span>
  </button>
</template>

<style scoped>
button {
  width: 80px;
}
</style>

<script>
export default {
  props: {
    button: {
      type: Object,
      validator: o => typeof o.click == `function`
    }
  },
  data() {
    return {
      remove: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { button } = this;
      var remove = {
        _click: ``,
        blur() {
          this.init();
        },
        get class() {
          return this._click ? `btn-danger` : `btn-outline-danger`;
        },
        click() {
          if (!this._click) return this._click++;
          button.click();
        },
        get disabled() {
          return Boolean(button.disabled);
        },
        init() {
          this._click = 0;
          return this;
        },
        get text() {
          return this._click ? `Confirm` : button.text || `Remove`;
        }
      };
      this.remove = remove.init();
    }
  }
};
</script>
