<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header">
      <div class="d-flex actions">
        <div title="Import selected account(s)"
             :class="[
               `btn`,
               table.items.some((item) => item.missingOnDB)
                 ? `btn-outline-primary`
                 : `btn-outline-secondary`,
             ]"
             v-on:click="table.click(`add`, `accounts`)">
          <i class="fas mr-1 fa-plus-square"></i>
          <span>Import selected</span>
        </div>
        <div  v-if="table.isNCA"
             title="Add new NCA account"
             class="btn btn-outline-secondary"
             v-on:click="table.addNCAAccount()">
          <i class="fas mr-1 fa-plus-square"></i>
          <span>Add account</span>
        </div>
        <div  v-if="table.isNCA"
             title="Add new NCA account"
             class="btn btn-outline-secondary"
             v-on:click="table.updateNCAAccount()">
          <i class="fas mr-1 fa-plus-square"></i>
          <span>Update account values</span>
        </div>
        <div title="Create Household"
             class="btn btn-outline-secondary"
             v-on:click="table.click(`create`, `household`)">
          <i class="fas mr-1 fa-house-user"></i>
          <span>Create Household</span>
        </div>
      </div>
      <div class="d-flex filters">
        <div title="Filter: No Household"
             class="btn"
             :class="table.class(`div-filter`, `household`)"
             v-on:click="table.click(`filter`, `household`)">
          <i class="fas fa-filter"
             :class="table.class(`filter`, `household`)"></i>
          <span class="ml-1">No Household</span>
        </div>
        <div title="Filter: No Contract"
             class="btn"
             :class="table.class(`div-filter`, `contract`)"
             v-on:click="table.click(`filter`, `contract`)">
          <i class="fas fa-filter"
             :class="table.class(`filter`, `contract`)"></i>
          <span class="ml-1">No Contract</span>
        </div>
      </div>
      <div class="flex-grow-1 text-right">
        <span v-text="table.records"></span>
      </div>
    </div>
    <template v-if="ready">
    <scroll-area class="pr-3">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <checkbox :o="table"
                        p="select"
                        :disabled="table.disabled(`select-all`)"
                        v-on:change="table.change(`select`)"></checkbox>
            </th>
            <th>
              <span>Number</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `number`)"
                 v-on:click="table.sort(`number`)"></i>
            </th>
            <th>
              <span>Name</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `name`)"
                 v-on:click="table.sort(`name`)"></i>
            </th>
            <th>
              <span>Household</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `household`)"
                 v-on:click="table.sort(`household`)"></i>
            </th>
            <th>
              <span>Contract</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `contract`)"
                 v-on:click="table.sort(`contract`)"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items"
              :key="index">
            <td>
              <i title="This account is missing on the most recent custodian data."
                 v-if="o.missingOnCustodian"
                 class="fas fa-exclamation-triangle missing-account-icon"></i>
              <checkbox v-else
                        :o="o"
                        p="select"
                        :disabled="table.disabled(`select`, o)"></checkbox>
            </td>
            <td>
              <a href="#" class="badge badge-custom"
                    :title="table.title(`account`)"
                    v-on:click="table.open(`account`, o)"
                    v-text="o.number"></a>
            </td>
            <td>
              <span v-text="o.name"></span>
            </td>
            <td>
              <template v-if="o.household != table.NONE">
                <a href="#" class="badge badge-custom"
                      :title="table.title(`household`)"
                      v-on:click="table.open(`household`, o)"
                      v-text="o.household"></a>
              </template>
              <template v-else>
                <span v-text="o.household"></span>
              </template>
            </td>
            <td>
              <template v-if="o.contract != table.NONE">
                <a href="#" class="badge badge-custom"
                      :title="table.title(`contract`)"
                      v-on:click="table.open(`contract`, o)"
                      v-text="o.contract"></a>
              </template>
              <template v-else>
                <span v-text="o.contract"></span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
    </template>
    <template v-else>
      <loading></loading>
    </template>
  </div>
</template>

<style scoped>
.missing-account-icon {
  color: var(--warning);
  fill: black;
  opacity: 1;
}

div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}

div.header div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}

div.actions div:hover i,
div.filters i:hover {
  opacity: 1;
}

div.actions {
  border-right: 1px solid gray;
}

div.filters div {
  padding-left: 1em;
}

table span {
  font-size: 0.9rem;
}

span.link {
  cursor: pointer;
  text-decoration: underline;
}

table th:nth-child(2) {
  width: 7em;
}

table td:nth-child(2) {
  white-space: nowrap;
}

table th:nth-last-child(2) {
  width: 22%;
}

table th:nth-last-child(1) {
  width: 7em;
}

table th:first-child {
  width: 1em;
}

td {
  vertical-align: inherit;
}

i {
  opacity: 0.3;
}

i:not(.fa-filter):hover,
i.sort,
i.fa-spin,
i.remove,
i.filter {
  cursor: pointer;
  opacity: 1;
}

i.remove {
  color: red;
}
</style>

<script>
import { checkbox, scrollArea, search, loading } from "../../../../component";
import { Api, Component, lib, List, Modal, Record } from "../../../../factory";
import { numeral } from "../../../../npm";
import { alert, events, session } from "../../../../service";

export default {
  name: `FirmMasterAccounts`,
  get components() {
    return {
      checkbox,
      scrollArea,
      search,
      loading
    };
  },
  data() {
    return {
      table: ``,
      ready: false
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var vue = this;
      var state = session.get(`state`);
      var table = {
        _data: ``,
        _items: ``,
        custodian: ``,
        async _load() {
          try {
            var { custodian } = state;
            this.custodian = custodian;
            var [
              { data: accounts },
              { data: contracts },
              { data: households },
            ] = await Promise.all(
              [
                `accounts` + (custodian._id != '*' ? `?custodianId=${custodian._id}` : `` ),
                `contracts`,
                `households?_sort=name`,
              ].map((p) => Api.get(p))
            );

            const accountContractMap = new Map();
            for (const contract of contracts) {
              if (contract.status != 2) continue; // only consider active contracts 
              contract.accounts.forEach((acc) => {
                accountContractMap.set(acc.number, contract);
              });
            }

            this._items = accounts
              .sort((a, b) => a.number.localeCompare(b.number))
              .map((a) => {
                let contract = accountContractMap.get(a.number);

                if (contract) {
                  a.contractId = contract._id;
                  a.contract = Record.id(contract);
                } else {
                  a.contract = this.NONE;
                }
                a.household = a.householdId
                  ? households.find((o) => o._id == a.householdId)?.name
                  : this.NONE;
                return a;
              });

            this._data.contracts = contracts;
            this._data.households = households;
            var r;
            const custodianType = custodian?.type?.toLowerCase();
            switch (custodianType) {
              case `td`:
              case `ipx`:
              case `warren averett`:
              case `schwab`:
              // case undefined: // all custodian, need to see if Lacey wants this
                try {
                  r = await Api.get(
                    `/custodian-accounts?custodianType=${custodianType}&master=${custodian.id}`
                  );

                  if (r?.data?.accountsNotOnDB?.length) {
                    var sAccounts = r.data.accountsNotOnDB
                      .filter(
                        (a) => !this._items.find((o) => a.number == o.number)
                      )
                      .map((a) => {
                        var { master, number } = a;
                        var name = a.name
                          .split(` `)
                          .map((s) => {
                            switch (s.toUpperCase()) {
                              case `SEP-IRA`:
                              case `IRA`:
                              case `DTD`:
                              case `U/A`:
                                return s;
                              default:
                                return s.includes(`/`) && s.length >= 8
                                  ? ``
                                  : lib.string.capitalize(s);
                            }
                          })
                          .join(` `);
                        var select = ``;
                        var contract = this.NONE;
                        var household = this.NONE;
                        return {
                          contract,
                          household,
                          master,
                          name,
                          number,
                          select,
                          missingOnDB: true,
                          custodianId: custodian._id
                        };
                      });
                    this._items.push(...sAccounts);
                  }

                  if (r?.data?.accountsNotOnCustodian?.length) {
                    const accountsNotOnCustodian = new Set(
                      r.data.accountsNotOnCustodian.map((acc) => acc.number)
                    );

                    this._items.forEach((account, index) => {
                      if (accountsNotOnCustodian.has(account.number)) {
                        account.missingOnCustodian = true;
                        vue.$set(table._items, index, account);
                      }
                    });
                  }
                } catch (error) {
                  console.error(error);
                }

                break;
              case `non-custodial assets`:
                break;
              // default:
              //   throw new Error(`Invalid custodian type, ${custodian?.type}!`);
            }
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
          vue.ready = true
        },
        _records: ``,
        _reload() {
          events.$emit(`nav`, `accounts`);
        },
        change(type) {
          switch (type) {
            case `select`:
              this.items
                .filter((o) => !o.hasOwnProperty(`_id`))
                .forEach((o) => (o.select = this.select));
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          var { filter, sort } = this.list;
          switch (type) {
            case `filter`:
              return filter[d] ? `filter` : ``;
            case `div-filter`:
              return filter[d] ? `btn-primary` : `btn-outline-secondary`;
            case `sort`:
              return sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        addNCAAccount() {
          return Modal.open(`NCA-account-add`, {
            custodian: state.custodian,
            methods: {
              success: () => {
                Modal.close();
                this._reload();
              },
            },
          });
        },
        updateNCAAccount() {
          return Modal.open(`NCA-account-update`, {
            custodian: state.custodian,
            accounts: this._items.map(a => a.number),
            methods: {
              success: () => {
                Modal.close();
                this._reload();
              },
            },
          });
        },

        async click(type, d) {
          switch (type) {
            case `add`:
              var accounts = this.items.filter((o) => o.select);
              if (!accounts.length)
                return alert.warning(
                  `Sorry, no account(s) have been selected!`,
                  3e3
                );
              var { data: households } = await Api.get(`households?_sort=name`);
              this._data.households = households;

              return Modal.open(`custodian-accounts`, {
                accounts,
                custodian: state.custodian,
                households,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  },
                },
              });
            case `create`:
              return Modal.open(`household`);
            case `filter`:
              var { list } = this;
              list.filter[d] = !list.filter[d];
              break;
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        display(type, d) {
          switch (type) {
            case `value`:
              return d.value ? numeral(d.value).format(`$0,0.00`) : this.NONE;
            default:
              throw new Error(`Invalid display type, ${type}!`);
          }
        },
        disabled(type, d) {
          switch (type) {
            case `select-all`:
              return this.items.every((item) => item.hasOwnProperty(`_id`));
            case `select`:
              return d.hasOwnProperty(`_id`);
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init(c) {
          this.state = Component.state(c, {
            list: {
              filter: { contract: ``, household: `` },
              search: ``,
              sort: { asc: ``, key: `` },
            },
          });
          this._data = { contracts: [], households: [] };
          this.NONE = `-`;
          this._load();
          return this;
        },
        get isNCA() {
          return this.custodian?.type?.toLowerCase() == `non-custodial assets`
        },
        get items() {
          var { filter, search, sort } = this.list;

          return List.sort(
            List.filter(Array.from(this._items), search),
            sort.key,
            sort.asc
          ).filter(
            (o) =>
              (!filter.contract || o.contract == this.NONE) &&
              (!filter.household || o.household == this.NONE)
          );
        },
        get list() {
          return this.state.list;
        },
        NONE: ``,
        async open(type, d) {
          // console.log("type", type);
          // console.log("d", d);
          switch (type) {
            case `account`:
              var { NONE } = this;
              return Modal.open(`account`, {
                account: d,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  },
                },
                NONE,
                isListedUnderCustodian: true,
              });
            case `contract`:
              var contract = this._data.contracts.find(
                (o) => o._id == d.contractId
              );
              return Modal.open(`contract`, {
                contract,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  },
                },
              });
            case `household`:
              var household = this._data.households.find(
                (o) => d.household == o.name
              );
              return Modal.open(`household`, {
                household,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  },
                },
              });
            default:
              throw new Error(`Invalid open type, ${type}!`);
          }
        },
        get records() {
          return List.records(this.items, this._items);
        },
        select: ``,
        sort(key) {
          var { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        state: ``,
        title(type, d) {
          switch (type) {
            case `account`:
            case `contract`:
            case `household`:
              return `View ${type}`;
            case `records`:
              return `View account records`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
      };
      this.table = table.init(this);
    },
  },
};
</script>
