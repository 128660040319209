<template>
  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2 text-secondary"></i>
          <span class="font-weight-bold text-secondary">Generating invoice</span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          :disabled="step !== 'confirm'"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-if="step === 'confirm'">
          <div class="font-weight-bold confirm-alert text-center">
            You are generating
            <span v-text="countContracts"></span> new
            <span v-text="invoiceText"></span>
          </div>
          <br />
          <div
            class="text-center text-secondary confirm-info"
          >The report will be unavailable after generating the invoices</div>
        </div>
        <div v-else class="text-center">
          <loading></loading>
          <div class="progress mt-3">
            <div
              :style="`width: ${(progressBarValue / progressBarMax) * 100}%`"
              class="progress-bar"
              role="progressbar"
            ></div>
          </div>
          <div
            class="font-weight-bold text-center text-secondary mt-4 confirm-info"
          >Please, wait until invoices are generated.</div>
          <div
            class="text-center text-secondary confirm-sub-info"
          >Don't close or refresh this window.</div>
        </div>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-sm btn-outline-danger mr-1"
            data-dismiss="modal"
            :disabled="step !== 'confirm'"
          >Cancel</button>
          <button
            data-cy="invoice-bulk-confirm-submit-btn"
            type="button"
            class="btn btn-sm btn-primary submit-btn"
            :disabled="step !== 'confirm'"
            v-on:click="submit"
          >Generate invoices</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.confirm-alert {
  font-size: 1.2em;
}
.confirm-info {
  font-size: 1.1em;
}
.confirm-sub-info {
  font-size: 0.7em;
}
</style>

<script>
import { loading } from "../../../../../component";
import { socket } from "../../../../../service";

export default {
  get components() {
    return { loading };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) =>
        typeof o.contracts == `object` &&
        typeof o.methods == `object` &&
        typeof o.methods.submit == `function` &&
        typeof o.methods.success == `function`,
    },
  },
  data() {
    return {
      countContracts: 0,
      progressBarValue: 0,
      progressBarMax: 100,
      invoiceText: "invoice.",
      step: "confirm",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.countContracts = this.data.contracts.length;
      this.progressBarValue = 0;
      this.progressBarMax = this.countContracts;

      socket.on("invoice processed", (msg) => {
        const { i, error, message, contract } = JSON.parse(msg)
        if (error) {
          throw new Error(`${contract} ${message}`)
        } else {
          this.progressBarValue = i;

          if (this.progressBarValue == this.progressBarMax) {
            this.data.methods.success();
          }
        }
      });
      if (this.countContracts > 1) this.invoiceText = "invoices.";
    },
    async submit() {
      this.step = "generating invoices";
      await this.data.methods.submit();
    },
  },
};
</script>
