<template>
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-file-alt mr-2"></i>
          <span class="font-weight-bold" v-text="invoices.text(`modal`)"></span>
        </p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <a ref="download" class="d-none"></a>
        <template v-if="invoices.ready">
          <scroll-area class="px-4 py-3">
            <div class="form-row">
              <div class="col">
                <div class="form-row">
                  <div class="col-3">
                    <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                    v-model="invoices.date" format="MMM D, YYYY">
                    </date-picker>
                    <small class="form-text text-muted">Invoice Date</small>
                  </div>
                  <div class="col-3">
                   <select class="form-control form-control-sm text-capitalize" disabled>
                       <option class="text-capitalize">
                       <span class="text-capitalize">{{ invoices.hasAdvance ? "Advance" : "Arrears" }}</span>
                     </option>
                   </select>
                   <small class="form-text text-muted">Invoice Type</small>
                 </div>
                </div>
                <hr>
                <h6>Values used to calculate fees</h6>
                <template v-if="invoices.hasAdvance">
                  <div class="form-row">
                    <div class="form-group col-4" v-if="invoices.hasLatest">
                      <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                      v-model="invoices.period.advance.latest"
                        format="MMM D, YYYY"></date-picker>
                      <small class="form-text text-muted">Latest</small>
                    </div> 
                    <div class="form-group col-4" v-if="invoices.hasAverageDaily">
                      <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                      v-model="invoices.period.advance.adb"
                        format="MMM D, YYYY" range></date-picker>
                      <small class="form-text text-muted">Average Daily Balance</small>
                    </div> 
                  </div>
                </template>
                <template v-if="invoices.hasArrears">
                  <div class="form-row">
                    <div class="form-group col-4" v-if="invoices.hasLatest">
                     <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                     v-model="invoices.period.arrears.latest"
                       format="MMM D, YYYY"></date-picker>
                     <small class="form-text text-muted">Latest</small>
                    </div>
                    <div class="form-group col-4" v-if="invoices.hasAverageDaily">
                      <date-picker :clearable="false" class="date-picker" :disabled-date="disableWeekends" 
                      v-model="invoices.period.arrears.adb"
                        format="MMM D, YYYY" range></date-picker>
                      <small class="form-text text-muted">Average Daily Balance</small>
                    </div> 
                  </div>
                </template>
                <div class="form-row">
                  <div class="form-group col-5">
                     <h6>Fee applied to the following period</h6>
                     <date-picker :clearable="false" class="date-picker" 
                       v-model="invoices.period.range"
                         format="MMM D, YYYY" range></date-picker>
                     <small class="form-text text-muted">Apply for Period</small>
                  </div>
                  <div class="form-group offset-5 col-2 align-self-end">
                      <button type="button" class="btn btn-sm btn-primary submit-btn"
                        v-on:click="invoices.click(`recalculate`)">Calculate Fees</button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <transition name="fade" mode="out-in">
              <template v-if="invoices.clean">
                <div style="position: relative">
                  <div class="d-flex billable-contracts total">
                    <span class="total" style="flex-grow: 1">
                      Total of billable contracts:
                      {{ invoices.billableContracts.length }}
                    </span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div>
                  <div class="d-flex billable-contracts total">
                    <span class="total" style="flex-grow: 1">
                      Total of billable contracts:
                      {{ invoices.billableContracts.length }}
                    </span>

                    <span class="total" style="margin-right: 8px">Total</span>
                    <span class="total">{{ invoices.animatedTotal }}</span>
                  </div>
                  <div v-if="invoices.recalculatingFees">
                    <div class="progress mt-3">
                      <div :style="`width: ${(invoices.progressBarValue /
                        invoices.progressBarMax) *
                        100
                        }%`" class="progress-bar" role="progressbar"></div>
                    </div>
                  </div>

                  <div class="mt-3">
                    <div class="table-responsive">
                      <p><em>Below are the accounts that have insufficient cash to cover the amount 
                        due; all billing information is available by clicking "Generate report."</em>
                      </p>
                      <table class="table table-bordered table-sm table-secondary">
                        <thead>
                          <tr>
                            <th scope="col">Household</th>
                            <th scope="col">Account</th>
                            <th scope="col">Cash Available</th>
                            <th scope="col">Fee Due</th>
                          </tr>
                        </thead>
                        <tbody>
                          <LastCashValueTable v-for="(acc, i) in invoices.billedAccounts" :key="i" :account="acc">
                          </LastCashValueTable>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <template v-if="invoices.contractsWithException">
                    <div key="contractsWithException-if" class="d-flex non-billable-contracts total">
                      <span class="total" style="flex-grow: 1">
                        Total of non-billable contracts:
                        {{ invoices.nonBillableContracts.length }}
                      </span>
                    </div>

                    <table class="table table-sm mb-5 non-billable-contracts">
                      <thead>
                        <tr>
                          <th>
                            <span>Id</span>
                          </th>
                          <th>
                            <span>Ref</span>
                          </th>
                          <th>
                            <span>Accounts</span>
                          </th>
                          <th>
                            <span>Reason</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(o, i) in invoices.contractsWithException" :key="i">
                          <td>
                            <span v-text="invoices.text(`contract-id`, o)"></span>
                          </td>
                          <td>
                            <span v-text="o.ref"></span>
                          </td>
                          <td>
                            <span v-for="(a, i) in o.accounts" :key="i" class="mr-2" v-text="a.number"></span>
                          </td>
                          <td>
                            <span v-text="o.reason"></span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </template>
                  <template v-else>
                    <div key="contractsWithException-else"></div>
                  </template>
                </div>
              </template>
            </transition>
          </scroll-area>
        </template>
        <template v-else>
          <loading></loading>
        </template>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button type="button" class="btn btn-sm btn-outline-danger mr-2" data-dismiss="modal">Cancel</button>
          <button type="button" class="btn btn-sm btn-secondary submit-btn mr-2" :disabled="invoices.disabled(`submit`)"
            v-on:click="invoices.click(`report`)">Generate report</button>
          <button type="button" class="btn btn-sm btn-primary submit-btn" :disabled="invoices.disabled(`submit`)"
            v-on:click="invoices.click(`submit`)">Generate invoices</button>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.date-picker {
  width: 100%;
}

.submit-btn {
  width: auto;
}
div.total {
  padding: 0 18px;
  border-top: 1px solid #040609;
  border-bottom: 1px solid #dee2e6;
  height: 45px;
  justify-content: center;
  align-items: center;
}
div.billable-contracts {
  background: #eef7ff;
}
div.non-billable-contracts {
  background: #ffefc9;
  margin-bottom: 24px;
}
h3 {
  opacity: 0.5;
}
span {
  font-size: 0.9em;
}
small {
  padding-left: 0.7em;
  margin-top: 0.1em;
}
button {
  width: 80px;
}
div.modal-body {
  height: calc(100vh - 12em);
}
div.form-group {
  margin-bottom: 0.5em;
}
p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}
table.non-billable-contracts,
table.non-billable-contracts thead,
table.non-billable-contracts th {
  border-top: none !important;
}
table.non-billable-contracts th:nth-child(1),
table.non-billable-contracts td:nth-child(1) {
  width: 12.5%;
}
table.non-billable-contracts th:nth-child(2),
table.non-billable-contracts td:nth-child(2) {
  width: 23.5%;
}
table.non-billable-contracts th:nth-last-child(2),
table.non-billable-contracts td:nth-last-child(2) {
  width: 20%;
}
table.non-billable-contracts th:nth-last-child(1),
table.non-billable-contracts td:nth-last-child(1) {
  width: 45%;
}
span.total {
  font-size: 16px;
  font-weight: 600;
}

table th:nth-last-child(1) {
  width: 2em;
}
i {
  opacity: 0.3;
}
i:hover {
  cursor: pointer;
  opacity: 1;
}
i.fa-minus-circle:hover {
  color: red;
}

.progress-bar {
  transition: width 0.5s;
}
</style>

<script>
import { checkbox, loading, scrollArea } from "../../../../../component";
import LastCashValueTable from "../../../../../component/account/last-cash-value-table.vue";
import { Api, lib, Record, Modal } from "../../../../../factory";
import { numeral } from "../../../../../npm";
import { alert, session, socket } from "../../../../../service";
import moment from "moment-timezone";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UsFederalHolidays from '@18f/us-federal-holidays';
import { isHoliday as isNyseHoliday } from "nyse-holidays";

export default {
  get components() {
    return {
      checkbox,
      LastCashValueTable,
      loading,
      scrollArea,
      DatePicker,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) =>
        typeof o.contracts == `object` &&
        typeof o.methods == `object` &&
        typeof o.methods.success == `function`,
    },
  },
  data() {
    return {
      invoices: ``,
    };
  },
  // watch: {
  //   "invoice.period": {
  //     handler() {
  //       var { period } = this.invoice;
  //       if (period.start) this.invoice.fees();
  //     },
  //     deep: true
  //   }
  // },
  created() {
    this.init();
  },
  destroyed() {
    socket.off(this.invoices.socketkey);
  },
  methods: {
    init() {
      const state = session.get(`state`);
      const { contracts, methods } = this.data;

      const invoices = {
        quarterBill: false,
        monthlyBill: false,
        cashflowAdjustment: false,
        thresholdBilling: false,
        billedAccounts: [],
        hasAdvance: false,
        hasArrears: false,
        hasLatest: false,
        hasAverageDaily: false,
        animateValue(start, end, duration) {
          let startTimestamp = null;
          const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min(
              (timestamp - startTimestamp) / duration,
              1
            );

            this.animatedTotal = numeral(
              progress * (end - start) + start
            ).format(`$0,0.00`);
            if (progress < 1) {
              window.requestAnimationFrame(step);
            }
          };
          window.requestAnimationFrame(step);
        },

        clean: true,
        animatedTotal: "$0.00",
        total: 0,
        progressBarValue: 0,
        progressBarMax: 100,
        socketkey: Date.now().toString(),
        mountAccount(account, contract) {
          const { number } = this._data.contractMap
            .get(contract._id)
            .accounts.find((a) => a.number == account.number);

          const { name: scheduleName } = contract.schedules[account.schedule];

          return {
            billing:
              (account.billing == -1 || account.billing == 99)
                ? account.billing == -1 ? this._text.SELF : this._text.BTI 
                : contract.accounts.find(ac=> ac._id == account.billing).number, 
            due: 0,
            fee: 0,
            number,
            owing: 0,
            value: 0,
            schedule: scheduleName,
          };
        },
        _date(data) {
          const format = `MMM D, YYYY`;

          switch (data) {
            case `format`:
              return format;
            case `start`:
              return moment().format(`L`);
            case `end`:
              return moment().add(3, `months`).format(`L`);
            default:
              return moment(data).format(`L`);
          }
        },
        _data: { dates: [], contractMap: new Map() },
        _dates() {
          var { period } = this;
          period.start = this._date(`start`);
          period.end = this._date(`end`);
          period.range = [
            moment(period.start, "L").toDate(),
            moment(period.end, "L").toDate(),
          ];
        },
        calculateFees(contractFees) {
          contractFees.accounts.forEach(acc => {
            this.billedAccounts.push(acc)
          })
          // save fees inside _data contract map
          this._data.contractMap.get(contractFees.contractId).fees =
            contractFees;

          // update account fees
          this.contractMap
            .get(contractFees.contractId)
            .accounts.forEach((a) => {
              const account = contractFees.accounts.find(
                (o) => o.number == a.number
              );

              a.value = account.value;
              a.due = account.due;
              a.fee = account.fee;
              a.owing = account.owing;
              a.householdName = account.householdName
              a.householdContacts = account.householdContacts
            });

          this.contractMap
            .get(contractFees.contractId)
            .schedules.forEach((s, i) => {
              const schedule = contractFees.schedules[i];
              s.minimum = schedule.minimum;
              s.splits = [...schedule.splits];
              s.fees = [...schedule.fees];
              s.owing = schedule.owing;
              s.total = schedule.total;
              // if thresholdBilling is enabled the `breaks` and `rates`
              // were reduced on the backend and we'll use those new ones
              // not the default one we got from contract.schedules.[rates/breaks]
              if (this.thresholdBilling) {
                s.breaks = schedule.breaks
                s.rates = schedule.rates
              }
            });
        },
        _init: (...args) => this.$set(...args),
        _load() {
          try {
            if (this._pending) return;
            this._pending = true;

            this.billableContracts.map(async (contract) => {
              this._data.contractMap.set(contract._id, {
                ...contract,
              });
            });

            this.billableContracts.map((contract) => {
              this.contractMap.set(contract._id, {
                ...this.contractMap.get(contract._id),
                accounts: contract.accounts.map((account) =>
                  this.mountAccount(account, contract)
                ),
                schedules: contract.schedules.map((schedule) =>
                  this.mountSchedule(schedule)
                ),
              });
            });

            this.loaded = true;
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          } finally {
            this._pending = false;
          }
        },
        _pending: false,
        recalculatingFees: false,
        justInitiated: true,
        _period: () => {

          const quarterStartDate = moment().subtract(1, "quarter").startOf("quarter").toDate();
          const quarterEndDate = moment().subtract(1, "quarter").endOf("quarter").toDate();
          const oneDayAgo = moment().subtract(1, 'day').toDate();
          const currentQuarterStart = moment().startOf("quarter").toDate()
          const currentQuarterEnd = moment().endOf("quarter").toDate()
          const now = new Date()

          return {
            start: now,
            end: now,
            advance: {
              adb: [quarterStartDate, quarterEndDate],
              latest: oneDayAgo
            },
            arrears: {
              adb: [quarterStartDate, quarterEndDate],
              latest: oneDayAgo
            },
            range: [ currentQuarterStart, currentQuarterEnd],
          }
        },
        _remove(type, d) {
          switch (type) {
            case `line-item`:
              this.line.items.splice(d, 1);
              break;
            default:
              throw new Error(`Invalid remove type, ${type}!`);
          }
        },
        mountSchedule(schedule) {
          return {
            ...schedule,
            fees: [],
            owing: 0,
            splits: [],
            total: { fees: 0, splits: 0 },
          };
        },
        _show() {
          var { all, numbers } = this.show;
          return { ...all, numbers };
        },
        async _submit() {
          if (this._pending) return;
          this._pending = true;
          let error = false;

          const iterableContracts = [...this.billableContracts];
          const bulkInvoicesInput = iterableContracts.map((contract) => {
            const { _id: contractId, ref } = contract;
            const { fees } = this._data.contractMap.get(contractId);

            return {
              contractId,
              date: this._date(this.date),
              fees,
              ref,
              refund: false,
              show: this._show(),
            };
          });
          try {
            await Api.post(`invoices/bulk`, { 
              contracts: bulkInvoicesInput, 
              quarterBill: this.quarterBill ?? false,
              monthlyBill: this.monthlyBill ?? false 
            });
          } catch (e) {
            console.error(e);
            alert.error(e.message);
            error = true;
          }

        },
        _text: { NONE: ``, SELF: ``, BTI: `` },
        _values() {
          return this.options(`values`)[0];
        },
        _update: () => new Promise((r) => this.$nextTick(r)),
        showAccountNumber(account) {
          const len = Math.floor(account.number.length / 2);

          const number = this.show.numbers
            ? [...new Array(len).fill(`*`), account.number.slice(len)].join(``)
            : account.number;

          return { ...account, number };
        },
        accounts(type) {
          const account = (o) => {
            const len = Math.floor(o.number.length / 2);

            const number = this.show.numbers
              ? [...new Array(len).fill(`*`), o.number.slice(len)].join(``)
              : o.number;

            return { ...o, number };
          };

          switch (type) {
            case `due`:
              return this.billableAccounts
                .filter(
                  (o) => o.due || this.show.all.due || this.hasLineItem(o)
                )
                .map((o) => account(o));

            case `fees`:
              const accounts = this.billableAccounts
                .slice()
                .map((o) => account(o));

              this.allFeesExceptions.forEach((exception) => {
                const i = accounts.findIndex(
                  (account) => account.number == exception.account
                );

                if (i < 0) return;

                const number = this._text.NONE;
                const type = lib.string.capitalize(exception.type);

                const schedule = this.allSchedules[exception.schedule].name;

                const ticker = exception.ticker
                  ? this.allExceptions.find(
                    (o) =>
                      o.type == `position` &&
                      o.ticker.symbol == exception.ticker
                  ).ticker
                  : ``;

                const exceptionDescription = ticker
                  ? `Ticker: ${ticker.symbol} (${ticker.name})`
                  : type;

                accounts.splice(i + 1, 0, {
                  ...exception,
                  name: exceptionDescription,
                  number,
                  schedule,
                });
              });

              return accounts.filter((o) => o.fee || this.show.all.fees);
          }
        },
        get billableAccounts() {
          const accounts = [];

          this.contractMap.forEach((contract) =>
            accounts.push(
              this.billableContracts.find((bc) => bc._id == contract._id)
                ? contract.accounts
                : []
            )
          );

          return accounts.flat();
        },
        get allExceptions() {
          const exceptions = [];

          this.billableContracts.forEach((contract) =>
            exceptions.push(contract.exceptions)
          );

          return exceptions.flat();
        },
        get allFeesExceptions() {
          const feesExceptions = [];

          this._data.contractMap.forEach((contract) => {
            if (!contract.fees) return;

            const { exceptions = [] } = contract.fees;
            feesExceptions.push(exceptions);
          });

          return feesExceptions.flat();
        },
        get allSchedules() {
          const schedules = [];

          this.billableContracts.forEach((contract) =>
            schedules.push(contract.schedules)
          );

          return schedules.flat();
        },
        blur(type, d, e) {
          switch (type) {
            case `line-item`:
              switch (e) {
                case `value`:
                  d[e] = numeral(d[e]).format(`0,0.00`);
                  break;
                default:
                  throw new Error(`Invalid blur type key, ${e}!`);
              }
              break;
            default:
              throw new Error(`Invalid blur type, ${type}!`);
          }
        },
        click(type, d, e) {
          switch (type) {
            case `recalculate`:
              if (this.period.start) this.fees();
              break;
            case `report`:
              return this._export(
                this.billedAccounts, 
                this.period, 
                this.billableContracts);
            case `submit`:
              return Modal.open("invoice-bulk-confirm", {
                contracts: this.billableContracts,
                methods: {
                  submit: () => this._submit(),
                  success: async () => {
                    this._pending = false;
                    methods.success();
                    Modal.close();
                  },
                },
              });
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        change(type, d, e) {
          switch (type) {
            case `type`:
              return this._dates();
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        _billableContracts: [],
        get billableContracts() {
          return this._billableContracts.filter(
            (c) => !this.contractsWithException[c._id]
          );
        },
        get nonBillableContracts() {
          return Object.values(this.contractsWithException).filter(Boolean);
        },
        contractsWithException: ``,
        contractMap: new Map(),
        date: ``,
        disabled(type) {
          switch (type) {
            case `submit`:
              return (
                this.clean || this._pending || !this.billableContracts.length
              );
            case `type`:
              return Object.prototype.hasOwnProperty.call(
                contracts[0],
                `billing`
              );
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        loaded: false,
        async fees() {
          this.clean = false;

          this.progressBarMax = this._billableContracts.length;

          if (this.recalculatingFees) return;
          this.recalculatingFees = true;
          this._billableContracts = [...contracts];
          this.contractsWithException = ``;

          contracts.map((contract) =>
            this.contractMap.set(contract._id, { ...contract })
          );

          console.time("load");
          if (!this.loaded) await this._load();
          console.timeEnd("load");
          console.time("calc");

          if (this._pending) return;
          this._pending = true;

          try {
            this.progressBarValue = 0;
            this.total = 0;
            this.billedAccounts = [];

            socket.off(this.socketkey);
            this.socketkey = Date.now().toString();

            // listen to events from the backend
            socket.on(this.socketkey, (msg) => {
              const { i, total, contractFees, error, contract, message } = JSON.parse(msg);
              if (error) {
                this.setContractError(
                  message,
                  contract
                );
              } else {
                this.calculateFees(contractFees);
              }
              this.progressBarValue = i;
              this.animateValue(this.total, this.total + total, 100);
              this.total += total;

              if (this.progressBarValue == this.progressBarMax) {
                this.last_period = { ...this.period };
                this.recalculatingFees = false;
                this._pending = false;
                this.ready = true;
                console.timeEnd("calc");
              }
            });

            await Api.post(`contracts/bulk-fees`, {
              contracts: this.billableContracts.map(
                (contract) => [contract._id, contract.billing.type]
              ),
              query: {
                date: this._date(this.date),
                advance: {
                  start: this._date(this.period.advance.adb[0]),
                  end: this._date(this.period.advance.adb[1]),
                  latest: this._date(this.period.advance.latest)
                },
                arrears: {
                  start: this._date(this.period.arrears.adb[0]),
                  end: this._date(this.period.arrears.adb[1]),
                  latest: this._date(this.period.arrears.latest)
                },
                start: this._date(this.period.range[0]),
                end: this._date(this.period.range[1]),
                quarterBill: this.quarterBill,
                monthlyBill: this.monthlyBill,
                thresholdBilling: this.thresholdBilling,
                cashflowAdjustment: this.cashflowAdjustment
              },
              socketkey: this.socketkey,
            })

          } catch (error) {
            console.error(error);
            alert.error(error.message);
          }
        },
        setContractError(error, contract) {
          if (this.contractsWithException === ``)
            this.contractsWithException = {};

          this.contractsWithException[contract._id] = {
            ...contract,
            reason: error.message,
          };
        },
        focus(type, d, e) {
          switch (type) {
            case `line-item`:
              switch (e) {
                case `value`:
                  d[e] = numeral(d[e]).value();
                  break;
                default:
                  throw new Error(`Invalid focus type key, ${e}`);
              }
              break;
            default:
              throw new Error(`Invalid focus type, ${type}!`);
          }
        },
        init() {
          if (state.firm.features) {
            this.quarterBill = state.firm.features?.quarterBill ?? false;
            this.monthlyBill = state.firm.features?.monthlyBill ?? false;
            this.cashflowAdjustment = state.firm.features?.cashflowAdjustment ?? false
            this.thresholdBilling = state.firm.features?.thresholdBilling ?? false 
          }

          this._billableContracts = [...contracts];

          this.hasAdvance = this._billableContracts.some(c => c.billing.type.toLowerCase() == "advance")
          this.hasArrears = this._billableContracts.some(c => c.billing.type.toLowerCase() == "arrears")
          this.hasLatest = this._billableContracts .some(c => c.billing.values.toLowerCase() == 'latest')
          this.hasAverageDaily = this._billableContracts .some(c => c.billing.values.toLowerCase() == 'average daily') 

          contracts.map((contract) =>
            this.contractMap.set(contract._id, { ...contract })
          );

          this.contractsWithException = ``;
          this.date = new Date();
          this.period = this._period();
          this.values = this._values();
          this._text = { NONE: ``, SELF: `Self`, BTI: `Bill to Invoice` };

          this.show = {
            all: { due: false, fees: false, tiers: false },
            numbers: true,
          };

          this.last_period = { ...this.period };
          this.recalculatingFees = false;
          this._pending = false;
          this.ready = true;
          return this;
        },
        options(type, data) {
          const format = this._date(`format`);
          const { dates } = this._data;
          const { period } = this;

          switch (type) {
            case `date`:
              return { format };
            case `period`:
              switch (data) {
                case `start`:
                  return {
                    format,
                    minDate: ``,
                    maxDate: period.end,
                  };
                case `end`:
                  return {
                    format,
                    minDate: period.start,
                    maxDate: "",
                  };
                case `values`:
                  return {
                    format,
                    dates: dates.map((data) => this._date(data)),
                  };
                default:
                  throw new Error(`Invalid ${type} data, ${data}!`);
              }
            case `values`:
              return [`Average Daily`];
            case `type`:
              return [`advance`, `arrears`];
            default:
              throw new Error(`Invalid options type, ${type}!`);
          }
        },
        period: {
          start: ``, 
          end: ``,
          advance: { adb:[], latest: null },
          arrears: { adb:[], latest: null }, 
          range: [],
        },
        ready: ``,
        show_recalc: ``,
        refs: [],
        show: {},
        text(type, d, e) {
          var value = (v, f = `$0,0.00`) => numeral(v).format(f);
          switch (type) {
            case `schedule`:
              return `Schedule ${String.fromCharCode(97 + e).toUpperCase()} - ${d.name
                }`;
            case `tier`:
              var start = (v) => value(v == 0 ? v : v + 1, `$0,0`);
              var end = (v) => value(v, `$0,0`);
              return e < d.breaks.length - 1
                ? `${start(d.breaks[e])} - ${end(d.breaks[e + 1])}`
                : `> ${start(d.breaks[e])}`;
            case `rate`:
              return `${numeral(d.rates[e]).format(`0,0.[00]`)}%`;
            case `fee`:
            case `split`:
            case `due`:
            case `value`:
              var v = typeof e == `number` ? d[`${type}s`][e] : d[type];
              return type == `fee`
                ? d.owing
                  ? `(*) ${value(v)}`
                  : value(v)
                : value(v);
            case `modal`:
              return `Bulk Invoicing`;
            case `contract-id`:
              return Record.id(d);
            case `total`:
              var v, key;
              switch (d) {
                case `due`:
                  v = this.accounts(`due`).reduce((v, a) => (v += a[d]), 0);
                  break;
                case `account-values`:
                case `account-fees`:
                  key = d.split(`-`).pop().slice(0, -1);
                  v = this.accounts(`fees`).reduce((v, a) => (v += a[key]), 0);
                  break;
                case `schedule-fees`:
                case `schedule-splits`:
                  key = d.split(`-`).pop();
                  v = e.total[key];
                  break;
                default:
                  throw new Error(`Invalid text ${type} d ${d}!`);
              }
              return value(v);
            case `owing`:
              return d
                ? d.minimum
                  ? `This schedule fees fell short of the pro-rata minimum (${value(
                    d.minimum
                  )} by ${value(d.owing)})}`
                  : ``
                : this.accounts(`fees`).find((o) => o.owing)
                  ? `(*) Fee includes a pro-rata amount based on the schedule minimum shortfall`
                  : ``;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        tiers(o) {
          return o.splits.filter((v) => v || this.show.all.tiers);
        },
        title(type, d) {
          switch (type) {
            case `add`:
            case `remove`:
              switch (d) {
                case `line-item`:
                  return type == `add` ? `Add Line Item` : `Remove item`;
                default:
                  throw new Error(`Invalid title ${type} data, ${d}`);
              }
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        values: ``,
         _export: async (accounts, period, contracts) => {
          try {
            // get the time zone as we'll need it to display the dates correctly
            period.tz = moment.tz.guess() 
            const payload = {accounts, period, contracts}
            var { data: file } = await Api.post( `invoices/report`, payload);

            var el = this.$refs.download;
            el.setAttribute(`href`, file.dataURI);
            el.setAttribute(`download`, file.name);
            el.click();
            el.removeAttribute(`href`);
            el.removeAttribute(`download`);

          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        }
      };

      this.invoices = invoices.init();
    },
    disableWeekends (date) {
      const holidayOpts = { shiftSaturdayHolidays: true, shiftSundayHolidays: true };
      // check for holidays and with the excpetions of "Columbus" and "Veteran" days
      const allHolidays = UsFederalHolidays.allForYear(date.getFullYear(), holidayOpts)
      const found = allHolidays.find(h => h.date.getTime() == date.getTime())
      if (found)
        return found.name.includes("Columbus") || found.name.includes("Veterans")
          ? false : true
      
      if (isNyseHoliday(date)) return true

      const day = new Date(date).getDay()
      return day === 0 || day === 6
    }
  },
};
</script>
