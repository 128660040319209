<template>
  <div class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <scroll-area class="pr-3">
      <div class="d-flex mt-2 mb-2">
        <u class="font-weight-bold">Exceptions</u>
      </div>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Type</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `type`)"
                 v-on:click="table.sort(`type`)"></i>
            </th>
            <th>
              <span>Account</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `account`)"
                 v-on:click="table.sort(`account`)"></i>
            </th>
            <th>
              <span>Schedule</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `schedule`)"
                 v-on:click="table.sort(`schedule`)"></i>
            </th>
            <th>
              <span>Ticker</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `ticker`)"
                 v-on:click="table.sort(`ticker`)"></i>
            </th>
            <th class="text-right">
              <i class="fas fa-plus-circle"
                 :title="table.title(`add`)"
                 v-on:click="table.click(`add`)"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.items"
              :key="index">
            <td>
              <select v-model="o.type"
                      class="form-control form-control-sm text-capitalize"
                      v-on:change="table.change(`type`, o)">
                <option v-for="(t, i) in table.types"
                        :key="i"
                        class="text-capitalize">
                  <span v-text="t"></span>
                </option>
              </select>
            </td>
            <td>
              <select v-model="o.account"
                      class="form-control form-control-sm">
                <option v-for="a in table.accounts"
                        :key="a.name"
                        :disabled="table.disabled(`accounts`, o, a)">
                  <span v-text="a.name"></span>
                </option>
              </select>
            </td>
            <td>
              <select v-model="o.schedule"
                      class="form-control form-control-sm">
                <option v-for="s in table.schedules"
                        :key="s.name">
                  <span v-text="s.name"></span>
                </option>
              </select>
            </td>
            <td class="ticker">
              <input v-model="o.ticker.symbol"
                     class="form-control form-control-sm"
                     :class="table.class(`ticker`, o)"
                     :disabled="table.disabled(`ticker`, o)" />
              <small class="form-text text-muted"
                     v-text="o.ticker.name"></small>
            </td>
            <td class="text-right">
              <i class="fas fa-minus-circle"
                 :title="table.title(`remove`)"
                 v-on:click="table.click(`remove`, index)"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <hr class="mt-4" />
      <div class="d-flex mt-2 mb-2">
        <u class="font-weight-bold">Line Items</u>
      </div>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <span>Description</span>
            </th>
            <th>
              <span>Account</span>
            </th>
            <th>
              <span>Timing</span>
            </th>
            <th>
              <span>Amount</span>
            </th>
            <th class="text-right">
              <i class="fas fa-plus-circle"
                 :title="table.title(`add-line-item`)"
                 v-on:click="table.click(`add-line-item`)"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(o, index) in table.lineItemItems"
              :key="index">
            <td>
              <input v-model="o.description"
                     class="form-control form-control-sm" />
            </td>
            <td>
              <select v-model="o.account"
                      class="form-control form-control-sm">
                <option v-for="a in table.accounts"
                        :key="a.name"
                        :disabled="table.disabled(`accounts`, o, a)">
                  <span v-text="a.name"></span>
                </option>
              </select>
            </td>
            <td>
              <select v-model="o.timing"
                      class="form-control form-control-sm">
                <option v-for="(t, i) in o.timings" :key="i">
                  <span v-text="t"></span>
                </option>
              </select>
            </td>
            <td>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <b class="px-1">$</b>
                  </span>
                </div>
                <input v-model="o.amount"
                       class="form-control form-control-sm"
                       type="number" />
              </div>
            </td>
            <td class="text-right">
              <i class="fas fa-minus-circle"
                 :title="table.title(`remove-line-item`)"
                 v-on:click="table.click(`remove-line-item`, index)"></i>
            </td>
          </tr>
        </tbody>
      </table>
      <hr class="mt-4" />
      <div class="d-flex mt-2 mb-2">
      </div>
      <!-- <contract-transactions-creation-table :contractAccounts="contract.accounts"
                                            v-model="contract.transactionsRules" /> -->

    </scroll-area>
  </div>
</template>

<style scoped>
td.ticker small {
  margin: 0 0 0 0.6em;
  font-size: 0.7em;
}

div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  max-width: 15em;
}

table th:nth-child(1) {
  width: 7em;
}

table th:nth-child(3) {
  width: 12em;
}

table th:nth-last-child(2) {
  width: 30%;
}

table th:nth-last-child(2) {
  width: 15em;
}

table th:nth-last-child(1) {
  width: 2em;
}

i {
  opacity: 0.3;
}

i:not(.disable):hover,
i.sort {
  cursor: pointer;
  opacity: 1;
}

span {
  font-size: 0.9em;
}

i.fa-minus-circle:hover {
  color: red;
}
</style>

<script>
import ContractTransactionsCreationTable from "../../../../../../../component/transactions/contract-transactions-creation-table.vue";
import { List } from "../../../../../../../factory";
import Search from "../../../../../../../component/search.vue";
import ScrollArea from "../../../../../../../component/scroll-area.vue";
import RebatedTransactions from "../../../invoice/rebated-transactions.vue";

export default {
  components: {
    ContractTransactionsCreationTable,
    Search,
    ScrollArea,
    RebatedTransactions
},
  props: {
    contract: {
      type: Object,
      required: true,
      validator: (o) => Array.isArray(o.accounts) &&
        typeof o.data == `object` &&
        typeof o.state == `object`,
    },
  },
  data() {
    return {
      table: ``,
      household: null,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const { contract } = this;

      if (!contract.transactionsRules)
        contract.transactionsRules = [];

      const table = {
        get _accounts() {
          return contract.accounts.filter((o) => o.schedule != contract.NONE);
        },
        _data: { markets: `` },
        _text: {
          ALL: ``,
          CASH: ``,
          NONE: ``,
          POSITION: ``,
          STOCKS: ``,
          EQUITY: ``,
          DEBIT: ``,
          DERV: ``,
          OTHER: ``
        },
        _securities: [], 
        _init: (...args) => this.$set(...args),
        get accounts() {
          const all = { name: this._text.ALL };
          const list = contract.accounts.map((a) => ({
            name: `(${a.number.trim()}) ${a.name?.trim()}`,
          }));
          return [all, ...list];
        },
        change(type, d) {
          switch (type) {
            case `type`:
              d.ticker.symbol =
                d.type != this._text.POSITION ? this._text.NONE : ``;
              break;
            default:
              throw new Error(`Invalid change type, ${type}!`);
          }
        },
        class(type, d) {
          switch (type) {
            case `ticker`:
              return d.ticker.search
                ? d.ticker.name
                  ? `is-valid`
                  : `is-invalid`
                : ``;
            case `up`:
              return d == 0 ? `disable` : ``;
            case `down`:
              return d < contract.signees.length - 1 ? `` : `disable`;
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        addLineItem() {
          const [account] = this.accounts;
          let timings = [ "OneTime", "Annual", "Quarter", "Month" ]
          // if we already add monthly, remove quartlery
          if (contract.lineItems.find(l => l.timing == 'Month'))
            timings.splice(2, 1) // remove 'Quarter'
          // and vice verse
          if (contract.lineItems.find(l => l.timing == 'Quarter'))
            timings.splice(3, 1) // remove 'Month'

          contract.lineItems.push({
            account: account.name,
            amount: 0,
            description: "",
            timings: timings,
            timing: 'OneTime'
          });
        },
        click(type, d) {
          switch (type) {
            case `add`:
              // select the top account from the list of accounts that do not
              // exist in exceptions. We need this so we do not pickup a previously
              // selected account under exceptions
              const [account] = this.accounts.filter(ac => !contract.exceptions
                .some(ex => ex.account == ac.name && this._securities.includes(ex.type)));
              const [schedule] = this.schedules;
              const type = contract.exceptions.find((o) => o.type == this._text.CASH && o.account == this._text.ALL)
                ? this.types[1]
                : this.types[0];
              contract.exceptions.push({
                account: account.name,
                schedule: schedule.name,
                ticker: {
                  name: ``,
                  search: ``,
                  symbol: ``,
                },
                type,
              });
              break;
            case `remove`:
              return contract.exceptions.splice(d, 1);
            case `add-line-item`:
              this.addLineItem();
              break;
            case `remove-line-item`:
              return contract.lineItems.splice(d, 1);
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        disabled(type, d, e) {
          switch (type) {
            case `accounts`:
              // check if the selection type is 'equity'/`derviatives`/`debit`/`other` 
              // then disable any previously selected accounts (including 'all accounts') 
              // under exceptions, from showing in the dropdown list. False otherwise
              if (this._securities.includes(d.type)) {
                const accountName = e.name == this._text.ALL ? this._text.ALL : e.name
                const hasSecurity = contract.exceptions
                  .some(ex => this._securities.includes(ex.type) && ex.account == accountName)
                return hasSecurity
              }  
              return false
            case `category`:
            case `ticker`:
              return d.type != this._text.POSITION;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        init() {
          if (!contract.state.hasOwnProperty(`exceptions`)) {
            this._init(contract.state, `exceptions`, {
              list: {
                search: ``,
                sort: { asc: ``, key: `` },
              },
            });
          }
          if (!contract.hasOwnProperty(`exceptions`))
            this._init(contract, `exceptions`, []);
          if (!contract.hasOwnProperty(`lineItems`))
            this._init(contract, `lineItems`, []);
          this._data = { markets: {} };
          this._text = {
            ALL: `All Accounts`,
            CASH: `Cash`,
            NONE: `-`,
            POSITION: `Position`,
            STOCKS: `Stocks`,
            EQUITY: `Equity`,
            DEBIT: `Fixed Income`,
            DERV: `Derivatives`,
            OTHER: `Other`
          };
          this._securities = [
            this._text.EQUITY, this._text.DEBIT,
            this._text.OTHER, this._text.DERV]
          return this;
        },
        get items() {
          const { search, sort } = this.list;
          return List.sort(List.filter(contract.exceptions, search), sort.key, sort.asc);
        },
        get lineItemItems() {
          const search = ``, sort = { asc: ``, key: `` };
          return List.sort(List.filter(contract.lineItems, search), sort.key, sort.asc);
        },
        get list() {
          return contract.state.exceptions.list;
        },
        get schedules() {
          return contract.data.schedules;
        },
        sort(key) {
          const { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        title(type, d) {
          switch (type) {
            case `add`:
              return `Add new exception`;
            case `add-line-item`:
              return `Add new line item`;
            case `remove`:
              return `Remove exception`;
            case `remove-line-item`:
              return `Remove line item`;
            case `billing`:
              return d
                ? `Remove billing assignment`
                : `Apply billing assignment`;
            case `down`:
              return d < contract.signees.length - 1 ? `Move down` : ``;
            case `household`:
              return `Toggle household filter`;
            case `select`:
              return `Select all / none`;
            case `up`:
              return d == 0 ? `` : `Move up`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        get types() {
          
          const { CASH, POSITION, EQUITY, DEBIT, DERV, OTHER } = this._text;
          return [CASH, POSITION, EQUITY, DEBIT, DERV, OTHER];
        },
      };
      this.table = table.init();
    },
  },
};
</script>
