
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-house-user mr-2"></i>
        <span class="font-weight-bold" v-text="household.text(`name`)"></span>
      </p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <scroll-area class="px-4 py-3">
        <div class="form-row">
          <div class="col-3">
            <div class="form-group">
              <label>Id</label>
              <input
                v-model="household.id"
                class="form-control form-control-sm"
                placeholder="Auto"
                disabled
              />
              <small class="form-text text-muted">Household Id</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Name<span class="red-asterisk">*</span></label>
              <input
                v-model="household.name"
                class="form-control form-control-sm"
                placeholder="Name"
              />
              <small class="form-text text-muted">Household Name</small>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <td>
                    <span>First Name<span class="red-asterisk">*</span></span>
                  </td>
                  <td>
                    <span>Last Name<span class="red-asterisk">*</span></span>
                  </td>
                  <td>
                    <span>Email</span>
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in household.contacts" :key="i" v-if="!o.shouldDelete">
                  <td>
                    <input
                      v-model="o.firstName"
                      class="form-control form-control-sm"
                      placeholder="Name"
                    />
                  </td>
                  <td>
                    <input
                      v-model="o.lastName"
                      class="form-control form-control-sm"
                      placeholder="Last Name"
                    />
                  </td>
                  <td>
                    <input
                      v-model="o.email"
                      type="email"
                      class="form-control form-control-sm"
                      placeholder="Email"
                    />
                  </td>
                  <td class="align-middle" style="min-width: 50px;">
                    <i
                      class="fas fa-plus-circle mr-2"
                      :class="household.class(`add`, i)"
                      :title="household.title(`add`, i)"
                      v-on:click="household.click(`add`, i)"
                    ></i>
                    <i
                      class="fas fa-minus-circle"
                      :class="household.class(`remove`, i)"
                      :title="household.title(`remove`, i)"
                      v-on:click="household.click(`remove`, i)"
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <hr />
        <label>Advisor</label>
          <div class="form-row">
            <div class="col vertical-line">
              <div class="dropdown" ref="dropdown">
                <button class="btn btn-sm btn-outline-secondary dropdown-toggle" type="button" id="ddmBtnAdvisors"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{ selectedAdvisors.length ? selectedAdvisors.map(a => a.name).join(', ') : 'Select Advisors' }}
                </button>
                <div class="dropdown-menu" aria-labelledby="ddmBtnAdvisors">
                  <div v-for="advisor in advisors" :key="advisor.id" class="dropdown-item">
                    <div class="form-check">
                      <input type="checkbox" class="form-check-input" :id="'advisor-' + advisor.id" :value="advisor"
                        v-model="selectedAdvisors"
                        :disabled="selectedAdvisors.length >= 2 && !selectedAdvisors.includes(advisor)">
                      <label class="form-check-label" :for="'advisor-' + advisor.id">
                        {{ advisor.name }}
                      </label>
                      <button v-if="advisor.id" @click.stop="confirmDelete(advisor)" type="button" class="close"
                        aria-label="Close"><span aria-hidden="true">&emsp;×</span></button>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="col">
            <div class="input-group input-group-sm">
              <input type="text" class="form-control" 
                placeholder="Advisor's name" 
                aria-label="Advisor's name" 
                ref="advisorNameInput"
                @keypress.enter="addAdvisor">
              <div class="input-group-append">
                <button 
                @click="addAdvisor"
                class="btn btn-outline-secondary" 
                type="button">Add</button>
              </div>
            </div>
            <small class="form-text text-muted">Add a new advisor</small>
          </div>
        </div>
        <hr />
        <label>Household Address</label>
        <div class="form-row">
          <div class="col">
            <div class="form-group">
                  <input type="text" class="form-control form-control-sm" 
                  ref="addressLine1" :value="household.address.addressLine1" required>
                  <small class="form-text text-muted">Address Line 1</small>
            </div>
            <div class="form-group">
                  <input type="text" class="form-control form-control-sm" 
                  :value="household.address.addressLine2"
                  ref="addressLine2">
                  <small class="form-text text-muted">Address Line 2 (optional)</small>
            </div>
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" 
                    :value="household.address.city" ref="city">
                <small class="form-text text-muted">City</small> 
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <select class="form-control form-control-sm" ref="state">
                <option value="">Select State/Province</option>
                <optgroup label="United States">
                  <option v-for="(state, code) in usStates" 
                  :value="code" :key="code" :selected="household.address.state === code">{{ state }}</option>
                </optgroup>
                <optgroup label="Canadian Provinces">
                    <option v-for="(province, code) in canadianProvinces" 
                    :value="code" :key="code" :selected="household.address.state === code">{{ province }}</option>
                </optgroup>
              </select> 
              <small class="form-text text-muted">State/Province</small>
            </div>
            <div class="form-group">
                <input type="text" class="form-control form-control-sm" 
                ref="postalcode" :value="household.address.postalcode" required>
                <small class="form-text text-muted">Zip/Postal Code</small> 
            </div>
            <div class="form-group">
              <select class="form-control form-control-sm" ref="country" required>
                <option value="">Select Country</option>
                <option v-for="(country,code) in countries" 
                :value="code" :key="code" :selected="household.address.country == code" >{{ country}}</option>
              </select>
              <small class="form-text text-muted">Country</small>
            </div>
          </div>
        </div>
      </scroll-area>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <remove-button :button="household.remove"></remove-button>
        <button
          type="button"
          class="btn btn-sm btn-primary ml-2"
          :disabled="household.disabled(`save`)"
          v-on:click="household.click(`save`)"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
