
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-house-user mr-2"></i>
        <span class="font-weight-bold">Custodian - Accounts</span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <scroll-area class="px-4 py-3">
        <div class="form-row mr-1">
          <div class="col-3">
            <div class="form-group">
              <label>Custodian</label>
              <input
                class="form-control form-control-sm"
                placeholder="Master"
                :value="custodian.id"
                readonly
              />
              <small class="form-text text-muted">Custodian Id</small>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label>Household</label>
              <select
                v-model="custodian.household"
                class="form-control form-control-sm"
                v-on:change="custodian.change(`household`)"
              >
                <option v-for="h in custodian.households" :key="h.name">
                  <span v-text="h.name"></span>
                </option>
              </select>
              <small class="form-text text-muted">Accounts Household</small>
            </div>
          </div>
        </div>
        <hr class="mr-2" />
        <div class="form-row">
          <div class="col">
            <table class="table table-sm table-borderless">
              <thead>
                <tr>
                  <td>
                    <span>Number</span>
                  </td>
                  <td>
                    <span>Name</span>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(o, i) in data.accounts" :key="i">
                  <td>
                    <input
                      class="form-control form-control-sm"
                      :value="o.number"
                      placeholder="Number"
                      readonly
                    />
                  </td>
                  <td>
                    <input
                      v-model="o.name"
                      class="form-control form-control-sm"
                      placeholder="Name"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </scroll-area>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button
          type="button"
          class="btn btn-sm btn-primary ml-2"
          :disabled="custodian.disabled(`save`)"
          v-on:click="custodian.click(`save`)"
        >
          <span class="button">Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
