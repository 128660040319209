<template>
  <div :class="validationDivClasses">
    <input
      ref="input"
      class="form-control form-control-sm"
      v-bind:value="value"
      v-on:input="handleInput($event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: false,
    validate: { type: Function }
  },
  data() {
    return {
      error: false,
      validationDivClasses: []
    }
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)

      if (this.validate) {
        this.validationDivClasses = ["needs-validation", "was-validated"]
        this.error = value == "" ? false : !this.validate(value)

        if (this.error) {
          this.$refs.input.setCustomValidity("Invalid field.")
        } else {
          this.$refs.input.setCustomValidity("")
        }
      }
    }
  }
};
</script>
