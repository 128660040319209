<template>
  <div
    :id="modal.id"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    :data-backdrop="modal.backdrop"
    :data-keyboard="modal.keyboard"
  >
    <template v-if="modal.enable">
      <component :is="modal.component" :data="modal.data"></component>
    </template>
  </div>
</template>

<style scoped></style>

<script>
import modalComponents from "../modal";
import { $ } from "../npm";
import { events } from "../service";

export default {
  get components() {
    return modalComponents;
  },
  data() {
    return {
      modal: ""
    };
  },
  computed: {},
  watch: {
    "modal.enable": {
      handler() {
        var { modal } = this;
        if (!modal.enable) return;
        this.$nextTick(() => {
          modal.show();
        });
      }
    }
  },
  created() {
    this.init();
    var { modal } = this;
    this.events = events.$watch({
      modal: modal.event.bind(modal)
    });
  },
  mounted() {
    var { modal } = this;
    $(modal.el).on("hidden.bs.modal", modal.hidden.bind(modal));
    $(modal.el).on("shown.bs.modal", modal.shown.bind(modal));
  },
  destroyed() {
    events.$unwatch(this.events);
  },
  methods: {
    init() {
      var { _uid: id } = this;
      this.modal = {
        _action(a) {
          $(this.el).modal(a);
        },
        active: false,
        get backdrop() {
          return this.close ? false : `static`;
        },
        get keyboard() {
          return `${this.close}`;
        },
        class: ``,
        close: ``,
        component: ``,
        data: ``,
        id,
        get el() {
          return `#${this.id}`;
        },
        enable: false,
        event(e) {
          switch (e.name) {
            case `modal`:
              var { component, data, close = false } = e.data;
              if (!component) return this._action(`hide`);
              this.component = component;
              this.data = data;
              this.enable = true;
              this.close = close;
              break;
          }
        },
        hidden() {
          events.$emit(`modal`, { action: `hidden` });
          this.enable = false;
        },
        show() {
          this._action(`show`);
        },
        shown() {},
        style: ``
      };
    }
  }
};
</script>
