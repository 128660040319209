
<div class="h-100">
  <div class="search">
    <search :o="table.list"></search>
  </div>
  <div class="d-flex p-1 pb-2 header">
    <div class="d-flex actions">
      <div
        title="Export Invoice(s)"
        class="btn btn-outline-secondary action-button"
        v-on:click="table.click(`export`, `invoice`)"
      >
        <i class="fas fa-file-csv"></i>
        <span>Download Fee File</span>
      </div>

      <div
        title="Download Invoice(s)"
        class="btn btn-outline-secondary action-button"
        v-on:click="table.downloadSelected(table.items.filter(i => i.select && !i.refund).map(i => i.pdf))"
      >
        <i class="fas fa-file-download"></i>
        <span>Download Invoices</span>
      </div>

      <div
        title="Send Invoice(s) to Clients"
        class="btn btn-outline-secondary action-button"
        v-on:click="table.openSendInvoices()"
      >
        <i class="fas fa-paper-plane"></i>
        <span>Send to clients</span>
      </div>

      <div
        title="Delete Invoice(s)"
        class="btn btn-outline-secondary action-button"
        v-on:click="table.deleteSelected()"
      >
        <i class="fas fa-trash-alt"></i>
        <span>Delete selected</span>
      </div>

      <div
        title="Set Invoice(s) status as paid"
        class="btn btn-outline-secondary action-button"
        v-on:click="table.paidSelected()"
      >
        <i class="fas fa-check"></i>
        <span>Set as paid</span>
      </div>

      <!-- <div
        title="Set Invoice(s) status as paid"
        class="btn btn-outline-secondary action-button"
        v-on:click="table.pendingSelected()"
      >
        <i class="fas fa-trash-alt"></i>
        <span>Set as pending</span>
      </div>-->
    </div>
    <div class="d-flex filters">
      <select v-model="table.selectedYear" class="custom-select custom-select-sm" @change="table.handleYearSelection()" >
        <option v-for="year in table.years" :key="year" :value="year">
          Range: {{ year }}
        </option>
      </select>
    </div>
    <div class="flex-grow-1 text-right" style="display: flex; flex-direction: column">
      <span v-text="table.records"></span>
      <span v-text="table.selected"></span>
    </div>
  </div>
  <template v-if="ready">
  <scroll-area class="pr-3">
    <a ref="download" class="d-none"></a>
    <table class="table table-sm">
      <thead>
        <tr>
          <th>
            <checkbox :o="table" p="select" v-on:change="table.change(`select`)"></checkbox>
          </th>
          <th>
            <span>Id</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `id`)"
              v-on:click="table.sort(`id`)"
            ></i>
          </th>
          <th>
            <span>Ref</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `ref`)"
              v-on:click="table.sort(`ref`)"
            ></i>
          </th>
          <th>
            <span>Accounts</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `accounts`)"
              v-on:click="table.sort(`accounts`)"
            ></i>
          </th>
          <th>
            <span>Schedules</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `schedules`)"
              v-on:click="table.sort(`schedules`)"
            ></i>
          </th>
          <th>
            <span>Date</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `date`)"
              v-on:click="table.sort(`date`)"
            ></i>
          </th>
          <th>
            <span>Status</span>
            <i
              class="fas fa-sort pl-1"
              :class="table.class(`sort`, `status`)"
              v-on:click="table.sort(`status`)"
            ></i>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody :key="table.body_key">
        <tr
          v-for="(o, index) in table.items"
          :key="o._id"
          data-cy="custodian-billing-invoice-row"
        >
          <td>
            <checkbox
              :o="o"
              p="select"
              v-on:change="table.change(`select`, o)"
              :disabled="table.disabled(`select`, o)"
            ></checkbox>
          </td>
          <td>
            <a
              href="#"
              class="badge badge-custom"
              :title="table.title(`id`)"
              v-on:click="table.click(`invoice`, o)"
              v-text="o.id"
            ></a>
          </td>
          <td class="text-nowrap">
            <span v-text="o.ref"></span>
          </td>
          <td class="pre accounts">
            <div>
              <span
                v-for="(a, i) in o.accounts"
                :key="i"
                class="mr-2"
                :title="table.title(`account`, a)"
                v-text="a"
              ></span>
            </div>
          </td>
          <td class="pre">
            <schedule-badges :schedules="o.schedules"></schedule-badges>
          </td>
          <td class="text-nowrap">
            <span v-text="o.date"></span>
          </td>
          <td>
            <span class="badge" :class="table.class(`status`, o)" v-text="o.status"></span>
          </td>
          <td class="text-nowrap">
            <i
              class="far fa-file-pdf mr-2"
              :title="table.title(`pdf`)"
              v-on:click="table.click(`pdf`, o)"
            ></i>
            <!-- <template v-if="!o.refund">
              <i
                class="fas fa-dollar-sign"
                :title="table.title(`refund`)"
                v-on:click="table.click(`refund`, o)"
              ></i>
            </template>-->
          </td>
        </tr>
      </tbody>
    </table>
  </scroll-area>
 </template>
  <template v-else>
    <loading></loading>
 </template>
</div>
