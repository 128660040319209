

<div>
    <div class="d-flex">
        <div>
            <p class="font-weight-bold">Rebated Transactions</p>
        </div>
    </div>
    <table class="table table-sm mb-4">
        <thead>
            <tr>
                <th style="white-space: nowrap">
                    <span>Date</span>
                </th>
                <th>
                    <span>Account</span>
                </th>
                <th>
                    <span>Category</span>
                </th>
                <th>
                    <span>Ticker</span>
                </th>
                <th class="text-right">
                    <span>Amount</span>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(transaction, i) of transactions"
                :key="i">
                <td style="white-space: nowrap">
                    <span v-text="formatDate(transaction.date)"></span>
                </td>
                <td>
                    <span v-text="obfuscateAccountNumber(transaction.accountNumber)"></span>
                </td>
                <td>
                    <span
                          v-text="transaction.trnFees > 0 ? transaction.category : transaction.detailDescription"></span>
                </td>
                <td>
                    <span v-text="transaction.ticker"></span>
                </td>
                <td class="text-right">
                    <span
                          v-text="formatMoney(transaction.trnFees > 0 ? transaction.trnFees : transaction.amount)"></span>
                </td>
            </tr>
        </tbody>
        <tfoot>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">
                    <span class="font-weight-bold">Total</span>
                </td>
                <td class="text-right no-wrap">
                    <span class="total"
                          v-text="total"></span>
                </td>
            </tr>
        </tfoot>
    </table>
</div>
