<template>
  <div class="card">
    <div class="card-header px-3 py-2" id="headingOne" >
      <div class="card-header-title">
        <button class="btn btn-block text-left" type="button" 
        data-toggle="collapse" data-target="#collapseOne" 
        aria-expanded="false" aria-controls="collapseOne">
          <span class="font-weight-bold">Households</span>
          <i class="fa fa-chevron-up ml-1"></i>
        </button>
      </div>
      <button
        title="Onboard new client"
        type="button"
        class="btn btn-sm btn-outline-secondary new-client"
        v-on:click="table.newClient()"
      >
        <i class="fas fa-user-plus mr-2"></i>
        <span>New Client</span>
    </button>
    </div>
    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionMenu">
     <div class="card-body">
       <search :o="table"></search>
       <div class="d-flex pt-1 pl-2">
       </div>
       <div class="households">
         <scroll-area class="pr-3">
           <table class="table table-sm">
             <tbody>
               <tr v-for="(o, i) in table.items" :key="i" class="household">
                 <td>
                   <i
                     class="far fa-edit mr-1"
                     :title="table.title(`edit`)"
                     v-on:click="table.click(`edit`, o)"
                   ></i>
                   <a
                    href="#"
                     class="badge badge-light"
                     :title="table.title(`view`)"
                     v-on:click="table.click(`view`, o)"
                     v-text="o.name"
                   ></a>
                 </td>
               </tr>
             </tbody>
           </table>
         </scroll-area>
       </div>
     </div>
    </div>
  </div>
</template>

<style scoped>
a.badge {
  font-size: 0.8rem;
}
.new-client {
  float: right;
  font-size: 0.8rem;
}
div.card-header {
  display: flex;
  justify-content: space-between;
}
div.card-header-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
div.card-header i,
div.card-header span {
  opacity: 0.7;
}
div.households {
  height: 50vh;
  margin-top: 6px;
  margin-right: -15px;
  margin-bottom: -10px;
}
div.action {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.75rem;
}
div.action:hover i {
  opacity: 1;
}
div.action i {
  vertical-align: bottom;
  opacity: 0.3;
  padding-bottom: 2px;
}
tr.household {
  font-size: 0.9rem;
}
i {
  opacity: 0.3;
}
table i:hover {
  cursor: pointer;
  opacity: 1;
}
span.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<script>
import { fileUpload, scrollArea, search } from "../../../component";
import { Api, List, Modal } from "../../../factory";
import { alert, events, session } from "../../../service";

export default {
  get components() {
    return {
      fileUpload,
      scrollArea,
      search
    };
  },
  data() {
    return {
      table: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      var table = {
        async _load() {
          try {
            var { data } = await Api.get(`households`);
            this._data = data
              //.filter(o => o._id != `5f56c6d71f6c540011a4e146`) // for demo, skip
              .sort((a, b) => a.name.localeCompare(b.name));
            if (data.length) {
              if (
                !state.household ||
                !data.find(o => o._id == state.household._id)
              )
                state.household = data[0];
            } else {
              state.household = ``;
            }
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _view: o => {
          if (state.household && state.household._id != o._id)
            return (state.household = o);
          state.household = ``;
          this.$nextTick(() => {
            state.household = o;
          });
        },
        click(type, d) {
          switch (type) {
            case `add`:
            case `edit`:
              return Modal.open(`household`, {
                household: d || {},
                methods: {
                  success: () => {
                    this._load();
                    Modal.close();
                  }
                }
              });
            case `import`:
              return alert.warning(`Not yet implemented!`, 3e3);
            case `view`:
              return this._view(d);
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        init() {
          this._data = ``;
          this.search = ``;
          this._load();
          return this;
        },
        get items() {
          return List.filter(Array.from(this._data), this.search);
        },
        title(type) {
          switch (type) {
            case `add`:
              return `Add Household`;
            case `edit`:
              return `Edit Household`;
            case `import`:
              return `Import households`;
            case `view`:
              return `View household`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        async newClient() {
          return Modal.open(`ClientOnboarding`, {
            methods: {
              success: () => {
                this._load();
                Modal.close();
              }
            },
            files: (await Api.get(`/files/firm-files`)).data
          });
        }
      };
      this.table = table.init();
    }
  }
};
</script>
