
import Vue from "vue";
import numeral from "numeral";
import firebase from "firebase/app";
import "firebase/auth";

import app from "./js/app/index.vue";
import config from "./config";
import { session } from "./js/app/service";

import "./css/main.css";
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

firebase.initializeApp(config.firebase);

session.set("config", config);
session.set("state", {});

numeral.options.scalePercentBy100 = false;

new Vue({
  render: h => h(app)
}).$mount("#app");
