
<button
  type="button"
  class="btn btn-sm"
  :class="remove.class"
  :disabled="remove.disabled"
  v-on:click="remove.click()"
  v-on:blur="remove.blur()"
>
  <span v-text="remove.text"></span>
</button>
