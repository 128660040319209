<template>
  <div class="card">
    <div class="card-header px-3 py-2">
      <i class="fas fa-building mr-2"></i>
      <span class="font-weight-bold">Firms</span>
    </div>
    <div class="card-body">
      <search :o="table"></search>
      <div class="d-flex pt-1 pl-2">
        <div
          class="action"
          :title="table.title(`add`)"
          v-on:click="table.click(`add`)"
        >
          <i class="fas fa-plus-square"></i>
          <span class="text-primary link">Add</span>
        </div>
      </div>
      <div class="firms">
        <scroll-area class="pr-3">
          <table class="table table-sm">
            <tbody>
              <tr v-for="(o, i) in table.items" :key="i" class="firm">
                <td>
                  <i
                    class="fas fa-building mr-2"
                    :title="table.title(`edit`)"
                    v-on:click="table.click(`edit`, o)"
                  ></i>
                  <span
                    class="text-primary link"
                    :title="table.title(`view`)"
                    v-on:click="table.click(`view`, o)"
                    v-text="o.name"
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </scroll-area>
      </div>
    </div>
  </div>
</template>

<style scoped>
div.card-header i,
div.card-header span {
  opacity: 0.7;
}
div.firms {
  height: calc(100vh - 12.8em);
  margin-top: 6px;
  margin-right: -15px;
  margin-bottom: -10px;
}
div.action {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.75rem;
}
div.action:hover i {
  opacity: 1;
}
div.action i {
  vertical-align: bottom;
  opacity: 0.3;
  padding-bottom: 2px;
}
tr.firm {
  font-size: 0.9rem;
}
i {
  opacity: 0.3;
}
table i:hover {
  cursor: pointer;
  opacity: 1;
}
span.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<script>
import { scrollArea, search } from "../../../component";
import { Api, List, Modal } from "../../../factory";
import { alert, events, session } from "../../../service";

export default {
  get components() {
    return {
      scrollArea,
      search
    };
  },
  data() {
    return {
      file: ``,
      table: ``
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var state = session.get(`state`);
      var table = {
        _init: (...args) => this.$set(...args),
        async _load() {
          try {
            var { data } = await Api.get(`firms`);
            this._data = data.sort((a, b) => a.name.localeCompare(b.name));
            if (!state.hasOwnProperty(`firm`)) this._init(state, `firm`, ``);
            if (data.length) {
              if (!state.firm || !data.find(o => o._id == state.firm._id))
                state.firm = data[0];
            }
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _view: o => {
          if (state.firm && state.firm._id != o._id) return (state.firm = o);
          state.firm = ``;
          this.$nextTick(() => {
            state.firm = o;
          });
        },
        click(type, d) {
          switch (type) {
            case `add`:
            case `edit`:
              return Modal.open(`firm`, {
                firm: d || {},
                methods: {
                  success: () => {
                    this._load();
                    Modal.close();
                  }
                }
              });
            case `view`:
              return this._view(d);
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        init() {
          this._data = ``;
          this.search = ``;
          this._load();
          return this;
        },
        get items() {
          return List.filter(Array.from(this._data), this.search);
        },
        title(type) {
          switch (type) {
            case `add`:
              return `Add Firm`;
            case `edit`:
              return `Edit Firm`;
            case `view`:
              return `View Firm`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        }
      };
      this.table = table.init();
    }
  }
};
</script>
