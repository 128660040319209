<template>
  <div
    class="modal-dialog modal-dialog-centered modal-lg min-modal"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="fas fa-database mr-2"></i>
          <span class="font-weight-bold">Account values</span>
        </p>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <scroll-area>
          <table class="table table-sm">
            <thead>
              <tr>
                <th class="col-2">
                  <span>Date</span>
                </th>
                <th class="col-3">
                  <span>Value</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(record, index) in records" :key="index">
                <td>{{ formattedDate(record.date) }}</td>
                <td>
                  <div class="input-group input-group-sm">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <b class="px-1">$</b>
                      </span>
                    </div>
                    <input
                      v-model="record.cash"
                      class="form-control form-control-sm"
                    />
                  </div>
                </td>
                <td class="text-right pr-3">
                  <i
                    class="fas fa-arrow-down"
                    title="Update values below"
                    v-on:click="updateBelow(index)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </scroll-area>
      </div>
      <div class="modal-footer pr-3">
        <div class="text-right">
          <button
            type="button"
            class="btn btn-sm btn-primary ml-2"
            v-on:click="submit()"
          >
            <span class="button">Save</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.min-modal {
  min-width: 50vw;
}
.min-modal .modal-body {
  height: 50vh;
}
i {
  opacity: 0.3;
}
i:hover {
  cursor: pointer;
  opacity: 1;
}
</style>

<script>
import { scrollArea } from "../../../../../component";
import { Api } from "../../../../../factory";
import { sortBy } from "lodash";
import moment from "moment";

export default {
  get components() {
    return {
      scrollArea,
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
      validator: (o) => typeof o.account == `object`,
    },
  },
  data() {
    return {
      records: [],
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      const { data } = await Api.get(
        `records?accountId=${this.data.account._id}&_sort=-_created`
      );

      this.records = sortBy(data, (rec) => new Date(rec.date).getTime());
    },
    updateBelow(index) {
      const reference = this.records[index];

      this.records.slice(index).forEach((rec) => (rec.cash = reference.cash));
    },
    formattedDate(dateString) {
      const format = `MMM D, YYYY`;
      return moment(dateString, "L").format(format);
    },
    async submit() {
      await Api.put("records/nca", {
        records: this.records,
      });
      this.data.methods.success()
    },
  },
};
</script>
