
<div class="root">
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <img :src="login.logo" />
          <form>
            <div class="form-group">
              <label>2FA</label>
              <input
                v-model="login.mfa"
                class="form-control"
                placeholder="2FA Code"
              />
            </div>
            <div class="form-group">
              <hr style="opacity: 0.4" />
              <button
                type="button"
                class="btn btn-info btn-block"
                :disabled="login.disabled('reset')"
                v-on:click="login.submit()"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
