
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">Email Templates</div>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-2">
      <div class="px-4 py-3">
        <div class="mt-2">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary mr-2"
            v-on:click="viewConsentEmail()"
          >
            <span>View</span>
          </button>
          Email consent
        </div>
        <hr />
        <div class="mt-2">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary mr-2"
            v-on:click="viewDiscloseFeeSchedule()"
          >
            <span>View</span>
          </button>
          Disclose fee schedule in effect
        </div>
        <hr />
        <div class="mt-2">
          <button
            type="button"
            class="btn btn-sm btn-outline-primary mr-2"
            v-on:click="viewUpdatedFeeSchedule()"
          >
            <span>View</span>
          </button>
          Updated fee schedule
        </div>
      </div>
    </div>
  </div>
</div>
