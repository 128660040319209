
<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-cog mr-2"></i>
        <span class="font-weight-bold">Settings</span>
      </p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="p-3">
         <div class="row">
           <h4 class="col-4">Features</h4>
         </div>
         <hr />
         <div class="row">
           <div class="col-4">
             <div class="form-group">
               <label>Default Home View</label>
               <select
                 v-model="modal.view"
                 class="form-control form-control-sm"
                 v-on:change="modal.change(`view`)"
               >
                 <option v-for="(o, i) in modal.options(`view`)" :key="i">
                   <span v-text="o"></span>
                 </option>
               </select>
               <small class="form-text text-muted">Your default home screen </small>
             </div>
           </div>
           <div class="col"></div>
         </div>
         <div class="row">
           <h4 class="col-4">Integrations</h4>
         </div>
         <hr />
         <div class="row">
           <div class="col">
            <div class="form-group">
              <label><strong>Redtail CRM</strong></label>
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="redTailIntegrationSwitch" 
                @change="changeRedtailVars" :checked="rtUserAuthKey" :disabled="wbAuthenticated">
                <label class="custom-control-label" for="redTailIntegrationSwitch">Allow bi-directional data exchange with Redtail</label>
              </div>
               <div v-if="rtShowInput" class="row pt-2">
                 <div class="col-10">
                     <input type="text" class="form-control form-control-sm" placeholder="User Auth Key" v-model="rtUserAuthKey">
                     <small class="form-text text-muted">Copy the UserAuthKey from Redtail Imaging and paste it here</small>
                 </div>
                 <div class="col-2" style="padding-left: 0;">
                   <button class="btn btn-sm btn-primary" @click="saveRTUserAuthKey">Save</button>
                 </div>
              </div>
            </div>
         </div>
         </div>
         <div class="row">
            <div class="col">
              <div class="form-group">
                <label><strong>Wealthbox CRM</strong></label>
                <div class="row align-items-center">
                  <div class="col-9">
                    Allow bi-directional data exchange with Wealthbox
                  </div>
                  <div class="col-3 text-right">
                    <button v-show="wbAuthenticated" class="btn btn-sm btn-secondary" @click="disconnectOauth">Disconnect</button>
                    <button v-show="!wbAuthenticated" :disabled="rtShowInput" class="btn btn-sm btn-primary" @click="openAuthPopup">Connect</button>
                  </div>
                </div>
              </div>
            </div>
         </div>
      </div>
    </div>
  </div>
</div>
