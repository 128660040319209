
<div class="modal-dialog modal-dialog-centered modal-lg min-modal" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-database mr-2"></i>
        <span class="font-weight-bold">New account</span>
      </p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="btn mr-2 btn-outline-secondary" v-on:click="startUpload()">
        <i class="fas mr-1 fa-plus-square"></i>
        <span class="small">Import from CSV</span>
        <input ref="upload" class="d-none" type="file" accept=".csv" v-on:change="upload" />
      </div>
      <div class="btn btn-link" v-on:click="downloadSample()">
        <i class="fas small mr-1 fa-file-download"></i>
        <span class="small">Download sample CSV</span>
      </div>
      <table class="mt-3 table table-sm">
        <thead>
          <tr>
            <th>
              <span>Number *</span>
            </th>
            <th>
              <span>Name</span>
            </th>
            <th>
              <span>Start date</span>
            </th>
            <th>
              <span>Initial value</span>
            </th>
            <th class="text-right">
              <i class="fas fa-plus-circle" v-on:click="addRow()"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(account, index) in accounts" :key="index">
            <td>
              <input v-model="account.number" class="form-control form-control-sm" />
            </td>
            <td>
              <input v-model="account.name" class="form-control form-control-sm" />
            </td>
            <td>
              <date-picker :o="account" p="startDate"></date-picker>
            </td>
            <td>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <b class="px-1">$</b>
                  </span>
                </div>
                <input
                  v-model="account.initialValue"
                  class="form-control form-control-sm"
                  type="number"
                />
              </div>
            </td>
            <td class="text-right">
              <i class="fas fa-minus-circle" v-on:click="removeRow(index)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer pr-3">
      <div class="text-right">
        <button type="button" class="btn btn-sm btn-primary submit-button" v-on:click="submit()">
          <span class="button">Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
