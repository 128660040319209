
<div class="root">
  <div>
    <div class="card">
      <div class="card-body">
        <div>
          <img :src="login.logo" />
          <form @submit="login.checkForm">
            <template v-if="login.state == 2">
              <div class="form-group">
                <label>Name</label>
                <input v-model="login.name"
                       class="form-control"
                       placeholder="Name" />
              </div>
            </template>
            <div class="form-group">
              <label>Email</label>
              <input v-model="login.email"
                     class="form-control"
                     data-cy="login-email"
                     placeholder="Email" />
            </div>
            <template v-if="!login.state">
              <div class="form-group">
                <label>Password</label>
                <input v-model="login.pass"
                       type="password"
                       data-cy="login-password"
                       class="form-control"
                       placeholder="Password" />
              </div>
            </template>
            <div class="form-group mb-0">
              <hr style="opacity: 0.4" />
              <button data-cy="login-submit"
                      type="submit"
                      class="btn btn-block btn-custom"
                      :class="login.class(`submit`)"
                      :disabled="login.disabled(`submit`)"
                      v-on:click="login.click()">
                <span v-text="login.text(`submit`)"></span>
              </button>
            </div>
            <template v-if="!login.state">
              <div class="form-row links">
                <div class="col">
                  <p class="mb-0 text-right">
                    <a href="#"
                       class="text-right"
                       v-on:click="login.click(`forgot`)"
                       v-text="login.text(`forgot`)"></a>
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="form-row links">
                <div class="col">
                  <p class="mb-0 text-right">
                    <a href="#"
                       v-on:click="login.click(`cancel`)"
                       v-text="login.text(`cancel`)"></a>
                  </p>
                </div>
              </div>
            </template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
