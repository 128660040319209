
<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title">
        <i class="fas fa-bell mr-2"></i>
        <span class="font-weight-bold" v-text="table.title(`modal`)"></span>
      </p>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-3">
      <div class="form-row p-2">
        <div class="col-3">
          <div class="form-row">
            <div class="col-2 text-right">
              <span class="align-middle">Date</span>
            </div>
            <div class="col">
              <template v-if="period.date">
                <date-picker
                  :o="period"
                  :options="period.options"
                ></date-picker>
              </template>
            </div>
          </div>
        </div>
        <div class="col-6">
          <template v-if="table.mute">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              :title="table.title(`mute`)"
              :disabled="table.disabled(`mute`)"
              v-on:click="table.click(`mute`)"
            >
              <span>Mute</span>
            </button>
          </template>
        </div>
        <div class="col">
          <search :o="table.list"></search>
        </div>
      </div>
      <hr class="mx-2 mt-1 mb-2" />
      <div class="d-flex px-2">
        <div>
          <span class="font-weight-bold">Messages</span>
        </div>
        <div class="records flex-grow-1 text-right">
          <span v-text="table.records"></span>
        </div>
      </div>
      <div class="main">
        <template v-if="table.ready">
          <template v-if="table.items.length">
            <scroll-area class="pl-2 pr-3 pb-3 pt-2">
              <table class="table table-sm">
                <tbody>
                  <tr v-for="(s, i) in table.items" :key="i">
                    <td>
                      <span v-text="s"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </scroll-area>
          </template>
          <template v-else>
            <div class="d-flex flex-column justify-content-center h-100">
              <div class="text-center">
                <span class="none">No Notifications found</span>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <loading></loading>
        </template>
      </div>
    </div>
    <div class="modal-footer px-3">
      <div></div>
      <div class="text-center">
        <span class="text-center alerts" v-text="table.alerts"></span>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          :disabled="table.disabled(`mute`, `all`)"
          :title="table.title(`mute`, `all`)"
          v-on:click="table.click(`mute`, `all`)"
        >
          <span>Mute All</span>
        </button>
      </div>
    </div>
  </div>
</div>
