
<div class="modal-dialog modal-dialog-centered modal-lg"
     role="document">
  <div class="modal-content">
    <div class="modal-header">
      <div class="modal-title">Send Invoice To Clients</div>
      <button type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body p-2">
      <div class="px-4 py-3" >
        <Title>The following email will be sent to this household's contacts:</Title>
        <div class="mt-4 email-body">
          <Template603 v-if="isFirm603"></Template603>
          <TemplateEmbarc v-else-if="isFirmEmbarcaderoCapital"></TemplateEmbarc>
          <DefaultTemplate v-else></DefaultTemplate>
        </div>
      </div>
    </div>

    <div class="modal-footer pr-3">
      <div>
        <button type="button"
                class="btn btn-sm modal-button btn-outline-danger"
                v-on:click="cancel()">
          <span>Cancel</span>
        </button>
        <button type="button"
                class="btn btn-sm modal-button btn-primary ml-2"
                v-on:click="send()">
          <span>Send</span>
        </button>
      </div>
    </div>
  </div>
</div>
